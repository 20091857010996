<template>
  <div class="flex items-center  justify-between xl:px-[9rem]   font-pop  md:px-10  md:py py-4 shadow-md xxs:px-4   xxs:bg-white xxs:shadow-lg z-10">

      <div class="flex items-center">
           <div class=" font-pop font-semibold text-[20px]  text-[#9d5252] mr-3">
             Work<span class=" font-semibold text-[#c94d4d]"> order</span> 
           </div>
           <div class="border-l-2 border-[#B6B6B8] md:flex xxs:hidden">
            <router-link to="/Dashboard">
             <img class="ml-3 w-10 h-10" src="../assets/main-menu.png" alt="">
            </router-link>
           </div>
          

           <div class="flex items-center  gap-4 xl:ml-6  md:ml-4 ">
          <!-- <router-link to="/Inventory"  exact :class="{ 'text-red-500': $route.path === '/Inventory'}" >
            Dashboard
          </router-link> -->

          

          <router-link v-if="permidata.data && permidata.data.includes('Work Order')" to="/WO" exact :class="{ 'text-red-500': $route.path === '/WO'}">
            List Work Order
          </router-link>

          <router-link v-if="permidata.data && permidata.data.includes('Team')" to="/MasterTeam" exact :class="{ 'text-red-500': $route.path === '/MasterTeam'}">
            Team List
          </router-link>

          <router-link to="/Report" exact :class="{ 'text-red-500': $route.path === '/Report'}">
            Report
          </router-link>

         
         </div>

  </div>


           <!-- <div class="  bg-white shadow-lg  text-[20px] mt-10">
             
           </div> -->

         <img
      id="avatarButton"
      type="button"
      @click="toggleModal"
      class="w-12 h-12 p-1 rounded-full ring-2 ring-gray-300 dark:ring-gray-500"
      src="https://pbs.twimg.com/media/GF6JAuVWcAETdhA.jpg"
      alt="User dropdown"
    /> 

      
         </div>

        
       
      

   
        
         
         


<div v-if="isModalOpen" ref="dropdown" class="z-10 absolute mt-[4rem] right-24      bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600">
      <div class="px-4 py-3 text-sm text-gray-900 dark:text-white">
        <div>{{nama}}</div>
        <div class="font-medium truncate">{{email}}</div>
      </div>
      <ul class="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="avatarButton">
        <li >
          <router-link :to="'/Profile/' + this.id" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Profile</router-link>
        </li>
      </ul>
      <div class="py-1">
        <button @click="postlogout" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Sign out</button>
      </div>
    </div>
  
   

   <!-- <div class="xl:flex xxs:hidden flex-row justify-center items-center rounded-full xxs:bg-white md:pt-2 md:pb-2 xl:mt-5 xxs:mt-0 md:w-[131px] md:h-[60px] xxs:w-[87px] xxs:h-[40px]" style="box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;" >
     <img class="md:h-[50px] xxs:h-[27px]" src="../assets/orang.svg" alt="">
     <button @click="toggleModal" data-collapse-toggle="navbar-hamburger" type="button" class="inline-flex items-center justify-center ml-2 text-sm text-gray-500 rounded-lg focus:outline-none" aria-controls="navbar-hamburger" aria-expanded="false">
       <img class="md:h-[34px] xxs:h-[20px]" src="../assets/settings.svg"  alt="">
     </button>

     <div v-if="isModalOpen" class="absolute mt-[12rem] z-50 flex items-end justify-end mr-14">
       <div class="w-[180px] p-4 rounded-lg shadow-md" >
         <ul class="font-pop font-[500] text-[16px]" >
           <li class="mb-2">
             <router-link to="/Profile" class="">Profile</router-link >
           </li>
           <li>
             <router-link  to="/" class="">Keluar</router-link >
           </li>
         </ul>
       </div>
     </div>
   </div> -->

</template>



<style scoped>
.bottom-nav {
color: #fff;
width: 100%;
position: fixed;
top: 0;
left: 0;
justify-content: space-between;
padding: 20px;
z-index: 1;
}

</style>


<script>
import axios from "../token";
export default {
  name: "NavBar",
  data() {
    return {
      nama: localStorage.getItem('name') || 'Default Value',
      email: localStorage.getItem('email') || 'Default Value',
      id:localStorage.getItem('id'),
      permidata:[],
      isDropdownOpen: false,
      isModalOpen: false, // Add this line to define isModalOpen
      avatarSrc: 'https://awsimages.detik.net.id/community/media/visual/2019/02/19/42393387-9c5c-4be4-97b8-49260708719e.jpeg?w=600&q=90',
    };
  },
  methods: {
    toggleModal() {
      this.isModalOpen = !this.isModalOpen;
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    getpermi1() {
      axios
        .get(`${process.env.VUE_APP_BACKEND_HOST}/permission/check`)
        .then((response) => {
          this.permidata = response.data;

        
        })
        .catch((error) => {
          console.log(error);
        });
    },
    postlogout() {
      axios
        .post(`${process.env.VUE_APP_BACKEND_HOST}/logout`)
        .then((response) => {
          this.logout = response.data;
           this.$router.push('/');
           localStorage.removeItem('token');
          localStorage.removeItem('id');
          localStorage.removeItem('name');
          localStorage.removeItem('email');
          localStorage.removeItem('file');
          localStorage.removeItem('id');
          localStorage.removeItem('activeTab');
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {
    isLargeScreen() {
      return window.innerWidth > 480;
    },
   
  },
  mounted() {
    this.token = localStorage.getItem('token')
    if (!this.token) {
      this.$router.push('/404');
    }
    this.getpermi1();
  },
};
</script>