
<template>

  <nav class="flex mb-6 font-pop" aria-label="Breadcrumb" style=" animation: muncul-kiri-kanan-fade 1s;"> 
  <ol class="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
    <li>
      <div class="flex items-center">
       
        <router-link to="/User" class="ms-1 text-sm font-medium text-gray-700 hover:text-red-500 md:ms-2 dark:text-gray-400 dark:hover:text-white">Pengguna</router-link>
      </div>
    </li>
    <li aria-current="page">
      <div class="flex items-center">
        <svg class="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4"/>
        </svg>
        <span class="ms-1 text-sm font-medium text-red-500 md:ms-2 dark:text-gray-400">Detail karyawan</span>
      </div>
    </li>
  </ol>
</nav>
    <div class="space-y-12 bg-white md:mb-0 md:px-10 py-10 xxs:px-4 xxs:mb-16 rounded-xl font-pop" style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;  animation: muncul-kiri-kanan-fade 1s;">

      <div class="flex flex-row justify-between mb-10">

        <div class="flex flex-col">
        
          <div class="flex flex-col w-[30rem]">
          <p class="mt-4">Bio</p>
          <h1 class="mt-2">{{ description }}</h1>
        </div>
       
        </div>
        <div class="flex flex-col items-center ">
         
          <img class=" h-32 rounded-lg shadow-lg object-cover cursor-pointer mb-6" :src="imageUrl" @click="showPreview = true" alt="Employee Photo">
         
          <h2>{{ name}}</h2>
          <h1>{{ email }}</h1>
        </div>

      </div>

    

   <div class="flex flex-col">   
    <div class="grid grid-cols-5 gap-4 text-[12px] font-semibold text-gray-500">
     <div  @click="changeTab('Informasi')" >
      <a
    href="#" class="inline-flex items-center px-4 py-3 rounded-lg  bg-gray-50  w-full" :class="{ 'bg-red-500 text-white': activeTab === 'Informasi'}" 
    aria-current="page" 
  >
  Informasi User
  
  </a></div>
  <div  @click="changeTab('payroll')" >
      <a
    href="#" class="inline-flex items-center px-4 py-3 rounded-lg  bg-gray-50  w-full" :class="{ 'bg-red-500 text-white': activeTab === 'payroll'}" 
    aria-current="page" 
  >
  Informasi Gaji
  
  </a></div>
  <div  @click="changeTab('dokumen')" >
      <a
    href="#" class="inline-flex items-center px-4 py-3 rounded-lg  bg-gray-50  w-full" :class="{ 'bg-red-500 text-white': activeTab === 'dokumen'}" 
    aria-current="page" 
  >
  Dokumen
  
  </a></div>
     <div  @click="changeTab('Jadwal')" >
      <a
    href="#" class="inline-flex items-center px-4 py-3 rounded-lg  bg-gray-50  w-full"  :class="{ 'bg-red-500 text-white': activeTab === 'Jadwal'}"
    aria-current="page" 
  >
  Jadwal Kerja
  </a></div>
     <div  @click="changeTab('Inventaris')" >  <a
    href="#" class="inline-flex items-center px-4 py-3 rounded-lg  bg-gray-50  w-full"  :class="{ 'bg-red-500 text-white': activeTab === 'Inventaris'}"
    aria-current="page" 
  >
  Inventaris
  </a></div>
     <div  @click="changeTab('Riwayat Presensi')" >  <a
    href="#" class="inline-flex items-center px-4 py-3 rounded-lg  bg-gray-50  w-full"  :class="{ 'bg-red-500 text-white': activeTab === 'Riwayat Presensi'}"
    aria-current="page" 
  >
  Riwayat Presensi
  </a></div>
     <div  @click="changeTab('Riwayat Cuti')" >  <a
    href="#" class="inline-flex items-center px-4 py-3 rounded-lg  bg-gray-50  w-full"  :class="{ 'bg-red-500 text-white': activeTab === 'Riwayat Cuti'}"
    aria-current="page" 
  >
  Riwayat Cuti
  </a></div>
  <div  @click="changeTab('Jatah Cuti')" >  <a
    href="#" class="inline-flex items-center px-4 py-3 rounded-lg  bg-gray-50  w-full"  :class="{ 'bg-red-500 text-white': activeTab === 'Jatah Cuti'}"
    aria-current="page" 
  >
   Jatah Cuti
  </a></div>
     <div  @click="changeTab('Riwayat Lembur')" >  <a
    href="#" class="inline-flex items-center px-4 py-3 rounded-lg  bg-gray-50  w-full"  :class="{ 'bg-red-500 text-white': activeTab === 'Riwayat Lembur'}"
    aria-current="page" 
  >
  Riwayat Lembur
  </a></div>
     <div  @click="changeTab('Riwayat Gaji')" >  <a
    href="#" class="inline-flex items-center px-4 py-3 rounded-lg  bg-gray-50  w-full"  :class="{ 'bg-red-500 text-white': activeTab === 'Riwayat Gaji'}"
    aria-current="page" 
  >
  Riwayat Gaji
  </a></div>
     <div  @click="changeTab('Riwayat Pinjaman')" >  <a
    href="#" class="inline-flex items-center px-4 py-3 rounded-lg  bg-gray-50  w-full"  :class="{ 'bg-red-500 text-white': activeTab === 'Riwayat Pinjaman'}"
    aria-current="page" 
  >
  Riwayat Pinjaman
  </a></div> 
  <div  @click="changeTab('Pembayaran Pinjaman')" >  <a
    href="#" class="inline-flex items-center px-4 py-3 rounded-lg  bg-gray-50  w-full"  :class="{ 'bg-red-500 text-white': activeTab === 'Riwayat Pinjaman'}"
    aria-current="page" 
  >
   Pembayaran Pinjaman
  </a></div>
</div>
</div>
</div>

<div class="space-y-12 bg-white md:mb-0 md:px-10 py-10 xxs:px-4 xxs:mb-16 rounded-xl mt-8 font-pop" style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;  animation: muncul-kiri-kanan-fade 1s;">
<template v-if="activeTab === 'Informasi'" >

  <div class="flex justify-between" style=" animation: muncul-kiri-kanan-fade 0.8s;">
<h2>Informasi Data Karyawan</h2>
<router-link :to="'/EditUser/' + this.$route.params.id "
      class="w-[142px] h-[30px] p-1 mr-4 rounded text-white bg-red-500"
     
    >
      <div class="flex flex-row justify-center">
        <img src="../../assets/add.png" class="mr-1 w-5" alt="" />
        <h3 class="text-[12px] font-medium">Ubah Data</h3>
      </div>
    </router-link>
</div>
<div class="mt-10 grid xxs:grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-6 " style=" animation: muncul-kiri-kanan-fade 0.8s;">

          
<div class="sm:col-span-2">
  <label for="first-name" class="block text-sm font-medium leading-6 text-gray-900">Nomor Induk Karyawan</label>
  <div class="mt-2">
    <input v-model="nik" type="text" name="first-name" id="first-name" autocomplete="given-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled />
  </div>
</div>

<div class="sm:col-span-2">
  <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Email</label>
  <div class="mt-2">
    <input  v-model="email" type="email" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled />
  </div>
</div>



<div class="sm:col-span-2">
  <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Nama Lengkap</label>
  <div class="mt-2">
    <input v-model="name" type="text" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled />
  </div>
</div>



<div class="sm:col-span-1">
  <label for="country" class="block text-sm font-medium leading-6 text-gray-900">Jenis Kelamin</label>
  <div class="mt-2">
    <select v-model="gender" id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6" disabled>
      <option value="" disabled selected>Jenis Kelamin</option>
      <option value="L">Laki - Laki</option>
      <option value="P">Perempuan</option>
    </select>
  </div>
</div>

<div class="sm:col-span-0">
  <label for="region" class="block text-sm font-medium leading-6 text-gray-900">Tempat</label>
  <div class="mt-2">
    <input v-model="birthPlace" type="text" name="region" id="region" autocomplete="address-level1" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"  disabled/>
  </div>
</div>

<div class="sm:col-span-1">
  <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Tanggal Lahir</label>
  <div class="mt-2">
    <input v-model="birthDate" type="date" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled />
  </div>
</div>

<div class="sm:col-span-2">
  <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Alamat Lengkap</label>
  <div class="mt-2">
    <input v-model="address" type="text" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"  disabled/>
  </div>
</div>

<div class="sm:col-span-1">
  <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">No HP</label>
  <div class="mt-2">
    <input  v-model="phone" type="text" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
  </div>
</div>

<div class="sm:col-span-1">
  <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">No KTP</label>
  <div class="mt-2">
    <input v-model="ktp" type="text" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled />
  </div>
</div>

<div class="sm:col-span-1">
  <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">No SIM</label>
  <div class="mt-2">
    <input v-model="sim" type="text" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled />
  </div>
</div>

<div class="sm:col-span-1">
  <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">No Paspor</label>
  <div class="mt-2">
    <input v-model="passport" type="text" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled />
  </div>
</div>

<div class="sm:col-span-1">
  <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Nama Bank</label>
  <div class="mt-2">
    <input v-model="bank_name" type="text" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled />
  </div>
</div>

<div class="sm:col-span-1">
  <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">No Rek. Bank</label>
  <div class="mt-2">
    <input v-model="bank_account_number" type="text" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled />
  </div>
</div>


<div class="sm:col-span-1">
  <label for="country" class="block text-sm font-medium leading-6 text-gray-900">Agama</label>
  <div class="mt-2">
    <select v-model="religion" id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6" disabled>
      <option value="" disabled selected>Pilih Agama</option>
      <option>Islam</option>
      <option>Kristen</option>
      <option>Buddha</option>
    </select>
  </div>
</div>

<div class="sm:col-span-1">
  <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Golongan Darah</label>
  <div class="mt-2">
    <select v-model="bloodType" id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6" disabled>
      <option value="" disabled selected>Pilih Golongan Darah</option>
      <option>A</option>
      <option>O</option>
      <option>AB</option>
    </select>
  </div>
</div>

<div class="sm:col-span-1">
  <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Status Kawin</label>
  <div class="mt-2">
    <select v-model="maritalStatus" id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6" disabled>
      <option value="" disabled selected>Pilih Status Kawin</option>
      <option>Kawin</option>
      <option>Duda</option>
      <option>Janda</option>
    </select>
  </div>
</div>

<div class="sm:col-span-1">
  <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Jumlah Anak</label>
  <div class="mt-2">
    <input v-model="children" type="text" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled />
  </div>
</div>

<div class="sm:col-span-1">
  <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">No. BPJS</label>
  <div class="mt-2">
    <input v-model="bpjsHealth" type="text" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled />
  </div>
</div>

<div class="sm:col-span-1">
  <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Status Karyawan</label>
  <div class="mt-2">
    <select v-model="employeeStatus" id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6" disabled>
      <option value="" disabled selected>Pilih Status Karyawan</option>
      <option value="1">Aktif</option>
      <option value="0">TIdak</option>
    </select>
  </div>
</div>



<div class="sm:col-span-1">
  <label for="country" class="block text-sm font-medium leading-6 text-gray-900">Departement</label>
  <div class="mt-2">
    <select   v-model="department" id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6" disabled>
      <option value="" disabled selected>Pilih Departement</option>
      <option
        v-for="categoory in projectdata.data"
        :key="categoory.id"
        :value="categoory.id"
>
  {{ categoory.name }}
</option>
    </select>
  </div>
</div>

<div class="sm:col-span-1">
  <label for="country" class="block text-sm font-medium leading-6 text-gray-900">Jabatan</label>
  <div class="mt-2">
    <select   v-model="position" id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6" disabled>
      <option value="" disabled selected>Pilih Jabatan</option>
      <option
        v-for="project in positiondata.data"
        :key="project.id"
        :value="project.id"
>
  {{ project.name }}
</option>
    </select>
  </div>
</div>

<div class="sm:col-span-1">
  <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Tanggal Gabung</label>
  <div class="mt-2">
    <input v-model="joinDate" type="date" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled />
  </div>
</div>

<div class="sm:col-span-1">
  <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Tanggal Pengakatan</label>
  <div class="mt-2">
    <input v-model="appointmentDate" type="date" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled />
  </div>
</div>

<div class="sm:col-span-1">
  <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Tanggal Berhenti</label>
  <div class="mt-2">
    <input v-model="resignDate" type="date" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled />
  </div>
</div> 




<div class="sm:col-span-1">
  <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Jenis Ikatan Kerja</label>
  <div class="mt-2">
    <select v-model="contractType" id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6" disabled>
      <option value="" disabled selected>Pilih Ikatan Kerja</option>
      <option value="tetap">Karyawan Tetap</option>
      <option value="kontrak">Kontrak</option>
    </select>
  </div>
</div>



<div class="sm:col-span-1">
  <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Mulai Kontrak</label>
  <div class="mt-2">
    <input v-model="contractStart" type="date" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled />
  </div>
</div>

<div class="sm:col-span-1">
  <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Selesai Kontrak</label>
  <div class="mt-2">
    <input v-model="contractEnd" type="date" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled />
  </div>
</div>


 <!-- <div class="col-span-full">
  <label for="about" class="block text-sm font-medium leading-6 text-gray-900">Bio Karyawan</label>
  <div class="mt-2">
    <textarea id="about" name="about" rows="3" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled />
  </div>
  
</div>  -->
</div>

</template>

<template v-if="activeTab === 'Jadwal'">

  <div class="md:flex md:flex-row md:justify-between xxs:hidden" style=" animation: muncul-kiri-kanan-fade 0.8s;">
    <div class="relative text-gray-600">
  <input type="search" name="serch" placeholder="Pencarian..." class="bg-[#FAFBFD] h-10 px-5 pr-24 rounded text-sm focus:outline-none">
  <button type="submit" class="absolute right-0 top-0 mt-3 mr-4">
    <svg class="h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 56.966 56.966" style="enable-background:new 0 0 56.966 56.966;" xml:space="preserve" width="512px" height="512px">
      <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z"/>
    </svg>
  </button>
</div>


<div class="" style=" animation: muncul-kiri-kanan-fade 0.8s;">
  <transition name="fade-in">
 
  <button
      class="w-[142px] h-[30px] p-1 mr-4 rounded text-white bg-red-500"
      @click="open = true"
    >
      <div class="flex flex-row justify-center">
        <img src="../../assets/add.png" class="mr-1 w-5" alt="" />
        <h3 class="text-[12px] font-medium">Tambah Shift</h3>
      </div>
    </button>


</transition>
</div>
</div>
   

  <transition>
    <div
      class="fixed z-10 left-0 top-0 w-full h-full flex items-center justify-center overflow-auto bg-black/40 bg-opacity-50 font-pop font-regular"
      v-if="open"
    >
   
      <div class="bg-white px-16 py-4 rounded-lg shadow-lg">
        <div class="w-full">
          <h1 class="text-[20px] mb-4">Tambah Shift</h1>
          <hr class="mb-4" />

          <div class="sm:col-span-1">
            <label for="country" class="block text-sm font-medium leading-6 text-gray-900">Senin</label>
            <div class="mt-2">
              <select   v-model="Senin" id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6" >
                <option value="" disabled selected>Pilih Shift</option>
                <option
                  v-for="project in categorydata.data"
                  :key="project.id"
                  :value="project.id"
          >
            {{ project.name }}
          </option>
              </select>
            </div>
          </div>

                  <div class="sm:col-span-1">
          <label for="country" class="block text-sm font-medium leading-6 text-gray-900">Selasa</label>
          <div class="mt-2">
            <select   v-model="Selasa" id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6" >
              <option value="" disabled selected>Pilih Shift</option>
              <option
                v-for="project in categorydata.data"
                :key="project.id"
                :value="project.id"
        >
          {{ project.name }}
        </option>
            </select>
          </div>
        </div>

        <div class="sm:col-span-1">
          <label for="country" class="block text-sm font-medium leading-6 text-gray-900">Rabu</label>
          <div class="mt-2">
            <select   v-model="Rabu" id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6" >
              <option value="" disabled selected>Pilih Shift</option>
              <option
                v-for="project in categorydata.data"
                :key="project.id"
                :value="project.id"
        >
          {{ project.name }}
        </option>
            </select>
          </div>
        </div>

        <div class="sm:col-span-1">
          <label for="country" class="block text-sm font-medium leading-6 text-gray-900">Kamis</label>
          <div class="mt-2">
            <select   v-model="Kamis" id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6" >
              <option value="" disabled selected>Pilih Shift</option>
              <option
                v-for="project in categorydata.data"
                :key="project.id"
                :value="project.id"
        >
          {{ project.name }}
        </option>
            </select>
          </div>
        </div>

                  <div class="sm:col-span-1">
            <label for="country" class="block text-sm font-medium leading-6 text-gray-900">Jumat</label>
            <div class="mt-2">
              <select   v-model="Jumat" id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6" >
                <option value="" disabled selected>Pilih Shift</option>
                <option
                  v-for="project in categorydata.data"
                  :key="project.id"
                  :value="project.id"
          >
            {{ project.name }}
          </option>
              </select>
            </div>
          </div>

          <div class="sm:col-span-1">
            <label for="country" class="block text-sm font-medium leading-6 text-gray-900">Sabtu</label>
            <div class="mt-2">
              <select   v-model="Sabtu" id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6" >
                <option value="" disabled selected>Pilih Shift</option>
                <option
                  v-for="project in categorydata.data"
                  :key="project.id"
                  :value="project.id"
          >
            {{ project.name }}
          </option>
              </select>
            </div>
          </div>

          <div class="sm:col-span-1">
            <label for="country" class="block text-sm font-medium leading-6 text-gray-900">Minggu</label>
            <div class="mt-2">
              <select   v-model="Minggu" id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6" >
                <option value="" disabled selected>Pilih Shift</option>
                <option
                  v-for="project in categorydata.data"
                  :key="project.id"
                  :value="project.id"
          >
            {{ project.name }}
          </option>
              </select>
            </div>
          </div>

          <div class="flex justify-end">
            <button
              class="mt-8 bg-red-500 px-5 py-3 rounded-md"
              @click="open = false"
            >
              <h1 class="text-[12px] text-white">Kembali</h1>
            </button>
            <form @submit.prevent="submitForm">
            <button
              class="ml-4 mt-8 bg-[#A6CF98] px-[3rem] py-3 rounded-md"
               type="submit"
            >
              <h1 class="text-[12px] text-white">Simpan</h1>
            </button>
          </form>
          </div>
        </div>
      </div>

    </div>
  </transition>

  <transition>
    <div
      class="fixed z-10 left-0 top-0 w-full h-full flex items-center justify-center overflow-auto bg-black/40 bg-opacity-50 font-pop font-regular"
      v-if="openshift"
    >
   
      <div class="bg-white px-16 py-4 rounded-lg shadow-lg">
        <div class="w-full">
          <h1 class="text-[20px] mb-4">Tambah Shift</h1>
          <hr class="mb-4" />

          <div class="sm:col-span-1">
            <label for="country" class="block text-sm font-medium leading-6 text-gray-900">Senin</label>
            <div class="mt-2">
              <select   v-model="Senin" id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6" >
                <option value="" disabled selected>Pilih Jabatan</option>
                <option
                  v-for="project in categorydata.data"
                  :key="project.id"
                  :value="project.id"
          >
            {{ project.name }}
          </option>
              </select>
            </div>
          </div>

                  <div class="sm:col-span-1">
          <label for="country" class="block text-sm font-medium leading-6 text-gray-900">Selasa</label>
          <div class="mt-2">
            <select   v-model="Selasa" id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6" >
              <option value="" disabled selected>Pilih Jabatan</option>
              <option
                v-for="project in categorydata.data"
                :key="project.id"
                :value="project.id"
        >
          {{ project.name }}
        </option>
            </select>
          </div>
        </div>

        <div class="sm:col-span-1">
          <label for="country" class="block text-sm font-medium leading-6 text-gray-900">Rabu</label>
          <div class="mt-2">
            <select   v-model="Rabu" id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6" >
              <option value="" disabled selected>Pilih Jabatan</option>
              <option
                v-for="project in categorydata.data"
                :key="project.id"
                :value="project.id"
        >
          {{ project.name }}
        </option>
            </select>
          </div>
        </div>

        <div class="sm:col-span-1">
          <label for="country" class="block text-sm font-medium leading-6 text-gray-900">Kamis</label>
          <div class="mt-2">
            <select   v-model="Kamis" id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6" >
              <option value="" disabled selected>Pilih Jabatan</option>
              <option
                v-for="project in categorydata.data"
                :key="project.id"
                :value="project.id"
        >
          {{ project.name }}
        </option>
            </select>
          </div>
        </div>

                  <div class="sm:col-span-1">
            <label for="country" class="block text-sm font-medium leading-6 text-gray-900">Jumat</label>
            <div class="mt-2">
              <select   v-model="Jumat" id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6" >
                <option value="" disabled selected>Pilih Jabatan</option>
                <option
                  v-for="project in categorydata.data"
                  :key="project.id"
                  :value="project.id"
          >
            {{ project.name }}
          </option>
              </select>
            </div>
          </div>

          <div class="sm:col-span-1">
            <label for="country" class="block text-sm font-medium leading-6 text-gray-900">Sabtu</label>
            <div class="mt-2">
              <select   v-model="Sabtu" id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6" >
                <option value="" disabled selected>Pilih Jabatan</option>
                <option
                  v-for="project in categorydata.data"
                  :key="project.id"
                  :value="project.id"
          >
            {{ project.name }}
          </option>
              </select>
            </div>
          </div>

          <div class="sm:col-span-1">
            <label for="country" class="block text-sm font-medium leading-6 text-gray-900">Minggu</label>
            <div class="mt-2">
              <select   v-model="Minggu" id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6" >
                <option value="" disabled selected>Pilih Jabatan</option>
                <option
                  v-for="project in categorydata.data"
                  :key="project.id"
                  :value="project.id"
          >
            {{ project.name }}
          </option>
              </select>
            </div>
          </div>

          <div class="flex justify-end">
            <button
              class="mt-8 bg-red-500 px-5 py-3 rounded-md"
              @click="openshift = false"
            >
              <h1 class="text-[12px] text-white">Kembali</h1>
            </button>
            <form @submit.prevent="submitUpdateShift">
            <button
              class="ml-4 mt-8 bg-[#A6CF98] px-[3rem] py-3 rounded-md"
               type="submit"
            >
              <h1 class="text-[12px] text-white">Simpan</h1>
            </button>
          </form>
          </div>
        </div>
      </div>

    </div>
  </transition>

  <div class="grid grid-cols-2 gap-4">
  <div  class="  bg-white  rounded overflow-hidden cursor-pointer px-6 "   v-for="project in daydata"
                  :key="project.id"  style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;  animation: muncul-kiri-kanan-fade 1.5s;">
     
         <div class="flex flex-col">
          <h2 class="text-[12px] font-semibold mb-2">{{ project.day}}</h2>
          <h2 class="text-[12px] font-semibold mb-2">{{ project.shift}}</h2>       
       </div>
      </div>

     </div>
</template>

<template v-if="activeTab === 'payroll'">

  <div class="flex justify-between">
            <h1 class="font-pop  ">Informasi Gaji</h1>
            <router-link :to="'/EditUser/' + this.$route.params.id ">
            <button
      class="w-[10rem] h-[30px] p-1 mr-4 rounded text-white bg-red-500"
      @click="payroll = true"
    >
      <div class="flex flex-row justify-center">
        <img src="../../assets/add.png" class="mr-1 w-5" alt="" />
        <h3 class="text-[12px] font-medium">Ubah Data</h3>
      </div>
    </button>
  </router-link>
          </div>

          <transition>
    <div
      class="fixed left-0 top-0 w-full h-full flex items-center justify-center overflow-auto bg-black/40 bg-opacity-50 font-pop font-regular"
      v-if="payroll"
    >
   
      <div class="bg-white px-16 py-4 rounded-lg shadow-lg">
        <div class="w-full">
          <h1 class="text-[20px] mb-4">Ubah Data</h1>
          <hr class="mb-4" />

   
 
 <div class="mt-10 grid xxs:grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-6">

 
   <div class="sm:col-span-2">
   <label for="project" class="block text-sm font-medium leading-6 text-gray-900">Status</label>
   <div class="mt-2">
     <select v-model="status"  id="project" name="project" autocomplete="project-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled>
       <option value="" disabled selected>Pilih Status</option>
       <option   value="TK/0">TK/0</option>
       <option   value="TK/1">TK/1</option>
       <option   value="TK/2">TK/2</option>
       <option   value="TK/3">TK/3</option>
       <option   value="K/0">K/0</option>
       <option  value="K/1">K/1</option>
       <option  value="K/2">K/2</option>
       <option  value="K/3">K/3</option>
     </select>
   </div>
 </div>

    <div class="sm:col-span-2">
     <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Gaji Pokok</label>
     <div class="mt-2">
       <input  v-model="gajipokok" type="text" @input="handleInput($event, 'gajipokok')" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled />
     </div>
   </div>

   

   <div class="sm:col-span-2">
     <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">T.Jabatan</label>
     <div class="mt-2">
       <input v-model="Jabatan" type="text" @input="handleInput($event, 'Jabatan')" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled />
     </div>
   </div>

   <div class="sm:col-span-2">
     <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">T.Transportasi & Telepon</label>
     <div class="mt-2">
       <input v-model="Transportasi" type="text" @input="handleInput($event, 'Transportasi')" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled />
     </div>
   </div>

   <div class="sm:col-span-2">
     <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">T.Makan</label>
     <div class="mt-2">
       <input v-model="Makan" type="text" @input="handleInput($event, 'Makan')"  name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled />
     </div>
   </div>

   <div class="sm:col-span-2">
     <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Pokok untuk Hitung BPJS</label>
     <div class="mt-2">
       <input v-model="PokokBPJS" type="text" @input="handleInput($event, 'PokokBPJS')"  name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled />
     </div>
   </div>
 
   </div>
   </div>


          <div class="flex justify-end">
            <button
              class="mt-8 bg-red-500 px-5 py-3 rounded-md"
              @click="payroll = false"
            >
              <h1 class="text-[12px] text-white">Kembali</h1>
            </button>
            <form @submit.prevent="submitUpdateShift">
            <button
              class="ml-4 mt-8 bg-[#A6CF98] px-[3rem] py-3 rounded-md"
               type="submit"
            >
              <h1 class="text-[12px] text-white">Simpan</h1>
            </button>
          </form>
          </div>
        </div>
    

    </div>
  </transition>


<div class="border-b border-gray-900/10 pb-12 mb-6">
 
          <div class="mt-10 grid xxs:grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-6">

          
            <div class="sm:col-span-2">
            <label for="project" class="block text-sm font-medium leading-6 text-gray-900">Status</label>
            <div class="mt-2">
              <select v-model="status"  id="project" name="project" autocomplete="project-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled>
                <option value="" disabled selected>Pilih Status</option>
                <option   value="TK/0">TK/0</option>
                <option   value="TK/1">TK/1</option>
                <option   value="TK/2">TK/2</option>
                <option   value="TK/3">TK/3</option>
                <option   value="K/0">K/0</option>
                <option  value="K/1">K/1</option>
                <option  value="K/2">K/2</option>
                <option  value="K/3">K/3</option>
              </select>
            </div>
          </div>

             <div class="sm:col-span-2">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Gaji Pokok</label>
              <div class="mt-2">
                <input  v-model="gajipokok" type="text" @input="handleInput($event, 'gajipokok')" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled />
              </div>
            </div>

            

            <div class="sm:col-span-2">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">T.Jabatan</label>
              <div class="mt-2">
                <input v-model="Jabatan" type="text" @input="handleInput($event, 'Jabatan')" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled />
              </div>
            </div>

            <div class="sm:col-span-2">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">T.Transportasi & Telepon</label>
              <div class="mt-2">
                <input v-model="Transportasi" type="text" @input="handleInput($event, 'Transportasi')" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled />
              </div>
            </div>

            <div class="sm:col-span-2">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">T.Makan</label>
              <div class="mt-2">
                <input v-model="Makan" type="text" @input="handleInput($event, 'Makan')"  name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled />
              </div>
            </div>

            <div class="sm:col-span-2">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Pokok untuk Hitung BPJS</label>
              <div class="mt-2">
                <input v-model="PokokBPJS" type="text" @input="handleInput($event, 'PokokBPJS')"  name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled />
              </div>
            </div>
          
            </div>
            </div>



</template>

<template v-if="activeTab === 'dokumen'">

<div class="flex justify-between">
          <h1 class="font-pop  ">Dokumen User</h1>
          <router-link :to="'/EditUser/' + this.$route.params.id ">
          <button
    class="w-[10rem] h-[30px] p-1 mr-4 rounded text-white bg-red-500"
    @click="dokumen = true"
  >
    <div class="flex flex-row justify-center">
      <img src="../../assets/add.png" class="mr-1 w-5" alt="" />
      <h3 class="text-[12px] font-medium">Ubah Dokumen</h3>
    </div>
  </button>
</router-link>
        </div>

        <transition>
  <div
    class="fixed  left-0 top-0 w-full h-full flex items-center justify-center overflow-auto bg-black/40 bg-opacity-50 font-pop font-regular"
    v-if="dokumen"
  >
 
    <div class="bg-white px-16 py-4 rounded-lg shadow-lg">
      <div class="w-[40rem]">
        <h1 class="text-[20px] mb-4">Tambah Dokumen</h1>
        <hr class="mb-4" />

 

<div class="mt-10 grid xxs:grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-6">


  <div class="sm:col-span-full">
    <label for="qty" class="block text-sm font-medium leading-6 text-gray-900">Upload File</label>
    <div class="">
      <input
        ref="berkas"
        id="berkas"
        @change="handleFileChange"
        type="file"
        multiple
        autocomplete="qty"
        class="block w-full rounded-md border-0  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
      />
    </div>

 </div>
 </div>
 </div>


<div v-if="selectedFiles" class="border-dashed border-2  border-gray-400 rounded-lg grid grid-cols-3   p-2 mt-4 gap-3">
  <div v-for="(file, index) in selectedFiles" :key="index">
    <img :src="file.url" :alt="file.name" class="rounded-lg shadow-lg w-44 h-44" />
    <button @click="removeFile(index)" class="flex justify-center items-center mt-2 px-3 py-1 bg-red-500 text-white rounded-lg">Hapus</button>
  </div>
</div> 



        <div class="flex justify-end">
          <button
            class="mt-8 bg-red-500 px-5 py-3 rounded-md"
            @click="dokumen = false"
          >
            <h1 class="text-[12px] text-white">Kembali</h1>
          </button>
          <form @submit.prevent="submitUpdateShift">
          <button
            class="ml-4 mt-8 bg-[#A6CF98] px-[3rem] py-3 rounded-md"
             type="submit"
          >
            <h1 class="text-[12px] text-white">Simpan</h1>
          </button>
        </form>
        </div>
      </div>
  

  </div>
</transition>

<div v-if="imageUrls" class="border-dashed border-2 border-gray-400 rounded-lg grid grid-cols-3 px-6 py-6 mt-6 gap-3">
  <div v-for="(imageUrl, index) in imageUrls" :key="index">
    <img class="rounded-lg shadow-lg w-56 h-56 object-cover cursor-pointer" :src="imageUrl" @click="openPreview(imageUrl)" alt="Employee Photo">
  </div>
</div>




</template>

<template v-if="activeTab === 'Inventaris'">

  <div class="md:flex md:flex-row md:justify-between xxs:hidden" style=" animation: muncul-kiri-kanan-fade 0.8s;">
    <div class="relative text-gray-600">
  <input type="search" name="serch" placeholder="Pencarian..." class="bg-[#FAFBFD] h-10 px-5 pr-24 rounded text-sm focus:outline-none">
  <button type="submit" class="absolute right-0 top-0 mt-3 mr-4">
    <svg class="h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 56.966 56.966" style="enable-background:new 0 0 56.966 56.966;" xml:space="preserve" width="512px" height="512px">
      <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z"/>
    </svg>
  </button>
</div>


<div class="" style=" animation: muncul-kiri-kanan-fade 0.8s;">
  <transition name="fade-in">
 
  <button
      class="w-full h-[30px] p-1 mr-4 rounded text-white bg-red-500"
      @click="open = true"
    >
      <div class="flex flex-row justify-center">
        <img src="../../assets/add.png" class="mr-1 w-5" alt="" />
        <h3 class="text-[12px] font-medium">Tambah Inventaris</h3>
      </div>
    </button>


</transition>
</div>
</div>

  <transition>
      <div
        class="fixed  left-0 top-0 w-full h-full flex items-center justify-center overflow-auto bg-black/40 bg-opacity-50 font-pop font-regular"
        v-if="open"
      >
     
        <div class="bg-white px-16 py-4 rounded-lg shadow-lg">
          <div class="w-full">
            <h1 class="text-[24px] mb-4">Tambah Inventaris</h1>
            <hr class="mb-4" />
            <div class="flex flex-row justify-between">
              <div class="mt-2 grid xxs:grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-6">
              <div class="sm:col-span-full">
                <label for="first-name" class="block text-sm font-medium leading-6 text-gray-900">Nama Item</label>
                <div class="mt-2">
                  <input    v-model="item_name" type="text" name="first-name" id="first-name" autocomplete="given-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                </div>
              </div>
    
              <!-- <div class="sm:col-span-3">
                <label for="country" class="block text-sm font-medium leading-6 text-gray-900">Nama Manager</label>
                <div class="mt-2">
                  <select   v-model="pm" id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                    <option value="" disabled selected>Pilih Manager</option>
                    <option
                      v-for="data1 in userdata.data"
                      :key="data1.id"
                      :value="data1.id"
              >
                {{ data1.user.name }}
              </option>
                  </select>
                </div>
              </div>
  
              <div class="sm:col-span-2">
                <label for="country" class="block text-sm font-medium leading-6 text-gray-900">Kategory Project</label>
                <div class="mt-2">
                  <select   v-model="category" id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                    <option value="" disabled selected>Kategory Kapal</option>
                    <option
                      v-for="categoory in categorydata.data"
                      :key="categoory.id"
                      :value="categoory.id"
              >
                {{ categoory.name }}
              </option>
                  </select>
                </div>
              </div>
   -->
              <div class="sm:col-span-3">
                <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Tanggal Mulai</label>
                <div class="mt-2">
                  <input    v-model="date1" type="date" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                </div>
              </div>
  
              <div class="sm:col-span-3">
                <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Tanggal Dikembalikan</label>
                <div class="mt-2">
                  <input   v-model="date2" type="date" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                </div>
              </div>
  
              <div class="sm:col-span-full ">
            <label for="message" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Keterangan</label>
            <div class="mt-2">
            <textarea id="message" v-model="description4" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Tambahkan Keterangan Jika Perlu..."></textarea>
            </div>
            </div>
                
              </div>
            </div>
            <div class="flex justify-end">
              <button
                class="mt-8 bg-red-500 px-5 py-3 rounded-md"
                @click="open = false"
              >
                <h1 class="text-[12px] text-white">Kembali</h1>
              </button>
              <form @submit.prevent="submitForm1">
              <button
                class="ml-4 mt-8 bg-[#A6CF98] px-[3rem] py-3 rounded-md"
                 type="submit"
              >
                <h1 class="text-[12px] text-white">Simpan</h1>
              </button>
            </form>
            </div>
          </div>
        </div>
    
      </div>
    </transition>


    <transition>
      <div
        class="fixed  left-0 top-0 w-full h-full flex items-center justify-center overflow-auto bg-black/40 bg-opacity-50 font-pop font-regular"
        v-if="open4"
      >
     
        <div class="bg-white px-16 py-4 rounded-lg shadow-lg">
          <div class="w-full">
            <h1 class="text-[24px] mb-4">Ubah Inventaris</h1>
            <hr class="mb-4" />
            <div class="flex flex-row justify-between">
              <div class="mt-2 grid xxs:grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-6">
              <div class="sm:col-span-full">
                <label for="first-name" class="block text-sm font-medium leading-6 text-gray-900">Nama Item</label>
                <div class="mt-2">
                  <input    v-model="item_name" type="text" name="first-name" id="first-name" autocomplete="given-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                </div>
              </div>
    
          
              <div class="sm:col-span-3">
                <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Tanggal Mulai</label>
                <div class="mt-2">
                  <input    v-model="date1" type="date" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                </div>
              </div>
  
              <div class="sm:col-span-3">
                <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Tanggal Dikembalikan</label>
                <div class="mt-2">
                  <input   v-model="date2" type="date" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                </div>
              </div>
  
              <div class="sm:col-span-full ">
            <label for="message" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Keterangan</label>
            <div class="mt-2">
            <textarea id="message" v-model="description4" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Tambahkan Keterangan Jika Perlu..."></textarea>
            </div>
            </div>
                
              </div>
            </div>
            <div class="flex justify-end">
              <button
                class="mt-8 bg-red-500 px-5 py-3 rounded-md"
                @click="closeinve()"
              >
                <h1 class="text-[12px] text-white">Kembali</h1>
              </button>
              <form @submit.prevent="submitUpdateInve">
              <button
                class="ml-4 mt-8 bg-[#A6CF98] px-[3rem] py-3 rounded-md"
                 type="submit"
              >
                <h1 class="text-[12px] text-white">Simpan</h1>
              </button>
            </form>
            </div>
          </div>
        </div>
    
      </div>
    </transition>

  <div class="  first-letter:relative overflow-x-auto border sm:rounded-lg bg-white shadow-lg font-pop" style=" animation: muncul-kiri-kanan-fade 0.8s;">
     
     <table class="min-w-full text-sm text-left text-gray-500 ] dark:text-gray-400">
         <thead class="text-xs text-gray-700 border-b bg-[#64707628]  dark:bg-gray-700 dark:text-gray-400 text-[14px]">
             <tr class="text-[#868686]">
                 <th scope="col" class="px-6 py-3  font-medium">
                     No
                 </th>
                 <th scope="col" class="px-6 py-3  font-medium">
                    Nama Barang
                 </th>
                
                 <th scope="col" class="px-6 py-3">
                     <div class="flex items-center  font-medium">
                        Jumlah
                     </div>
                 </th>
                 <th scope="col" class="px-6 py-3">
                     <div class="flex items-center  font-medium">
                        Tgl Terima
                     </div>
                 </th>
                 <th scope="col" class="px-6 py-3">
                     <div class="flex items-center  font-medium">
                         Tgl Kembalikan
                     </div>
                 </th>
                 <th scope="col" class="px-6 py-3">
                     <div class="flex items-center  font-medium">
                         User Input
                     </div>
                 </th>
                 <th scope="col" class="px-6 py-3">
                     <div class="flex items-center  font-medium">
                         User Update
                     </div>
                 </th>
                 <th scope="col" class="px-6 py-3">
                     <div class="flex items-center  font-medium">
                      Aksi
                     </div>
                 </th>
             </tr>
         </thead>
         <tbody>
             <tr class=" border-b dark:bg-gray-800 dark:border-gray-700 text-[16px] font-medium text-black" v-for="(entry, index) in invedata" :key="entry.id">
                 <td class="px-6 py-4">
                     {{index+1}}
                  </td>
                 <th scope="row" class="flex flex-row items-center px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">

                     <h1 class="">{{entry.item_name}}</h1>
                 </th>
               
                 <td class="px-6 py-4">
                    1
                 </td>
                 <td class="px-6 py-4">
                    {{ entry.borrowed_date  }}
                 </td>
                 <td class="px-6 py-4">
                  {{ entry.returned_date  }}
                 </td>
                 <td class="px-6 py-4">
                   {{  entry.created_by }}
                 </td>
                 <td class=" py-4">
                         
                             <h3 class="text-[12px] font-medium">{{ entry.updated_by }}</h3>
                           
                 </td>
                 <td class="px-6 py-4">
                    <button @click="getinve(entry)"  class=" hover:underline hover:text-red-500">Ubah</button>
                     
             </td>
             </tr>
            
         </tbody>
     </table>
 </div>
</template>

<template v-if="activeTab === 'Riwayat Presensi'">
  <div class="flex flex-row gap-4 items-center justify-end" style=" animation: muncul-kiri-kanan-fade 0.8s;">
    <label for="month">Bulan:</label>
    <select class="block w-[10rem] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" v-model="selectedMonth" id="month">
      <option v-for="(month, index) in months" :key="index" :value="index + 1">{{ month }}</option>
    </select>

    <label for="year">Tahun:</label>
    <select class="block  w-[10rem] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" v-model="selectedYear" id="year">
      <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
    </select>

    
    <select class="block  w-[10rem] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" v-model="kehadiran" id="year">
      <option  value="" selected>Semua</option>
      <option  value="to">Hadir</option>
      <option  value="nti">Tidak Hadir</option>
      
    </select>

    <!-- Add a button or trigger an action to apply the filter -->
    <button class="rounded-md bg-green-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" @click="applyFilter">Terapkan Filter</button>
  </div>

  <div
    class="first-letter:relative overflow-x-auto border sm:rounded-lg bg-white shadow-lg font-pop"
    style=" animation: muncul-kiri-kanan-fade 0.8s;" >
    <table
      class="min-w-full text-sm text-left text-gray-500 ] dark:text-gray-400"
    >
      <thead
        class="text-xs text-gray-700 border-b bg-[#64707628] dark:bg-gray-700 dark:text-gray-400 text-[14px]"
      >
        <tr class="text-[#868686]">
        <th scope="col" class="px-2 py-3 font-medium">No</th>
          <th scope="col" class="px-6 py-3 font-medium">Tanggal</th>
          <th scope="col" class="px-14 py-3">
            <div class="flex items-center font-medium text-center">Jam</div>
          </th>
          <th scope="col" class="px-6 py-3">
            <div class="flex items-center font-medium">Total Jam kerja</div>
          </th>
          <th scope="col" class="px-6 py-3">
            <div class="flex items-center font-medium">Absen</div>
          </th>
          <th scope="col" class="px-6 py-3">
            <div class="flex items-center font-medium">Hari kerja</div>
          </th>
          <th scope="col" class="px-6 py-3">
            <div class="flex items-center font-medium">Overtime</div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="border-b dark:bg-gray-800 dark:border-gray-700 text-[16px] font-medium text-black"
          v-for="(entry, index) in absendata.data" :key="entry.id" >
          <td class="px-3 py-4">{{index+1}}</td>
          <td class="px-6 py-4">{{entry.date}}</td>
          <td scope="row" class=" flex flex-row px-4 py-4">
            <h2>{{entry.time_in}}</h2>
            <p class="mr-2 ml-2">To</p>
            <h2>{{entry.time_out}}</h2>
          </td>
          <td class="px-6 py-4 text-center">{{entry.work_hour}}</td>
          <td class="px-6 py-4 text-center">{{entry.late}}</td>
          <td class="px-6 py-4 text-center">1</td>
          <td class="px-6 py-4 text-center">0</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<template v-if="activeTab === 'Riwayat Cuti'">
  <div class="  first-letter:relative overflow-x-auto border sm:rounded-lg bg-white shadow-lg font-pop" style=" animation: muncul-kiri-kanan-fade 1s;">
     
     <table class="min-w-full text-sm text-left text-gray-500 ] dark:text-gray-400">
         <thead class="text-xs text-gray-700 border-b bg-[#64707628]  dark:bg-gray-700 dark:text-gray-400 text-[14px]">
             <tr class="text-[#868686]">
                 <th scope="col" class="px-10 py-3  font-medium">
                     No
                 </th>
                 <th scope="col" class="px-10 py-3  font-medium">
                     Employee Name
                 </th>
                 <th scope="col" class="px-1 py-3">
                     <div class="flex items-center  font-medium">
                        Type Pengajuan
                     </div>
                 </th>
                 <th scope="col" class="px-6 py-3">
                     <div class="flex items-center  font-medium">
                         Tanggal Pengajuan 
                     </div>
                 </th>
                 <th scope="col" class="px-6 py-3">
                     <div class="flex items-center  font-medium">
                         Tanggal Akhir Pengajuan
                     </div>
                 </th>
                 <th scope="col" class="px-6 py-3">
                     <div class="flex items-center  font-medium">
                     Jumlah hari
                     </div>
                 </th>
                 <th scope="col" class="px-6 py-3">
                     <div class="flex items-center  font-medium">
                         Status
                     </div>
                 </th>
                 <th scope="col" class=" py-3">
                     <div class="flex items-center  font-medium">
                     </div>
                 </th>
             </tr>
         </thead>
         <tbody v-if="permohonanData.data">
             <tr class=" border-b dark:bg-gray-800 dark:border-gray-700 text-[16px] font-medium text-black" v-for="(permohonan,index) in permohonanData.data" :key="permohonan.id">
                 <td class="px-10 py-4">
                    {{ index +1 }}
                 </td>
                 <th scope="row" class="flex flex-row items-center px-10 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                     <img src="https://pbs.twimg.com/media/GF6JAuVWcAETdhA.jpg" class="w-10 h-10 rounded-full" alt="">
                     <h1 class="ml-2">{{ permohonan.employee_name }}</h1>
                 </th>
                 <td class="px-1 py-4">
                    {{ permohonan.type }}
                 </td>
                 <td class="px-6 py-4">
                     {{ permohonan.start_date }}
                 </td>
                 <td class="px-6 py-4">
                     {{ permohonan.end_date }}
                 </td>
                 <td class="px-6 py-4">
                     {{ permohonan.days }}
                 </td>
                 <td class=" py-4">
                     <button
                     :class="{
                         'bg-[#FFE69A]/30 text-[#EFB812]': permohonan.status === 'Proses',
                         'bg-[#FF5E5E]/30 text-[#ED4747]': permohonan.status === 'Ditolak',
                         'bg-[#82C97C]/30 text-[#21B115]': permohonan.status === 'Disetujui',
                     }"
                     class="w-[118px] h-[30px] flex-shrink-0 rounded-lg  ml-4"
                     >
                     <h3 class="text-[12px] font-medium">{{ permohonan.status }}</h3>
                     </button>
                 </td>
                 <td class=" py-4">
                    <FormApproval :permohonandata="permohonan" ></FormApproval>
                     
             </td>
             </tr>
            
         </tbody>
        <tbody v-else>
          </tbody>
     </table>
 </div>
</template>

<template v-if="activeTab === 'Jatah Cuti'">

<div class="flex justify-between items-center w-full" style="animation: muncul-kiri-kanan-fade 0.5s;">
<h1 class="flex">Jatah Cuti</h1>
</div>

<div class="  first-letter:relative overflow-x-auto border sm:rounded-lg bg-white shadow-lg font-pop" style=" animation: muncul-kiri-kanan-fade 1s;">
   
   <table class="min-w-full text-sm text-left text-gray-500 ] dark:text-gray-400">
       <thead class="text-xs text-gray-700 border-b bg-[#64707628]  dark:bg-gray-700 dark:text-gray-400 text-[14px]">
           <tr class="text-[#868686]">
               <th scope="col" class="px-10 py-3  font-medium">
                   Tahun Ke
               </th>
               <th scope="col" class="px-10 py-3  font-medium">
                   Periode Awal
               </th>
               <th scope="col" class="px-1 py-3">
                   <div class="flex items-center  font-medium">
                      Periode Akhir
                   </div>
               </th>
               <th scope="col" class="px-6 py-3">
                   <div class="flex items-center  font-medium">
                       Jatah Cuti
                   </div>
               </th>
               <th scope="col" class="px-6 py-3">
                   <div class="flex items-center  font-medium">
                       Sudah Diambil
                   </div>
               </th>
               <th scope="col" class="px-6 py-3">
                   <div class="flex items-center  font-medium">
                   Sisa Cuti
                   </div>
               </th>
           </tr>
       </thead>
       <tbody v-if="riwayatCuti.data">
           <tr class=" border-b dark:bg-gray-800 dark:border-gray-700 text-[16px] font-medium text-black" v-for="(permohonan,index) in riwayatCuti.data" :key="permohonan.id">
               <td class="px-10 py-4">
                  {{ index +1 }}
               </td>
               <th scope="row" class="flex flex-row items-center px-10 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                   <h1 class="ml-2">{{ permohonan.first_periode }}</h1>
               </th>
               <td class="px-1 py-4">
                  {{ permohonan.last_periode }}
               </td>
               <td class="px-6 py-4">
                   {{ permohonan.quotas_paid_leave }}
               </td>
               <td class="px-6 py-4">
                   {{ permohonan.taken_paid_leave }}
               </td>
               <td class="px-6 py-4">
                   {{ permohonan.paid_leave_left }}
               </td>
           </tr>
          
       </tbody>
      <tbody v-else>
        </tbody>
   </table>
</div>
</template>


<template v-if="activeTab === 'Riwayat Lembur'">
  <div class="  first-letter:relative overflow-x-auto border sm:rounded-lg bg-white shadow-lg font-pop" style=" animation: muncul-kiri-kanan-fade 1s;">
     
     <table class="min-w-full text-sm text-left text-gray-500 ] dark:text-gray-400">
         <thead class="text-xs text-gray-700 border-b bg-[#64707628]  dark:bg-gray-700 dark:text-gray-400 text-[14px]">
             <tr class="text-[#868686]">
                 <th scope="col" class="px-10 py-3  font-medium">
                     No
                 </th>
                 <th scope="col" class="px-10 py-3  font-medium">
                     Nama
                 </th>
                 <th scope="col" class="px-1 py-3">
                     <div class="flex items-center  font-medium">
                        Tanggal Lembur
                     </div>
                 </th>
                 <th scope="col" class="px-6 py-3">
                     <div class="flex items-center  font-medium">
                        NIK
                     </div>
                 </th>
                 <th scope="col" class="px-6 py-3">
                     <div class="flex items-center  font-medium">
                         Jam Selesai Lembur
                     </div>
                 </th>

                 <th scope="col" class="px-6 py-3">
                     <div class="flex items-center  font-medium">
                         Status
                     </div>
                 </th>
                 <th scope="col" class=" py-3">
                     <div class="flex items-center  font-medium">
                     </div>
                 </th>
             </tr>
         </thead>
         <tbody>
             <tr class=" border-b dark:bg-gray-800 dark:border-gray-700 text-[16px] font-medium text-black" v-for="(permohonan,index) in permohonanData1.data" :key="permohonan.id">
                 <td class="px-10 py-4">
                    {{ index +1 }}
                 </td>
                 <th scope="row" class="flex flex-row items-center px-10 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                     <img src="https://pbs.twimg.com/media/GF6JAuVWcAETdhA.jpg" class="w-10 h-10 rounded-full" alt="">
                     <h1 class="ml-2">{{ permohonan.name }}</h1>
                 </th>
                 <td class="px-1 py-4">
                    {{ permohonan.date }}
                 </td>
                 <td class="px-6 py-4">
                     {{ permohonan.nik }}
                 </td>
                 <td class="px-6 py-4">
                     {{ permohonan.time_out }}
                 </td>
               
                 <td class=" py-4">
                     <button
                     :class="{
                         'bg-[#FFE69A]/30 text-[#EFB812]': permohonan.status === 'Proses',
                         'bg-[#FF5E5E]/30 text-[#ED4747]': permohonan.status === 'Ditolak',
                         'bg-[#82C97C]/30 text-[#21B115]': permohonan.status === 'Disetujui',
                     }"
                     class="w-[118px] h-[30px] flex-shrink-0 rounded-lg  ml-4"
                     >
                     <h3 class="text-[12px] font-medium">{{ permohonan.status }}</h3>
                     </button>
                 </td>
                 <td class=" py-4">
                     <FormApproval :permohonandata="permohonan" ></FormApproval>
                     
             </td>
             </tr>
            
         </tbody>
     </table>
 </div>
</template>

<template v-if="activeTab === 'Riwayat Gaji'">
<h1 class="flex justify-center items-center">Tidak Ada Riwayat Gaji</h1>
</template>

<template v-if="activeTab === 'Riwayat Pinjaman'">
  <div class="  first-letter:relative overflow-x-auto border sm:rounded-lg bg-white shadow-lg font-pop" style=" animation: muncul-kiri-kanan-fade 0.8s;">
     
     <table class="min-w-full text-sm text-left text-gray-500 ] dark:text-gray-400">
         <thead class="text-xs text-gray-700 border-b bg-[#64707628]  dark:bg-gray-700 dark:text-gray-400 text-[14px]">
             <tr class="text-[#868686]">
                 <th scope="col" class="px-6 py-3  font-medium">
                     No
                 </th>
                 <th scope="col" class="px-6 py-3  font-medium">
                   Pinjaman
                 </th>
                 <th scope="col" class="px-1 py-3">
                     <div class="flex items-center  font-medium">
                      Keterangan
                     </div>
                 </th>
                 <th scope="col" class="px-6 py-3">
                     <div class="flex items-center  font-medium">
                        Tanggal Pinjam
                     </div>
                 </th>
                 <th scope="col" class="px-6 py-3">
                     <div class="flex items-center  font-medium">
                       Tanggal bayar
                     </div>
                 </th>
                 <th scope="col" class="px-6 py-3">
                     <div class="flex items-center  font-medium">
                         User Input
                     </div>
                 </th>
                
                 <th scope="col" class="px-6 py-3">
                     <div class="flex items-center  font-medium">
                         User Update
                     </div>
                 </th>
                 <th scope="col" class="px-6 py-3">
                     <div class="flex items-center  font-medium">
                        Status 
                     </div>
                 </th>
             </tr>
         </thead>
         <tbody>
             <tr class=" border-b dark:bg-gray-800 dark:border-gray-700 text-[16px] font-medium text-black" v-for="(entry, index) in loandata.data" :key="entry.id">
                 <td class="px-6 py-4">
                     {{index+1}}
                  </td>
                 <th scope="row" class="flex flex-row items-center px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">

                     <h1 class="">{{entry.amount}}</h1>
                 </th>
                 <td class="px-1 py-4">
                {{ entry.description}}
                 </td>
                 <td class="px-6 py-4">
                   {{ entry.loan_date}}
                 </td>
                 <td class="px-6 py-4">
                    {{ entry.due_date }}
                 </td>
                 <td class="px-6 py-4">
                  {{  entry.created_by }}
                 </td>
                 <td class="px-6 py-4">
                   {{  entry.created_by }}
                 </td>
                 <td class=" py-4">
                         
                    <h3 class="text-[12px] font-medium">Sudah Bayar</h3>
                           
                 </td>
             </tr>
            
         </tbody>
     </table>
 </div>
<!-- <h1 class="flex justify-center items-center">Tidak Ada Riwayat Pinjaman</h1> -->
</template>

<template v-if="activeTab === 'Pembayaran Pinjaman'">

<div class="flex justify-between items-center w-full" style="animation: muncul-kiri-kanan-fade 0.5s;">
<h1 class="flex">Pembayaran Pinjaman</h1>
</div>

<div class="  first-letter:relative overflow-x-auto border sm:rounded-lg bg-white shadow-lg font-pop" style=" animation: muncul-kiri-kanan-fade 1s;">
   
   <table class="min-w-full text-sm text-left text-gray-500 ] dark:text-gray-400">
       <thead class="text-xs text-gray-700 border-b bg-[#64707628]  dark:bg-gray-700 dark:text-gray-400 text-[14px]">
           <tr class="text-[#868686]">
               <th scope="col" class="px-10 py-3  font-medium">
                   No
               </th>
               <th scope="col" class="px-10 py-3  font-medium">
                   Tgl Bayar
               </th>
               <th scope="col" class="px-1 py-3">
                   <div class="flex items-center  font-medium">
                      Jumlah Pinjaman
                   </div>
               </th>
               <th scope="col" class="px-6 py-3">
                   <div class="flex items-center  font-medium">
                       Jumlah Bayar 
                   </div>
               </th>
               <th scope="col" class="px-6 py-3">
                   <div class="flex items-center  font-medium">
                       Keterangan
                   </div>
               </th>
           </tr>
       </thead>
       <tbody v-if="pembayaranPinjaman.data">
           <tr class=" border-b dark:bg-gray-800 dark:border-gray-700 text-[16px] font-medium text-black" v-for="(permohonan,index) in pembayaranPinjaman.data" :key="permohonan.id">
               <td class="px-10 py-4">
                  {{ index +1 }}
               </td>
               <th scope="row" class="flex flex-row items-center px-10 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                   <h1 class="ml-2">{{ permohonan.payment_date }}</h1>
               </th>
               <td class="px-1 py-4">
                  {{ permohonan.loan_amount }}
               </td>
               <td class="px-6 py-4">
                   {{ permohonan.payment_amount }}
               </td>
               <td class="px-6 py-4">
                   {{ permohonan.description }}
               </td>
           </tr>
          
       </tbody>
      <tbody v-else>
        </tbody>
   </table>
</div>
</template>

</div>

<div v-if="showPreview" class="fixed  top-0 left-0 w-full h-full bg-black bg-opacity-70 flex justify-center items-center" @click="showPreview = false"  >
      <div class="max-w-4xl max-h-4xl overflow-auto">
        <img class="w-full h-[60rem] object-cover  rounded-lg" :src="imageUrl" alt="Employee Photo" style="  animation: muncul-kiri-kanan-fade 1s;">
      </div>
    </div>

    <!-- <div v-if="showPreview1" class="fixed  top-0 left-0 w-full h-full bg-black bg-opacity-70 flex justify-center items-center" @click="showPreview1 = false"  >
      <div class="max-w-4xl max-h-4xl overflow-auto">
        <img class="w-full rounded-lg" :src="imageUrl1" alt="Employee Photo" style="  animation: muncul-kiri-kanan-fade 1s;">
      </div>
    </div> -->

    <div v-if="showPreview1" class="fixed top-0 left-0 w-full h-full bg-black bg-opacity-70 flex justify-center items-center" @click="closePreview">
  <div class="max-w-4xl max-h-4xl overflow-auto">
    <img class="w-full h-[30rem] object-cover rounded-lg" :src="selectedImageUrl" alt="Employee Photo" style="animation: muncul-kiri-kanan-fade 1s;">
  </div>
</div>

<div v-if="successModal" class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50"  style="  animation: fadein 1s;">
    <div class="bg-white p-8 rounded-lg flex flex-col justify-center items-center  "  style="animation: muncul-kiri-kanan-fade 1s; ">
      <img src="../../assets/verify.png" class="w-16 h-16 mb-6 " alt="">
      <p>Data Karyawan Berhasil ditambahkan</p>
    </div>
  </div>

  <div v-if="errorModal" class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50" style="animation: fadein 1s;">
    <div class="bg-white p-8 rounded-lg flex flex-col justify-center items-center  "  style="animation: muncul-kiri-kanan-fade 1s; ">
      <img src="../../assets/cancel.png" class="w-16 h-16 mb-6 " alt="">
      <p class="  font-semibold w-72 text-center" >{{ message }}</p>
    </div>
  </div>
   
  </template>
  
  <script >
  import axios from "../../token";

  export default {
    data() {
      return {
        id: localStorage.getItem('id') || 'Default Value',
        isOpen: false,
        open: "",
        email: "",
        accountStatus:'',
        activeTab: 'Informasi',
      password: "",
      nik: "",
      workshift_id: "",
      showPreviewModal:false,
       selectedImageUrl: null,
      workday_id: "",
      name: "",
      gender: "",
      position: "",
      department: "",
      religion: "",
      birthPlace: "",
      birthDate: "",
      address: "",
      payroll:false,
      idinve:'',
      phone: "",
      ktp: "",
      sim: "",
      dokumen:false,
      passport: "",
      employeeStatus: "",
      contractType: "",
      contractStart: "",
      contractEnd: "",
      bloodType: "",
      maritalStatus: "",
      kehadiran:'',
      children: "",
      npwp: "",
      showPreview: false,
      showPreview1:false,
      bpjsHealth: "",
      permohonanData:[],
      permohonanData1:[],
      bpjsEmployment: "",
      joinDate: "",
      selectedFileUrl:[],
      selectedFileName:[],
      selectedFiles: [],
      appointmentDate: "",
      resignDate: "",
      description: "",
      imageUrls: [],
      riwayatCuti:[],
      pembayaranPinjaman:[],
      successModal:false,
      description4:'',
      errorModal:false,
      categorydata:[],
      open4:false,
      roledata:[],
      positiondata:[],
        projectdata:[],
        imageUrl1:null,
        daydata:[],
        invedata:[],
        loandata:[],
        absendata:[],
        imageUrl:null,
        selectedMonth: new Date().getMonth() + 1, // Default to the current month
      selectedYear: new Date().getFullYear(), // Default to the current year
      months: [
        'Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni',
        'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'
      ],
      years: [2022, 2023, 2024], // You can customize the list of years
      };
    },
    components:{
    
    },
    computed: {
      filteredEntries() {
        let filtered = this.absendata;
  
        if (this.selectedStatus.length > 0) {
          filtered = filtered.filter((abse) =>
            this.selectedStatus.includes(abse.status)
          );
        }
  
        if (this.selectedSort === "asc") {
          return filtered.sort((a, b) => a.jammasuk.localeCompare(b.jammasuk));
        } else {
          return filtered.sort((a, b) => b.jammasuk.localeCompare(a.jammasuk));
        }
      },
    },
    methods: {
      toggleModal() {
        this.isOpen = !this.isOpen;
      },
      removeFile(index) {
    this.selectedFiles.splice(index, 1);
  },
      openModal() {
        this.isModalOpen = true;
      },
      closeModal() {
        this.isModalOpen = false;
      },
      closePreview() {
    this.showPreview1 = false;
  },
      changeTab(tabName) {
    this.activeTab = tabName;
    localStorage.setItem('activeTab', tabName); 
  },
  handleFileChange(event) {
  const input = event.target;
  console.log("Selected files:", input.files);
  if (input.files.length > 0) {
    for (let i = 0; i < input.files.length; i++) {
      const selectedFile = input.files[i];
      // Check file extension
      const fileNameParts = selectedFile.name.split('.');
      const fileExtension = fileNameParts[fileNameParts.length - 1].toLowerCase();
      if (fileExtension !== 'png' && fileExtension !== 'jpeg' && fileExtension !== 'jpg') {
        console.log("File", selectedFile.name, "is not a valid PNG, JPEG, or JPG file. Skipping...");
        this.message = 'Jenis File Harus  PNG, JPEG, or JPG file';
    this.errorModal = true;
    setTimeout(() => {
      this.errorModal = false;
      this.open = false;
    }, 3000);
        continue; // Skip this file
      }
      console.log("Processing file:", selectedFile.name);
      // Create a new FileReader object to read file content as an array buffer
      const reader = new FileReader();
      reader.onload = () => {
        // Push file metadata along with its binary content to selectedFiles array
        this.selectedFiles.push({
          name: selectedFile.name,
          url: URL.createObjectURL(selectedFile),
          type: selectedFile.type,
          file: selectedFile,
          size: selectedFile.size,
          data: reader.result // Binary data (array buffer) of the file
        });
      };
      // Start reading the file as an array buffer
      reader.readAsArrayBuffer(selectedFile);
    }
    // Clear the file input's value after processing all selected files
    input.value = '';
  }
},

  removeEntry(index) {
      this.entries.splice(index, 1);
    },
  applyFilter() {
      
      console.log('Selected Month:', this.selectedMonth);
      console.log('Selected Year:', this.selectedYear);
      this.getabsen();
      
    },
    getinve(entry){
      this.open4=true
      this.item_name=entry.item_name,
      this.date1=entry.borrowed_date,
      this.date2=entry.returned_date,
      this.description4=entry.description
      this.idinve=entry.id
    },
    closeinve(){
      this.open4=false
    },
    async submitUpdateInve() {
  try {
    const formData = new FormData();
    formData.append('user_id', this.$route.params.id);
    formData.append('item_name', this.item_name);
    formData.append('borrowed_date', this.date1);
    formData.append('returned_date', this.date2);
    formData.append('_method','put');
    formData.append('id',this.idinve);
    formData.append('description', this.description4);

    const response = await axios.post(`${process.env.VUE_APP_BACKEND_HOST}/borrowed-item`, formData);
    
    this.successModal = true;
    setTimeout(() => {
      this.successModal = false;
    }, 3000);

    console.log(response);

    this.getinventory()

    this.item_name = '';
    this.date1 = '';
    this.date2 = '';
    this.description = '';
    this.open4 = false;
  } catch (error) {
    console.log(error);
    this.message = error.response.data.message;
    this.errorModal = true;
    setTimeout(() => {
      this.errorModal = false;
    }, 3000);
  }
},
      async submitForm() {
        try {
          const response = await axios.post(`${process.env.VUE_APP_BACKEND_HOST}/work-day`, {
          user_id:this.$route.params.id,
          sun:this.Minggu,
          mon:this.Senin,
          tue:this.Selasa,
          wed:this.Rabu,
          thu:this.Kamis,
          fri:this.Jumat,
          sat:this.Sabtu

          },
          );
          this.getday()
        this.successModal = true;
        setTimeout(() => {
          this.successModal = false;
          this.open=false;
        }, 3000);
          console.log(response);
        
        
         
        } catch (error) {
          console.log(error);
          this.message = error.response.data.message;
          this.errorModal = true;
        setTimeout(() => {
          this.errorModal = false;
        }, 3000);
         
        }
      },
      async submitForm1() {
        try {
          const response = await axios.post(`${process.env.VUE_APP_BACKEND_HOST}/borrowed-item`, {
          user_id:this.$route.params.id,
          item_name:this.item_name,
          borrowed_date:this.date1,
          returned_date:this.date2,
          description:this.description4
          
          },
          );
          
        this.successModal = true;
        setTimeout(() => {
          this.successModal = false;
        }, 3000);
          console.log(response);
        
          this.getinventory()
        
        this.item_name='';
        this.date1='';
        this.date2='';
        this.description='';
        this.open=false;
        } catch (error) {
          console.log(error);
          this.message = error.response.data.message;
          this.errorModal = true;
        setTimeout(() => {
          this.errorModal = false;
        }, 3000);
         
        }
      },
      getcategory() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/work-shift`)
          .then((response) => {
            this.categorydata = response.data;
  
          })
          .catch((error) => {
            console.log(error);
          });
      },

      getcategory1() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/position`)
          .then((response) => {
            this.positiondata = response.data;
  
          })
          .catch((error) => {
            console.log(error);
          });
      },

      getinventory() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/borrowed-item/user`,{
            params:{
              user_id:this.$route.params.id
            }
          })
          .then((response) => {
            this.invedata = response.data;
  
          })
          .catch((error) => {
            console.log(error);
          });
      },
      getabsen() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/presence/check`,{
            params:{
              user_id:this.$route.params.id,
              year:this.selectedYear,
              month:this.selectedMonth,
              filter:this.kehadiran
            }
          })
          .then((response) => {
            this.absendata = response.data;
  
          })
          .catch((error) => {
            console.log(error);
            this.message = error.response.data.message;
          this.errorModal = true;
        setTimeout(() => {
          this.errorModal = false;
        }, 3000);
          });
      },
      getrole() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/role`)
          .then((response) => {
            this.roledata = response.data;
  
          })
          .catch((error) => {
            console.log(error);
          });
      },
      getproject() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/department`)
          .then((response) => {
            this.projectdata = response.data;
  
          })
          .catch((error) => {
            console.log(error);
          });
      },
      getday() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/work-day/user`,{
            params:{
            user_id:this.$route.params.id,
            }
          })
          .then((response) => {
            this.daydata = response.data;
  
          })
          .catch((error) => {
            console.log(error);
          });
      },
      getloan() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/loan/user`,{
            params:{
            user_id:this.$route.params.id,
            }
          })
          .then((response) => {
            this.loandata = response.data;
  
          })
          .catch((error) => {
            console.log(error);
          });
      },
      getshift() {
     
     axios
       .get(`${process.env.VUE_APP_BACKEND_HOST}/hrd/employee`,{
       params:{
        id:this.$route.params.id,
        
       }
       })
       .then((response) => {
         this.shiftdata = response.data;
         if (this.shiftdata && this.shiftdata.data.length > 0) {
        const firstEntry = this.shiftdata.data[0]; 
        this.nik = firstEntry.employee_id || '';
       
        this.email = firstEntry.user.email || '' ;
        this.name = firstEntry.user.name || '';
        this.gender = firstEntry.gender || '' ;
        this.birthPlace = firstEntry.birth_place || '' ;
        this.birthDate= firstEntry.birth_date || '' ;
        this.address= firstEntry.address  || '' ;
        this.phone= firstEntry.phone || '' ;
        this.ktp= firstEntry.id_card  || '' ;
        this.sim= firstEntry.driver_license  || '' ;
        this.passport= firstEntry.passport  || '' ;
        this.religion=  firstEntry.religion  || '' ;
        this.bloodType=  firstEntry.blood_type  || '' ;
        this.maritalStatus=  firstEntry.marital_status  || '' ;
          this.children=  firstEntry.number_of_children  || '' ;
          this.npwp=  firstEntry.address  || '' ;
          this.bpjsHealth = firstEntry.bpjs_kesehatan  || '' ;
          this.bpjsEmployment=  firstEntry.bpjs_ketenagakerjaan  || '' ;
          this.joinDate=  firstEntry.join_date  || '' ;
          this.appointmentDate=  firstEntry.appointment_date  || '' ;
          this.resignDate=  firstEntry.resign_date  || '' ;
          this.description=  firstEntry.description  || '' ;
          this.position =  firstEntry.position.id  || '' ;
          this.department =  firstEntry.department.id  || '' ;
          this.employeeStatus =  firstEntry.is_active || '' ;
          this.contractType =  firstEntry.work_bond || '' ;
          this.bank_name =  firstEntry.bank_name || '' ;
          this.bank_account_number =  firstEntry.bank_account_number || '' ;
          this.status =  firstEntry.dependents  || '' ;
          this.gajipokok =  firstEntry.basic_salary || '' ;
          this.Jabatan =  firstEntry.position_allowance || '' ;
          this.Transportasi =  firstEntry.transport_n_comm_allowance || '' ;
          this.Makan =  firstEntry.meal_allowance || '' ;
          this.PokokBPJS =  firstEntry.pokok_bpjs || '' ;
          this.fetchImage(firstEntry.photo);
          this.contractStart =   firstEntry.contract_start_date || '' ;
          this.contractEnd = firstEntry.contract_end_date || '' ;
          firstEntry.document.forEach(entry => {
            this.fetchImage1(entry.id);
          });

          console.log(firstEntry.document.id)
      }
    
       })
       .catch((error) => {
         console.log(error);
       });
     
   },
   async fetchImage(id) {
  try {
    const response = await axios.get(`${process.env.VUE_APP_BACKEND_HOST}/file`, {
      params: {
        id: id,
      },
      responseType: 'arraybuffer',
    });

    const base64Image = btoa(
      new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), '')
    );

    const imageUrl = `data:${response.headers['content-type']};base64,${base64Image}`;

    // Update the imageUrl property for the employee
    this.imageUrl = imageUrl;

    // Update the imageUrl property for all shifts
    this.shiftdata.data.forEach((shift) => {
      shift.imageUrl = imageUrl;
    });

  } catch (error) {
    console.error('Error fetching image:', error);
  }
},
async fetchImage1(id) {
  try {
    const response = await axios.get(`${process.env.VUE_APP_BACKEND_HOST}/file`, {
      params: {
        id: id,
      },
      responseType: 'arraybuffer',
    });

    const base64Image = btoa(
      new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), '')
    );

    const imageUrl2 = `data:${response.headers['content-type']};base64,${base64Image}`;
  
    // Update the imageUrl property for the employee
    this.imageUrls.push(imageUrl2);

    // Update the imageUrl property for the corresponding shift


  } catch (error) {
    console.error('Error fetching image:', error);
  }
},
openPreview(imageUrl) {
    this.selectedImageUrl = imageUrl;
    this.showPreview1 = true;
  },

getPermohonan1() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/employee-request/overtime/list`,{
            params:{
              user_id:this.$route.params.id
            }
          })
          .then((response) => {
            this.permohonanData1 = response.data;
          })
          .catch((error) => {
            console.log(error);
          });
      },
      getPermohonan() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/employee-request/list`,{
            params:{
              user_id:this.$route.params.id
            }
          })
          .then((response) => {
            this.permohonanData = response.data;
          })
          .catch((error) => {
            console.log(error);
          });
      },
      getRiwayatCuti() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/hrd/employee/paid-leave-history`, {
            params: {
              user_id: this.$route.params.id
            }
          }).then((response) => {
            this.riwayatCuti = response.data;
          }).catch((error) => {
            console.log(error);
          });
      },
      getPembayaranPinjaman() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/loan/payment/list`, {
            params: {
              user_id: this.$route.params.id
            }
          }).then((response) => {
            this.pembayaranPinjaman = response.data;
          }).catch((error) => {
            console.log(error);
          });
      },
    },
    mounted(){
      this.kehadiran=''
      this.getcategory();
      this.getPermohonan1();
      this.getPermohonan();
      this.getRiwayatCuti();
      this.getPembayaranPinjaman();
      this.getproject();
      this.activeTab ='Informasi'
      this.getrole();
      this.getshift();
      this.getday();
      this.getinventory();
      this.getloan();
      this.getcategory1();
    },
    created() {
  const storedTab = localStorage.getItem('activeTab');
  if (storedTab) {
    this.activeTab = storedTab;
  } else {
    this.activeTab = 'Informasi';
  }
},
  };
  </script>