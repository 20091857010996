import axios from "axios";

axios.defaults.baseURL = process.env.VUE_APP_BACKEND_HOST;
axios.defaults.headers.common['Accept'] = 'application/json';

axios.interceptors.request.use(
  (config) => {
    // Modify config.headers to set your global header
    config.headers['ngrok-skip-browser-warning'] = 'adudu';

    const token = localStorage.getItem('token');

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axios;
