
<template>

  <nav class="flex mb-6 font-pop" aria-label="Breadcrumb" style=" animation: muncul-kiri-kanan-fade 1s;"> 
    <ol class="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
      <li>
        <div class="flex items-center">
         
          <router-link to="/TransferStock" class="ms-1 text-sm font-medium text-gray-700 hover:text-red-500 md:ms-2 dark:text-gray-400 dark:hover:text-white">Transfer Stock</router-link>
        </div>
      </li>
      <li aria-current="page">
        <div class="flex items-center">
          <svg class="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4"/>
          </svg>
          <span class="ms-1 text-sm font-medium text-red-500 md:ms-2 dark:text-gray-400">Ubah Transfer Stock</span>
        </div>
      </li>
    </ol>
  </nav>
  
  
      <div class="font-pop">
      <form @submit.prevent="submittemp" class="space-y-12 bg-white md:mb-0 md:px-24 py-4 xxs:px-4 xxs:mb-16 rounded-xl" style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; animation: muncul-kiri-kanan-fade 1s;">
        <div class="border-b border-gray-900/10 pb-12">
          <h2 class="text-base text-[22px] font-semibold leading-7 text-gray-900 mt-4">Ubah Transfer Stock</h2>
          <p class="mt-1 text-sm leading-6 text-gray-600">Lakukan Perubahan Data Transfer Stock.</p>
          <div class="mt-6 grid xxs:grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-6">
  
            <div class="sm:col-span-full">
            <label for="qty" class="block text-sm font-medium leading-6 text-gray-900">Tanggal Transfer</label>
            <div class="mt-2">
              <input v-model="date" type="date" name="qty" id="qty" autocomplete="qty" class="block w-[14rem] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>
          
  
            <div class="sm:col-span-3">
              <label for="project" class="block text-sm font-medium leading-6 text-gray-900">Project  Pengirim</label>
              <div class="mt-2">
                <select v-model="project1" id="project" @change="material2" name="project" autocomplete="project-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                  <option value="" disabled selected>Pilih Project</option>
                  <option v-for="data2 in projectdata2.data" :key="data2.name" :value="data2.id">{{ data2.name }}</option>
                </select>
              </div>
            </div>
            
            
            <div class="sm:col-span-3">
              <label for="project" class="block text-sm font-medium leading-6 text-gray-900">Project Penerima</label>
              <div class="mt-2">
                <select v-model="project2" id="project" @change="material2" name="project" autocomplete="project-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                  <option value="" disabled selected>Pilih Project</option>
                  <option v-for="data2 in projectdata2.data" :key="data2.id" :value="data2.id">{{ data2.name }}</option>
                </select>
              </div>
            </div>
            </div>
         
        </div>
  
       
  
        <div class="border-b border-gray-900/10 pb-12">
          <div class="mt-4 grid xxs:grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-6">
  
          
  
            <div class="sm:col-span-3">
              <label for="material" class="block text-sm font-medium leading-6 text-gray-900">Material</label>
              <div class="mt-2">
                <select v-model="selectedMaterial" id="material" @change="unit" name="material" autocomplete="material-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                  <option value="" disabled selected>Pilih Material</option>
                  <option v-for="data1 in userdata.data" :key="{ id: data1.id, name: data1.name, symbol:data1.symbol }" :value="{ id: data1.id, name: data1.name , symbol:data1.symbol }">{{ data1.name_dimension }}</option>
                </select>
              </div>
            </div>
           
           
  
  
            <div class="sm:col-span-1">
              <label for="negara" class="block text-sm font-medium leading-6 text-gray-900">EOM</label>
              <div class="mt-2">
                <input v-model="eom" type="text" name="negara" id="negara" autocomplete="negara" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled />
              </div>
            </div>
  
            <div class="sm:col-span-2">
              <label for="qty" class="block text-sm font-medium leading-6 text-gray-900">Qty</label>
              <div class="mt-2">
                <input v-model="quantity" type="text" name="qty" id="qty" autocomplete="qty" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>
            
            
          </div>
          <div class="flex justify-end mt-6">
            <form @submit.prevent="resetForm">
          <button   class="px-4 py-2 text-sm font-medium text-white bg-red-400 rounded-md hover:bg-red-700 focus:outline-none">Batal</button>
        </form>
          <button v-if="!isEditMode" type="submit" class="px-4 py-2 text-sm font-medium text-white bg-green-400 rounded-md hover:bg-green-700 focus:outline-none">Tambahkan</button>
          <button v-if="isEditMode" type="submit" class="px-4 py-2 text-sm font-medium text-white bg-green-400 rounded-md hover:bg-green-700 focus:outline-none">Ubah Data</button>
         
        </div>
          
        </div>
        
  <div class="border-b border-gray-900/10 pb-12">
    <div class="relative overflow-x-auto shadow-md sm:rounded-lg font-pop ">
      <table class="w-full text-[12px] text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead class="t text-gray-700  bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
  
                <th scope="col" class="px-6 py-3">
                      No
                  </th>
                  <th scope="col" class="px-6 py-3">
                      Material
                  </th>
                 
                  <th scope="col" class="px-6 py-3">
                      Qty
                  </th>
                  <th scope="col" class="px-6 py-3">
                      Eom
                  </th>
                 
                  <th scope="col" class="px-6 py-3">
                      Hapus
                  </th>
                  <th scope="col" class="px-6 py-3">
                      
                  </th>
              </tr>
          </thead>
          <tbody>
              <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"  v-for="(entry, index) in transferdata.data" :key="index">
                <td class="px-6 py-4">
                      {{ index + 1 }}
                  </td>
                  <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      {{ entry.material.name }}
                  </th>
                  <td class="px-6 py-4">
                      {{ entry.quantity }}
                  </td>
                  <td class="px-6 py-4">
                      {{ entry.material.unit_name }}
                  </td>
                   
                <td class="px-6 py-4 ">
                  <a  href="#"  class="font-medium text-red-600 dark:text-red-500 hover:underline"  @click.prevent="editEntry(index)">Ubah</a> 
                 </td>
                 <td>
                  <a  href="#"  class="font-medium text-red-600 dark:text-red-500 hover:underline"  @click.prevent="removeEntry(index)">Hapus</a> 
                 </td>
              </tr>
             
          </tbody>
      </table>
  </div>
  <div class="sm:col-span-full mt-6">
            <label for="message" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Keterangan</label>
            <div class="mt-2">
            <textarea id="message" v-model="description" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Tambahkan Keterangan Jika Perlu..."></textarea>
            </div>
            </div>
  
  </div>
  <div class="mt-10 flex items-center justify-end gap-x-6">
        <router-link to="/stockOut"  class="rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Kembali</router-link>
        <form @submit.prevent="submitForm">
        <button type="submit" class="rounded-md bg-green-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Simpan</button>
      </form>
      </div>
  
      </form>
    </div>
    <div v-if="successModal" class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50"  style="  animation: fadein 1s;">
      <div class="bg-white p-8 rounded-lg flex flex-col justify-center items-center  "  style="animation: muncul-kiri-kanan-fade 1s; ">
        <img src="../../../assets/verify.png" class="w-16 h-16 mb-6 " alt="">
        <p>Data Transfer Stock Berhasil diubah</p>
      </div>
    </div>
  
    <div v-if="errorModal" class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50"  style="  animation: fadein 1s;">
      <div class="bg-white p-8 rounded-lg flex flex-col justify-center items-center  "  style="animation: muncul-kiri-kanan-fade 1s; ">
        <img src="../../../assets/cancel.png" class="w-16 h-16 mb-6 " alt="">
        <p class="  font-semibold w-72 text-center" >{{ message }}</p>
      </div>
    </div>
  
  </template>
  
  
  
  
  
  
  <script>
  import axios from "../../../token";
  import { ref } from 'vue';
  import { setupLeaveConfirmation } from '../../../token/confirmLeave.js';
  
  
  
  
  export default {
    data() {
      return {
        id: localStorage.getItem('id') || 'Default Value',
        isOpen: false,
        selectedMaterial:null,
        entries: [],
        open: "",
        date:'',
        project1: '',
        project2: '',
        eom: '',
        Deskripsi: '',
        price:[],
        qty: [],
        description:[],
        category: '',
        material:[],
        coo: [],
        BeratSatuan: '',
        dimension:[],
        weight: [],
        successModal:false,
        errorModal:false,
        status:"Proses Pengerjaan",
        categorydata:[],
        projectdata:[],
        projectdata2:[],
        userdata:[],
        material1:'',
        transferdata:[]
      };
    },
    setup() {
      const isHovered = ref(false);
      setupLeaveConfirmation();
  
      return {
       
        isHovered,
        setupLeaveConfirmation
        
      }
  },
    computed: {
      filteredEntries() {
        let filtered = this.absendata;
  
        if (this.selectedStatus.length > 0) {
          filtered = filtered.filter((abse) =>
            this.selectedStatus.includes(abse.status)
          );
        }
  
        if (this.selectedSort === "asc") {
          return filtered.sort((a, b) => a.jammasuk.localeCompare(b.jammasuk));
        } else {
          return filtered.sort((a, b) => b.jammasuk.localeCompare(a.jammasuk));
        }
      },
    },
    methods: {
      unit() {
      const selectedMaterial = this.userdata.data.find(material => material.id === this.selectedMaterial.id);
      if (selectedMaterial) {
        this.eom = selectedMaterial.symbol;
      } else {
        this.eom = '';
      }
    },
    material2() {
      this.material1 = this.project1;
      console.log(this.material1)
      if (this.material1) {
        this.getuser()
      } else {
        this.material1 = '';
      }
    },
     
  
      toggleModal() {
        this.isOpen = !this.isOpen;
      },
      openModal() {
        this.isModalOpen = true;
      },
      closeModal() {
        this.isModalOpen = false;
      },
      removeEntry(index) {
      
        this.transferdata.data.splice(index, 1);
      },
      editEntry(index) {
      const editedEntry = this.transferdata.data[index];
      this.selectedMaterial = editedEntry.material ? { id: editedEntry.material.id, name: editedEntry.material.name, symbol: editedEntry.material.unit_name } : null;
      this.quantity = editedEntry.quantity || '';   
      this.eom = editedEntry.material.unit_name || '';
      this.isEditMode = true;
      this.editedIndex = index
    },
      submittemp() {
  
        const emptyFields = [];
  
  
  if (!this.selectedMaterial || /^\s*$/.test(this.selectedMaterial)) {
    emptyFields.push("Material");
  }
  
  
  if (!this.quantity || /^\s*$/.test(this.quantity)) {
    emptyFields.push("Quantity");
  }
  
  if (!this.eom || /^\s*$/.test(this.eom)) {
    emptyFields.push("EOM");
  }
  
  
  
  
  if (emptyFields.length > 0) {
  
    this.errorModal = true;
    this.message = `Field ${emptyFields.join(", ")} Tidak Boleh Kosong.`;
  
    setTimeout(() => {
      this.errorModal = false;
      this.open = false;
    }, 3000);
  
    return;
  }
  
  
  if (!this.isEditMode){
const materialExists = this.mrdata.data.some(entry => entry.material.name === this.selectedMaterial.name);

if (materialExists) {
  this.errorModal = true;
  this.message = 'Data Material Sudah ada di Purchase Order';

  setTimeout(() => {
    this.errorModal = false;
    this.open = false;
  }, 3000);
  this.resetForm();
  
  return;
}
}


const entryData = {
    material: this.selectedMaterial ? { id: this.selectedMaterial.id, name: this.selectedMaterial.name, unit_name:this.selectedMaterial.symbol} : null,
    quantity: this.quantity,
   
  };

  if (this.isEditMode) {
    this.transferdata.data[this.editedIndex] = { ...entryData };
   
    this.isEditMode = false;

  } else {
    console.log(entryData)
    this.transferdata.data.push(entryData);
  }

  this.resetForm();
      },
      resetForm() {
    this.selectedMaterial=[];
    this.editedIndex = ''
    this.quantity = '';
    this.eom = '';
   
  },
      async submitForm() {
        const formData = new FormData();
  
        this.transferdata.data.forEach((entries) => {
          formData.append('material[]', entries.material.id);
          formData.append('qty[]', entries.quantity);
          
  
        },
          formData.append('from_project',this.project1),
          formData.append('to_project',this.project2),
          formData.append('description',this.description),
          formData.append('received_by',this.id),
          formData.append('date',this.date),
          formData.append('_method','put'),
          formData.append('id',this.$route.params.id )
        );
        
        try {
          const response = await axios.post(`${process.env.VUE_APP_BACKEND_HOST}/transfer-stock`, formData);
  
          console.log(response);
          this.successModal = true;
  
         
          setTimeout(() => {
            this.successModal = false;
            this.open = false;
          }, 1000);
          setTimeout(() => {
        this.$router.push('/TransferStock');
      }, 2000);
        } catch (error) {
          console.log(error);
  
          this.errorModal = true;
          
          setTimeout(() => {
            this.errorModal = false;
          }, 4000);
          this.message = error.response.data.message;
          
        }
      },
      getcategory() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/project-category`)
          .then((response) => {
            this.categorydata = response.data;
  
          })
          .catch((error) => {
            console.log(error);
          });
      },
      gettransfer() {
        axios
        .get(`${process.env.VUE_APP_BACKEND_HOST}/transfer-stock`,{
            params:{
              id:this.$route.params.id 
            }
          })
          .then((response) => {
            this.transferdata = response.data;
            if (this.transferdata && this.transferdata.data.length > 0) {
            const firstEntry = this.transferdata.data[0]; 
            this.project1 = firstEntry.from_project_id || '';
            this.getuser();
            console.log(firstEntry.date)
            this.project2 = firstEntry.to_project_id || '' ;
            this.date = firstEntry.date || '';
             }
  
          })
          .catch((error) => {
            console.log(error);
          });
      },
      getuser() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/material`,{
            params:{
              project_id:this.project1
            }
          })
          .then((response) => {
            this.userdata = response.data;
  
          })
          .catch((error) => {
            console.log(error);
          });
      },
      getproject2() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/project/list`)
          .then((response) => {
            this.projectdata2 = response.data;
  
          })
          .catch((error) => {
            console.log(error);
          });
      },
      getproject() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/unit`)
          .then((response) => {
            this.projectdata = response.data;
  
          })
          .catch((error) => {
            console.log(error);
          });
      },
    },
    mounted(){
      this.getcategory();
      this.getproject2();
      this.getproject();
      this.getuser();
      this.gettransfer();
    }
  };
  </script>
  