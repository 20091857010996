<template>
  <h1 class="mb-4 font-pop font-medium text-[18px]" style=" animation: muncul-kiri-kanan-fade 0.5s;">Monitoring Project
  </h1>
  <div class="flex flex-row font-pop gap-4  ">
    <div class="border-dashed border-2 border-gray-300 p-2 rounded overflow-x-auto h-[260px] w-[44rem]"
      style=" animation: muncul-kiri-kanan-fade 0.5s;">
      <div class="grid grid-cols-2 gap-4">

        <div class="flex flex-row bg-white shadow rounded p-2 gap-4" v-for="item in categorydata.data" :key="item.id">
          <img class="w-24 h-24 object-cover rounded shadow-lg"
            src="https://asset.kompas.com/crops/k0__dJytqamRwPJW6K4j_fqCJvw=/0x0:1800x1200/750x500/data/photo/2022/09/10/631c1d6276c69.jpg"
            alt="">
          <div class="flex flex-col justify-center font-medium">
            <h1>{{ item.name }}</h1>
            <p>0</p>
          </div>
        </div>

      </div>
    </div>
    <div class="grid grid-cols-3 gap-6 w-full" style=" animation: muncul-kiri-kanan-fade 0.8s;">

      <div class="flex flex-col justify-center items-center bg-white  rounded p-2 gap-4"
        style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; ">
        <img class="w-24 h-24  object-cover rounded " src="../assets/expired.png" alt="">
        <div class=" font-medium">
          <h1 class="text-[20px]">Tertunda Project</h1>
          <p class="text-center text-[32px]">{{ tertunda }}</p>
        </div>
      </div>

      <div class="flex flex-col justify-center items-center bg-white  rounded p-2 gap-4"
        style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; ">
        <img class="w-24 h-24  object-cover rounded " src="../assets/progress.png" alt="">
        <div class=" font-medium">
          <h1 class="text-[20px]">Tahap Pengerjaan Project</h1>
          <p class="text-center text-[32px]">{{ pengerjaan }}</p>
        </div>
      </div>


      <div class="flex flex-col justify-center items-center bg-white  rounded p-2 gap-4"
        style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; ">
        <img class="w-24 h-24  object-cover rounded " src="../assets/complete.png" alt="">
        <div class=" font-medium">
          <h1 class="text-[20px]">Selesai Project</h1>
          <p class="text-center text-[32px]">{{ selesai }}</p>
        </div>
      </div>

    </div>
  </div>

  <div class="flex flex-col font-pop  mt-6">
    <div class="flex flex-row mb-4 font-medium">
      <!-- <div class="" style="  animation: muncul-kiri-kanan-fade 1s;">Statistik Project</div> -->
      <div class="" style="  animation: muncul-kiri-kanan-fade 1s;">Informasi Data Project</div>
    </div>
  </div>

  <div class=" font-pop">

    <!-- <div class="flex flex-col justify-center items-center bg-white  rounded-lg  w-[24rem] py-4 h-[20rem]  " style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;  animation: muncul-kiri-kanan-fade 1s;">
            <donut />
            <p class="text-center text-[14px] px-10 mt-4" style="  animation: muncul-kiri-kanan-fade 1s;">Data disajikan untuk memotoring status pengerjaan project</p>
            </div> -->
    <div class="bg-white shadow-lg first-letter:relative overflow-x-auto border sm:rounded-lg bg-transparent font-pop "
      style="  animation: muncul-kiri-kanan-fade 1s;">

      <table class="min-w-full text-sm text-left   text-gray-500  dark:text-gray-400">
        <thead class="text-xs text-gray-700 border-b bg-[#64707628]  dark:bg-gray-700 dark:text-gray-400 text-[14px]">
          <tr class="text-[#868686]">
            <th scope="col" class="px-6 py-3  font-medium">
              No
            </th>
            <th scope="col" class="px-6 py-3  font-medium">
              PO ID
            </th>
            <th scope="col" class="px-6 py-3">
              <div class="flex items-center  font-medium">
                Nama Project
              </div>
            </th>

            <th scope="col" class="px-6 py-3">
              <div class="flex items-center  font-medium">
                Kategory
              </div>
            </th>
            <th scope="col" class="px-6 py-3">
              <div class="flex items-center  font-medium">
                Tanggal Mulai
              </div>
            </th>
            <th scope="col" class="px-6 py-3">
              <div class="flex items-center  font-medium">
                Tanggal Akhir
              </div>
            </th>
            <th scope="col" class="px-6 py-3">
              <div class="flex items-center  font-medium">
                Project Manager
              </div>
            </th>


            <th scope="col" class="px-10 py-3">
              <div class="flex items-center  font-medium">
                Status
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class=" border-b dark:bg-gray-800 dark:border-gray-700 text-[14px] font-medium text-black"
            v-for="(material,index) in projectdata2.data" :key="material.id">
            <td class="px-6 py-4">
              {{ index + 1 }}
            </td>
            <td class="px-6 py-4">
              {{ material.code }}
            </td>
            <td class="px-6 py-4">
              {{ material.name }}
            </td>
            <td class="px-6 py-4 max-w-[200px] overflow-hidden overflow-ellipsis">
              {{ material.category }}
            </td>
            <td class="px-6 py-4">
              {{ material.start_date }}
            </td>
            <td class="px-6 py-4">
              {{ material.end_date }}
            </td>
            <td class="px-6 py-4">
              {{ material.pm }}
            </td>
            <td class="px-6 py-4">
              <button :class="{
                            'bg-[#FFE69A]/30 text-[#EFB812]': material.status === 'Proses Pengerjaan',
                            'bg-[#FF5E5E]/30 text-[#ED4747]': material.status === 'Ditunda',
                            'bg-[#82C97C]/30 text-[#21B115]': material.status === 'Selesai',
                        }" class="w-[118px] h-[30px] flex-shrink-0 rounded-lg  ml-4">
                <h3 class="text-[12px] font-medium">{{ material.status }}</h3>
              </button>

            </td>
          </tr>

        </tbody>
      </table>

      <div class=" mx-16 my-4 " v-if="pagination.last_page">

        <div class="flex justify-end mt-8 text-sm">
          <!-- <button
            @click="getmaterial(1)"
            :disabled="pagination.current_page === 1"
            class="px-4 py-2 mx-1 text-white bg-blue-500 rounded-md hover:bg-blue-600"
          >
            First
          </button> -->
          <button @click="getproject2(pagination.current_page - 1)"
            :disabled="pagination.current_page === 1"
            class="px-4 py-2 mx-1 text-white bg-red-500 rounded-md hover:bg-red-600">
            Sebelumnya
          </button>
          <button v-for="page in pagination.last_page" :key="page"
            @click="getproject2(page)"
            :class="{ 'bg-red-500 text-white': pagination.current_page === page }"
            class="px-4 py-2 mx-1  text-black rounded-md hover:bg-red-600">
            {{ page }}
          </button>
          <button @click="getproject2(pagination.current_page + 1)"
            :disabled="pagination.current_page === pagination.last_page"
            class="px-4 py-2 mx-1 text-white bg-red-500 rounded-md hover:bg-red-600">
            Selanjutnya
          </button>
          <!-- <button
            @click="getmaterial(pagination.last_page)"
            :disabled="pagination.current_page === pagination.last_page"
            class="px-4 py-2 mx-1 text-white bg-blue-500 rounded-md hover:bg-blue-600"
          >
            Last
          </button> -->
        </div>
      </div>

    </div>
  </div>






</template>

<script>
// import donut  from '../Project/DoughnutChart.vue';
import axios from "../token";
export default{
  components:{
    // donut
  },
  data() {
      return {
        id: localStorage.getItem('id') || 'Default Value',
        isOpen: false,
        open: "",
        project:"",
        material:"",
        qty:"",
        price:"",
        weight:"",
        coo:"",
        dimension:"",
        successModal:false,
        status:"Proses Pengerjaan",
        categorydata:[],
        projectdata:[],
        projectdata2:[],
        userdata:[],
        tertunda:'',
        materialdata:[],
        countdata:[],
        search: '',
        pagination: {
          current_page: 1,
          from: null,
          last_page: null
        },
        items: [
        { title: 'Total', value: '', image:require('@/assets/MTO.png'), description: 'Material Take Off' },
        { title: 'Total', value: '', image:require('@/assets/learning.png'), description: 'Material Request' },
        { title: 'Total', value: '', image:require('@/assets/po.png'), description: 'Purchase Order' },
        { title: 'Total', value: '', image:require('@/assets/stockin.png'), description: 'Stock In' },
        { title: 'Total', value: '', image:require('@/assets/stockout.png'), description: 'Stock Out' }
      ]
      };
    },
    computed: {
      filteredEntries() {
        let filtered = this.absendata;
  
        if (this.selectedStatus.length > 0) {
          filtered = filtered.filter((abse) =>
            this.selectedStatus.includes(abse.status)
          );
        }
  
        if (this.selectedSort === "asc") {
          return filtered.sort((a, b) => a.jammasuk.localeCompare(b.jammasuk));
        } else {
          return filtered.sort((a, b) => b.jammasuk.localeCompare(a.jammasuk));
        }
      },
      materialDataLength() {
      return this.materialdata.length;
    }
    },
    methods: {
      toggleModal() {
        this.isOpen = !this.isOpen;
      },
      openModal() {
        this.isModalOpen = true;
      },
      closeModal() {
        this.isModalOpen = false;
      },
      async submitForm() {
        try {
          const response = await axios.post(`${process.env.VUE_APP_BACKEND_HOST}/project`, {
            project: this.project,
            material: this.material,
            qty: this.qty,
            price: this.price,
            weight: this.weight,
            coo: this.coo,
            dimension: this.dimension
          });
          console.log(response);
     
          this.successModal = true;
  
          this.getproject();
        
          setTimeout(() => {
            this.successModal = false;
            this.open = false;
          }, 1000);
        } catch (error) {
          console.log(error);
          this.message = "There was an error submitting the form.";
        }
      },
      getcategory() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/project-category`)
          .then((response) => {
            this.categorydata = response.data;
  
          })
          .catch((error) => {
            console.log(error);
          });
      },
      getcount() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/project/count-status`)
          .then((response) => {
            this.countdata = response.data;

         
          const firstEntry = this.countdata;
          console.log(firstEntry)
           this.tertunda = firstEntry.tertunda
            this.pengerjaan = firstEntry.proses_pengerjaan
           this.selesai = firstEntry.selesai
          })
          .catch((error) => {
            console.log(error);
          });
      },
      getuser() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/material`)
          .then((response) => {
            this.userdata = response.data;
  
          })
          .catch((error) => {
            console.log(error);
          });
      },
      getproject2(page) {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/project/list`,{
            params:{
              limit: 5,
              page: page,
            }
          })
          .then((response) => {
            this.projectdata2 = response.data;
            this.pagination = {
              current_page: response.data.current_page,
              from: response.data.from,
              last_page: response.data.last_page
            }
          })
          .catch((error) => {
            console.log(error);
          });
      },
      getproject() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/unit`)
          .then((response) => {
            this.projectdata = response.data;
  
          })
          .catch((error) => {
            console.log(error);
          });
      },
      getmaterial() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/material-take-off/list`,{
            params:{
                search:this.search
            }
          })
          .then((response) => {
            this.materialdata = response.data;
  
          })
          .catch((error) => {
            console.log(error);
          });
      },
    },
    mounted(){
      this.getcategory();
      this.getproject2();
      this.getproject();
      this.getuser();
      this.getmaterial();
      this.getcount();
    }
}
</script>
