<template>
    <html class="h-screen">
      <body class="h-full ">
        <div
          class="flex min-h-full flex-1 flex-col justify-center px-6 py-10 lg:px-8 mt-[-20px] font-pop"
        >
          <div class="sm:mx-auto sm:w-full sm:max-w-sm">
            <div class="flex items-center justify-center">
              <div>
                <img
                  class="mx-auto h-16 w-16"
                  src="../assets/logo1.png"
                  alt=""
                />
              </div>
              <div class="ml-3 font-pop font-semibold text-[32px]  text-[#9d5252] mr-3">
             Fun<span class=" font-semibold text-[#c94d4d]">ity</span> 
           </div>
            </div>
            <h2
              class="mt-3 text-center font-pop font-regular  text-[#474747] tracking-wide xl:px-4 xl:text-[14px] xl:leading-5 md:text-md xxs:text-[12px] xl:px-{0.885rem}"
            >
             Solusi Terintegrasi untuk Efisiensi Bisnis
            </h2>
            <h1 class="text-white text-[12px] mt-4 text-center flex w-full justify-center rounded-md  bg-[#ff5c5c] px-3 py-2.5 " v-if="error">{{ error }}</h1>
          </div>
          <div class="mt-5 sm:mx-auto sm:w-full sm:max-w-sm">
             <form @submit.prevent="login">
              <div class="mb-6">
                <label
                  for="name"
                  class="block text-[14px] font-regular font-pop leading-6 text-[#474747]"
                  >Nama</label
                >
                <div class="mt-2">
                  <input
                  name="email"
                  type="email"
                  placeholder="Masukkan Email Anda"
                  v-model="email"
                  autocomplete="off"
                  class="block w-full rounded-md border-0 py-3 font-regular font-pop text-gray-500 shadow-lg ring-1 ring-inset ring-gray-400   placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6 xxs:text-[14px]"
                 
                />
                <h1 class=" text-red-500 text-[12px] mt-2"  v-if="!email && submitted">Harap Isi Email Anda</h1>
           
                </div>
              </div>
  
              <div class="mb-6">
                <div class="flex items-center justify-between">
                  <label
                    for="password"
                    class="block text-[14px] font-regular font-pop leading-6 text-[#474747]"
                    >Kata Sandi</label
                  >
                </div>
                <div class="mt-2">
                  <input
              name="password"
              type="password"
              placeholder="Masukkan Kata Sandi Anda"
               v-model="password"
              class="block w-full rounded-md border-0 py-3 font-regular font-pop text-gray-500 shadow-lg ring-1 ring-inset  ring-gray-400 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6 xxs:text-[14px]"
            />
            <h1 class=" text-red-500 text-[12px] mt-2" v-if="!password && submitted">Harap Isi Kata Sandi Anda</h1>
           
                </div>
                </div>
  
              <div class="flex items-center justify-end mb-6">
  
               
    
                <!-- <div class="text-sm">
                  <a
                    href="#"
                    class="font-regular font-pop text-[#474747] "
                    >Lupa Sandi?</a
                  >
                </div> -->
              </div>
  
              <div>
              
                <button
                  class="flex w-full justify-center rounded-md  bg-[#ff5c5c] px-3 py-2.5 text-[12px] font-semibold font-pop leading-6 text-white  transition ease-in-out delay-110   hover:bg-[#a73030]  "
                   type="submit" style="box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;">
                  Masuk 
                </button>
   
               
  
             
              </div>
            </form>
          </div>
        </div>
      </body>
    </html>
  </template>
  
  
  
  <script>
  import axios from "axios";
  export default {
    data() {
      return {
        ket:'',
        email: '',
        password: '',
        error: '',
        submitted: false,
      };
    },
    mounted() {
      // if(localStorage.getItem('token')){
      //   this.$router.push(localStorage.getItem('activeTab'))
      // } else {
      //   this.$router.push('/')
      // }
    },
    methods: {
       login() {
       


        axios.post( `${process.env.VUE_APP_BACKEND_HOST}/login`, {
          email: this.email,
          password: this.password,
        })
        .then(response => {
          // Simpan token di local storage atau state
          const token = response.data.token;
          const userId = response.data.id;
          const email = response.data.email;
          const name = response.data.name;
          const file = response.data.photo;
          localStorage.setItem('token', token);
          localStorage.setItem('file', file);
          localStorage.setItem('id', userId);
          localStorage.setItem('email', email);
          localStorage.setItem('name', name);
  
         
          this.$router.push('/dashboard');
        })
        .catch(error => {
          console.error(error);
          this.submitted = true;

          if (this.email && this.password &&  this.submitted) {
          this.error = "Email Atau Kata Sandi Anda Salah";
          return;
          }

          if (!this.email) {
          this.ket = "Harap Isi Email Anda";
          return;
          }
          if (!this.password) {
          this.ket = "Harap Isi Kata Sandi Anda";
          return;
}
          this.ket =  error.response.data.message;
        });
      },
    },
  };
  </script>
  
  