<template>
    <dashboard-layout :isDarkMode="isDarkMode">
      <!-- Sidebar content -->
      <template #sidebar>
      
      
        <BottomBar v-if="isLargeScreen"/>
      </template>
  
      <!-- Navbar content -->
      <template #navbar>
        <NavBar :isDarkMode="isDarkMode" />
      </template>
  
      <!-- Page content -->
      <template #content>
        <RiwayatBar />
        <!--<RiwayatBar1 :isDarkMode="isDarkMode"/> -->
      </template>
    </dashboard-layout>
  </template>
  
  <script>
import RiwayatBar from '../Absensi/Component/TablePresensi'
import NavBar from '../Absensi/Component/NavBar'
import BottomBar from '../Absensi/Component/BottomBar'



import DashboardLayout from "../Absensi/Component/DashboardLayout.vue";
  
  export default {
    components: {
    RiwayatBar,
    NavBar,
    BottomBar,
    DashboardLayout,
    },
    computed: {
    isLargeScreen() {
      return window.innerWidth < 480; 
    },
  },
  data() {
    return {
      isDarkMode: false,
    };
  },
  methods: {
    toggleDarkMode() {
      this.isDarkMode = !this.isDarkMode;
    },
  },
  };
  </script>
  
  <style>
  /* Add any custom CSS styles here */
  </style>