import { createRouter, createWebHistory } from "vue-router";
import LoginPage from "../Login/LoginPage.vue";
import DangerPage from "../Login/LoginCuk.vue";
import Dashboard from "../Dashboard/DashboardPage.vue";
import Konfigurasi from "../Konfigurasi/KonfigurasiPage.vue";
import Project from "../Project/ProjectPage.vue";
import addproject from "../Project/ProjectList/ListViewPage.vue";
import Review from "../Project/ReviewProject/ProjectReviewPage.vue";
import HR from "../HR/HRPage.vue";
import Pinjaman from "../Pinjaman/PinjamanPage.vue";
import BayarPinjaman from "../BayarPinjaman/BayarPinjamanPage.vue";
import WO from "../Work Order/WOPage.vue";
import DetailUser from "../HR/DetailUser/DetailPage.vue";
import ProfileUser from "../Profile/DetailPage.vue";
import HRView from "../HR/ViewUser/UserViewPage.vue";
import User from "../HR/Users/UsersPage.vue";
import EditUser from "../HR/Users/EditUsersPage.vue";
import ProjectConfig from "../Konfigurasi/ProjectConfig/ProjectConfigPage.vue";
import MaterialConfig from "../Konfigurasi/MaterialConfig/MaterialConfigPage.vue";
import SupplierConfig from "../Konfigurasi/SupplierConfig/SupplierConfigPage.vue";
import UnitConfig from "../Konfigurasi/UnitConfig/UnitConfigPage.vue";
import DepartementConfig from "../Konfigurasi/DepartementConfig/DepartementConfigPage.vue";
import PermissionConfig from "../Konfigurasi/PermissionConfig/PermissionConfigPage.vue";
import ShiftConfig from "../Konfigurasi/ShiftConfig/ShiftConfigPage.vue";
import PosisiConfig from "../Konfigurasi/PosisiConfig/PosisiConfigPage.vue";
import RoleConfig from "../Konfigurasi/RoleConfig/RoleConfigPage.vue";
import PublicHolidayConfig from "../Konfigurasi/PublicHolidayConfig/PublicHolidayConfigPage.vue";
import TeamConfig from "../Work Order/Team/TeamConfigPage.vue";
import Absensi from "../Absensi/AbsensiPage.vue";
import Pengajuan from "../Absensi/RequestPage.vue";
import PL from "../Absensi/LemburPage.vue";
import DashboardAbsen from "../Absensi/AdminPage.vue";
import Inventory from "../Invetory/InvetoryPage.vue";
import payroll from "../Payroll/PayrollPage.vue";
import MTO from "../Invetory/MTO/MTOPage.vue";
import MR from "../Invetory/MR/MRPage.vue";
import PO from "../Invetory/PO/POPage.vue";
import KS from "../Invetory/Kartu-stock/KartuPage.vue";
import StockIn from "../Invetory/Stock-in/StockInPage.vue";
import StockOut from "../Invetory/Stock-out/StockOutPage.vue";
import Transfer from "../Invetory/Transfer-stock/TransferPage.vue";
import AddMaterial from "../Invetory/MTO/Add/AddMTOPage.vue";
import EditMaterial from "../Invetory/MTO/Add/EditMTOPage.vue";
import EditRequest from "../Invetory/MR/Add/EditMRPage.vue";
import ApprovalRequest from "../Invetory/MR/Add/ApprovalMRPage.vue";
import EditPO from "../Invetory/PO/Add/EditPOPage.vue";
import EditSI from "../Invetory/Stock-in/Add/EditStockInPage.vue";
import EditSO from "../Invetory/Stock-out/Add/EditStockOutPage.vue";
import EditTransfer from "../Invetory/Transfer-stock/Add/EditTransferPage.vue";
import EditWO from "../Work Order/add/EditWOPage.vue";
import EditReport from "../Work Order/Report/EditReportPage.vue";
import ViewReport from "../Project/Report/EditReportPage.vue";
import AddMR from "../Invetory/MR/Add/AddMRPage.vue";
import AddPO from "../Invetory/PO/Add/AddPOPage.vue";
import AddWO from "../Work Order/add/AddWOPage.vue";
import AddPayroll from "../Payroll/add/PayrollPage.vue";
import AddReport from "../Work Order/Report/AddReportPage.vue";
import Report from "../Work Order/Report/ReportPage.vue";
import AddSI from "../Invetory/Stock-in/Add/AddStockInPage.vue";
import AddSO from "../Invetory/Stock-out/Add/AddStockOutPage.vue";
import AddTransfer from "../Invetory/Transfer-stock/Add/AddTransferPage.vue";
import Riwayat from "../Absensi/RiwayatPage.vue";

const routes = [
  {
    path: "/",
    name: "Login",
    component: LoginPage,
  },
  {
    path: "/Dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/Konfigurasi",
    name: "Konfigurasi",
    component: Konfigurasi,
  },
  {
    path: "/Project",
    name: "Project",
    component: Project,
  },
  {
    path: "/Payroll",
    name: "Payroll",
    component: payroll,
  },
  {
    path: "/WO",
    name: "Work Order",
    component: WO,
  },
  {
    path: "/TambahProject",
    name: "Project add",
    component: addproject,
  },
  {
    path: "/Review/:id",
    name: "Review Project",
    component: Review,
  },
  {
    path: "/HumanResource",
    name: "HumanResource",
    component: HR,
  },
  {
    path: "/Pinjaman",
    name: "Pinjaman",
    component: Pinjaman,
  },
  {
    path: "/BayarPinjaman",
    name: "BayarPinjaman",
    component: BayarPinjaman,
  },
  {
    path: "/User",
    name: "View user",
    component: HRView,
  },
  {
    path: "/EditUser/:id",
    name: "Edit user",
    component: EditUser,
  },
  {
    path: "/DetailUser/:id",
    name: "Detail user",
    component: DetailUser,
  },
  {
    path: "/Profile/:id",
    name: "Profile user",
    component: ProfileUser,
  },
  {
    path: "/Inventory",
    name: "Inventory",
    component: Inventory,
  },
  {
    path: "/MTO",
    name: "Material Take Off",
    component: MTO,
  },
  {
    path: "/MR",
    name: "Material Request",
    component: MR,
  },
  {
    path: "/PO",
    name: "Purchase Order",
    component: PO,
  },
  {
    path: "/stockIn",
    name: "Stock In",
    component: StockIn,
  },
  {
    path: "/404",
    name: "DangerPage",
    component: DangerPage,
  },
  {
    path: "/stockOut",
    name: "Stock Out",
    component: StockOut,
  },
  {
    path: "/KartuStock",
    name: "Kartu Stock",
    component: KS,
  },
  {
    path: "/TambahUser",
    name: "Tambah User",
    component: User,
  },
  {
    path: "/MasterProject",
    name: "Tambah Master Project",
    component: ProjectConfig,
  },
  {
    path: "/MasterMaterial",
    name: "Tambah Master Material",
    component: MaterialConfig,
  },
  {
    path: "/MasterSupplier",
    name: "Tambah Master Supplier",
    component: SupplierConfig,
  },
  {
    path: "/MasterUnit",
    name: "Tambah Master Unit",
    component: UnitConfig,
  },
  {
    path: "/MasterDepartement",
    name: "Tambah Master Departement",
    component: DepartementConfig,
  },
  {
    path: "/MasterPermission",
    name: "Tambah Master Permission",
    component: PermissionConfig,
  },
  {
    path: "/MasterShift",
    name: "Tambah Master Shift",
    component: ShiftConfig,
  },
  {
    path: "/MasterPosisi",
    name: "Tambah Master Posisi",
    component: PosisiConfig,
  },
  {
    path: "/MasterRole",
    name: "Tambah Master Role",
    component: RoleConfig,
  },
  {
    path: "/MasterHariLibur",
    name: "Tambah Master Hari Libur",
    component: PublicHolidayConfig,
  },
  {
    path: "/MasterTeam",
    name: "Tambah Master Team",
    component: TeamConfig,
  },
  {
    path: "/TambahMaterial",
    name: "Tambah Material",
    component: AddMaterial,
  },
  {
    path: "/EditMaterial/:id",
    name: "Edit Material",
    component: EditMaterial,
  },
  {
    path: "/EditReport/:id",
    name: "Edit Report",
    component: EditReport,
  },
  {
    path: "/ViewReport/:id",
    name: "View Report",
    component: ViewReport,
  },
  {
    path: "/EditMaterialRequest/:id/:projectId",
    name: "EditMaterialRequest",
    component: EditRequest,
  },
  {
    path: "/ApprovalMaterialRequest/:id/:projectId",
    name: "ApprovalMaterialRequest",
    component: ApprovalRequest,
  },
  {
    path: "/EditPO/:id/:projectId",
    name: "Edit Purchase Order",
    component: EditPO,
  },
  {
    path: "/EditSI/:id/:projectId",
    name: "Edit Stock In",
    component: EditSI,
  },
  {
    path: "/EditSO/:id/:projectId",
    name: "Edit Stock Out",
    component: EditSO,
  },
  {
    path: "/EditTranser/:id",
    name: "Edit Transfer Stock",
    component: EditTransfer,
  },
  {
    path: "/EditWorkOrder/:id",
    name: "Edit Work Order",
    component: EditWO,
  },
  {
    path: "/TambahMR",
    name: "Tambah Material Request",
    component: AddMR,
  },
  {
    path: "/TambahPayroll/:id/:userId/:year/:month",
    name: "TambahPayroll",
    component: AddPayroll,
    props: true,
  },
  {
    path: "/TambahPO",
    name: "Tambah Purchase Order",
    component: AddPO,
  },
  {
    path: "/TambahStockIn",
    name: "Tambah Stock In",
    component: AddSI,
  },
  {
    path: "/TambahStockOut",
    name: "Tambah Stock Out",
    component: AddSO,
  },
  {
    path: "/TambahWO",
    name: "Tambah Work Order",
    component: AddWO,
  },
  {
    path: "/Report",
    name: "Report",
    component: Report,
  },
  {
    path: "/TambahReport",
    name: "Tambah Report",
    component: AddReport,
  },
  {
    path: "/TambahTransferStock",
    name: "Tambah Transfer Out",
    component: AddTransfer,
  },
  {
    path: "/TransferStock",
    name: "Transfer Stock",
    component: Transfer,
  },
  {
    path: "/Absensi",
    name: "Absensi",
    components: {
      default: Absensi,
    },
  },
  {
    path: "/Pengajuan",
    name: "Pengajuan",
    components: {
      default: Pengajuan,
    },
  },
  {
    path: "/PengajuanLembur",
    name: "Pengajuan Lembur",
    components: {
      default: PL,
    },
  },
  {
    path: "/DashboardAbsensi",
    name: "Dashboard Absen",
    components: {
      default: DashboardAbsen,
    },
  },
  {
    path: "/RiwayatPengajuan",
    name: "Riwayat Pengajuan ",
    components: {
      default: Riwayat,
    },
  },
];

const router = createRouter({
  history: createWebHistory( process.env.BASE_URL ),
    routes,

  
});


// router.beforeEach((to, from, next) => {
 
//   if (from.path === '/TambahMaterial' && to.path !== '/TambahMaterial') {
   
//     if (confirm('Apakah Anda yakin ingin meninggalkan halaman ini? Perubahan yang Anda buat mungkin tidak disimpan.')) {
//       next(); 
//     } else {
//       next(false);
//     }
//   } else {
//     next();   
//   }
// });

export default router;
