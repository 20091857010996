<template>

    <div class="flex h-screen xl:bg-[#FAFBFD] xxs:bg-[#FAFBFD] "   >
      <!-- Sidebar -->
        <slot name="sidebar"></slot>
     
  
      <div class="flex-1 flex flex-col overflow-hidden " v-if="isLargeScreen">
        <!-- Navbar -->
        <slot name="navbar" ></slot>
        
       
  
        <!-- Page Content -->
        <main class="flex-1 overflow-x-hidden overflow-y-auto xl:px-32 md:px-10 xxs:px-6 py-12 ">
          <slot name="content"></slot>
        </main>
       
      </div>

      <div class="" v-else>
        <!-- Navbar -->
        <slot name="navbar" ></slot>
        
       
  
        <!-- Page Content -->
        <main class="flex-1 overflow-x-hidden overflow-y-auto xl:px-16 md:px-0 xxs:px-6 py-4">
          <slot name="content"></slot>
        </main>
       
      </div>
    
    </div>
  </template>
  
  <script>
  export default {
    name: 'HRLayout',
    props: {
    isDarkMode: Boolean,
  },
  computed: {
    isLargeScreen() {
      return window.innerWidth >= 480; 
    },
  },
  }


  </script>
  
  <style>
  /* Add any custom CSS styles here */
  </style>
  