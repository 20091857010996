<template >


  <div class="px-4 font-pop flex flex-row justify-between font-medium text-[#373636] " style=" animation: muncul-kiri-kanan-fade 0.8s;">
    <div class="flex flex-col">
    <h1 class="text-[24px] uppercase mb-4">Kartu Stock</h1>
    <div class="sm:col-span-full mb-10">
            <label for="project" class="block text-sm font-medium leading-6 text-gray-900">Project</label>
            <div class="mt-2">
              <select v-model="project" id="project" @change="material2" name="project" autocomplete="project-name" class="block w-[24rem] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                <option value="" disabled selected>Pilih Project</option>
                <option v-for="data2 in projectdata2.data" :key="data2.id" :value="data2.id">{{ data2.name }}</option>
              </select>
            </div>
          </div>
          </div>
          <div class="mt-20">
          <button
      class="w-[142px]  px-3 py-2  mr-4 rounded text-white bg-red-500"
      @click="cetaklaporan()"
    >
      <div class="flex flex-row justify-center" >
        <img src="../../assets/printer.png" class="mr-1 w-5" alt="" />
        <h3 class="text-[12px] font-medium">Cetak Laporan</h3>
      </div>
    </button>
  </div>
          
   
  </div>

  <transition>
    <div
      class="fixed z-10 left-0 top-0 w-full h-full flex items-center justify-center overflow-auto bg-black/40 bg-opacity-50 font-pop font-regular"
      v-if="open5"
    >
   
      <div class="bg-white px-16 py-4 rounded-lg shadow-lg">
        <div class="w-full">
          <h1 class="text-[20px] mb-4">Cetak Laporan</h1>
          <hr class="mb-4" />
        
          <div class="flex flex-col gap-4">
          <select name="" v-model="extension" id="" class="px-3 py-2 font-pop rounded text-sm  ">
          <option value="" selected>Pilih Extension</option>
          <option value="xls" >Excel</option>
          <option value="pdf" >PDF</option>
        </select>

       
          <select name="" v-model="harga" id="" class="px-3 py-2 font-pop rounded text-sm  ">
          <option value="" disabled >Pilih Filter</option>
          <option  value="false">Non Harga</option>
          <option  v-if="permidata.data && permidata.data.includes('Harga Kartu Stock')" value="true" >Harga</option>
       
        </select>


 </div>
           
          <div class="flex justify-end">
            <button
              class="mt-8 bg-red-500 px-5 py-3 rounded-md"
              @click="close5()"
            >
              <h1 class="text-[12px] text-white">Kembali</h1>
            </button>
            
            <button
              class="ml-4 mt-8 bg-[#A6CF98] px-[3rem] py-3 rounded-md"
               type="submit" @click="previewReport(this.extension,this.harga)"
            >
              <h1 class="text-[12px] text-white">Cetak</h1>
            </button>
       
          </div>
        </div>
      </div>
    
    </div>
  </transition>
  
  <div class="flex  px-4 font-pop ">
  

    
 
  <div class="w-full text-[#373636]">

    <div class="relative overflow-x-auto shadow-md sm:rounded-lg font-pop" style=" animation: muncul-kiri-kanan-fade 0.8s;">
    <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead class="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
            <tr>
                <!-- <th scope="col" class="p-4">
                    <div class="flex items-center">
                        <input id="checkbox-all" type="checkbox" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                        <label for="checkbox-all" class="sr-only">checkbox</label>
                    </div>
                </th> -->
                <th scope="col" class="px-3 py-3">
                   No
                </th>
                <th scope="col" class="px-6 py-3">
                  Nama Material
                </th>
                <th scope="col" class="px-6 py-3">
                  Quantity
                </th>
                <th scope="col" class="px-6 py-3">
                  Stock In
                </th>
                <th scope="col" class="px-6 py-3">
                  Stock Out
                </th>
                <th scope="col" class="px-6 py-3">
                  Transfer Stock
                </th>
                <th scope="col" class="px-6 py-3">
                  
                </th>
              
            </tr>
        </thead>
        <tbody v-if="materialdata.data" >
            <tr class="bg-white border-b text-[14px] font-medium dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600" v-for="(item,index) in materialdata.data" :key="index">
                <!-- <td class="w-4 p-4">
                    <div class="flex items-center">
                        <input id="checkbox-table-1" type="checkbox" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                        <label for="checkbox-table-1" class="sr-only">checkbox</label>
                    </div>
                </td> -->
                <th scope="row" class="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                  {{ index + 1 }}
                </th>
                <td class="px-6 py-4">
                  {{ item.name  }}
                </td>
                <td class=" px-6 py-4 ">
                  {{ item.quantity }}
                </td>
                <td class="px-6 py-4">
                  {{  item.stock_in}}
                </td>
                <td class="px-6 py-4">
                  {{  item.stock_out}}
                </td>

                <td class="px-6 py-4">
                  <div class="flex flex-row gap-8">
                   {{   item.transfer_stock }}
                </div>
                </td>
                <td class="px-6 py-4">
                  <button @click="toggleVisibility(item.material_id)" class="accordion-header bg-red-500 p-1 rounded shadow-md">
                    <i :class="['fas', item.visible ? 'fa-chevron-down' : 'fa-chevron-up','text-white' ]"></i>
                  </button>
                </td>

                
              

            </tr>
           
        </tbody>
        <tbody v-else style=" animation: muncul-kiri-kanan-fade 0.8s;">
    <tr >
        
    </tr>
</tbody>
    </table>

    
</div>

<div  v-show="isAdditionalTableVisible"   class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50"  style="  animation: fadein 1s;">
    <div class="bg-white p-8 rounded-lg flex flex-col   "  style="animation: muncul-kiri-kanan-fade 1s; ">
      <h1 class="font-medium text-lg mb-4">View Detail Kartu Stock</h1>
      <hr class="w-full" >
          <div  class="h-auto max-h-screen items-center opacity-100 transition-all duration-1000  px-24">
          
    <div class="relative overflow-x-auto shadow-md sm:rounded-lg font-pop mt-6 mb-6">
    <table class="w-full text-[12px] text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead class="t text-gray-700  bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
                <th scope="col" class="px-6 py-3">
                    No
                </th>
                <th scope="col" class="px-6 py-3">
                    Tanggal
                </th>
                <th scope="col" class="px-6 py-3">
                    Status
                </th>
                <th scope="col" class="px-6 py-3">
                    Quantity
                </th>
                <th scope="col" class="px-6 py-3">
                    Balance
                </th>
            </tr>
        </thead>
        <tbody>
            <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"  v-for="(entry, index) in materialdata1.data" :key="index">
              <td class="px-6 py-4">
                    {{ index + 1 }}
                </td>
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {{ entry.date }}
                </th>
                <td class="px-6 py-4">
                    {{ entry.type  }}
                </td>
                <td class="px-6 py-4">
                    {{ entry.quantity   }}
                </td>
                <td class="px-6 py-4">
                    {{ entry.balance }}
                </td>
              
            </tr>
           
        </tbody>
    </table>
</div>
        </div>
        <button
                class=" max-w-full w-[10rem] mt-8 bg-red-500 px-5 py-3 rounded-md"
                @click="isAdditionalTableVisible =false"
              >
                <h1 class="text-[12px] text-white">Kembali</h1>
              </button>
    </div>
  </div>





    
    <!-- <div class="bg-white p-2  rounded-t-lg  mb-4 flex cursor-pointer  justify-between font-semibold text-[14px]"  style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; animation: muncul-kiri-kanan-fade 0.8s;">
     <span>#</span>
     <span>Nama Material</span>
     <span>Quantity</span>
     <span>Stock In</span>
     <span>Stock Out</span>
     <span>Transfer Stock</span>
     <span></span>
     
    </div>
    <div v-for="(item,index) in materialdata.data" :key="item.project_id">
       
        <div
          class="group flex flex-col gap-2 rounded-lg bg-white p-5  mb-2 text-[#373636] font-medium"
          style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; animation: muncul-kiri-kanan-fade 0.8s;"
      >
          <div class="flex cursor-pointer  justify-between">
            <span>{{ index + 1 }}</span>
            <span>{{ item.name }}</span>
            <span>{{ item.quantity }}</span>
            <span>{{ item.stock_in }}</span>
            <span>{{ item.stock_out }}</span>
            <span>{{ item.transfer_stock }}</span>
           
            <button @click="toggleVisibility(item.material_id)" class="accordion-header bg-red-500 p-1 rounded shadow-md">
              <i :class="['fas', item.visible ? 'fa-chevron-down' : 'fa-chevron-up','text-white' ]"></i>
        </button>
          </div>
          <hr class="w-full" v-show="item.visible">
          <div v-show="item.visible" class="h-auto max-h-screen items-center opacity-100 transition-all duration-1000  px-24">
          
            <div class="relative overflow-x-auto shadow-md sm:rounded-lg font-pop mt-6 mb-6">
    <table class="w-full text-[12px] text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead class="t text-gray-700  bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
                <th scope="col" class="px-6 py-3">
                    No
                </th>
                <th scope="col" class="px-6 py-3">
                    Tanggal
                </th>
                <th scope="col" class="px-6 py-3">
                    Status
                </th>
                <th scope="col" class="px-6 py-3">
                    Quantity
                </th>
                <th scope="col" class="px-6 py-3">
                    Balance
                </th>
            </tr>
        </thead>
        <tbody>
            <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"  v-for="(entry, index) in materialdata1.data" :key="index">
              <td class="px-6 py-4">
                    {{ index + 1 }}
                </td>
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {{ entry.date }}
                </th>
                <td class="px-6 py-4">
                    {{ entry.type  }}
                </td>
                <td class="px-6 py-4">
                    {{ entry.quantity   }}
                </td>
                <td class="px-6 py-4">
                    {{ entry.balance }}
                </td>
              
            </tr>
           
        </tbody>
    </table>
</div>
        </div>
        
        </div>
      </div> -->
      
   
  </div>

  
  
  
  
  
  </div>

  


  <div v-if="successModal" class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50"  style="  animation: fadein 1s;">
    <div class="bg-white p-8 rounded-lg flex flex-col justify-center items-center  "  style="animation: muncul-kiri-kanan-fade 1s; ">
      <img src="../../assets/verify.png" class="w-16 h-16 mb-6 " alt="">
      <p>Data Material Request Berhasil ditambahkan</p>
    </div>
  </div>

  <div v-if="errorModal" class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50" style="animation: fadein 1s;">
    <div class="bg-white p-8 rounded-lg flex flex-col justify-center items-center  "  style="animation: muncul-kiri-kanan-fade 1s; ">
      <img src="../../assets/cancel.png" class="w-16 h-16 mb-6 " alt="">
      <p class="  font-semibold w-72 text-center" >{{ message }}</p>
    </div>
  </div>

  <div v-if="uploading" class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50" style="animation: fadein 1s;">
        <div class="bg-white p-8 rounded-lg flex flex-col justify-center items-center  "  style="animation: muncul-kiri-kanan-fade 1s; ">
          <img src="../../assets/loading.gif" class="w-16 h-16 mb-6 " alt="">
          <p class="  font-semibold w-72 text-center" >Laporan Sedang diproses ...</p>
        </div>
      </div>

      <div v-if="previewUrl" class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50"  style="animation: muncul-kiri-kanan-fade 1s;" @click="closePreview">
      <iframe :src="previewUrl" class="flex items-center justify-center w-[100rem] h-[50rem]" @click="preventClose"></iframe>
    </div>
    
    </template>
    
    <script>
    import axios from "../../token";
  
  
    export default {
      data() {
        return {
          id: localStorage.getItem('id') || 'Default Value',
          isOpen: false,
          open: "",
          project:'',
          material:"",
          harga:"false",
          qty:"",
          price:"",
          weight:"",
          coo:"",
          extension:"",
          uploading:false,
          previewUrl:null,
          open5:false,
          dimension:"",
          successModal:false,
          errorModal:false,
          status:"Proses Pengerjaan",
          categorydata:[],
          pagination: {
        current_page: 1,
        from: null,
        last_page: null
      },
          projectdata:[],
          projectdata2:[],
        isAdditionalTableVisible: false,

          userdata:[],
          materialdata:[],
          materialdata1:[],
          search:'',
          permidata: [],
        };
      },
      computed: {
        filteredEntries() {
          let filtered = this.absendata;
    
          if (this.selectedStatus.length > 0) {
            filtered = filtered.filter((abse) =>
              this.selectedStatus.includes(abse.status)
            );
          }
    
          if (this.selectedSort === "asc") {
            return filtered.sort((a, b) => a.jammasuk.localeCompare(b.jammasuk));
          } else {
            return filtered.sort((a, b) => b.jammasuk.localeCompare(a.jammasuk));
          }
        },
      },
      methods: {
        toggleModal() {
          this.isOpen = !this.isOpen;
        },
        closePreview() {
      this.previewUrl = null;
    },
    close5(){
      this.open5=false;
    },
    cetaklaporan(){
      this.open5=true;
    },
    getlaporan(extension, filter) {
  let params = {
    with_price: filter,
    project_id: this.project,
    extension: extension // Menggunakan nilai ekstensi yang diterima
  };

  this.uploading = true;
  axios
    .get(`${process.env.VUE_APP_BACKEND_HOST}/material-project/stock-card/report`, {
      responseType: 'blob',
      params: params 
    })
    .then((response) => {
      this.uploading = false;
      if (extension === 'pdf') {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        this.previewUrl = window.URL.createObjectURL(blob);
      } else if (extension === 'xls') {
        // Create a blob with Excel content type
        const blob = new Blob([response.data], { type: 'application/x-msexcel' });
        // Invoke download function directly
        this.downloadBlob(blob, 'report.xls');
      }
    })
    .catch((error) => {
      this.uploading = false; // Pastikan set uploading ke false pada kesalahan
      console.error(error);
    });
},

previewReport(extension, filter) {
  this.getlaporan(extension, filter);
},

exportReport() {
  if (this.previewUrl) {
    window.open(this.previewUrl, '_blank');
  } 
},

downloadBlob(blob, filename) {
  // Create a temporary link element
  const link = document.createElement('a');
  // Set link's href to object URL of the blob
  link.href = window.URL.createObjectURL(blob);
  // Set download attribute to specify filename
  link.download = filename;
  
  // Programmatically trigger click event to download
  link.click();
  // Cleanup
  window.URL.revokeObjectURL(link.href);
},

        openModal() {
          this.isModalOpen = true;
        },
        closeModal() {
          this.isModalOpen = false;
        },
        toggleVisibility(id) {
  const clickedItem = this.materialdata.data.find(item => item.material_id === id);

  if (clickedItem) {
    clickedItem.visible = !clickedItem.visible;
    this.isAdditionalTableVisible = clickedItem.visible;

    this.materialdata.data.forEach(item => {
      if (item.material_id !== id) {
        item.visible = false;
      }
    });

    if (clickedItem.visible) {
      this.getmaterial1(id);
      this.materialdata1 = [];
    } else {
      this.materialdata1 = [];
    }
  }
},
      material2() {
    this.material1 = this.project;
    console.log(this.material1)
    if (this.material1) {
      this.getmaterial()
    } else {
      this.material1 = '';
    }
  },
  
        async submitForm() {
          try {
            const response = await axios.post(`${process.env.VUE_APP_BACKEND_HOST}/project`, {
              project: this.project,
              material: this.material,
              qty: this.qty,
              price: this.price,
              weight: this.weight,
              coo: this.coo,
              dimension: this.dimension
            });
            console.log(response);
            await new Promise(resolve => setTimeout(resolve, 1000));
            this.successModal = true;
    
            this.getproject();
          
            setTimeout(() => {
              this.successModal = false;
              this.open = false;
            }, 1000);
          } catch (error) {
            console.log(error);
            this.message = "There was an error submitting the form.";
          }
        },
  //       async deleteform(id) {
  //     try {
  //         const response = await axios.delete(`${process.env.VUE_APP_BACKEND_HOST}/material-take-off/`, {
  //             params: {
  //                 id: id
  //             }
  //         });
  //         console.log(response);
  //         await new Promise(resolve => setTimeout(resolve, 1000));
  //         this.successModal = true;
  //         this.getproject();
  //         setTimeout(() => {
  //             this.successModal = false;
  //             this.open = false;
  //         }, 1000);
  //     } catch (error) {
  //         console.log(error);
  //         this.message = "There was an error submitting the form.";
  //     }
  // },
  async deleteMaterial(id) {
        try {
          let data = new URLSearchParams();
          data.append('id[]', id);
  
          let response = await axios.delete('http://192.168.36.20:3001/api/material-take-off', {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
             
            },
            data: data
          });
          this.getmaterial();
          console.log(response.data);
          // Handle response as needed
        } catch (error) {
          console.error(error);
          // Handle error
        }
      },
        getcategory() {
          axios
            .get(`${process.env.VUE_APP_BACKEND_HOST}/project-category`)
            .then((response) => {
              this.categorydata = response.data;
    
            })
            .catch((error) => {
              console.log(error);
            });
        },
        getuser() {
          axios
            .get(`${process.env.VUE_APP_BACKEND_HOST}/material`)
            .then((response) => {
              this.userdata = response.data;
    
            })
            .catch((error) => {
              console.log(error);
            });
        },
        getproject2() {
          axios
            .get(`${process.env.VUE_APP_BACKEND_HOST}/project/list`)
            .then((response) => {
              this.projectdata2 = response.data;
    
            })
            .catch((error) => {
              console.log(error);
            });
        },
        getproject() {
          axios
            .get(`${process.env.VUE_APP_BACKEND_HOST}/unit`)
            .then((response) => {
              this.projectdata = response.data;
    
            })
            .catch((error) => {
              console.log(error);
            });
        },
        getmaterial() {
          axios
            .get(`${process.env.VUE_APP_BACKEND_HOST}/material-project/list`,{
              params: this.project !== '' ? { project_id: this.project } : null
            })
            .then((response) => {
              this.materialdata = response.data;
            
            })
            .catch((error) => {
              console.log(error);
              console.log(this.project)
             
              if (this.project) {
  
              this.message = error.response.data.message;
              this.errorModal = true;
              

              setTimeout(() => {
                this.errorModal = false;
                this.open = false;
                this.project=''
              }, 3000); }
            });
        },
    //     async getmaterial(page) {
    //   let params = {
    //     search: this.search,
    //     limit: 10,
    //     page: Number.isInteger(page) ? page : 1
    //   };

    //   if (this.filterValue) {
    //     params.filter = 'project';
    //     params.filter_value = this.filterValue;
    //   }

    //   if (this.urutan1) {
    //     params.sort = this.urutan1;
    //     params.sort_by = 'code';
    //   }

    //   try {
    //     const response = await axios.get(`${process.env.VUE_APP_BACKEND_HOST}/material-request/list`, { params });
    //     this.materialdata = response.data;
    //     this.pagination = {
    //       current_page: response.data.current_page,
    //       from: response.data.from,
    //       last_page: response.data.last_page
    //     };
    //   } catch (error) {
    //     console.error(error);
    //     this.materialdata = [];
    //     this.pagination = {
    //       current_page: 1,
    //       from: null,
    //       last_page: null
    //     };
    //   }
    // },
        getmaterial1(id,page) {
          axios
            .get(`${process.env.VUE_APP_BACKEND_HOST}/material-project/history`,{
              params: this.project !== '' ? { project_id: this.project,material_id:id} : null,
              limit:2,
              page: Number.isInteger(page) ? page : 1
            })
            .then((response) => {
              this.materialdata1 = response.data;
              this.pagination = {
          current_page: response.data.current_page,
          from: response.data.from,
          last_page: response.data.last_page
        }
            })
            .catch((error) => {
              console.log(error);
              this.isAdditionalTableVisible= false
              this.materialdata1 = [];
        this.pagination = {
          current_page: 1,
          from: null,
          last_page: null
        };
              if (this.project) {
              this.message = error.response.data.message;
              this.errorModal = true;

              setTimeout(() => {
                this.errorModal = false;
                this.open = false;
                
              }, 3000); }
            });
        },
        getpermi1() {
      axios
        .get(`${process.env.VUE_APP_BACKEND_HOST}/permission/check`)
        .then((response) => {
          this.permidata = response.data;

        
        })
        .catch((error) => {
          console.log(error);
        });
    },
      },
      mounted(){
        this.getcategory();
        this.getproject2();
        this.getproject();
        this.getuser();
        this.getmaterial();
        this.getpermi1();
      }
    };
    </script>
    