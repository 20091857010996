<template>
    <dashboard-layout :isDarkMode="isDarkMode">
      <!-- Sidebar content -->
      <template #sidebar>
        <SideBar v-if="isLargeScreen" :isDarkMode="isDarkMode" @toggleDarkMode="toggleDarkMode"  />
        <BottomBar v-else/>
      </template>
  
      <!-- Navbar content -->
      <template #navbar>
        <NavBar :isDarkMode="isDarkMode" />
      </template>
  
      <!-- Page content -->
      <template #content>
        <MonitorView :isDarkMode="isDarkMode" />
      </template>
    </dashboard-layout>
  </template>
  
  <script>
import NavBar from '../Absensi/Component/NavBar'
import BottomBar from '../Absensi/Component/BottomBar'

import MonitorView from '../Absensi/Component/MonitorView.vue'

import DashboardLayout from "../Absensi/Component/DashboardLayout.vue";
  
  
  export default {
    components: {
    NavBar,
    BottomBar,
    DashboardLayout,
    MonitorView
},
    computed: {
    isLargeScreen() {
      return window.innerWidth > 480; 
    },
  },
  data() {
    return {
      isDarkMode: false,
    };
  },
  methods: {
    toggleDarkMode() {
      this.isDarkMode = !this.isDarkMode;
    },
  },
  };
  </script>
  
  <style>
  /* Add any custom CSS styles here */
  </style>