<template>
    <div class="font-pop flex flex-row gap-8">
    <div v-for="(item, index) in items" :key="index" class="flex flex-col bg-white rounded-lg w-[24rem] py-10" style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; animation: muncul-kiri-kanan-fade 0.5s;">
      <div class="flex flex-col justify-between">
        <div class="flex flex-row  items-center font-medium px-14 gap-2">
          <div class="text-center">
          <h1 class="text-[26px]">{{ item.title }}</h1>
          <h2 class="text-[24px]">{{ item.value }}</h2>
        </div>
          <div class="flex flex-col font-medium">
          <img :src="item.image" class="w-24 h-24 mr-10 ml-10" alt="">
         
        </div>
        
        </div>
        <p class="text-center mt-8 font-medium">{{ item.description }}</p>
        
      </div>
    </div>
  </div>
         
          <div class="flex flex-col font-pop  mt-14 ">
            <div class="flex flex-row mb-4 font-medium">
            <!-- <div class="" style="  animation: muncul-kiri-kanan-fade 1s;">Statistik Inventory</div> -->
            <div class="" style="  animation: muncul-kiri-kanan-fade 1s;">Informasi Data Material Request</div>
          </div>
          <div class="flex flex-row gap-8">
            <!-- <div class="flex flex-col justify-center items-center bg-white  rounded-lg  w-[24rem] h-[23rem] " style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;  animation: muncul-kiri-kanan-fade 1s;">
            <donut />
            <p class="text-center text-[14px] px-10 mt-4" style="  animation: muncul-kiri-kanan-fade 1s;">Data disajikan untuk memotoring  berapa jumlah data dari MTO,MR dan PO</p>
            </div> -->

    <div class="relative w-full overflow-x-auto shadow-md sm:rounded-lg font-pop" style=" animation: muncul-kiri-kanan-fade 0.8s;">
    <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead class="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
            <tr>
                <!-- <th scope="col" class="p-4">
                    <div class="flex items-center">
                        <input id="checkbox-all" type="checkbox" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                        <label for="checkbox-all" class="sr-only">checkbox</label>
                    </div>
                </th> -->
                <th scope="col" class="px-3 py-3">
                   No
                </th>
                <th scope="col" class="px-6 py-3">
                  ID Material Request
                </th>
                <th scope="col" class="px-6 py-3">
                  Nama Project
                </th>
                <th scope="col" class="px-6 py-3">
                  Total Item
                </th>
                <th scope="col" class="px-6 py-3">
                  Dibuat Oleh
                </th>
                <th scope="col" class="px-6 py-3">
                  Status
                </th>
               
            </tr>
        </thead>
        <tbody v-if="materialdata.data" >
            <tr class="bg-white border-b text-[14px] font-medium dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600" v-for="(item,index) in materialdata.data" :key="item.project_id">
                <!-- <td class="w-4 p-4">
                    <div class="flex items-center">
                        <input id="checkbox-table-1" type="checkbox" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                        <label for="checkbox-table-1" class="sr-only">checkbox</label>
                    </div>
                </td> -->
                <th scope="row" class="px-3 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                  {{ index + 1 }}
                </th>
                <td class="px-6 py-4">
                  {{ item.code  }}
                </td>
                <td class="px-6 py-4">
                  {{ item.project_name }}
                </td>
                <td class="px-6 py-4">
                  <p class="">{{ item.total_item  }}</p>
                </td>
                <td class="px-6 py-4">
                  {{  item.created_by}}
                </td>
                <td class="px-6 py-4">
                  <button
                        :class="{
                            'bg-[#FFE69A]/30 text-[#EFB812]': item.status === 'Proses',
                            'bg-[#FF5E5E]/30 text-[#ED4747]': item.status === 'Ditolak',
                            'bg-[#82C97C]/30 text-[#21B115]': item.status === 'Disetujui',
                        }"
                        class="w-[118px] h-[30px] flex-shrink-0 rounded-lg  ml-4"
                        >
                        <h3 class="text-[12px] font-medium">{{ item.status }}</h3>
                        </button>
                </td>

                <!-- <td class="px-6 py-4">
                  <div class="flex flex-row gap-8">
                  <router-link :to="'/EditMaterialRequest/' + item.id + '/' + item.project_id">
                  <button @click="toggleVisibility(item.id)" class="accordion-header  ">
                    <img class="w-8 h-8" src="../assets/edit.png" alt="">
                  </button>
                  </router-link>
                  <router-link :to="'/ApprovalMaterialRequest/' + item.id + '/' + item.project_id">
                  <img class="w-8 h-8" src="../assets/notifikasi.png" alt="">
                  </router-link>
                </div>
                </td> -->
            </tr>
           
        </tbody>
        <tbody v-else style=" animation: muncul-kiri-kanan-fade 0.8s;">
    <tr >
        <td colspan="6" class="text-center font-pop text-[16px] py-4 text-gray-600 dark:text-gray-400">Data Tidak Tersedia</td>
    </tr>
</tbody>
    </table>
</div>
</div>



          </div>

      
</template>

<script>
// import donut  from '../Invetory/DoughnutChart.vue';
import axios from "../token";

export default{
  components:{
    // donut,

  },
  data() {
      return {
        id: localStorage.getItem('id') || 'Default Value',
        isOpen: false,
        open: "",
        project:"",
        material:"",
        qty:"",
        price:"",
        weight:"",
        coo:"",
        dimension:"",
        materialrequest:'',
        successModal:false,
        status:"Proses Pengerjaan",
        categorydata:[],
        projectdata:[],
      
 
        countdata:[],
        projectdata2:[],
        userdata:[],
        materialdata:[],
        search:'',
        items: [
        { title: 'Total', value: '', image:require('@/assets/MTO.png'), description: 'Material Take Off' },
        { title: 'Total', value: '', image:require('@/assets/learning.png'), description: 'Material Request' },
        { title: 'Total', value: '', image:require('@/assets/po.png'), description: 'Purchase Order' },
        { title: 'Total', value: '', image:require('@/assets/stockin.png'), description: 'Stock In' },
        { title: 'Total', value: '', image:require('@/assets/stockout.png'), description: 'Stock Out' }
      ],
   
      };
    },
    computed: {
      filteredEntries() {
        let filtered = this.absendata;
  
        if (this.selectedStatus.length > 0) {
          filtered = filtered.filter((abse) =>
            this.selectedStatus.includes(abse.status)
          );
        }
  
        if (this.selectedSort === "asc") {
          return filtered.sort((a, b) => a.jammasuk.localeCompare(b.jammasuk));
        } else {
          return filtered.sort((a, b) => b.jammasuk.localeCompare(a.jammasuk));
        }
      },
      materialDataLength() {
      return this.materialdata.length;
    }
    },
    methods: {
      toggleModal() {
        this.isOpen = !this.isOpen;
      },
      openModal() {
        this.isModalOpen = true;
      },
      closeModal() {
        this.isModalOpen = false;
      },
      getImageSource(status) {
      if (status === 'Ditolak') {
        return require("../assets/rejected.gif");
      } else if (status === 'Disetujui') {
        return require("../assets/file.gif");
      } else {
        return require("../assets/file.gif");
      }
    },
    getStatusStyle(status) {
      if (status === 'Proses') {
        return { backgroundColor: 'rgba(255, 230, 154, 0.5)' }; 
      } else if (status === 'Ditolak') {
        return { backgroundColor: 'rgba(255, 94, 94, 0.5)' }; 
      } else if (status === 'Disetujui') {
        return { backgroundColor: '#00ff00' };
      }
      
      return {};
    },
    getTextClass(status) {
     
      if (status === 'Ditolak') {
        return 'text-[#ED4747]';
      } else if (status === 'Disetujui') {
        return 'text-[#21B115]';
      }
     
      return 'text-[#EFB812]'; 
    },
      async submitForm() {
        try {
          const response = await axios.post(`${process.env.VUE_APP_BACKEND_HOST}/project`, {
            project: this.project,
            material: this.material,
            qty: this.qty,
            price: this.price,
            weight: this.weight,
            coo: this.coo,
            dimension: this.dimension
          });
          console.log(response);
          await new Promise(resolve => setTimeout(resolve, 1000));
          this.successModal = true;
  
          this.getproject();
        
          setTimeout(() => {
            this.successModal = false;
            this.open = false;
          }, 1000);
        } catch (error) {
          console.log(error);
          this.message = "There was an error submitting the form.";
        }
      },
      getcount() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/project/count`)
          .then((response) => {
            this.countdata = response.data;

         
          const firstEntry = this.countdata.data;
          this.items[0].value = parseInt(firstEntry.material_take_off);
          this.items[1].value = firstEntry.material_request;
          this.items[2].value = firstEntry.purchase_order;
          this.items[3].value = firstEntry.stock_in;
          this.items[4].value = firstEntry.stock_out;
           
          })
          .catch((error) => {
            console.log(error);
          });
      },
      getcategory() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/project-category`)
          .then((response) => {
            this.categorydata = response.data;
  
          })
          .catch((error) => {
            console.log(error);
          });
      },
      getuser() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/material`)
          .then((response) => {
            this.userdata = response.data;
  
          })
          .catch((error) => {
            console.log(error);
          });
      },
      getproject2() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/project/list`)
          .then((response) => {
            this.projectdata2 = response.data;
  
          })
          .catch((error) => {
            console.log(error);
          });
      },
      getproject() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/unit`)
          .then((response) => {
            this.projectdata = response.data;
  
          })
          .catch((error) => {
            console.log(error);
          });
      },
      getmaterial() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/material-request/list`,{
            params:{
                search:this.search,
                limit:5
            }
          })
          .then((response) => {
            this.materialdata = response.data;
  
          })
          .catch((error) => {
            console.log(error);
          });
      },
    },

    mounted(){
      this.getcategory();
      this.getproject2();
      this.getproject();
      this.getuser();
      this.getmaterial();
      this.getcount();
    }
}
</script>
