<template>
   <div class="">
        <button class="w-[118px] h-[30px] flex-shrink-0 rounded-lg text-[#868686] bg-[#EBEDF0] "  @click="open = true">
            <h3 class="text-[12px] font-medium">Lihat Data</h3>
          </button>
    </div>
    <transition>
    <div
      class="fixed z-10 left-0 top-0 w-full h-full flex items-center justify-center overflow-auto bg-black/40 bg-opacity-50 font-pop font-regular"
      v-if="open"
    >
   
      <div class="bg-white px-16 py-4 w-[50rem] rounded-lg shadow-lg">
        <div class="w-full">
          <h1 class="text-[20px] mb-4">Ubah Presensi</h1>
          <hr class="mb-4" />
          <div class="border-b  border-gray-900/10 pb-12  pb-6">
            <h1 class="font-pop font-medium mt-6 mb-4" style="  animation: muncul-kiri-kanan-fade 0.5s;">Perubahan Presensi</h1>
          <div class="mt-4 grid xxs:grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-6">
            
         
           
            <div class="sm:col-span-3">
                <label for="country" class="block text-sm font-medium leading-6 text-gray-900">Nama Karyawan</label>
                <div class="mt-2">
                  <select   v-model="name" @change="material2"  id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6" disabled>
                    <option value="" disabled selected>Pilih Karyawan</option>
                    <option
                      v-for="data1 in userdata.data"
                      :key="data1.user.name"
                      :value="data1.id"
              >
                {{ data1.user.name }}
              </option>
                  </select>
                </div>
              </div>

              <div class="sm:col-span-3">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Tanggal</label>
              <div class="mt-2">
                <input    v-model="date" type="date" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>

            <div class="sm:col-span-2">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Jam Masuk</label>
              <div class="mt-2">
                <input    v-model="time1" type="time" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>

            <div class="sm:col-span-2">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Jam Keluar</label>
              <div class="mt-2">
                <input    v-model="time2" type="time" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>

            <div class="sm:col-span-2">
                <label for="country" class="block text-sm font-medium leading-6 text-gray-900">Shift Kerja</label>
                <div class="mt-2">
                  <select   v-model="day" id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                    <option value="" disabled selected>Pilih Shift Kerja</option>
                    <option
                      v-for="data1 in daydata.data"
                      :key="data1.id"
                      :value="data1.id"
              >
                {{ data1.name }}
              </option>
                  </select>
                </div>
              </div>

            </div>
          </div>
  
              
  

          <div class="flex justify-end">
            <button
              class="mt-8 bg-red-500 px-5 py-3 rounded-md"
              @click="open = false"
            >
              <h1 class="text-[12px] text-white">Kembali</h1>
            </button>
            <form @submit.prevent="submitAbsen">
            <button
              class="ml-4 mt-8 bg-green-400  px-[3rem] py-3 rounded-md"
               type="submit"
            >
              <h1 class="text-[12px] text-white">Simpan</h1>
            </button>
          </form>
          </div>
        </div>
      </div>

    </div>
  </transition>

  <div v-if="successModal" class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50"  style="  animation: fadein 1s;">
    <div class="bg-white p-8 rounded-lg flex flex-col justify-center items-center  "  style="animation: muncul-kiri-kanan-fade 1s; ">
      <img src="../../assets/verify.png" class="w-16 h-16 mb-6 " alt="">
      <p>Data Permohonan Berhasil Diajukan</p>
    </div>
  </div>

  <div v-if="errorModal" class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50" style="animation: fadein 1s;">
    <div class="bg-white p-8 rounded-lg flex flex-col justify-center items-center  "  style="animation: muncul-kiri-kanan-fade 1s; ">
      <img src="../../assets/cancel.png" class="w-16 h-16 mb-6 " alt="">
      <p class="  font-semibold w-72 text-center" >{{ message }}</p>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
export default {
  data() {
    return {
      open: false,
      Nama:'',
      NomorInduk:"",
      permohonanData: [], 
      successModal:false,
      errorModal:false,
      userdata:[],
      daydata:[],
      posisiId:"",
      departementId:"",
      divisiId:"",
      manager:"",
      permohonan:'',
      mulaipengajuan:new Date().toISOString().substr(0, 10),
      selesaipengajuan:new Date().toISOString().substr(0, 10),
      keterangan:"",
      selectedFileName: "",
      berkas:"",
      selectedMonth: new Date().getMonth() + 1, // Default to the current month
      selectedYear: new Date().getFullYear(), // Default to the current year
      months: [
        'Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni',
        'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'
      ],
      years: [2022, 2023, 2024], // You can customize the list of years
      
      status:"Pending",
      name:this.permohonandata.user_id,
      date:this.permohonandata.date,
      time1:this.permohonandata.time_in,
      time2:this.permohonandata.time_out,
    };
  },
  props: {
    permohonandata: {
      type: Object, // Adjust the type according to your data structure
      required: true,
    },
  },
  
  created() {
   
    const initialDatetime = new Date().toISOString().slice(0, 16); 
    this.selectedDatetime = initialDatetime;
  }, 
  methods: {
   
    getday() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/work-shift`,{
           
          })
          .then((response) => {
            this.daydata = response.data;
  
          })
          .catch((error) => {
            console.log(error);
          });
      },
   
        async submitAbsen() {
          
          const formData = new FormData();
    
          formData.append('user_id',this.name);
          formData.append('date',this.date);
          formData.append('time_in',this.time1);
          formData.append('time_out',this.time2);
          formData.append('workshift_id', this.day);
          formData.append('presence_id', this.permohonandata.id);
          
          try {
            const response = await axios.post(`${process.env.VUE_APP_BACKEND_HOST}/presence/modify`, formData);
    
            console.log(response);
          
            this.successModal = true;
        
          
            setTimeout(() => {
              this.successModal = false;
              this.open = false;
            }, 2000);
            setTimeout(() => {
          }, 2000);
          } catch (error) {
            console.log(error);
            this.uploading = false; 
            this.errorModal = true;
            setTimeout(() => {
              this.errorModal = false;
            }, 3000);
            this.message = error.response.data.message;
          } 
        },
   
    getPermohonan() {
      axios
        .get(`${process.env.VUE_APP_BACKEND_HOST}/presence/list`,{
          params:{
            date:'2023-12-14'
          }
        })
        .then((response) => {
          this.permohonanData = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getuser() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/hrd/employee`)
          .then((response) => {
            this.userdata = response.data;
  
          })
          .catch((error) => {
            console.log(error);
          });
      },
  },
  mounted() {
    this.getPermohonan();
    this.getuser();
    this.getday();
  },
};
</script>
<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
