<template>
  
   <div class="md:flex md:flex-row md:justify-between xxs:hidden">
  
    <div class="relative text-gray-600">
  <input type="search" name="serch" placeholder="Pencarian..." class="bg-[#FAFBFD] h-10 px-5 pr-24 rounded text-sm focus:outline-none">
  <button type="submit" class="absolute right-0 top-0 mt-3 mr-4">
    <svg class="h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 56.966 56.966" style="enable-background:new 0 0 56.966 56.966;" xml:space="preserve" width="512px" height="512px">
      <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z"/>
    </svg>
  </button>
</div>

<div class="">
  <transition name="fade-in">
 
  <button
      class="w-[142px] h-[30px] p-1 mr-4 rounded text-white bg-red-500"
      @click="open = true"
    >
      <div class="flex flex-row justify-center">
        <img src="../../assets/add.png" class="mr-1 w-5" alt="" />
        <h3 class="text-[12px] font-medium">Tambah Team</h3>
      </div>
    </button>
 
</transition>
   
 
</div>

  </div>

  <div class="grid md:grid-cols-4 gap-10 justify-between items-center xxs:grid-cols-1 font-pop mt-10">
  <div v-for="category in categorydata.data" :key="category.id"  style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;  animation: muncul-kiri-kanan-fade 1s;"   @click="openCategory(category.id)"
  class="relative flex w-72 h-52 flex-col overflow-hidden rounded-xl bg-white bg-clip-border text-gray-700 shadow-md">
 
  <div class="p-6">
    <h4 class="block font-pop text-[16px] antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
      {{ category.name }}
    </h4>
    <p class="block mt-3 font-sans text-xl antialiased font-normal leading-relaxed text-gray-700">
      {{ category.leader }}
    </p>
  </div>
  <div class="flex items-center justify-between p-6">
    <div class="flex items-center -space-x-3">
      <img alt="natali craig"
        src="https://i.pinimg.com/originals/c6/e9/ed/c6e9ed167165ca99c4d428426e256fae.png"
        class="relative inline-block h-9 w-9 !rounded-full  border-2 border-white object-cover object-center hover:z-10" />
      <img alt="Tania Andrew"
        src="https://i.pinimg.com/originals/c6/e9/ed/c6e9ed167165ca99c4d428426e256fae.png"
        class="relative inline-block h-9 w-9 !rounded-full  border-2 border-white object-cover object-center hover:z-10" />
    </div>
    <p class="block font-pop text-base antialiased font-normal leading-relaxed text-inherit">
      Total Member {{ category.member_count }}
    </p>
  </div>
</div> 
</div>

  <!-- <div class="grid md:grid-cols-4 gap-10 justify-between items-center xxs:grid-cols-1 font-pop mt-10">
     
     <div v-for="category in categorydata.data" :key="category.id"  class="  bg-white  rounded overflow-hidden cursor-pointer px-6 "  style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;  animation: muncul-kiri-kanan-fade 1s;"   @click="openCategory(category.id)">
       <transition name="fade-in">
         <div class="flex flex-row  items-center">
          <h2 class="text-[12px] font-semibold mb-2">{{ category.name }}</h2>
           <div class=" flex flex-col p-6"> 
            
           
           </div>
           
          
         
       
       </div>
     </transition>
     </div>
 
   </div> -->

  <transition>
    <div
      class="fixed z-10 left-0 top-0 w-full h-full flex items-center justify-center overflow-auto bg-black/40 bg-opacity-50 font-pop font-regular"
      v-if="open"
    >
   
      <div class="bg-white px-16 py-4 w-[50rem] rounded-lg shadow-lg">
        <div class="w-full">
          <h1 class="text-[20px] mb-4">Tambah Team</h1>
          <hr class="mb-4" />
          <div class="border-b  border-gray-900/10 pb-12  pb-6">
          <div class="mt-4 grid xxs:grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-6">
            
          <div class="sm:col-span-3">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Nama Team</label>
              <div class="mt-2">
                <input    v-model="name" type="text" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>

            <div class="sm:col-span-3">
                <label for="country" class="block text-sm font-medium leading-6 text-gray-900">Nama Team Leader</label>
                <div class="mt-2">
                  <select   v-model="teamleader" id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                    <option value="" disabled selected>Pilih Team Leader</option>
                    <option
                      v-for="data1 in userdata.data"
                      :key="data1.id"
                      :value="data1.id"
              >
                {{ data1.user.name }}
              </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
             <div class="flex flex-row">
              <div class="sm:col-span-full mt-6 mr-6">
                <label for="country" class="block text-sm font-medium leading-6 text-gray-900">Nama Anggota </label>
                <div class="mt-2">
                  <select   v-model="member" id="country" name="country" autocomplete="country-name" class="block max-w-full w-[25rem] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                    <option value="" disabled selected>Pilih Anggota</option>
                    <option
                      v-for="data1 in userdata.data"
                      :key="{ id: data1.id, name: data1.user.name  }" :value="{ id: data1.id, name: data1.user.name }"
              >
                {{ data1.user.name }}
              </option>
                  </select>
                </div>
                
              </div>
              <div class="mt-14">
               <form @submit.prevent="submittemp">
              <button type="submit" class="px-4 py-2 text-sm font-medium text-white bg-green-400 rounded-md hover:bg-green-700 focus:outline-none ">Tambahkan</button>
            </form>
            </div>
          </div>
              

             

  <div class="border-b  border-gray-900/10 pb-12  pb-6">
  <div class="relative overflow-x-auto shadow-md sm:rounded-lg font-pop mt-10">
    <table class="w-full text-[12px] text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead class="t text-gray-700  bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
                <th scope="col" class="px-6 py-3">
                    No
                </th>
                <th scope="col" class="px-6 py-3">
                    Nama
                </th>
                <th scope="col" class="px-6 py-3">
                    Aksi
                </th>
            </tr>
        </thead>
        <tbody>
            <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"  v-for="(entry, index) in entries" :key="index">
              <td class="px-6 py-4">
                    {{ index + 1 }}
                </td>
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {{ entry.member.name }}
                </th>
                <td class="px-6 py-4">
                  <a
          href="#"
          class="font-medium text-red-600 dark:text-red-500 hover:underline"
          @click.prevent="removeEntry(index)"
        >Hapus</a>
                </td>
            </tr>
           
        </tbody>
    </table>
</div>
</div>

          <div class="flex justify-end">
            <button
              class="mt-8 bg-red-500 px-5 py-3 rounded-md"
              @click="closeModal()"
            >
              <h1 class="text-[12px] text-white">Kembali</h1>
            </button>
            <form @submit.prevent="submitForm">
            <button
              class="ml-4 mt-8 bg-green-400  px-[3rem] py-3 rounded-md"
               type="submit"
            >
              <h1 class="text-[12px] text-white">Simpan</h1>
            </button>
          </form>
          </div>
        </div>
      </div>

    </div>
  </transition>


  <transition>
    <div
      class="fixed z-10 left-0 top-0 w-full h-full flex items-center justify-center overflow-auto bg-black/40 bg-opacity-50 font-pop font-regular"
      v-if="open1"
    >
   
      <div class="bg-white px-16 py-4 w-[50rem] rounded-lg shadow-lg">
        <div class="w-full">
          <h1 class="text-[20px] mb-4">Detail Team</h1>
          <hr class="mb-4" />
          <div class="border-b  border-gray-900/10 pb-12  pb-6">
          <div class="mt-4 grid xxs:grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-6">
            
          <div class="sm:col-span-3">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Nama Team</label>
              <div class="mt-2">
                <input    v-model="name" type="text" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled/>
              </div>
            </div>

            <div class="sm:col-span-3">
                <label for="country" class="block text-sm font-medium leading-6 text-gray-900">Nama Team Leader</label>
                <div class="mt-2">
                  <select   v-model="teamleader" id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6" disabled>
                    <option value="" disabled selected>Pilih Team Leader</option>
                    <option
                      v-for="data1 in userdata.data"
                      :key="data1.user_id"
                      :value="data1.user_id"
              >
                {{ data1.user.name }}
              </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          
          <div class="flex flex-row">
              <div class="sm:col-span-full mt-6 mr-6">
                <label for="country" class="block text-sm font-medium leading-6 text-gray-900">Nama Anggota </label>
                <div class="mt-2">
                  <select   v-model="member" id="country" name="country" autocomplete="country-name" class="block max-w-full w-[25rem] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                    <option value="" disabled selected>Pilih Anggota</option>
                    <option
                      v-for="data1 in userdata.data"
                      :key="{ user_id: data1.id, member: data1.user.name  }" :value="{ user_id: data1.id, member: data1.user.name }"
              >
                {{ data1.user.name }}
              </option>
                  </select>
                </div>
                
              </div>
              <div class="mt-14">
               <form @submit.prevent="submittemp1">
              <button type="submit" class="px-4 py-2 text-sm font-medium text-white bg-green-400 rounded-md hover:bg-green-700 focus:outline-none ">Tambahkan</button>
            </form>
            </div>
          </div>
            
              

             

  <div class="border-b  border-gray-900/10 pb-12  pb-6">
  <div class="relative overflow-x-auto shadow-md sm:rounded-lg font-pop mt-10">
    <table class="w-full text-[12px] text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead class="t text-gray-700  bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
                <th scope="col" class="px-6 py-3">
                    No
                </th>
                <th scope="col" class="px-6 py-3">
                    Nama
                </th>
                <th scope="col" class="px-6 py-3">
                    Aksi
                </th>
            </tr>
        </thead>
        <tbody>
            <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"  v-for="(entry, index) in teamdata.detailData" :key="index">
              <td class="px-6 py-4">
                    {{ index + 1 }}
                </td>
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {{ entry.member.member || entry.member }}
                </th>
              <td class="px-6 py-4">
                <a
          href="#"
          class="font-medium text-red-600 dark:text-red-500 hover:underline"
          @click.prevent="removeEntry1(index)"
        >Hapus</a>
              </td>
            </tr>
           
        </tbody>
    </table>
</div>
</div>

          <div class="flex justify-end">
            <button
              class="mt-8 bg-red-500 px-5 py-3 rounded-md"
              @click="closeModal1()"
            >
              <h1 class="text-[12px] text-white">Kembali</h1>
            </button>
            <form @submit.prevent="submitUpdate">
            <button
              class="ml-4 mt-8 bg-green-400  px-[3rem] py-3 rounded-md"
               type="submit"
            >
              <h1 class="text-[12px] text-white">Simpan</h1>
            </button>
          </form>
          </div>
        </div>
      </div>

    </div>
  </transition>

  

  <div v-if="successModal" class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50"  style="  animation: fadein 1s;">
      <div class="bg-white p-8 rounded-lg flex flex-col justify-center items-center  "  style="animation: muncul-kiri-kanan-fade 1s; ">
        <img src="../../assets/verify.png" class="w-16 h-16 mb-6 " alt="">
        <p>Data Team Berhasil ditambahkan</p>
      </div>
    </div>

    <div v-if="errorModal" class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50" style="animation: fadein 1s;">
    <div class="bg-white p-8 rounded-lg flex flex-col justify-center items-center  "  style="animation: muncul-kiri-kanan-fade 1s; ">
      <img src="../../assets/cancel.png" class="w-16 h-16 mb-6 " alt="">
      <p class="  font-semibold w-72 text-center" >{{ message }}</p>
    </div>
  </div>

</template>

<script>

import axios from "../../token";
export default {
  data() {
    return {
      searchTerm: "",
      open: "",
      id:'',
      open1: false,
      selectedDate: this.getInitialDate(),
      teamleader: "",
      name:'',
      member:'',
      successModal:false,
      errorModal:false,
      categorydata:[],
      userdata:[],
      entries: [],
      teamdata:[],
    };
  },
  components: {
   
  },
  computed: {
    filteredEntries() {
      let filtered = this.entries;

      if (this.selectedStatus.length > 0) {
        filtered = filtered.filter((entry) =>
          this.selectedStatus.includes(entry.status)
        );
      }

      if (this.selectedSort === "asc") {
        return filtered.sort((a, b) => a.dateIn.localeCompare(b.dateIn));
      } else {
        return filtered.sort((a, b) => b.dateIn.localeCompare(a.dateIn));
      }
    },
  },
  
  methods: {
    openModal() {
      this.isModalOpen = true;
    },
    openCategory(categoryId) {
    
    

    
    this.getteam(categoryId);

    
    this.open1 = !this.open1;
        this.teamdata=[]
        this.member=''
        this.teamleader = '';
        this.name = '';
    // if (!this.open1) {
    //   this.teamleader = '';
    //   this.teamdata=[];
    //   this.name='';
    // }
  },
    closeModal() {
      this.open = false;
    },
    closeModal1() {
      this.open1 = false;
      this.teamdata=[]
        this.member=''
        this.teamleader = '';
        this.name = '';
    },
    removeEntry(index) {
      this.entries.splice(index, 1);
    },
    removeEntry1(index) {
      this.teamdata.detailData.splice(index, 1);
    },
    submittemp() {

const emptyFields = [];



if (!this.member || /^\s*$/.test(this.member)) {
emptyFields.push("Anggota");
}




if (emptyFields.length > 0) {

this.errorModal = true;
this.message = `Field ${emptyFields.join(", ")} Tidak Boleh Kosong.`;

setTimeout(() => {
this.errorModal = false;
this.open = false;
}, 3000);

return;
}



const index = this.entries.findIndex(item => item.member.name === this.member.name);


if (index === -1) {
  this.entries.push({
    member: this.member
  });
} else {
  this.message = 'Data sudah ada dalam tabel'
  this.errorModal = true;
  setTimeout(() => {
this.errorModal = false;

}, 3000);


}
this.member = '';

},
submittemp1() {

const emptyFields = [];



if (!this.member || /^\s*$/.test(this.member)) {
emptyFields.push("Anggota");
}




if (emptyFields.length > 0) {

this.errorModal = true;
this.message = `Field ${emptyFields.join(", ")} Tidak Boleh Kosong.`;

setTimeout(() => {
this.errorModal = false;

}, 3000);

return;
}


const index = this.teamdata.detailData.findIndex(item => item.member === this.member.member);


if (index === -1) {
  this.teamdata.detailData.push({
    member: this.member
  });
} else {
  this.message = 'Data sudah ada dalam tabel'
  this.errorModal = true;
  setTimeout(() => {
this.errorModal = false;

}, 3000);


}
this.member = '';

},
    applyFilters() {
      this.closeModal();
    },
    getInitialDate() {
      return new Date().toISOString().split("T")[0];
    },
    async submitForm() {
      const formData = new FormData();

      this.entries.forEach((entries) => {
        formData.append('member[]', entries.member.id);
        

      },
        formData.append('leader',this.teamleader),
        formData.append('name',this.name)
      );
      
      try {
        const response = await axios.post(`${process.env.VUE_APP_BACKEND_HOST}/team`, formData);

        console.log(response);
        await new Promise(resolve => setTimeout(resolve, 500));
        this.successModal = true;

      
        setTimeout(() => {
          this.successModal = false;
          this.open = false;
        }, 3000);
        this.getuser()
        this.open = false;
        this.entries=[]
        this.member=''
        this.teamleader = '';
        this.name = '';
        this.qty = '';
        this.eom = '';
      } catch (error) {
        console.log(error);
        this.message = error.response.data.message;
        this.errorModal = true;
        setTimeout(() => {
          this.errorModal = false;
        }, 3000);
        
      }
    },
    async submitUpdate() {
      const formData = new FormData();

      this.teamdata.detailData.forEach((entries) => {
        formData.append('member[]', entries.member.user_id || entries.user_id);
        

      },
        formData.append('leader',this.teamleader),
        formData.append('_method','put'),
        formData.append('name',this.name),
        formData.append('id',this.id)
      );
      
      try {
        const response = await axios.post(`${process.env.VUE_APP_BACKEND_HOST}/team`, formData);

        console.log(response);
        await new Promise(resolve => setTimeout(resolve, 500));
        this.successModal = true;

      
        setTimeout(() => {
          this.successModal = false;
          this.open = false;
        }, 3000);
        this.getuser()
        this.open = false;
        this.entries=[]
        this.member=''
        this.teamleader = '';
        this.name = '';
        this.qty = '';
        this.eom = '';
      } catch (error) {
        console.log(error);
        this.message = error.response.data.message;
        this.errorModal = true;
        setTimeout(() => {
          this.errorModal = false;
        }, 3000);
        
      }
    },
    getuser() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/hrd/employee`)
          .then((response) => {
            this.userdata = response.data;
  
          })
          .catch((error) => {
            console.log(error);
          });
      },
    getcategory() {
      axios
        .get(`${process.env.VUE_APP_BACKEND_HOST}/team/list`)
        .then((response) => {
          this.categorydata = response.data;

        
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getteam(id) {
      axios
        .get(`${process.env.VUE_APP_BACKEND_HOST}/team`,{
          params:{
            id:id
          }
        })
        .then((response) => {
          this.teamdata = response.data;
          if (this.teamdata && this.teamdata.data.length > 0) {
        const firstEntry = this.teamdata.data[0]; 
        this.name=firstEntry.name || '';
        this.teamleader=firstEntry.user_id || '';
        this.id=firstEntry.id || '';
      }

        
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted(){
    this.getcategory();
    this.getuser();
  }
};
</script>

<style>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.search-input:focus {
  outline: none;
  box-shadow: none; /* Optional: Remove box-shadow if it's present */
}
</style>
