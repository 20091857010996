<template>

  <!-- <div class="md:flex md:flex-row md:justify-between xxs:hidden" >
 <div class="relative text-gray-600">
<input type="text" name="search" v-model="search" @input="getshift" placeholder="Pencarian..." class="bg-[#FAFBFD] h-10 px-5 w-64 rounded text-sm focus:outline-none">
<button type="submit" class="absolute right-0 top-0 mt-3 mr-4">
 <svg class="h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 56.966 56.966" style="enable-background:new 0 0 56.966 56.966;" xml:space="preserve" width="512px" height="512px">
   <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z"/>
 </svg>
</button>
</div>

<div class=""  v-if="permidata.data && permidata.data.includes('loan')">
<transition name="fade-in">

<button
   class="w-[142px] h-[30px] p-1 mr-4 rounded text-white bg-red-500"
   @click="open = true"
 >
   <div class="flex flex-row justify-center">
     <img src="../assets/add.png" class="mr-1 w-5" alt="" />
     <h3 class="text-[12px] font-medium">Tambah Pinjaman</h3>
   </div>
 </button>

</transition> -->
 <!-- <button
   class="w-[118px] h-[30px] flex-shrink-0 rounded text-[#BBBBBB] border border-[#BBBBBB]"  @click="toggleModal"
 >
   <div class="flex flex-row justify-center">
     <img src="../assets/filter.png" class="mr-1 w-5" alt="" />
     <h3 class="text-[12px] text-[#565656] font-medium">Filter & Short</h3>
   </div>
 </button> -->

<!-- </div>

</div> -->



 <div
   v-if="isOpen"
   class="absolute flex items-center justify-end z-10 top-[14rem] right-0 px-32 font-pop"
 >
   <div class="modal bg-white rounded-lg shadow-lg p-4 w-52">
   
     <div class="mb-4">
       <label class="block text-sm font-medium text-gray-700"
         >Filter by Category:</label
       >
       <div class="mt-1">
         <label for="cuti" class="flex items-center cursor-pointer">
           <input
             type="checkbox"
             id="cuti"
             v-model="selectedStatus"
             value="Hadir"
             class="h-5 w-5 text-blue-500 rounded-md border-gray-300 focus:ring-blue-400"
           />
           <span class="ml-2 text-sm text-gray-700">Kapal Tanker</span>
         </label>
         <label for="sakit" class="flex items-center cursor-pointer">
           <input
             type="checkbox" 
             id="sakit"
             v-model="selectedStatus"
             value="Tidak Hadir"
             class="h-5 w-5 text-blue-500 rounded-md border-gray-300 focus:ring-blue-400"
           />
           <span class="ml-2 text-sm text-gray-700">Kapal Ferry</span>
         </label>
         <label for="izin" class="flex items-center cursor-pointer">
           <input
             type="checkbox"
             id="izin"
             v-model="selectedStatus"
             value="Cuti"
             class="h-5 w-5 text-blue-500 rounded-md border-gray-300 focus:ring-blue-400"
           />
           <span class="ml-2 text-sm text-gray-700">Kapal Persiar</span>
         </label>
       </div>
     </div>

    
     <div class="mb-4">
       <label class="block text-sm font-medium text-gray-700"
         >Sort by:</label
       >
       <div class="mt-1">
         <label for="asc" class="flex items-center cursor-pointer">
           <input
             type="radio"
             id="asc"
             v-model="selectedSort"
             value="asc"
             class="h-5 w-5 text-blue-500 rounded-full border-gray-300 focus:ring-blue-400"
           />
           <span class="ml-2 text-sm text-gray-700">Ascending</span>
         </label>
         <label for="desc" class="flex items-center cursor-pointer">
           <input
             type="radio"
             id="desc"
             v-model="selectedSort"
             value="desc"
             class="h-5 w-5 text-blue-500 rounded-full border-gray-300 focus:ring-blue-400"
           />
           <span class="ml-2 text-sm text-gray-700">Descending</span>
         </label>
       </div>
     </div>
   </div>
 </div>

 <!-- <div class="grid md:grid-cols-4 gap-10 justify-between items-center xxs:grid-cols-1 font-pop mt-10 w-full">
  

   <div v-for="shift in shiftdata.data" :key="shift.id" @click="openCategory(shift.id)"  class="  bg-white  rounded overflow-hidden cursor-pointer text-center  px-6  "  style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;  animation: muncul-kiri-kanan-fade 0.5s;">
   
       <div class="flex flex-row  items-center">
         <img class=" object-cover md:w-15 md:h-15 xxs:w-14 xxs:h-full rounded " src="https://pbs.twimg.com/media/GF6JAuVWcAETdhA.jpg"  alt="Profile Image">
         <div class=" flex flex-col p-6"> 
           <h2 class="text-[12px] font-semibold mb-2">{{ shift.user.name || 0 }}</h2>
           <div class="flex flex-row">
             <p class="md:text-[12px]   xxs:text-[12px] text-gray-600 mr-4">{{ shift.amount || 0 }}</p>
         
         </div>
         
         
         </div>
     
         
     
     </div>
   
  </div >

 </div> -->

 <!-- <div class="md:flex items-center justify-between border-t border-gray-200 mt-10 py-3 xxs:hidden" style="animation: muncul-kiri-kanan-fade 1.5s;">
 <div class="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
   <div>
     <p class="text-sm text-gray-700">
       Tampilan {{ ' ' || 0 }} <span class="font-medium">{{ currentPage || 0 }}</span>
       {{ ' ' || 0 }} Dari {{ ' ' || 0 }} <span class="font-medium">{{ Math.ceil(filteredEntries.length / perPage) || 0 }}</span>
       {{ ' ' || 0 }} Data
     </p>
     <h1>{{ console.log(totalPages)|| 0 }}</h1>
   </div>
   <div>
     <nav class="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
       <button @click="currentPage -= 1" :disabled="currentPage === 1" class="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
         <span class="sr-only">Previous</span>
         <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
       </button>
       
       <button v-for="page in totalPages" :key="page" @click="changePage(page)" :class="{ 'bg-red-500': currentPage === page, 'text-white': currentPage === page }" class="relative z-10 inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
       {{ page || 0 }}
       </button>
       <button @click="currentPage += 1" :disabled="currentPage === Math.ceil(filteredEntries.length / perPage)" class="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
         <span class="sr-only">Next</span>
         <ChevronRightIcon class="md:h-5 md:w-5 xxs:hidden" aria-hidden="true" />
       </button>
     </nav>
   </div>
 </div>
</div> -->


<div class="flex flex-row gap-4 items-center justify-between mb-4 font-pop" style=" animation: muncul-kiri-kanan-fade 0.5s;">
  <div class="text-[18px] font-medium text-gray-500">Informasi Payroll Bulan {{ selectedMonth }} - Tahun {{ selectedYear  }}</div>
  <div class="flex flex-row gap-4 items-center">
  <!-- <label for="month">Bulan:</label>
  <select class="block w-[10rem] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" v-model="selectedMonth" id="month">
    <option v-for="(month, index) in months" :key="index" :value="index + 1">{{ month }}</option>
  </select>

  <label for="year">Tahun:</label>
  <select class="block  w-[10rem] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" v-model="selectedYear" id="year">
    <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
  </select>


  <button class="rounded-md bg-green-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" @click="applyFilter">Terapkan Filter</button>  -->

  <button
      class="w-[118px] py-1 flex-shrink-0 rounded text-[#BBBBBB] border border-[#BBBBBB]"  @click="open6=true"
    >
      <div class="flex flex-row justify-center">
        <img src="../assets/filter.png" class="mr-1 w-5" alt="" />
        <h3 class="text-[12px] text-[#565656] font-medium">Filter & Short</h3>
      </div>
    </button>
  <button class="rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" @click="previewReport">Cetak Laporan</button>

  <button class="rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" @click="sendSelectedUsers">Kirim Slip Gaji</button>
</div>
</div>


<transition>
    <div
      class="fixed z-10 left-0 top-0 w-full h-full flex items-center justify-center overflow-auto backdrop-blur-sm bg-black/10 bg-opacity-50 font-pop font-regular"
      v-if="open6"
    >
   
      <div class="bg-white px-16 py-4 rounded-lg shadow-lg">
        <div class="w-full">
          <h1 class="text-[20px] mb-4">Filter & Short</h1>
          <hr class="mb-4" />
        
          <div class="flex flex-col gap-4">
          <!-- <select name="" v-model="extension" id="" class="px-3 py-2 font-pop rounded text-sm  ">
          <option value="" selected>Pilih Extension</option>
          <option value="xls" >Excel</option>
          <option value="pdf" >PDF</option>
        </select> -->
<div class="flex flex-row gap-4">
  <div class="flex flex-col">
        <label for="month">Bulan:</label>
  <select class="block w-[10rem] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" v-model="selectedMonth" id="month">
    <option v-for="(month, index) in months" :key="index" :value="index + 1">{{ month }}</option>
  </select>
</div>
<div class="flex flex-col">
  <label for="year">Tahun:</label>
  <select class="block  w-[10rem] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" v-model="selectedYear" id="year">
    <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
  </select>
</div>
</div>

        <select name="" v-model="filter" id="" class="px-3 py-2 font-pop rounded text-sm  ">
          <option value="" selected>Pilih Filter</option>
          <option value="department" >Departement</option>
          <option value="position" >Posisi</option>
          <option value="employeeStatus" >Kontrak Kerja</option>
          <option value="accountStatus" >Status</option>
        </select>

        <div class="sm:col-span-full"  v-if="this.filter === 'department'">
              <label for="country" class="block text-sm font-medium leading-6 text-gray-900">Departement</label>
              <div class="mt-2">
                <select   v-model="id" id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                  <option value=""  selected>Pilih Departement</option>
                  <option
                    v-for="categoory in projectdata.data"
                    :key="categoory.id"
                    :value="categoory.id"
            >
              {{ categoory.name }}
            </option>
                </select>
              </div>
            </div>

            <div class="sm:col-span-full" v-if="this.filter === 'position'">
              <label for="country" class="block text-sm font-medium leading-6 text-gray-900">Jabatan</label>
              <div class="mt-2">
                <select   v-model="id" id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                  <option value=""  selected>Pilih Jabatan</option>
                  <option
                    v-for="project in categorydata.data"
                    :key="project.id"
                    :value="project.id"
            >
              {{ project.name }}
            </option>
                </select>
              </div>
            </div>

            <div class="sm:col-span-full" v-if="this.filter === 'accountStatus'">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Status Karyawan</label>
              <div class="mt-2">
                <select v-model="id" id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                  <option value=""  selected>Pilih Status Karyawan</option>
                  <option value="1">Aktif</option>
                  <option value="0">TIdak</option>
                </select>
              </div>
            </div>

            <div class="sm:col-span-full" v-if="this.filter === 'employeeStatus'">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Jenis Ikatan Kerja</label>
              <div class="mt-2">
                <select v-model="id" id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                  <option value=""  selected>Pilih Ikatan Kerja</option>
                  <option value="tetap">Karyawan Tetap</option>
                  <option value="kontrak">Kontrak</option>
                </select>
              </div>
            </div>

      </div>
           
          <div class="flex justify-end">
            <button
              class="mt-8 bg-red-500 px-5 py-3 rounded-md"
              @click="close6()"
            >
              <h1 class="text-[12px] text-white">Kembali</h1>
            </button>
            
            <button
              class="ml-4 mt-8 bg-[#A6CF98] px-[3rem] py-3 rounded-md"
               type="submit" @click="getfilter(this.filter,this.id)"
            >
              <h1 class="text-[12px] text-white">Terapkan</h1>
            </button>
       
          </div>
        </div>
      </div>
    
    </div>
  </transition>




<div class="relative overflow-x-auto   sm:rounded-lg font-pop" style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; animation: muncul-kiri-kanan-fade 0.8s;">
  <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
      <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" class="text-center  px-6 py-3">
                  No
              </th>
              <th  scope="col"  class="text-center   px-6 py-3">
                  NIK
              </th>
              <th scope="col" class=" text-center   px-6 py-3">
                  Nama
              </th>
              <th scope="col" class="text-center   px-6 py-3">
                  Gaji Pokok
              </th>
              <th scope="col" class="text-center  px-6 py-3">
                  T.Jabatan
              </th>
              <th scope="col" class="text-center  px-6 py-3">
                  T.Transportasi & Telepon 
              </th>
              <th scope="col" class="text-center  px-6 py-3">
                  T.Makan 
              </th>
              <th scope="col" class="text-center  px-6 py-3">
                  Lembur
              </th>
              <th scope="col" class="px-8 py-3">
                  THR/Bonus/Incetive
              </th>
              <th scope="col" class="text-center  px-6 py-3">
                  Adjustment
              </th>
              <th scope="col" class="text-center  px-6 py-3">
                  Gaji Kotor
              </th>
              <th scope="col" class="text-center  px-6 py-3">
                  Telat
              </th>
              <th scope="col" class="text-center  px-6 py-3">
                  Absen
              </th>
              <th scope="col" class="text-center  px-6 py-3">
                  JHT Karyawan
              </th>
              <th scope="col" class="text-center  px-6 py-3">
                 Pensiun Karyawan
              </th>
              <th scope="col"  class="text-center  px-6 py-3">
                 BPJS Kesehatan
              </th>
              <th scope="col"  class="text-center  px-6 py-3">
              Pajak
              </th>
              <th scope="col"  class="text-center  px-6 py-3">
              Pinjaman
              </th>
              <th scope="col"  class="text-center  px-6 py-3">
              Total Pengurangan
              </th>
              <th scope="col"  class="text-center  px-6 py-3">
               Take Home Pay
              </th>
              <th scope="col" class="px-6 py-3">
                <input type="checkbox" v-model="selectAll" @change="checkAll" />
              </th>
             
          </tr>
      </thead>
      <tbody>
          <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600" v-for="(shift,index) in userdata.data" :key="index">
            <td class="text-center  px-6 py-4">
                {{ index + 1 || 0 }}
              </td>
          
              <td class="px-6 py-4 cursor-pointer  hover:underline" >
                <router-link :to="{ 
                  name: 'TambahPayroll', 
                  params: { 
                    id: shift.id !== null ? shift.id : 'null', 
                    userId: shift.user_id ,
                    year: selectedYear, 
                     month: selectedMonth 
                  },
               
                }">
                {{ shift.employee_id|| 0 }}
              </router-link>
              </td>
           
            
              <td class="px-6 py-4 cursor-pointer truncate hover:underline">
                <router-link :to="{ 
                  name: 'TambahPayroll', 
                  params: { 
                    id: shift.id !== null ? shift.id : 'null', 
                    userId: shift.user_id ,
                    year: selectedYear, 
                     month: selectedMonth 
                  },
               
                }">
                {{ shift.name || 0 }}
              </router-link>
            </td>

           
              <td class="  px-6 py-4">
                 {{ shift.basic_salary|| 0 }}
              </td>
              <td class="  px-6 py-4">
                {{ shift.position_allowance|| 0 }}
              </td>
              <td class="  px-6 py-4">
                  {{ shift.transport_n_comm_allowance|| 0 }}
              </td>
              <td class="  px-6 py-4">
                  {{ shift.meal_allowance|| 0 }}
              </td>
              <td class="  px-6 py-4">
                  {{ shift.overtime|| 0 }}
              </td>
              <td class="  px-6 py-4">
                  {{ shift.thr_bonus_incentive|| 0 }}
              </td>
              <td class="  px-6 py-4">
                  {{ shift.adjustment|| 0 }}
              </td>
              <td class="  px-6 py-4">
                  {{ shift.bruto|| 0 }}
              </td>

              <td class="  px-6 py-4">
                  {{ shift.late_deduction|| 0 }}
              </td>

              <td class="  px-6 py-4">
                  {{ shift.absence_deduction|| 0 }}
              </td>
              <td class="  px-6 py-4">
                  {{ shift.jht|| 0 }}
              </td>
              <td class="  px-6 py-4">
                  {{ shift.jp_karyawan|| 0 }}
              </td>
              <td class="  px-6 py-4">
                  {{ shift.jkn_karyawan
|| 0 }}
              </td>
              <td class="  px-6 py-4">
                  {{ shift.pph21|| 0 }}
              </td>
              <td class="  px-6 py-4">
                  {{ shift.loan_payment
|| 0 }}
              </td>
              <td class="  px-6 py-4">
                  {{ shift.tot_pengurang
|| 0 }}
              </td>
              <td class=" px-6 py-4">
                  {{ shift.take_home_pay
|| 0 }}
              </td>
             <td class="px-6 py-4">
                <input type="checkbox" v-model="selectedUsers" :value="shift.user_id" />
              </td>
            
          </tr>
        
      </tbody>
  </table>
</div>

<div>
   
   <div class="flex justify-end mt-8 text-sm font-pop">
     <!-- <button
       @click="getmaterial(1)"
       :disabled="pagination.current_page === 1"
       class="px-4 py-2 mx-1 text-white bg-blue-500 rounded-md hover:bg-blue-600"
     >
       First
     </button> -->
     <button
       @click="getuser('','',pagination.current_page - 1)"
       :disabled="pagination.current_page === 1"
       class="px-4 py-2 mx-1 text-white bg-red-500 rounded-md hover:bg-red-600"
     >
     Sebelumnya
     </button>
     <button
       v-for="page in pagination.last_page"
       :key="page"
       @click="getuser('','',page)"
       :class="{ 'bg-red-500 text-white': pagination.current_page === page }"
       class="px-4 py-2 mx-1  text-black rounded-md hover:bg-red-600"
     >
       {{ page }}
     </button>
     <button
       @click="getuser('','',pagination.current_page + 1)"
       :disabled="pagination.current_page === pagination.last_page"
       class="px-4 py-2 mx-1 text-white bg-red-500 rounded-md hover:bg-red-600"
     >
       Selanjutnya
     </button>
     <!-- <button
       @click="getmaterial(pagination.last_page)"
       :disabled="pagination.current_page === pagination.last_page"
       class="px-4 py-2 mx-1 text-white bg-blue-500 rounded-md hover:bg-blue-600"
     >
       Last
     </button> -->
   </div>
 </div>



<transition>
    <div
      class="fixed z-10 left-0 top-0 w-full h-full flex items-center justify-center overflow-auto bg-black/40 bg-opacity-50 font-pop font-regular"
      v-if="open"
    >

      <div class="bg-white px-16 py-4 rounded-lg shadow-lg">
        <div class="w-full">
          <h1 class="text-[24px] mb-4">Input </h1>
          <hr class="mb-4" />
          <div class="flex flex-row justify-between">
            <div class="mt-2 grid xxs:grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-6">
            
  
            <div class="sm:col-span-3">
              <label for="country" class="block text-sm font-medium leading-6 text-gray-900">Karyawan</label>
              <div class="mt-2">
                <select   v-model="karyawan" id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                  <option value="" disabled selected>Pilih Karyawawan</option>
                  <option
                    v-for="data1 in userdata.data"
                    :key="data1.id"
                    :value="data1.id"
            >
              {{ data1.name || 0 }}
            </option>
                </select>
              </div>
            </div>

            <div class="sm:col-span-3">
              <label for="first-name" class="block text-sm font-medium leading-6 text-gray-900">Nominal</label>
              <div class="mt-2">
                <input    v-model="amount" type="number" name="first-name" id="first-name" autocomplete="given-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>


            <div class="sm:col-span-3">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Tanggal Mulai</label>
              <div class="mt-2">
                <input    v-model="date1" type="date" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>

            <div class="sm:col-span-3">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Tanggal Akhir</label>
              <div class="mt-2">
                <input   v-model="date2" type="date" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>

            <div class="sm:col-span-full ">
          <label for="message" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Keterangan</label>
          <div class="mt-2">
          <textarea id="message" v-model="description" rows="4" class="block p-1.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Tambahkan Keterangan Jika Perlu..."></textarea>
          </div>
          </div>

           
              
            </div>
          </div>
          <div class="flex justify-end">
            <button
              class="mt-8 bg-red-500 px-5 py-3 rounded-md"
              @click="open = false"
            >
              <h1 class="text-[12px] text-white">Kembali</h1>
            </button>
            <form @submit.prevent="submitForm">
            <button
              class="ml-4 mt-8 bg-[#A6CF98] px-[3rem] py-3 rounded-md"
               type="submit"
            >
              <h1 class="text-[12px] text-white">Simpan</h1>
            </button>
          </form>
          </div>
        </div>
      </div>
    
    </div>
  </transition>

  <transition>
    <div
      class="fixed z-10 left-0 top-0 w-full  h-full flex items-center justify-center overflow-auto bg-black/40 bg-opacity-50 font-pop font-regular"
      v-if="open1"
    >

      <div class="bg-white px-16 py-4 rounded-lg shadow-lg">
        <div class="w-full">
          <h1 class="text-[24px] mb-4">Update Pinjaman</h1>
          <hr class="mb-4" />
          <div class="flex flex-row justify-between">
            <div class="mt-2 grid xxs:grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-6">
            
  
            <div class="sm:col-span-full">
              <label for="country" class="block text-sm font-medium leading-6 text-gray-900">Karyawan</label>
              <div class="mt-2">
                <select   v-model="karyawan" id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6" disabled>
                  <option value="" disabled selected>Pilih Karyawawan</option>
                  <option
                    v-for="data1 in userdata.data"
                    :key="data1.id"
                    :value="data1.id"
            >
              {{ data1.name || 0 }}
            </option>
                </select>
              </div>
            </div>

            <div class="sm:col-span-3">
              <label for="first-name" class="block text-sm font-medium leading-6 text-gray-900">Nominal</label>
              <div class="mt-2">
                <input    v-model="amount" type="number" name="first-name" id="first-name" autocomplete="given-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>

            <div class="sm:col-span-3">
              <label for="first-name" class="block text-sm font-medium leading-6 text-gray-900">Bayar Pinjaman</label>
              <div class="mt-2">
                <input    v-model="amount1" type="number" name="first-name" id="first-name" autocomplete="given-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>


            <div class="sm:col-span-3">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Tanggal Mulai</label>
              <div class="mt-2">
                <input    v-model="date1" type="date" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled/>
              </div>
            </div>

            <div class="sm:col-span-3">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Tanggal Akhir</label>
              <div class="mt-2">
                <input   v-model="date2" type="date" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled />
              </div>
            </div>

            <div class="sm:col-span-full ">
          <label for="message" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Keterangan</label>
          <div class="mt-2">
          <textarea id="message" v-model="description" rows="4" class="block p-1.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Tambahkan Keterangan Jika Perlu..."></textarea>
          </div>
          </div>

           
              
            </div>
          </div>
          <div class="flex justify-end">
            <button
              class="mt-8 bg-red-500 px-5 py-3 rounded-md"
              @click="open1 = false"
            >
              <h1 class="text-[12px] text-white">Kembali</h1>
            </button>
            <form @submit.prevent="submitForm">
            <button
              class="ml-4 mt-8 bg-[#A6CF98] px-[3rem] py-3 rounded-md"
               type="submit"
            >
              <h1 class="text-[12px] text-white">Simpan</h1>
            </button>
          </form>
          </div>
        </div>
      </div>
    
    </div>
  </transition>

  <div v-if="successModal" class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50"  style="  animation: fadein 1s;">
  <div class="bg-white p-8 rounded-lg flex flex-col justify-center items-center  "  style="animation: muncul-kiri-kanan-fade 1s; ">
    <img src="../assets/verify.png" class="w-16 h-16 mb-6 " alt="">
    <p>{{ message }}</p>
  </div>
</div>

<div v-if="errorModal" class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50" style="animation: fadein 1s;">
  <div class="bg-white p-8 rounded-lg flex flex-col justify-center items-center  "  style="animation: muncul-kiri-kanan-fade 1s; ">
    <img src="../assets/cancel.png" class="w-16 h-16 mb-6 " alt="">
    <p class="  font-semibold w-72 text-center" >{{ message || 0 }}</p>
  </div>
</div>

  <div v-if="errorModal2" class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50  flex justify-center items-center z-50" style="animation: fadein 1s;">
      <div class="bg-white p-8 rounded-lg flex flex-col justify-center items-center      "  style="animation: muncul-kiri-kanan-fade 1s; "    >
            <img src="../assets/cancel.png" class="w-16 h-16 mb-6 " alt="">
            <p class="  font-semibold w-72 text-center mb-4" >{{ master.message }}</p>
            <div class="relative overflow-x-auto overflow-y-auto h-[15rem]">
            <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 sticky top-0 z-10">
                    <tr>
                        <th scope="col" class="px-6 py-3">
                            Nama & Nomor Pegawai
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700" v-for="(entry, index) in master.failed" :key="index">
                        <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            {{ entry }}
                        </th>
                    </tr>
                </tbody>
            </table>
      </div>

      <div class="flex flex-row gap-6">

        <button @click="errorModal2=false" class="rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
            Tutup
          </button>
          </div>

          </div>

    </div>

<div v-if="previewUrl" class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50"  style="animation: muncul-kiri-kanan-fade 1s;" @click="closePreview">
      <iframe :src="previewUrl" class="flex items-center justify-center w-[100rem] h-[50rem]" @click="preventClose"></iframe>
    </div>

  <div v-if="uploading" class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50" style="animation: fadein 1s;">
        <div class="bg-white p-8 rounded-lg flex flex-col justify-center items-center  "  style="animation: muncul-kiri-kanan-fade 1s; ">
          <img src="../assets/loading.gif" class="w-16 h-16 mb-6 " alt="">
          <p class="  font-semibold w-72 text-center" >Sedang diproses ...</p>
        </div>
      </div>

</template>


<script>
import axios from "../token";
export default {
 data() {
   return {
     isOpen: false,
     open:false,
     open1:false,
     shiftdata: [],
   currentPage: 1,
   perPage: 10, 
   selectedStatus: [], 
   previewUrl: null,
      uploading:false,
   successModal:false,
   open6:false,
     errorModal: false,
   errorModal2: false,
   search:'',
   userdata:[],
   teamdata:[],
   karyawan:'',
   date1:'',
   filter:'',
   projectdata:[],
   categorydata:[],
   pagination : {
          current_page: 1,
          from: null,
          last_page: null
        },
     date2: '',
   master: [],
   amount:'',
     amount1: '',
    selectedUsers: [],
    selectAll: false,
   selectedMonth: new Date().getMonth() + 1, // Default to the current month
    selectedYear: new Date().getFullYear(), // Default to the current year
    months: [
      'Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni',
      'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'
    ],
    years: [2022, 2023, 2024], // You can customize the list of years
    
   description:'',
   permidata:[],
   };
 },
 computed: {
filteredEntries() {
 if (this.shiftdata && this.shiftdata.data) {
   if (this.selectedStatus.length > 0) {
     return this.shiftdata.filter((shift) =>
       this.selectedStatus.includes(shift.id)
     );
   }
   return this.shiftdata.data;
 }
 return [];
},
totalPages() {
 return Math.ceil(this.filteredEntries.length / this.perPage);
},
pagedShiftData() {
 const start = (this.currentPage - 1) * this.perPage;
 const end = start + this.perPage;
 return this.filteredEntries.slice(start, end);
},
},  
  methods: {
checkAll() {
    this.selectedUsers = this.selectAll ? this.userdata.data.map(user => user.user_id) : [];
  },
    sendSelectedUsers() {
  this.uploading = true;
    let formData = new FormData();
    this.selectedUsers.forEach(userId => {
      formData.append('user_ids[]', userId);
    });
  // send to /salary/send
  const year = this.selectedYear || new Date().getFullYear();
  const month = this.selectedMonth || new Date().getMonth() + 1;

  //put year and month in the formData
  formData.append('year', year);
  formData.append('month', month);

  axios
    .post(`${process.env.VUE_APP_BACKEND_HOST}/salary/send`, formData)
    .then((response) => {
      this.uploading = false;
      this.message = response.data.message;
      this.successModal = true;
      this.getuser();
    })
    .catch((error) => {
      this.uploading = false;
      if (!('failed' in error.response.data)) {
        this.message = error.response.data.message;
        this.errorModal = true;
        setTimeout(() => {
          this.errorModal = false;
        }, 5000);
      } else {
        this.master = error.response.data;
        this.errorModal2 = true;
      }
    });
  },
   toggleModal() {
     this.isOpen = !this.isOpen;
   },
   CetakLaporan() {
     this.getlaporan()
   },
   closePreview() {
      this.previewUrl = null;
    },
   applyFilter() {
    
    console.log('Selected Month:', this.selectedMonth);
    console.log('Selected Year:', this.selectedYear);
    this.getuser();
    
  },
  close6(){
        this.open6=false
        this.id='';
        this.filter='';
        this.extension='';
      },
      getfilter(filter,id){
      this.getuser(filter,id)
    },

   openCategory(categoryId) {
    
  this.getteam(categoryId);
  this.open1 = !this.open1;
},
  
 getlaporan() {
    this.uploading = true; 
    axios
      .get(`${process.env.VUE_APP_BACKEND_HOST}/salary/payroll`, {
        responseType: 'blob',
        params: {
          year: this.selectedYear,
          month: this.selectedMonth,
        }
      })
      .then((response) => {
        this.uploading = false; 
        const blob = new Blob([response.data], { type: 'application/pdf' });
        this.previewUrl = window.URL.createObjectURL(blob);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  previewReport() {
    this.getlaporan() 
  },

  exportReport() {
    if (this.previewUrl) {
      // Assuming you have a library or method to handle file downloads
      this.downloadFile(this.previewUrl, 'report.pdf');
    } else {
      this.getShift();
    }
  },

  downloadFile(url, filename) {
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;

    // Append the link to the body and trigger the click event
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
  },
  // async getmaterial(page) {
  //     let params = {
  //       search: this.search,
  //       limit: 8,
  //       page: Number.isInteger(page) ? page : 1
  //     };

  //     if (this.filterValue) {
  //       params.filter = 'project';
  //       params.filter_value = this.filterValue;
  //     }

  //     if (this.urutan1) {
  //       params.sort = this.urutan1;
  //       params.sort_by = 'code';
  //     }

  //     try {
  //       const response = await axios.get(`${process.env.VUE_APP_BACKEND_HOST}/material-take-off/project`, { params });
  //       this.materialdata = response.data;
  //       this.pagination = {
  //         current_page: response.data.current_page,
  //         from: response.data.from,
  //         last_page: response.data.last_page
  //       };
  //     } catch (error) {
  //       console.error(error);
  //       this.materialdata = [];
  //       this.pagination = {
  //         current_page: 1,
  //         from: null,
  //         last_page: null
  //       };
  //     }
  //   },
 getuser(filter,id,page) {

  const year = this.selectedYear || new Date().getFullYear();
  const month = this.selectedMonth || new Date().getMonth() + 1;


            let params = {
            year:year,
            month:month,
            limit: 12,
            page: Number.isInteger(page) ? page : 1
      };
  if (filter) {
    params.filter = filter;
    params.filter_value = id;
  
  }

      axios
        .get(`${process.env.VUE_APP_BACKEND_HOST}/salary/recap`,{
          params: params 
        })
        .then((response) => {
          this.userdata = response.data;
          this.open6=false
          this.pagination = {
          current_page: response.data.current_page,
          from: response.data.from,
          last_page: response.data.last_page
        };
          
        })
        .catch((error) => {
          console.log(error);
          this.userdata = []
          this.pagination = {
          current_page: 1,
          from: null,
          last_page: null
        };
          this.open6=false
        });
    },
 changePage(page) {
 this.currentPage = page;
},
getpermi1() {
    axios
      .get(`${process.env.VUE_APP_BACKEND_HOST}/permission/check`)
      .then((response) => {
        this.permidata = response.data;

      
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getcategory() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/position`)
          .then((response) => {
            this.categorydata = response.data;
  
          })
          .catch((error) => {
            console.log(error);
          });
      },
      getproject() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/department`)
          .then((response) => {
            this.projectdata = response.data;
  
          })
          .catch((error) => {
            console.log(error);
          });
      },
 },
 mounted() {

 this.getuser();
 this.getcategory();
    this.getproject();

},
}
</script>


<style scoped>
.fade-in-enter-active, .fade-in-leave-active {
transition: opacity 1s;
}
.fade-in-enter, .fade-in-leave-to {
opacity: 0;
}

</style>
