
<template>


<nav class="flex mb-6 font-pop" aria-label="Breadcrumb" style=" animation: muncul-kiri-kanan-fade 1s;"> 
  <ol class="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
    <li>
      <div class="flex items-center">
       
        <router-link to="/MTO" class="ms-1 text-sm font-medium text-gray-700 hover:text-red-500 md:ms-2 dark:text-gray-400 dark:hover:text-white">Material Take Off</router-link>
      </div>
    </li>
    <li aria-current="page">
      <div class="flex items-center">
        <svg class="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4"/>
        </svg>
        <span class="ms-1 text-sm font-medium text-red-500 md:ms-2 dark:text-gray-400">Edit Material Take Off</span>
      </div>
    </li>
  </ol>
</nav>


    <div class="font-pop">
    <form @submit.prevent="saveChanges" class="space-y-12 bg-white md:mb-0 md:px-24 py-4 xxs:px-4 xxs:mb-16 rounded-xl" style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; animation: muncul-kiri-kanan-fade 1s;">
      <div class="border-b border-gray-900/10 pb-12">
        <h2 class="text-base text-[22px] font-semibold leading-7 text-gray-900 mt-4">Ubah Material Take Off</h2>
        <p class="mt-1 text-sm leading-6 text-gray-600">Lakukan Perubahan ubah Data Material.</p>
        <div class="mt-6 grid xxs:grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-6">
          <div class="sm:col-span-full">
            <label for="project" class="block text-sm font-medium leading-6 text-gray-900">Project</label>
            <div class="mt-2">
              <select v-model="project" id="project" name="project" autocomplete="project-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled>
                <option value="" disabled selected>Pilih Project</option>
                <option v-for="data2 in projectdata2.data" :key="data2.id" :value="data2.id">{{ data2.name }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="border-b border-gray-900/10 pb-12">
        <div class="mt-4 grid xxs:grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-6">
          <div class="sm:col-span-3">
            <label for="material" class="block text-sm font-medium leading-6 text-gray-900">Material</label>
            <div class="mt-2">
              <select v-model="selectedMaterial" id="material" @change="unit" name="material" autocomplete="material-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                <option value="" disabled selected>Pilih Material</option>
                <option v-for="data1 in materialdata.data" :key="{ id: data1.id, name: data1.name,dimension:data1.dimension , symbol:data1.symbol }" :value="{ id: data1.id, name: data1.name, dimension:data1.dimension, symbol:data1.symbol}">{{ data1.name_dimension }}</option>
              </select>
            </div> 
          </div>
          <div class="sm:col-span-3">
            <label for="deskripsi" class="block text-sm font-medium leading-6 text-gray-900">Demensi</label>
            <div class="mt-2">
              <input v-model="dimension" type="text" name="deskripsi" id="deskripsi" autocomplete="deskripsi" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>
          <div class="sm:col-span-3">
            <label for="deskripsi" class="block text-sm font-medium leading-6 text-gray-900">Deskripsi</label>
            <div class="mt-2">
              <input v-model="description" type="text" name="deskripsi" id="deskripsi" autocomplete="deskripsi" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>
         
          <div class="sm:col-span-1">
            <label for="qty" class="block text-sm font-medium leading-6 text-gray-900">Qty</label>
            <div class="mt-2">
              <input v-model="quantity" type="text" name="qty" id="qty" autocomplete="qty" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              
            </div>
          </div>

          <div class="sm:col-span-1">
            <label for="negara" class="block text-sm font-medium leading-6 text-gray-900">EOM</label>
            <div class="mt-2">
              <input v-model="eom" type="text" name="negara" id="negara" autocomplete="negara" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled />
            </div>
          </div>

          <div class="sm:col-span-1">
            <label for="negara" class="block text-sm font-medium leading-6 text-gray-900">Negara</label>
            <div class="mt-2">
              <input v-model="coo" type="text" name="negara" id="negara" autocomplete="negara" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>

          <div class="sm:col-span-1">
            <label for="berat-satuan" class="block text-sm font-medium leading-6 text-gray-900">Berat Satuan</label>
            <div class="mt-2">
              <input v-model="weight" type="text" name="berat-satuan" id="berat-satuan" autocomplete="berat-satuan" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>

          <div class="sm:col-span-2">
            <label for="qty" class="block text-sm font-medium leading-6 text-gray-900">Total Berat</label>
            <div class="mt-2">
              <input v-model="weightTotal"  type="text" name="qty" id="qty" autocomplete="qty" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>

        

        
          
        </div>
        
        <div class="flex justify-end mt-6 gap-4">
          <form @submit.prevent="resetForm">
          <button   class="px-4 py-2 text-sm font-medium text-white bg-red-400 rounded-md hover:bg-red-700 focus:outline-none">Batal</button>
        </form>
          <button v-if="!isEditMode" type="submit" class="px-4 py-2 text-sm font-medium text-white bg-green-400 rounded-md hover:bg-green-700 focus:outline-none">Tambahkan</button>
          <button v-if="isEditMode" type="submit" class="px-4 py-2 text-sm font-medium text-white bg-green-400 rounded-md hover:bg-green-700 focus:outline-none">Ubah Data</button>
         
          
      </div>
        
      </div>
      
<div class="border-b border-gray-900/10 pb-12">
  <div class="relative overflow-x-auto shadow-md sm:rounded-lg font-pop ">
    <table class="w-full text-[12px] text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead class="t text-gray-700  bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" class="px-6 py-3">
                    No
                </th>
                <th scope="col" class="px-6 py-3">
                    Material
                </th>
                <th scope="col" class="px-6 py-3">
                    Dekripsi
                </th>
                <th scope="col" class="px-6 py-3">
                    Demensi
                </th>
                <th scope="col" class="px-6 py-3">
                    Qty
                </th>
              
                <th scope="col" class="px-6 py-3">
                    Eom
                </th>
                <th scope="col" class="px-6 py-3">
                    Negara
                </th>
                <th scope="col" class="px-6 py-3">
                    Berat Satuan
                </th>
                <th scope="col" class="px-6 py-3">
                    Berat Total
                </th>
               
                <th scope="col" class="px-6 py-3">
                    Aksi
                </th>
                <th scope="col" class="px-6 py-3">
                  
                </th>
            </tr>
        </thead>
        <tbody>
            <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"  v-for="(mto,index) in userdata.data" :key="mto.id">
                <td class="px-6 py-4">
                    {{index + 1 }}
                </td>
                <td scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                 
                    {{ mto.material.name }}
                </td>
                <td class="px-6 py-4">
                  {{  mto.description === undefined ? '':mto.description }}
                 
                </td>
                <td class="px-6 py-4">
                    {{ mto.material.dimension }}
                </td>
                <td class="px-6 py-4">
                    {{ mto.quantity }}
                </td>
              
                <td class="px-6 py-4">
                    {{ mto.material.unit_name }}
                </td>
                <td class="px-6 py-4">
                  {{  mto.coo === undefined ? '' :mto.coo }}
                </td>
                <td class="px-6 py-4">
                    {{ mto.weight }}
                </td>
                <td class="px-6 py-4">
                    {{ mto.weight * mto.quantity   }}
                </td>
                
                <td class="px-6 py-4 ">
                  <a  href="#"  class="font-medium text-red-600 dark:text-red-500 hover:underline"  @click.prevent="editEntry(index)">Ubah</a> 
                 </td>
                 <td>
                  <a  href="#"  class="font-medium text-red-600 dark:text-red-500 hover:underline"  @click.prevent="removeEntry(index)">Hapus</a> 
                 </td>
            </tr>
           
        </tbody>
    </table>
</div>
</div>

<div class="mt-10 flex items-center justify-end gap-x-6">
      <router-link to="/MTO"  class="rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Kembali</router-link>
      <form @submit.prevent="submitForm">
      <button type="submit" class="rounded-md bg-green-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Simpan</button>
    </form>
    </div>

    </form>
  </div>
  <div v-if="successModal" class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50"  style="  animation: fadein 1s;">
    <div class="bg-white p-8 rounded-lg flex flex-col justify-center items-center  "  style="animation: muncul-kiri-kanan-fade 1s; ">
      <img src="../../../assets/verify.png" class="w-16 h-16 mb-6 " alt="">
      <p>Data Material Take Off Berhasil Diubah</p>
    </div>
  </div>

  <div v-if="errorModal" class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50" style="animation: fadein 1s;">
    <div class="bg-white p-8 rounded-lg flex flex-col justify-center items-center  "  style="animation: muncul-kiri-kanan-fade 1s; ">
      <img src="../../../assets/cancel.png" class="w-16 h-16 mb-6 " alt="">
      <p class="  font-semibold w-72 text-center" >{{ message }}</p>
    </div>
  </div>
</template>






<script>
import axios from "../../../token";
import { ref } from 'vue';
import { setupLeaveConfirmation } from '../../../token/confirmLeave.js';





export default {
  data() {
    return {
      selectedMaterial: null,
      editedIndex: '',
      isEditMode: false,
      isOpen: false,
      entries: [],
      open: "",
      weightTotal:'',
      project:this.$route.params.id ,
      eom: '',
      Deskripsi: '',
      price:[],
      quantity: [],
      description:[],
      category: '',
      material:[],
      dataItem: {
        item: {
        coo:this.coo ,
        project:this.project_id
    
      }
      },
      BeratSatuan: '',
      dimension:[],
      weight: [],
      successModal:false,
      errorModal:false,
      status:"Proses Pengerjaan",
      categorydata:[],
      projectdata:[],
      projectdata2:[],
      userdata: [],
      materialdata:[],
      itemsPerPage: 5,
      currentPage: 1,
      message:''
    };
  },
  props: {
   
    item: {
      type: Object,
      required: true
    }
  },
  setup() {
    const isHovered = ref(false);
    setupLeaveConfirmation();

    return {
     
      isHovered,
      setupLeaveConfirmation
      
    }
},
  computed: {
    filteredEntries() {
      let filtered = this.absendata;

      if (this.selectedStatus.length > 0) {
        filtered = filtered.filter((abse) =>
          this.selectedStatus.includes(abse.status)
        );
      }

      if (this.selectedSort === "asc") {
        return filtered.sort((a, b) => a.jammasuk.localeCompare(b.jammasuk));
      } else {
        return filtered.sort((a, b) => b.jammasuk.localeCompare(a.jammasuk));
      }
    },
    totalPages() {
      
      return Math.ceil( 35 / this.itemsPerPage);
    },
    paginatedUsers() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.userdata.data.slice(start, end);
    },
  },
  watch: {
    quantity() {
      console.log(this.weightTotal)
      this.updateqty();
  },
  weight() {
      console.log(this.weightTotal)
      this.updateqty();
  },
  },
  methods: {
    updateqty(){
      this.weightTotal = this.quantity * this.weight
    },
        unit() {
      const selectedMaterial1 = this.materialdata.data.find(material => material.id === this.selectedMaterial.id);

      if (selectedMaterial1) {
        this.eom = selectedMaterial1.symbol;
        this.dimension =selectedMaterial1.dimension
      } else {
        this.eom = '';
      }
    },
    handleInput(event, propertyName) {
      // Mendapatkan nilai input tanpa karakter non-angka
      const numericValue = event.target.value.replace(/\D/g, '');
      // Memformat nilai numerik sesuai dengan preferensi Anda
      const formattedValue = this.formatNumber(numericValue); // Anda perlu menentukan fungsi formatNumber sesuai preferensi Anda
      // Perbarui nilai properti dengan nilai yang diformat
      this[propertyName] = formattedValue;
    },
    formatNumber(value) {
      // Fungsi ini dapat Anda sesuaikan sesuai dengan preferensi Anda untuk format angka
      // Contoh: Tambahkan titik sebagai pemisah ribuan
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
   removeEntry(index) {
      this.userdata.data.splice(index, 1);
    },
    
    toggleModal() {
      this.isOpen = !this.isOpen;
    },
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    editEntry(index) {
      const editedEntry = this.userdata.data[index];
      console.log([editedEntry.material.id, editedEntry.material.unit_name] )
      this.selectedMaterial = editedEntry.material ? { id: editedEntry.material.id, name: editedEntry.material.name, dimension: editedEntry.material.dimension, symbol: editedEntry.material.unit_name } : null;
     
      this.description = editedEntry.description || '';
      this.quantity = editedEntry.quantity || '';
      this.category = editedEntry.category || '';
      this.coo = editedEntry.coo || '';
      this.weight = editedEntry.weight || '';
      this.eom = editedEntry.material.unit_name || '';
      this.weightTotal =  editedEntry.weightTotal || '';
      this.isEditMode = true;
      this.editedIndex = index
    },
    saveChanges() {
    const entryData = {
    material: this.selectedMaterial ? { id: this.selectedMaterial.id, name: this.selectedMaterial.name, dimension:this.selectedMaterial.dimension, unit_name:this.selectedMaterial.symbol} : null,
    price: this.price,
    description: this.description,
    quantity: this.quantity,
    category: this.category,
    coo: this.coo,
    weight: this.weight,
    weightTotal : this.weightTotal
  };

  if (this.isEditMode) {
    this.userdata.data[this.editedIndex] = { ...entryData };
   
    this.isEditMode = false;

  } else {
    console.log(entryData)
    this.userdata.data.push(entryData);
  }

  this.resetForm();
},
    resetForm() {
    this.selectedMaterial=[];
    this.editedIndex = '';
    this.price = '';
    this.dimension = '';
    this.description = '';
    this.weightTotal='';
    this.quantity = '';
    this.category = '';
    this.coo = '';
    this.weight = '';
  },
    async submitForm() {
      const formData = new FormData();

       this.userdata.data.forEach((userdata) => {
        formData.append('material[]', userdata.material.id);
        formData.append('description[]', userdata.description);
        formData.append('qty[]', userdata.quantity);
        formData.append('price[]',0);
        formData.append('eom[]', userdata.material.unit_name);
        formData.append('weight[]', userdata.weight);
        formData.append('coo[]', userdata.coo);
        

      },
        formData.append('project',this.project),
        formData.append('_method','put')
      );  
      try {
        const response = await axios.post(`${process.env.VUE_APP_BACKEND_HOST}/material-take-off`, formData);

        console.log(response);
        this.successModal = true;
      
        setTimeout(() => {
          this.successModal = false;
          this.open = false;
        }, 2000);
        setTimeout(() => {
        this.$router.push('/MTO');
      }, 2000);
      } catch (error) {
        console.log(error);
        this.errorModal = true;

        setTimeout(() => {
          this.errorModal = false;
          this.open = false;
        }, 2000);
        this.message = error.response.data.message;
      }
    },
    getcategory() {
      axios
        .get(`${process.env.VUE_APP_BACKEND_HOST}/project-category`)
        .then((response) => {
          this.categorydata = response.data;

        })
        .catch((error) => {
          console.log(error);
        });
    },
    getuser() {
      const materialId = this.$route.params.id;
      axios
        .get(`${process.env.VUE_APP_BACKEND_HOST}/material-take-off`,{
          params:{
            project_id:materialId
          }
        })
        .then((response) => {
          
          this.userdata = response.data;

        })
        .catch((error) => {
          console.log(error);
        });
    },
    getproject2() {
      axios
        .get(`${process.env.VUE_APP_BACKEND_HOST}/project/list`)
        .then((response) => {
          this.projectdata2 = response.data;

        })
        .catch((error) => {
          console.log(error);
        });
    },
    getproject() {
      axios
        .get(`${process.env.VUE_APP_BACKEND_HOST}/unit`)
        .then((response) => {
          this.projectdata = response.data;

        })
        .catch((error) => {
          console.log(error);
        });
    },
    getmaterial() {
      axios
        .get(`${process.env.VUE_APP_BACKEND_HOST}/material`)
        .then((response) => {
          this.materialdata = response.data;

        })
        .catch((error) => {
          console.log(error);
        });
    },
    changePage(value) {
      this.currentPage += value;
    },
  },
  mounted(){
    this.getcategory();
    this.getproject2();
    this.getproject();
    this.getuser();
    this.getmaterial();
  }
};
</script>
