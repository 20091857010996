<template>
  <div class="flex justify-between mb-4">
    <h1 class="font-pop font-medium text-[18px]">Riwayat Lembur</h1>
    <div>
      <div class="flex items-center gap-4" style="  animation: muncul-kiri-kanan-fade 0.5s;">
        <button class="w-[118px] py-1 flex-shrink-0 rounded text-[#BBBBBB] border border-[#BBBBBB]" @click="open6=true">
          <div class="flex flex-row justify-center">
            <img src="../../assets/filter.png" class="mr-1 w-5" alt="" />
            <h3 class="text-[12px] text-[#565656] font-medium">Filter & Short</h3>
          </div>
        </button>
        <!-- sync button when click calling base_url/hrd/overtime/sync. blue button with sync icon -->
        <button class="w-[118px] py-1 flex-shrink-0 rounded text-white bg-[#3B82F6] border border-[#3B82F6]"
          @click="getSync()" style="  animation: muncul-kiri-kanan-fade 0.5s;">
          <div class="flex flex-row justify-center">
            <img src="../../assets/sync.png" class="mr-1 w-5" alt="" />
            <h3 class="text-[12px] font-medium">Sinkronisasi</h3>
          </div>

        </button>
        <FormPermohonan />
      </div>
    </div>
  </div>

  <transition>
    <div
      class="fixed z-10 left-0 top-0 w-full h-full flex items-center justify-center overflow-auto backdrop-blur-sm bg-black/10 bg-opacity-50 font-pop font-regular"
      v-if="open6">

      <div class="bg-white px-16 py-4 rounded-lg shadow-lg">
        <div class="w-full">
          <h1 class="text-[20px] mb-4">Filter & Short</h1>
          <hr class="mb-4" />

          <div class="flex flex-col gap-4">
            <!-- <select name="" v-model="extension" id="" class="px-3 py-2 font-pop rounded text-sm  ">
          <option value="" selected>Pilih Extension</option>
          <option value="xls" >Excel</option>
          <option value="pdf" >PDF</option>
        </select> -->

            <div class="flex flex-col gap-4 font-pop">
              <label for="tanggal" class="w-32">Pilih Tanggal:</label>
              <input type="date"
                class="block  rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                v-model="selectedTanggal" id="tanggal">
            </div>

            <select name="" v-model="filter" id="" class="px-3 py-2 font-pop rounded text-sm  ">
              <option value="" selected>Pilih Filter</option>
              <option value="department">Departement</option>
              <option value="position">Posisi</option>
              <option value="employeeStatus">Kontrak Kerja</option>
              <option value="accountStatus">Status</option>
            </select>

            <div class="sm:col-span-2" v-if="this.filter === 'department'">
              <label for="country" class="block text-sm font-medium leading-6 text-gray-900">Departement</label>
              <div class="mt-2">
                <select v-model="id" id="country" name="country" autocomplete="country-name"
                  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                  <option value="" selected>Pilih Departement</option>
                  <option v-for="categoory in projectdata.data" :key="categoory.id" :value="categoory.id">
                    {{ categoory.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="sm:col-span-1" v-if="this.filter === 'position'">
              <label for="country" class="block text-sm font-medium leading-6 text-gray-900">Jabatan</label>
              <div class="mt-2">
                <select v-model="id" id="country" name="country" autocomplete="country-name"
                  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                  <option value="" selected>Pilih Jabatan</option>
                  <option v-for="project in categorydata.data" :key="project.id" :value="project.id">
                    {{ project.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="sm:col-span-1" v-if="this.filter === 'accountStatus'">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Status Karyawan</label>
              <div class="mt-2">
                <select v-model="id" id="country" name="country" autocomplete="country-name"
                  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                  <option value="" selected>Pilih Status Karyawan</option>
                  <option value="1">Aktif</option>
                  <option value="0">TIdak</option>
                </select>
              </div>
            </div>

            <div class="sm:col-span-1" v-if="this.filter === 'employeeStatus'">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Jenis Ikatan
                Kerja</label>
              <div class="mt-2">
                <select v-model="id" id="country" name="country" autocomplete="country-name"
                  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                  <option value="" selected>Pilih Ikatan Kerja</option>
                  <option value="tetap">Karyawan Tetap</option>
                  <option value="kontrak">Kontrak</option>
                </select>
              </div>
            </div>

            <select name="" v-model="is_temp" id="" class="px-3 py-2 font-pop rounded text-sm  ">
              <option value="" disabled>Tipe data</option>
              <option value="false">Sudah Sinkron</option>
              <option value="true">Belum Sinkron</option>
            </select>

          </div>

          <div class="flex justify-end">
            <button class="mt-8 bg-red-500 px-5 py-3 rounded-md" @click="close6()">
              <h1 class="text-[12px] text-white">Kembali</h1>
            </button>

            <button class="ml-4 mt-8 bg-[#A6CF98] px-[3rem] py-3 rounded-md" type="submit"
              @click="getfilter(this.selectedTanggal,this.filter,this.id,this.is_temp)">
              <h1 class="text-[12px] text-white">Terapkan</h1>
            </button>

          </div>
        </div>
      </div>

    </div>
  </transition>

  <div class="  first-letter:relative overflow-x-auto border sm:rounded-lg bg-white shadow-lg font-pop"
    style=" animation: muncul-kiri-kanan-fade 1s;">

    <table class="min-w-full text-sm text-left text-gray-500 ] dark:text-gray-400">
      <thead class="text-xs text-gray-700 border-b bg-[#64707628]  dark:bg-gray-700 dark:text-gray-400 text-[14px]">
        <tr class="text-[#868686]">
          <th scope="col" class="px-10 py-3  font-medium">
            No
          </th>
          <th scope="col" class="px-10 py-3  font-medium">
            Nama
          </th>
          <th scope="col" class="px-1 py-3">
            <div class="flex items-center  font-medium">
              Tanggal Lembur
            </div>
          </th>
          <th scope="col" class="px-6 py-3">
            <div class="flex items-center  font-medium">
              NIK
            </div>
          </th>
          <th scope="col" class="px-6 py-3">
            <div class="flex items-center  font-medium">
              Jam Selesai Lembur
            </div>
          </th>

          <th scope="col" class="px-6 py-3">
            <div class="flex items-center  font-medium">
              Status
            </div>
          </th>
          <th scope="col" class=" py-3">
            <div class="flex items-center  font-medium">
            </div>
          </th>
        </tr>
      </thead>
      <tbody v-if="permohonanData.data">
        <tr class=" border-b dark:bg-gray-800 dark:border-gray-700 text-[16px] font-medium text-black"
          v-for="(permohonan,index) in permohonanData.data" :key="permohonan.id">
          <td class="px-10 py-4">
            {{ index +1 }}
          </td>
          <th scope="row"
            class="flex flex-row items-center px-10 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
            <img src="https://pbs.twimg.com/media/GF6JAuVWcAETdhA.jpg" class="w-10 h-10 rounded-full" alt="">
            <h1 class="ml-2">{{ permohonan.name }}</h1>
          </th>
          <td class="px-1 py-4">
            {{ permohonan.date }}
          </td>
          <td class="px-6 py-4">
            {{ permohonan.nik }}
          </td>
          <td class="px-6 py-4">
            {{ permohonan.time_out || '-:-' }}
          </td>

          <td class=" py-4">
            <button :class="{
                            'bg-[#FFE69A]/30 text-[#EFB812]': permohonan.status === 'Proses',
                            'bg-[#FF5E5E]/30 text-[#ED4747]': permohonan.status === 'Ditolak',
                            'bg-[#82C97C]/30 text-[#21B115]': permohonan.status === 'Disetujui',
                        }" class="w-[118px] h-[30px] flex-shrink-0 rounded-lg  ml-4">
              <h3 class="text-[12px] font-medium">{{ permohonan.status }}</h3>
            </button>
          </td>
          <td class="px-6 py-4">
            <div class="flex flex-row gap-4">
              <FormApproval :permohonandata="permohonan"></FormApproval>
              <img class="w-8 h-8 cursor-pointer" @click="deleteMaterial(permohonan.id,this.is_temp)"
                src="../../assets/bin.png" alt="">
            </div>


          </td>
        </tr>

      </tbody>
      <tbody v-else style=" animation: muncul-kiri-kanan-fade 0.8s;">
        <tr>
          <td colspan="8" class="text-center font-pop text-[16px] py-4 text-gray-600 dark:text-gray-400">Data Tidak
            Tersedia</td>
        </tr>
      </tbody>
    </table>
  </div>

  <div v-if="successModal"
    class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50"
    style="  animation: fadein 1s;">
    <div class="bg-white p-8 rounded-lg flex flex-col justify-center items-center  "
      style="animation: muncul-kiri-kanan-fade 1s; ">
      <img src="../../assets/checked.png" class="w-16 h-16 mb-6 " alt="">
      <p>{{ this.successModalMessage ?? 'Berhasil' }}</p>
    </div>
  </div>

  <div v-if="errorModal"
    class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50"
    style="animation: fadein 1s;">
    <div class="bg-white p-8 rounded-lg flex flex-col justify-center items-center  "
      style="animation: muncul-kiri-kanan-fade 1s; ">
      <img src="../../assets/cancel.png" class="w-16 h-16 mb-6 " alt="">
      <p class="  font-semibold w-72 text-center">{{ message }}</p>
    </div>
  </div>

</template>
  
  <script>
 import axios from "../../token";
  import FormApproval from './FormApprovalLembur'
  import FormPermohonan from "../Component/FormLembur.vue"
  
  export default {
    components: { FormApproval,FormPermohonan },
  
    data() {
      return {
        permohonanData: [],
        open:false, // Initialize permohonanData as an empty array
        selectedTanggal: new Date().toISOString().substr(0, 10),
        open6:false,
        filter:'',
        categorydata:[],
        projectdata: [],
        is_temp: 'false',
        successModal: false,
        successModalMessage: '',
      };
    },
    methods: {
        handleChangeDate(event) {
   
   const selectedDate = event.target.value;
   
 
   this.selectedTanggal = selectedDate;
   this.getPermohonan(this.selectedTanggal)
 
 },
 async deleteMaterial(id, is_temp){
        try {
          let data = new URLSearchParams();
          data.append('id', id);
          data.append('is_temp', is_temp);
  
          let response = await axios.delete(`${process.env.VUE_APP_BACKEND_HOST}/employee-request/overtime`, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
             
            },
            data: data,
            params: {
              is_temp: is_temp
            }
          });
          this.getPermohonan();
          console.log(response.data);
          this.successModalMessage = response.data.message;
          this.successModal = true;
          setTimeout(() => {
            this.successModal = false;
          }, 3000);
        } catch (error) {
          console.error(error);
          this.message = error.response.data.message;
          this.errorModal = true;
          setTimeout(() => {
            this.errorModal = false;
          }, 3000);
        }
      },
 close6(){
        this.open6=false
        this.id='';
        this.filter='';
        this.extension='';
      },
      getfilter(date,filter,id){
      this.getPermohonan(date,filter,id,this.is_temp)
    },
      getPermohonan(date,filter,id,is_temp) {
        if (!date) {
            date = new Date().toISOString().substr(0, 10);
        }

        let params = {
            date: date,
            is_temp: is_temp || 'false'
        };
  if (filter) {
    params.filter = filter;
    params.filter_value = id;
    params.date=date;
    params.is_temp='true'
  }
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/employee-request/overtime/list`,{
            params: params 
        })
          .then((response) => {
            this.permohonanData = response.data;
            this.open6=false
          })
          .catch((error) => {
            console.log(error);
            this.permohonanData = [];
            this.open6=false
          });
      },
      getSync() {
        // get request from base_url/hrd/overtime/sync
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/hrd/overtime/sync`)
          .then((response) => {
            this.successModalMessage = response.data.message;
            this.successModal = true;
            setTimeout(() => {
              this.successModal = false;
            }, 3000);
          })
          .catch((error) => {
            console.log(error);
          });
      },
      getcategory() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/position`)
          .then((response) => {
            this.categorydata = response.data;
  
          })
          .catch((error) => {
            console.log(error);
          });
      },
      getproject() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/department`)
          .then((response) => {
            this.projectdata = response.data;
  
          })
          .catch((error) => {
            console.log(error);
          });
      },
    },
    mounted() {
      this.getPermohonan();
      this.getcategory();
      this.getproject();
    },
  };
  </script>
  
  