<template>

<nav class="flex mb-10 font-pop" aria-label="Breadcrumb">
  <ol class="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
    <li>
      <div class="flex items-center">
       
        <router-link to="/Konfigurasi" class="ms-1 text-sm font-medium text-gray-700 hover:text-red-500 md:ms-2 dark:text-gray-400 dark:hover:text-white">Konfigurasi</router-link>
      </div>
    </li>
    <li aria-current="page">
      <div class="flex items-center">
        <svg class="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4"/>
        </svg>
        <span class="ms-1 text-sm font-medium text-red-500 md:ms-2 dark:text-gray-400">Master Role</span>
      </div>
    </li>
  </ol>
</nav>

   <div class="md:flex md:flex-row md:justify-between xxs:hidden">
    <div class="relative text-gray-600">
  <input type="search" name="serch" placeholder="Pencarian..." class="bg-[#FAFBFD] h-10 px-5 pr-24 rounded text-sm focus:outline-none">
  <button type="submit" class="absolute right-0 top-0 mt-3 mr-4">
    <svg class="h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 56.966 56.966" style="enable-background:new 0 0 56.966 56.966;" xml:space="preserve" width="512px" height="512px">
      <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z"/>
    </svg>
  </button>
</div>

<div class="">
  <transition name="fade-in">
 
  <button
      class="w-[142px] h-[30px] p-1 mr-4 rounded text-white bg-red-500"
      @click="open = true"
    >
      <div class="flex flex-row justify-center">
        <img src="../../assets/add.png" class="mr-1 w-5" alt="" />
        <h3 class="text-[12px] font-medium">Tambah Role</h3>
      </div>
    </button>
 
</transition>
   
 
</div>

  </div>

  <div class="grid md:grid-cols-4 gap-10 justify-between items-center xxs:grid-cols-1 font-pop mt-10">
     
     <div v-for="category in categorydata.data" :key="category.id"   class="  bg-white  rounded overflow-hidden cursor-pointer px-6 "  style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;  animation: muncul-kiri-kanan-fade 1.5s;">
       <transition name="fade-in">
         <div class="flex flex-row justify-between  items-center">
          <h2 class="text-[12px] font-semibold mb-2 underline"  @click="open1 = true,toggleVisibility(category.id)" >{{ category.name }}</h2>
           <div class=" flex flex-col p-6"> 
            
           
           </div>
           <img class="w-8 h-8 cursor-pointer" @click="deleteMaterial(category.id)" src="../../assets/bin.png" alt="">
           
          
         
       
       </div>
     </transition>
     </div>
 
   </div>

   <transition>
    <div
      class="fixed z-10 left-0 top-0 w-full h-full flex items-center justify-center overflow-auto bg-black/40 bg-opacity-50 font-pop font-regular"
      v-if="open1"
    >
  
      <div class="bg-white px-16 py-4 w-[60rem] max-w-full rounded-lg shadow-lg">
        <div class="w-full">
          <div class="flex justify-between mb-8 mt-4">
          <h1 class="text-[20px] ">Setup Permission</h1>
          <div class="flex flex-row gap-4 items-center">
          <label for="month">Group</label>
          <select @change="handleMonthChange"  class="block w-[10rem] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" v-model="selectedMonth" id="month">
            <option   value="" disabled selected>Pilih Group Permission</option>
            <option   value="1">Absensi</option>
            <option   value="5">Payroll</option>
            <option   value="3">Inventory</option>
            <option   value="6">Pinjaman</option>
            <option   value="4">Konfigurasi</option>
            <option   value="7">Work Order</option>
            <option   value="2">HR</option>
            <option   value="8">Project</option>
          </select>

              <!-- <button class="rounded-md bg-green-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" @click="applyFilter">Terapkan Filter</button> -->
            </div>
          </div>
          <hr class="mb-4" />
          <ul class=" flex flex-row gap-4 text-sm font-medium text-gray-500 dark:text-gray-400 md:me-4 mb-4 md:mb-0">
  
           
        <!-- <li  @click="changeTab('project',8)">
  <a
    href="#"
    class="inline-flex items-center px-4 py-3 text-black  rounded-lg active w-full dark:bg-blue-600 bg-gray-50"
    aria-current="page" :class="{ 'bg-red-500 text-white': activeTab === 'project' }"
  >
    Project
  </a>
</li>

<li  @click="changeTab('Inventory',3)">
  <a
    href="#"
    class="inline-flex items-center px-4 py-3 rounded-lg  bg-gray-50  w-full dark:bg-gray-800 dark:hover:bg-gray-700 dark:hover:text-white"  :class="{ 'bg-red-500 text-white': activeTab === 'Inventory' }"
  >
    Inventory
  </a>
</li>

<li @click="changeTab('HR',7)">
  <a
    href="#"
    class="inline-flex items-center px-4 py-3 rounded-lg  bg-gray-50  w-full dark:bg-gray-800 dark:hover:bg-gray-700 dark:hover:text-white"
    :class="{ 'bg-red-500 text-white': activeTab === 'HR' }"

  >
    HumanResource
  </a>
</li>

<li @click="changeTab('Work',6)">
  <a
    href="#"
    class="inline-flex items-center px-4 py-3 rounded-lg  bg-gray-50  w-full dark:bg-gray-800 dark:hover:bg-gray-700 dark:hover:text-white"
    :class="{ 'bg-red-500 text-white': activeTab === 'Work' }"

  >
    Work Order
  </a>
</li>

<li @click="changeTab('Absensi',1)">
  <a
    href="#"
    class="inline-flex items-center px-4 py-3 rounded-lg  bg-gray-50  w-full dark:bg-gray-800 dark:hover:bg-gray-700 dark:hover:text-white"
    :class="{ 'bg-red-500 text-white': activeTab === 'Absensi' }"

  >
    Absensi
  </a>
</li>

<li @click="changeTab('Pinjaman',4)">
  <a
    href="#"
    class="inline-flex items-center px-4 py-3 rounded-lg  bg-gray-50  w-full dark:bg-gray-800 dark:hover:bg-gray-700 dark:hover:text-white"
    :class="{ 'bg-red-500 text-white': activeTab === 'Pinjaman' }"

  >
    Pinjaman
  </a>
</li>

<li @click="changeTab('Konfigurasi',5)">
  <a
    href="#"
    class="inline-flex items-center px-4 py-3 rounded-lg  bg-gray-50  w-full dark:bg-gray-800 dark:hover:bg-gray-700 dark:hover:text-white"
    :class="{ 'bg-red-500 text-white': activeTab === 'Konfigurasi' }"

  >
    Konfigurasi
  </a>
</li> -->





</ul>
          <div class="mt-4 grid xxs:grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-3 ">

            <div class="flex flex-col gap-4 mt-4" v-for="(category1, index) in filteredCategories" :key="index">
            <label class="inline-flex items-center me-5 cursor-pointer">
              <input
              type="checkbox"
              v-model="selectedCategoryIds"
              :value="category1.id"
              :checked="category1.isGranted"
              class="sr-only peer"
              @change="togglePermission(category1.id,index)"
          >
            <div class="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-red-300 dark:peer-focus:ring-red-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-red-600"></div>
            <span class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">{{ category1.name}}</span>
          </label>
        </div>
      </div>

          <div class="flex justify-end">
            <button
              class="mt-8 bg-red-500 px-5 py-3 rounded-md"
              @click="open1 = false"
            >
              <h1 class="text-[12px] text-white">Kembali</h1>
            </button>
            <form @submit.prevent="submitForm">
            <button
              class="ml-4 mt-8 bg-green-500 px-[3rem] py-3 rounded-md"
               type="submit"
            >
              <h1 class="text-[12px] text-white">Simpan</h1>
            </button>
          </form>
         </div>
        </div>
      </div>
  
    </div>
  </transition>

  <transition>
    <div
      class="fixed z-10 left-0 top-0 w-full h-full flex items-center justify-center overflow-auto bg-black/40 bg-opacity-50 font-pop font-regular"
      v-if="open"
    >
   
      <div class="bg-white px-16 py-4 rounded-lg shadow-lg">
        <div class="w-full">
          <h1 class="text-[20px] mb-4">Tambah Role</h1>
          <hr class="mb-4" />
          <div class="sm:col-span-2">
              <label for="last-name" class="block text-[14px] font-medium leading-6 text-gray-900">Nama Role</label>
              <div class="mt-2">
                <input    v-model="name" type="text" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>
          <div class="flex justify-end">
            <button
              class="mt-8 bg-red-500 px-5 py-3 rounded-md"
              @click="open = false"
            >
              <h1 class="text-[12px] text-white">Kembali</h1>
            </button>
            <form @submit.prevent="submitRole">
            <button
              class="ml-4 mt-8 bg-[#A6CF98] px-[3rem] py-3 rounded-md"
               type="submit"
            >
              <h1 class="text-[12px] text-white">Simpan</h1>
            </button>
          </form>
          </div>
        </div>
      </div>
    
    </div>
  </transition>

  <div v-if="successModal" class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50"  style="  animation: fadein 1s;">
      <div class="bg-white p-8 rounded-lg flex flex-col justify-center items-center  "  style="animation: muncul-kiri-kanan-fade 1s; ">
        <img src="../../assets/verify.png" class="w-16 h-16 mb-6 " alt="">
        <p>Data Role Berhasil ditambahkan</p>
      </div>
    </div>

    <div v-if="successModal1" class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50"  style="  animation: fadein 1s;">
      <div class="bg-white p-8 rounded-lg flex flex-col justify-center items-center  "  style="animation: muncul-kiri-kanan-fade 1s; ">
        <img src="../../assets/verify.png" class="w-16 h-16 mb-6 " alt="">
        <p>Data Role Berhasil dihapus</p>
      </div>
    </div>

    <div v-if="errorModal" class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50" style="animation: fadein 1s;">
    <div class="bg-white p-8 rounded-lg flex flex-col justify-center items-center  "  style="animation: muncul-kiri-kanan-fade 1s; ">
      <img src="../../assets/cancel.png" class="w-16 h-16 mb-6 " alt="">
      <p class="  font-semibold w-72 text-center" >{{ message }}</p>
    </div>
  </div>
    
</template>

<script>

import axios from "../../token";
export default {
  data() {
    return {
      activeTab: '',
      searchTerm: "",
      open: "",
      open1: false,
      permidata:[],
      successModal1:false,
      permidata1:[],
      selectedDate: this.getInitialDate(),
      name: "",
      successModal:false,
      categorydata:[],
      selectedCategoryIds: [],
      errorModal:false,
      id:'',
      selectedMonth:'',
    };
  },
  components: {
   
  },
  watch: {
    selectedMonth(newVal) {
      console.log('Selected Month changed:', newVal);
     
    }
  },
  computed: {
    filteredEntries() {
      let filtered = this.entries;

      if (this.selectedStatus.length > 0) {
        filtered = filtered.filter((entry) =>
          this.selectedStatus.includes(entry.status)
        );
      }

      if (this.selectedSort === "asc") {
        return filtered.sort((a, b) => a.dateIn.localeCompare(b.dateIn));
      } else {
        return filtered.sort((a, b) => b.dateIn.localeCompare(a.dateIn));
      }
    },
    filteredCategories() {
    console.log('Selected Month:', this.selectedMonth);
    console.log('Permi Data:', this.permidata.data);

    if (!this.selectedMonth) {
      return this.permidata.data; // If no group is selected, return all data
    } else {
      // If a group is selected, filter data based on the selected group
      const filteredData = this.permidata.data.filter(category => category.permission_group_id ===  this.selectedMonth);
      console.log(filteredData)
      return filteredData;
    }
  },
  },
  methods: {
    openModal() {
      this.isModalOpen = true;
    },
    changeTab(tabName,id) {
    this.activeTab = tabName;
    localStorage.setItem('activeTab', tabName); 
    this.getpermi1(id);
    this.getpermi(this.id);

  },
    closeModal() {
      this.isModalOpen = false;
    },
    async deleteMaterial(id) {
        try {
          let data = new URLSearchParams();
          data.append('id[]', id);
  
          let response = await axios.delete(`${process.env.VUE_APP_BACKEND_HOST}/role`, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
             
            },
            data: data
          });
          this.getcategory();
          console.log(response.data);
          this.successModal1 = true;
          setTimeout(() => {
            this.successModal1 = false;
          }, 3000);
        } catch (error) {
          console.error(error);
          this.message = error.response.data.message;
          this.errorModal = true;
          setTimeout(() => {
            this.errorModal = false;
          }, 3000);
        }
      },
    async submitRole() {
      try {
        const response = await axios.post(`${process.env.VUE_APP_BACKEND_HOST}/role`, {
          name: this.name,
        });
        console.log(response);
        this.successModal = true;
        setTimeout(() => {
          this.successModal = false;
          location.reload();
        }, 3000);
      } catch (error) {
        console.log(error);
        this.message = error.response.data.message;
        this.errorModal = true;
        setTimeout(() => {
            this.errorModal = false;
        }, 3000);
      }
    },
    applyFilters() {
      this.closeModal();
    },
    togglePermission(category) {
    // Cari item berdasarkan ID
    const item = this.permidata1.data.find(item => item.permission_id === category);
    console.log("category:", category);
    console.log("item:", item);
    
    // Jika item ditemukan
    if (item) {
        // Ubah nilai isGranted menjadi false
        const index = this.permidata.data.findIndex(data => data.id === item.permission_id);
        if (index !== -1) {
            this.permidata.data[index].isGranted = false;
        }
    }
},


    getInitialDate() {
      return new Date().toISOString().split("T")[0];
    },
    async submitForm() {
    const formData = new FormData();

    if (this.selectedCategoryIds.length > 0) { 
    this.selectedCategoryIds.forEach(entry => {
        formData.append('permission_id[]', entry);
    });
    } else {
      this.permidata.data.forEach(entry => {
        console.log(this.permidata.data)
        if (entry.isGranted) {
            formData.append('permission_id[]', entry.id); // Hanya menambahkan ID jika isGranted true
        }
    });
    }

    formData.append('id', this.id);
    formData.append('entity', 'role');

    try {
        const response = await axios.post(`${process.env.VUE_APP_BACKEND_HOST}/permission/assign`, formData);
        console.log(response);
     
        this.successModal = true;

        setTimeout(() => {
            this.successModal = false;
            this.open = false;
        }, 3000);

          // Bersihkan data setelah berhasil disimpan
          this.entries = [];
          this.member = '';
          this.teamleader = '';
          this.name = '';
          this.qty = '';
          this.eom = '';
          setTimeout(() => {
          window.location.reload();
        }, 3000);
    } catch (error) {
        console.log(error);
        this.message = error.response.data.message;
        this.errorModal = true;
        setTimeout(() => {
            this.errorModal = false;
        }, 3000);
    }
},
async toggleVisibility(id) {
    // Use Promise.all to run asynchronous operations concurrently
    this.permidata1.data=[]
    this.getpermi(id);
    this.id = id;

    // Now you can perform additional actions based on the comparison
    // ...
  },
    getcategory() {
      axios
        .get(`${process.env.VUE_APP_BACKEND_HOST}/role`)
        .then((response) => {
          this.categorydata = response.data;

        
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getpermi(id) {

try {
  
  const response = await axios.get(`${process.env.VUE_APP_BACKEND_HOST}/permission/entity`, {
    params: {
      entity: 'role',
      id: id
    }
  });

  this.permidata1 = response.data;

  // Mengupdate selectedCategoryIds berdasarkan data dari permintaan Axios
  this.selectedCategoryIds =   this.permidata.data
    .filter(category1 => {
      category1.isGranted = this.permidata1.data.some(item => parseInt(item.permission_id) == category1.id);
      return category1.isGranted;
    })
    .map(category1 => category1.id);

} catch (error) {
  console.log(error);
}
},
    getpermi1() {
      axios
        .get(`${process.env.VUE_APP_BACKEND_HOST}/permission`,{
          params:{
            // group_id:id
          }
        })
        .then((response) => {
          this.permidata = response.data;

        
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleMonthChange(event) {
      this.selectedMonth = event.target.value;
      console.log('Selected Month:', this.selectedMonth);
    
    },
    

  },
  mounted(){
    this.getcategory();
    this.getpermi1()
    this.getpermi()
    this.activeTab='project'
    this.selectedMonth = "1";
  },
  created() {
  const storedTab = localStorage.getItem('activeTab');
  if (storedTab) {
    this.activeTab = storedTab;
  } else {
    this.activeTab = 'project';
  }
},
};
</script>

<style>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.search-input:focus {
  outline: none;
  box-shadow: none; /* Optional: Remove box-shadow if it's present */
}
</style>
