<template>
  
   <div class="md:flex md:flex-row md:justify-between xxs:hidden">
  
    <div class="relative text-gray-600">
      <input type="search" v-model="search" @input="getmaterial" name="serch" placeholder="Pencarian..." class="bg-[#FAFBFD]  focus:ring-gray-500 focus:border-gray-500 h-10 px-5 w-64  rounded text-sm focus:outline-none border-1 border-gray-300">
      <button type="submit" class="absolute right-0 top-0 mt-3 mr-4">
        <svg class="h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 56.966 56.966" style="enable-background:new 0 0 56.966 56.966;" xml:space="preserve" width="512px" height="512px">
          <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z"/>
        </svg>
      </button>
      </div>

<div class="">
  <transition name="fade-in">
 <router-link to="/TambahReport">
  <button
      class="w-[142px] h-[30px] p-1 mr-4 rounded text-white bg-red-500"
      
    >
      <div class="flex flex-row justify-center">
        <img src="../../assets/add.png" class="mr-1 w-5" alt="" />
        <h3 class="text-[12px] font-medium">Tambah Report</h3>
      </div>
    </button>
  </router-link>
 
</transition>
   
 
</div>

  </div>
  <div class="grid xl:grid-cols-5 lg:grid-cols-4  md:grid-cols-3 xs:grid-cols-2 gap-10 justify-between items-center xxs:grid-cols-1 font-pop mt-10">
  <div v-for="category in categorydata.data" :key="category.id" class="relative flex flex-col mt-6 text-gray-700 bg-white shadow-md bg-clip-border rounded-xl w-80 h-80" style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;  animation: muncul-kiri-kanan-fade 1s;"   @click="openCategory(category.id)">
    <div class="relative h-44 mx-4 -mt-6 overflow-hidden text-white shadow-lg bg-clip-border rounded-xl bg-blue-gray-500 shadow-blue-gray-500/40">
  <div class="relative overflow-hidden">
    <div class="flex transition-transform duration-500 ease-in-out" :style="{ 'transform': 'translateX(' + translateX + ')' }">
      <div v-for="(image, index) in images" :key="index" class="w-full h-44 flex-shrink-0">
        <img :src="image.url" alt="Slide Image" class="w-full h-full object-cover">
      </div>
    </div>
  </div>
  </div>
  <div class="px-6 mt-4">
    <h5 class="block mb-2 font-pop text-xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
      {{ category.name }}
    </h5>
    <div class="flex w-full h-4 mb-2 overflow-hidden font-sans text-xs font-medium rounded-full flex-start bg-blue-gray-50">
      <div class="flex items-center justify-center w-1/2 h-full overflow-hidden text-black break-all  rounded-full"   :style="{ width: category.progress + '%', backgroundColor: parseInt(category.progress) === 100 ? '#527853' : '#FAA300' }">
        {{category.progress}}%
      </div>
    </div>  
    <p class="block font-pop  text-sm antialiased font-medium leading-relaxed text-inherit">
      ## Report ini terkait progress dari project <span class="font-bold underline">{{category.project_name}}</span>
      yang dikerjakan oleh team <span class="font-bold underline">{{category.team_name}}</span>
    </p>
  </div>
  <div class="p-6 pt-0 mt-4 flex flex-row justify-between items-center">
    <router-link :to="'/EditReport/' + category.id"
      class="align-middle select-none font-pop font-bold text-center  transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-2 px-5 rounded-lg bg-red-500 text-white shadow-md shadow-gray-900/10 hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
      type="button">
      View Report
    </router-link>
    <h1 class="font-pop text-sm font-medium">{{ category.date }}</h1>
  </div>
</div> 
 

  
  </div>

  <div style=" animation: muncul-kiri-kanan-fade 1s;">
   
   <div class="flex justify-end mt-8 text-sm">
     <!-- <button
       @click="getmaterial(1)"
       :disabled="pagination.current_page === 1"
       class="px-4 py-2 mx-1 text-white bg-blue-500 rounded-md hover:bg-blue-600"
     >
       First
     </button> -->
     <button
       @click="getcategory(pagination.current_page - 1)"
       :disabled="pagination.current_page === 1"
       class="px-4 py-2 mx-1 text-white bg-red-500 rounded-md hover:bg-red-600"
     >
     Sebelumnya
     </button>
     <button
       v-for="page in pagination.last_page"
       :key="page"
       @click="getcategory(page)"
       :class="{ 'bg-red-500 text-white': pagination.current_page === page }"
       class="px-4 py-2 mx-1  text-black rounded-md hover:bg-red-600"
     >
       {{ page }}
     </button>
     <button
       @click="getcategory(pagination.current_page + 1)"
       :disabled="pagination.current_page === pagination.last_page"
       class="px-4 py-2 mx-1 text-white bg-red-500 rounded-md hover:bg-red-600"
     >
       Selanjutnya
     </button>
     <!-- <button
       @click="getmaterial(pagination.last_page)"
       :disabled="pagination.current_page === pagination.last_page"
       class="px-4 py-2 mx-1 text-white bg-blue-500 rounded-md hover:bg-blue-600"
     >
       Last
     </button> -->
   </div>
 </div>

  <!-- <div class="grid md:grid-cols-4 gap-10 justify-between items-center xxs:grid-cols-1 font-pop mt-10">
     <router-link :to="'/EditReport/' + category.id" v-for="category in categorydata.data" :key="category.id"  class="  bg-white  rounded overflow-hidden cursor-pointer px-6 "  style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;  animation: muncul-kiri-kanan-fade 1s;"   @click="openCategory(category.id)">
       <transition name="fade-in">
         <div class="flex flex-row  items-center">
          <h2 class="text-[12px] font-semibold mb-2">{{ category.name }}</h2>
           <div class=" flex flex-col p-6"> 
            
           
           </div>
           
          
         
       
       </div>
     </transition>
     </router-link>
   
   </div> -->

  <transition>
    <div
      class="fixed z-10 left-0 top-0 w-full h-full flex items-center justify-center overflow-auto bg-black/40 bg-opacity-50 font-pop font-regular"
      v-if="open"
    >
   
      <div class="bg-white px-16 py-4 w-[50rem] rounded-lg shadow-lg">
        <div class="w-full">
          <h1 class="text-[20px] mb-4">Tambah Team</h1>
          <hr class="mb-4" />
          <div class="border-b  border-gray-900/10 pb-12  pb-6">
          <div class="mt-4 grid xxs:grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-6">
            
          <div class="sm:col-span-3">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Nama Team</label>
              <div class="mt-2">
                <input    v-model="name" type="text" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>

            <div class="sm:col-span-3">
                <label for="country" class="block text-sm font-medium leading-6 text-gray-900">Nama Team Leader</label>
                <div class="mt-2">
                  <select   v-model="teamleader" id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                    <option value="" disabled selected>Pilih Team Leader</option>
                    <option
                      v-for="data1 in userdata.data"
                      :key="data1.id"
                      :value="data1.id"
              >
                {{ data1.user.name }}
              </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
             <div class="flex flex-row">
              <div class="sm:col-span-full mt-6 mr-6">
                <label for="country" class="block text-sm font-medium leading-6 text-gray-900">Nama Anggota </label>
                <div class="mt-2">
                  <select   v-model="member" id="country" name="country" autocomplete="country-name" class="block max-w-full w-[25rem] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                    <option value="" disabled selected>Pilih Anggota</option>
                    <option
                      v-for="data1 in userdata.data"
                      :key="{ id: data1.id, name: data1.user.name  }" :value="{ id: data1.id, name: data1.user.name }"
              >
                {{ data1.user.name }}
              </option>
                  </select>
                </div>
                
              </div>
              <div class="mt-14">
               <form @submit.prevent="submittemp">
              <button type="submit" class="px-4 py-2 text-sm font-medium text-white bg-green-400 rounded-md hover:bg-green-700 focus:outline-none ">Tambahkan</button>
            </form>
            </div>
          </div>
              

             

  <div class="border-b  border-gray-900/10 pb-12  pb-6">
  <div class="relative overflow-x-auto shadow-md sm:rounded-lg font-pop mt-10">
    <table class="w-full text-[12px] text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead class="t text-gray-700  bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
                <th scope="col" class="px-6 py-3">
                    No
                </th>
                <th scope="col" class="px-6 py-3">
                    Nama
                </th>
                <th scope="col" class="px-6 py-3">
                    Aksi
                </th>
            </tr>
        </thead>
        <tbody>
            <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"  v-for="(entry, index) in entries" :key="index">
              <td class="px-6 py-4">
                    {{ index + 1 }}
                </td>
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {{ entry.member.name }}
                </th>
                <td class="px-6 py-4">
                  <a
          href="#"
          class="font-medium text-red-600 dark:text-red-500 hover:underline"
          @click.prevent="removeEntry(index)"
        >Hapus</a>
                </td>
            </tr>
           
        </tbody>
    </table>
</div>
</div>

          <div class="flex justify-end">
            <button
              class="mt-8 bg-red-500 px-5 py-3 rounded-md"
              @click="open = false"
            >
              <h1 class="text-[12px] text-white">Kembali</h1>
            </button>
            <form @submit.prevent="submitForm">
            <button
              class="ml-4 mt-8 bg-green-400  px-[3rem] py-3 rounded-md"
               type="submit"
            >
              <h1 class="text-[12px] text-white">Simpan</h1>
            </button>
          </form>
          </div>
        </div>
      </div>

    </div>
  </transition>


  <transition>
    <div
      class="fixed z-10 left-0 top-0 w-full h-full flex items-center justify-center overflow-auto bg-black/40 bg-opacity-50 font-pop font-regular"
      v-if="open1"
    >
   
      <div class="bg-white px-16 py-4 w-[50rem] rounded-lg shadow-lg">
        <div class="w-full">
          <h1 class="text-[20px] mb-4">Detail Team</h1>
          <hr class="mb-4" />
          <div class="border-b  border-gray-900/10 pb-12  pb-6">
          <div class="mt-4 grid xxs:grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-6">
            
          <div class="sm:col-span-3">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Nama Team</label>
              <div class="mt-2">
                <input    v-model="name" type="text" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled/>
              </div>
            </div>

            <div class="sm:col-span-3">
                <label for="country" class="block text-sm font-medium leading-6 text-gray-900">Nama Team Leader</label>
                <div class="mt-2">
                  <select   v-model="teamleader" id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6" disabled>
                    <option value="" disabled selected>Pilih Team Leader</option>
                    <option
                      v-for="data1 in userdata.data"
                      :key="data1.user.name"
                      :value="data1.user.name"
              >
                {{ data1.user.name }}
              </option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          
            
              

             

  <div class="border-b  border-gray-900/10 pb-12  pb-6">
  <div class="relative overflow-x-auto shadow-md sm:rounded-lg font-pop mt-10">
    <table class="w-full text-[12px] text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead class="t text-gray-700  bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
                <th scope="col" class="px-6 py-3">
                    No
                </th>
                <th scope="col" class="px-6 py-3">
                    Nama
                </th>
            </tr>
        </thead>
        <tbody>
            <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"  v-for="(entry, index) in teamdata.detailData" :key="index">
              <td class="px-6 py-4">
                    {{ index + 1 }}
                </td>
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {{ entry.member }}
                </th>
            </tr>
           
        </tbody>
    </table>
</div>
</div>

          <div class="flex justify-end">
            <button
              class="mt-8 bg-red-500 px-5 py-3 rounded-md"
              @click="openCategory()"
            >
              <h1 class="text-[12px] text-white">Kembali</h1>
            </button>
          </div>
        </div>
      </div>

    </div>
  </transition>

 


  <div v-if="successModal" class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50"  style="  animation: fadein 1s;">
      <div class="bg-white p-8 rounded-lg flex flex-col justify-center items-center  "  style="animation: muncul-kiri-kanan-fade 1s; ">
        <img src="../../assets/verify.png" class="w-16 h-16 mb-6 " alt="">
        <p>Data Team Berhasil ditambahkan</p>
      </div>
    </div>

    <div v-if="errorModal" class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50" style="animation: fadein 1s;">
    <div class="bg-white p-8 rounded-lg flex flex-col justify-center items-center  "  style="animation: muncul-kiri-kanan-fade 1s; ">
      <img src="../../assets/cancel.png" class="w-16 h-16 mb-6 " alt="">
      <p class="  font-semibold w-72 text-center" >{{ message }}</p>
    </div>
  </div>

  

</template>

<script>

import axios from "../../token";
export default {
  data() {
    return {
      searchTerm: "",
      open: "",
      open1: false,
      selectedDate: this.getInitialDate(),
      teamleader: "",
      name:'',
      member:'',
      successModal:false,
      errorModal:false,
      pagination : {
          current_page: 1,
          from: null,
          last_page: null
        },
      categorydata:[],
      userdata:[],
      entries: [],
      teamdata:[],
      images: [
        { url: 'https://www.iberian-partners.com/wp-content/uploads/2024/01/Gaji-Pelayaran-Bagian-Mesin.webp' },
        { url: 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEgoBq2qgsXRp9aeY_I2tYQdaIBY-BnidvxnN99dpdvS3BzjktrKOoowzCxcYfJfe_KHnocs0tI55Aor2dn0aEZ6TC7aS0NgBWq3XcC9t41Fy2KYnNb3FHyzJMqkjZ8iji-v01AoVSFIVu8/s1024/IMG-20200105-WA0014.jpg',},
        { url: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/06/Kristina_Regina_wheelhouse.jpg/640px-Kristina_Regina_wheelhouse.jpg',},
      ],
      currentIndex: 0,
      intervalId: null,
      intervalDuration: 3000, // Durasi otomatisasi (dalam milidetik)
      translateX: '0px'
    };
  },
  components: {
   
  },
  computed: {
    filteredEntries() {
      let filtered = this.entries;

      if (this.selectedStatus.length > 0) {
        filtered = filtered.filter((entry) =>
          this.selectedStatus.includes(entry.status)
        );
      }

      if (this.selectedSort === "asc") {
        return filtered.sort((a, b) => a.dateIn.localeCompare(b.dateIn));
      } else {
        return filtered.sort((a, b) => b.dateIn.localeCompare(a.dateIn));
      }
    },
  },
  
  methods: {
    openModal() {
      this.isModalOpen = true;
    },
    openCategory(categoryId) {
    
    

    
    this.getteam(categoryId);

    
    this.open1 = !this.open1;

    // if (!this.open1) {
    //   this.teamleader = '';
    //   this.teamdata=[];
    //   this.name='';
    // }
  },
    closeModal() {
      this.isModalOpen = false;
    },
    removeEntry(index) {
      this.entries.splice(index, 1);
    },
    submittemp() {

const emptyFields = [];



if (!this.member || /^\s*$/.test(this.member)) {
emptyFields.push("Anggota");
}




if (emptyFields.length > 0) {

this.errorModal = true;
this.message = `Field ${emptyFields.join(", ")} Tidak Boleh Kosong.`;

setTimeout(() => {
this.errorModal = false;
this.open = false;
}, 3000);

return;
}


this.entries.push({

  member: this.member,

});
this.member = '';

},
    applyFilters() {
      this.closeModal();
    },
    getInitialDate() {
      return new Date().toISOString().split("T")[0];
    },
    async submitForm() {
      const formData = new FormData();

      this.entries.forEach((entries) => {
        formData.append('member[]', entries.member.id);
        

      },
        formData.append('leader',this.teamleader),
        formData.append('name',this.name)
      );
      
      try {
        const response = await axios.post(`${process.env.VUE_APP_BACKEND_HOST}/team`, formData);

        console.log(response);
        await new Promise(resolve => setTimeout(resolve, 500));
        this.successModal = true;

      
        setTimeout(() => {
          this.successModal = false;
          this.open = false;
        }, 3000);
        this.getuser()
        this.open = false;
        this.entries=[]
        this.member=''
        this.teamleader = '';
        this.name = '';
        this.qty = '';
        this.eom = '';
      } catch (error) {
        console.log(error);
        this.message = error.response.data.message;
        this.errorModal = true;
        setTimeout(() => {
          this.errorModal = false;
        }, 3000);
        
      }
    },
    getuser() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/hrd/employee`)
          .then((response) => {
            this.userdata = response.data;
  
          })
          .catch((error) => {
            console.log(error);
          });
      },
      async getcategory(page) {
      let params = {
        search: this.search,
        limit: 10,
        page: Number.isInteger(page) ? page : 1
      };

      if (this.filterValue) {
        params.filter = 'project';
        params.filter_value = this.filterValue;
      }

      if (this.urutan1) {
        params.sort = this.urutan1;
        params.sort_by = 'code';
      }

      try {
        const response = await axios.get(`${process.env.VUE_APP_BACKEND_HOST}/project/report/list`, { params });
        this.categorydata = response.data;
        this.pagination = {
          current_page: response.data.current_page,
          from: response.data.from,
          last_page: response.data.last_page
        };
      } catch (error) {
        console.error(error);
        this.categorydata = [];
        this.pagination = {
          current_page: 1,
          from: null,
          last_page: null
        };
      }
    },
    // getcategory() {
    //   axios
    //     .get(`${process.env.VUE_APP_BACKEND_HOST}/project/report/list`)
    //     .then((response) => {
    //       this.categorydata = response.data;

        
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },
    getteam(id) {
      axios
        .get(`${process.env.VUE_APP_BACKEND_HOST}/team`,{
          params:{
            id:id
          }
        })
        .then((response) => {
          this.teamdata = response.data;
          if (this.teamdata && this.teamdata.data.length > 0) {
        const firstEntry = this.teamdata.data[0]; 
        this.name=firstEntry.name || '';
        this.teamleader=firstEntry.leader || '';
      }

        
        })
        .catch((error) => {
          console.log(error);
        });
    },
    startAutoPlay() {
      this.intervalId = setInterval(() => {
        this.nextSlide();
      }, this.intervalDuration);
    },
    // Metode untuk berpindah ke slide berikutnya
    nextSlide() {
      this.currentIndex = (this.currentIndex + 1) % this.images.length;
      this.translateX = '-' + (this.currentIndex * 100) + '%';
    }
  },
  mounted(){
    this.getcategory();
    this.getuser();
    this.startAutoPlay();
  }
};
</script>

<style>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.search-input:focus {
  outline: none;
  box-shadow: none; /* Optional: Remove box-shadow if it's present */
}
</style>
