<template>
<div class="py-[1rem] ">
    
   

   
    <TablePermohonan/>
</div>

</template>

<script>
import TablePermohonan from "../Component/TablePermohonan.vue"


export default {
    components :{ 
     TablePermohonan,
    
    },
}

</script>