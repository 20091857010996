<template>
  <div>
    <vue3-chart-js v-bind="{ ...barChart }"  />
  </div>
</template>

<script>
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";

export default {
  name: 'BarChart',
  components: {
    Vue3ChartJs,
  },
  setup() {
    const barChart = {
      type: "bar",
      data: {
        labels: ["2020", "2021", "2022", "2023", "2024"],
        datasets: [
          {
            label: 'Total Karyawan',
            backgroundColor: ["#9d5252", "#9d5252", "#9d5252", "#9d5252", "#9d5252"],
            data: [30, 10, 60, 15, 10],
            borderRadius: 5,
            barThickness: 48,
          },
        ],
      },
      options: {
        indexAxis: 'x',
        cutoutPercentage: 50,
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            stacked: true,
            display: true,
            barPercentage: 0.8,
            categoryPercentage: 0.1,
            position: 'right',
          },
          y: {

            stacked: false,
            display: true,
            position: 'right',
            max: 100,
            min: 0,
            beginAtZero: true,
          },
        },
        plugins: {
          legend: {
            position: 'top',
            
            labels: {
              display: true,
              font: {
              family: 'Poppins', 
              size: 12,
              weight:600
            },
            
            },
          },
        },
      },
    };

    return {
      barChart,
    };
  },
};
</script>

<style scoped>
/* Tambahkan gaya CSS jika diperlukan */
</style>
