<template>
    <div class="flex flex-row justify-between" style=" animation: muncul-kiri-kanan-fade 0.5s;">
      <div class="relative text-gray-600">
    <input type="search" name="serch" placeholder="Search" class="bg-white h-10 px-5 pr-24 rounded text-sm focus:outline-none">
    <button type="submit" class="absolute right-0 top-0 mt-3 mr-4">
      <svg class="h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 56.966 56.966" style="enable-background:new 0 0 56.966 56.966;" xml:space="preserve" width="512px" height="512px">
        <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z"/>
      </svg>
    </button>
  </div>
  <div class="">
    <button
        class="w-[142px] h-[30px] p-1 mr-4 rounded text-white bg-red-500"
        @click="open = true"
      >
        <div class="flex flex-row justify-center">
          <img src="../../assets/add.png" class="mr-1 w-5" alt="" />
          <h3 class="text-[12px] font-medium font-pop">Tambah Project</h3>
        </div>
      </button>
      <!-- <button
        class="w-[118px] h-[30px] flex-shrink-0 rounded text-[#BBBBBB] border border-[#BBBBBB]"  @click="toggleModal"
      >
        <div class="flex flex-row justify-center">
          <img src="../assets/filter.png" class="mr-1 w-5" alt="" />
          <h3 class="text-[12px] text-[#565656] font-medium">Filter & Short</h3>
        </div>
      </button> -->
  </div>
    </div>
  
  
     <div
        v-if="isOpen"
        class="absolute flex items-center justify-end z-10 top-[14rem] right-0 px-32 font-pop"
      >
        <div class="modal bg-white rounded-lg shadow-lg p-4 w-52">
        
          <div class="mb-4">
            <label class="block text-sm font-medium text-gray-700"
              >Filter by Category:</label
            >
            <div class="mt-1">
              <label for="cuti" class="flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  id="cuti"
                  v-model="selectedStatus"
                  value="Hadir"
                  class="h-5 w-5 text-blue-500 rounded-md border-gray-300 focus:ring-blue-400"
                />
                <span class="ml-2 text-sm text-gray-700">Kapal Tanker</span>
              </label>
              <label for="sakit" class="flex items-center cursor-pointer">
                <input
                  type="checkbox" 
                  id="sakit"
                  v-model="selectedStatus"
                  value="Tidak Hadir"
                  class="h-5 w-5 text-blue-500 rounded-md border-gray-300 focus:ring-blue-400"
                />
                <span class="ml-2 text-sm text-gray-700">Kapal Ferry</span>
              </label>
              <label for="izin" class="flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  id="izin"
                  v-model="selectedStatus"
                  value="Cuti"
                  class="h-5 w-5 text-blue-500 rounded-md border-gray-300 focus:ring-blue-400"
                />
                <span class="ml-2 text-sm text-gray-700">Kapal Persiar</span>
              </label>
            </div>
          </div>
  
        
          <div class="mb-4">
            <label class="block text-sm font-medium text-gray-700"
              >Sort by:</label
            >
            <div class="mt-1">
              <label for="asc" class="flex items-center cursor-pointer">
                <input
                  type="radio"
                  id="asc"
                  v-model="selectedSort"
                  value="asc"
                  class="h-5 w-5 text-blue-500 rounded-full border-gray-300 focus:ring-blue-400"
                />
                <span class="ml-2 text-sm text-gray-700">Ascending</span>
              </label>
              <label for="desc" class="flex items-center cursor-pointer">
                <input
                  type="radio"
                  id="desc"
                  v-model="selectedSort"
                  value="desc"
                  class="h-5 w-5 text-blue-500 rounded-full border-gray-300 focus:ring-blue-400"
                />
                <span class="ml-2 text-sm text-gray-700">Descending</span>
              </label>
            </div>
          </div>
  
  
        </div>
      </div>
   <!-- src="https://asset.kompas.com/crops/k0__dJytqamRwPJW6K4j_fqCJvw=/0x0:1800x1200/750x500/data/photo/2022/09/10/631c1d6276c69.jpg" -->
        <!-- <div class="grid md:grid-cols-3 gap-6 justify-between xxs:grid-cols-1 font-pop mt-4" to="/Review">
      <router-link :to="'/Review/' + item.id "
        v-for="item in projectdata.data"
        :key="item.id"
        class="flex md:flex-row xxs:flex-col bg-white  rounded md:transition-transform md:transform-gpu md:hover:scale-110   cursor-pointer" style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;  animation: muncul-kiri-kanan-fade 1s;"
      >
        <div>
          <img
            class="object-cover xl:w-[14rem] xl:h-[14rem]  xxs:h-full xxs:w-[30rem] rounded-xl p-2"
            :src="item.imageUrl ? item.imageUrl : 'https://asset.kompas.com/crops/k0__dJytqamRwPJW6K4j_fqCJvw=/0x0:1800x1200/750x500/data/photo/2022/09/10/631c1d6276c69.jpg'"
            alt=""
          />
        </div>
        <div class="flex flex-col justify-between p-4 leading-normal">
          <div class="flex flex-row justify-between">
            <h2  class="bg-red-100 text-red-800 text-[14px] font-medium me-2 px-2.5 rounded py-2">
              {{ item.name }}
            </h2>
          
          </div>
          <div class="max-w-full w-[15rem]">
              <h5 class="text-[16px] font-medium me-2 min-w-min rounded py-2" data-content="Project Manager">
                  Project Manager {{ item.pm.name }}
              </h5>
              <p class="mb-3 mt-1 text-justify text-[14px] font-normal text-gray-700 dark:text-gray-400" style="overflow-wrap: break-word;">
                  {{ item.description }}
              </p>
          </div>
        </div>
     
      </router-link>
    </div> -->

    
    <div class="grid xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 xs:grid-cols-2  gap-6 justify-between xxs:grid-cols-1 font-pop mt-10" >
<router-link  :to="'/Review/' + item.id "  v-for="item in projectdata.data" :key="item.id" class="max-w-sm w-[20rem] h-full   bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 md:transition-transform md:transform-gpu md:hover:scale-110 " style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;  animation: muncul-kiri-kanan-fade 1s;">
    <a href="#">
        <img class="rounded-t-lg w-full h-64 object-cover"  :src="item.imageUrl ? item.imageUrl : 'https://asset.kompas.com/crops/k0__dJytqamRwPJW6K4j_fqCJvw=/0x0:1800x1200/750x500/data/photo/2022/09/10/631c1d6276c69.jpg'" alt="" />
    </a>
    <div class="px-4 py-6">
        <a href="#">
            <h5 class=" text-[20px] font-bold tracking-tight text-black dark:text-white uppercase">  {{ item.name }}</h5>
        </a>
        
        <h5 class="text-[16px] font-medium me-2 min-w-min rounded py-2 uppercase" data-content="Project Manager">
                  Project Manager {{ item.pm.name }}
              </h5>
        <p class="mb-3 font-normal text-sm  text-gray-700 dark:text-gray-400 lowercase"> {{ item.description }}</p>

        <button
                        :class="{
                            'bg-[#FFE69A]/30 text-[#EFB812]': item.status === 'Proses Pengerjaan',
                            'bg-[#FF5E5E]/30 text-[#ED4747]': item.status === 'Ditunda',
                            'bg-[#82C97C]/30 text-[#21B115]': item.status === 'Selesai',
                        }"
                        class="w-[118px] h-[30px] flex-shrink-0 rounded-lg  "
                        >
                        <h3 class="text-[12px] font-medium">{{ item.status }}</h3>
                        </button>

       
      <!-- <router-link :to="'/Review/' + item.id " class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-red-500 rounded-lg hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
          View Project
            <svg class="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
          </svg>
      </router-link>  -->
    </div>
</router-link>
</div>

  
  
  
    <div class="flex items-center justify-between border-t border-gray-200 bg-white mt-10  py-3 sm:px-6 hidden">
      <div class="flex flex-1 justify-between sm:hidden">
        <a href="#" class="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">Previous</a>
        <a href="#" class="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">Next</a>
      </div>
      <div class="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p class="text-sm text-gray-700">
            Showing
            {{ ' ' }}
            <span class="font-medium">1</span>
            {{ ' ' }}
            to
            {{ ' ' }}
            <span class="font-medium">10</span>
            {{ ' ' }}
            of
            {{ ' ' }}
            <span class="font-medium">97</span>
            {{ ' ' }}
            results
          </p>
        </div>
        <div>
          <nav class="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
            <a href="#" class="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
              <span class="sr-only">Previous</span>
              <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
            </a>
            <!-- Current: "z-10 bg-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600", Default: "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0" -->
            <a href="#" aria-current="page" class="relative z-10 inline-flex items-center bg-red-500 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">1</a>
            <a href="#" class="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">2</a>
            <a href="#" class="relative hidden items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 md:inline-flex">3</a>
            <span class="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0">...</span>
            <a href="#" class="relative hidden items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 md:inline-flex">8</a>
            <a href="#" class="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">9</a>
            <a href="#" class="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">10</a>
            <a href="#" class="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
              <span class="sr-only">Next</span>
              <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
            </a>
          </nav>
        </div>
      </div>
    </div>
  
    <transition>
      <div
        class="fixed z-10 left-0 top-0 w-full h-full flex items-center justify-center overflow-auto bg-black/40 bg-opacity-50 font-pop font-regular"
        v-if="open"
      >
     
        <div class="bg-white px-16 py-4 rounded-lg shadow-lg">
          <div class="w-full">
            <h1 class="text-[24px] mb-4">Tambah Project</h1>
            <hr class="mb-4" />
            <div class="flex flex-row justify-between">
              <div class="mt-2 grid xxs:grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-6">
              <div class="sm:col-span-3">
                <label for="first-name" class="block text-sm font-medium leading-6 text-gray-900">Nama Project</label>
                <div class="mt-2">
                  <input    v-model="name" type="text" name="first-name" id="first-name" autocomplete="given-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                </div>
              </div>
    
              <div class="sm:col-span-3">
                <label for="country" class="block text-sm font-medium leading-6 text-gray-900">Nama Manager</label>
                <div class="mt-2">
                  <select   v-model="pm" id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                    <option value="" disabled selected>Pilih Manager</option>
                    <option
                      v-for="data1 in userdata.data"
                      :key="data1.id"
                      :value="data1.id"
              >
                {{ data1.user.name }}
              </option>
                  </select>
                </div>
              </div>
  
              <div class="sm:col-span-2">
                <label for="country" class="block text-sm font-medium leading-6 text-gray-900">Kategory Project</label>
                <div class="mt-2">
                  <select   v-model="category" id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                    <option value="" disabled selected>Kategory Kapal</option>
                    <option
                      v-for="categoory in categorydata.data"
                      :key="categoory.id"
                      :value="categoory.id"
              >
                {{ categoory.name }}
              </option>
                  </select>
                </div>
              </div>
  
              <div class="sm:col-span-2">
                <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Tanggal Mulai</label>
                <div class="mt-2">
                  <input    v-model="start_date" type="date" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                </div>
              </div>
  
              <div class="sm:col-span-2">
                <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Tanggal Selesai</label>
                <div class="mt-2">
                  <input   v-model="end_date" type="date" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                </div>
              </div>
  
             
              <div class="sm:col-span-full ">
            <label for="message" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Keterangan</label>
            <div class="mt-2">
            <textarea id="message" v-model="description" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Tambahkan Keterangan Jika Perlu..."></textarea>
            </div>
            </div>

            <div class="col-span-full">
                    <h1 class="font-pop font-medium mb-4">Upload Photo</h1>
                    <label for="dropzone-file" class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                    <div class="flex flex-col items-center justify-center pt-5 pb-6">
                        <svg   v-if="!uploadedFileName" class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
                        </svg>
                        <img v-if="uploadedFileName" class="w-12 h-12 mb-10" src="../../assets/picture.png" alt="">
                        <p v-if="uploadedFileName" class="text-[14px] text-gray-500 dark:text-gray-400">{{ uploadedFileName }}</p>
                        <p v-if="!uploadedFileName" class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Klik Untuk Upload</span> Atau Tarik dan Lepas</p>
                        <p v-if="!uploadedFileName" class="text-xs text-gray-500 dark:text-gray-400">JPG And PNG Only</p>
                    </div>
                    <input id="dropzone-file" ref="fileInput" type="file" class="hidden" @change="handleFileUpload" />
                </label>
                   
                  </div>

              </div>
            </div>
            <div class="flex justify-end">
              <button
                class="mt-8 bg-red-500 px-5 py-3 rounded-md"
                @click="open = false"
              >
                <h1 class="text-[12px] text-white">Kembali</h1>
              </button>
              <form @submit.prevent="submitForm">
              <button
                class="ml-4 mt-8 bg-[#A6CF98] px-[3rem] py-3 rounded-md"
                 type="submit"
              >
                <h1 class="text-[12px] text-white">Simpan</h1>
              </button>
            </form>
            </div>
          </div>
        </div>
      
      </div>
    </transition>
  
    <div v-if="successModal" class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50"  style="  animation: fadein 1s;">
        <div class="bg-white p-8 rounded-lg flex flex-col justify-center items-center  "  style="animation: muncul-kiri-kanan-fade 1s; ">
          <img src="../../assets/verify.png" class="w-16 h-16 mb-6 " alt="">
          <p>Data Project Berhasil ditambahkan</p>
        </div>
      </div>
  
      
  <div v-if="errorModal" class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50" style="animation: fadein 1s;">
    <div class="bg-white p-8 rounded-lg flex flex-col justify-center items-center  "  style="animation: muncul-kiri-kanan-fade 1s; ">
      <img src="../../assets/cancel.png" class="w-16 h-16 mb-6 " alt="">
      <p class="  font-semibold w-72 text-center" >{{ message }}</p>
    </div>
  </div>

  
  
  </template>
  
  <script>
  // import router from "@/router";
import axios from "../../token";
  
  export default {
    data() {
      return {
        id: localStorage.getItem('id') || 'Default Value',
        isOpen: false,
        open: "",
        name:"",
        pm:"",
        category:"",
        start_date:"",
        description:'',
        end_date:"",
        successModal:false,
        errorModal:false,
        status:"Proses Pengerjaan",
        categorydata:[],
        projectdata:[],
        userdata:[],
        photo:null,
        uploadedFileName: null,
        imageUrl:null,
      };
    },
    computed: {
      filteredEntries() {
        let filtered = this.absendata;
  
        if (this.selectedStatus.length > 0) {
          filtered = filtered.filter((abse) =>
            this.selectedStatus.includes(abse.status)
          );
        }
  
        if (this.selectedSort === "asc") {
          return filtered.sort((a, b) => a.jammasuk.localeCompare(b.jammasuk));
        } else {
          return filtered.sort((a, b) => b.jammasuk.localeCompare(a.jammasuk));
        }
      },
    },
    methods: {
      toggleModal() {
        this.isOpen = !this.isOpen;
      },
      openModal() {
        this.isModalOpen = true;
      },
      closeModal() {
        this.isModalOpen = false;
      },
      formatDate(date) {
  if (date instanceof Date) {
    const options = { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
    return date.toLocaleDateString('id-ID', options);
  } else {
    return ''; // Atau nilai default jika date bukan objek tanggal
  }
},
handleFileUpload(event) {
      const fileInput = event.target;
      const files = fileInput.files;

    if (files.length > 0) {
      const file = files[0];
      this.uploadedFileName = file.name;
    }
    },
    async submitForm() {
    try {
        const formData = new FormData();
        formData.append('name', this.name);
        formData.append('pm', this.pm);
        formData.append('category', this.category);
        formData.append('start_date', this.start_date);
        formData.append('end_date', this.end_date);
        formData.append('status', this.status);
        formData.append('description', this.description);

        if (this.$refs.fileInput.files.length > 0) {
            formData.append('photo', this.$refs.fileInput.files[0]);
        }

        const response = await axios.post(`${process.env.VUE_APP_BACKEND_HOST}/project`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        console.log(response);
        
        this.successModal = true;

        this.getproject();

        setTimeout(() => {
            this.successModal = false;
            this.open = false;
        }, 1000);
    } catch (error) {
        console.log(error);
        this.message = error.response.data.message;
        this.errorModal = true;
        setTimeout(() => {
          this.errorModal = false;
        }, 3000);
        }
},
      getcategory() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/project-category`)
          .then((response) => {
            this.categorydata = response.data;
  
          })
          .catch((error) => {
            console.log(error);
          });
      },
      getuser() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/hrd/employee`)
          .then((response) => {
            this.userdata = response.data;
  
          })
          .catch((error) => {
            console.log(error);
          });
      },
      getproject() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/project`)
          .then((response) => {
            this.projectdata = response.data;
            
            this.projectdata.data.forEach((project) => {
           this.fetchImage(project.photo, project);
         });
  
          })
          .catch((error) => {
            console.log(error);
          });
      },
      async fetchImage(id, project) {
      try {
        const response = await axios.get(`${process.env.VUE_APP_BACKEND_HOST}/file`, {
          params: {
            id: id,
          },
          responseType: 'arraybuffer',
        });

        const base64Image = btoa(
          new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), '')
        );

       
        project.imageUrl = `data:${response.headers['content-type']};base64,${base64Image}`;
      } catch (error) {
        console.error('Error fetching image:', error);
      }
    },
    },
    mounted(){
      this.getcategory();
      this.getproject();
      this.getuser();
    }
  };
  </script>
  