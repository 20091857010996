<template>

     <div class="md:flex md:flex-row md:justify-between xxs:hidden font-pop" >
      <div class="flex items-center gap-4">
    <div class="relative text-gray-600">
  <input type="text" name="search" v-model="search" @input="handleInput" placeholder="Pencarian..." class="bg-white shadow-lg h-10 px-5 w-64 rounded text-sm focus:outline-none">
  <button type="submit" class="absolute right-0 top-0 mt-3 mr-4">
    <svg class="h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 56.966 56.966" style="enable-background:new 0 0 56.966 56.966;" xml:space="preserve" width="512px" height="512px">
      <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z"/>
    </svg>
  </button>
</div>


<button
      class="w-[118px] py-2 flex-shrink-0 rounded text-[#BBBBBB] border border-[#BBBBBB]"  @click="open6=true"
    >
      <div class="flex flex-row justify-center">
        <img src="../../assets/filter.png" class="mr-1 w-5" alt="" />
        <h3 class="text-[12px] text-[#565656] font-medium">Filter & Short</h3>
      </div>
    </button>
  </div>



<div class="flex items-center">
  
    
  <router-link to="/TambahUser">
   
  <button
      class="w-[142px] px-3 py-2  mr-4 rounded text-white bg-red-500"
      @click="open = true"
    >
      <div class="flex flex-row justify-center">
        <img src="../../assets/add.png" class="mr-1 w-5" alt="" />
        <h3 class="text-[12px] font-medium">Tambah User</h3>
      </div>
    </button>

   
  </router-link>
  
  



<button
      class="w-[142px] px-3 py-2  mr-4 rounded text-white bg-red-500"
      @click="cetak()"
    >
      <div class="flex flex-row justify-center">
        <img src="../../assets/printer.png" class="mr-1 w-5" alt="" />
        <h3 class="text-[12px] font-medium">Cetak Laporan</h3>
      </div>
    </button>
    

    <!-- previewReport(this.extension) -->


<!-- <button class="rounded-md mt-[-1rem] bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" @click="previewReport">Cetak Laporan</button> -->
  
 
</div>


  </div>

  <transition>
    <div
      class="fixed z-10 left-0 top-0 w-full h-full flex items-center justify-center overflow-auto backdrop-blur-sm bg-black/10 bg-opacity-50 font-pop font-regular"
      v-if="open6"
    >
   
      <div class="bg-white px-16 py-4 rounded-lg shadow-lg">
        <div class="w-full">
          <h1 class="text-[20px] mb-4">Filter & Short</h1>
          <hr class="mb-4" />
        
          <div class="flex flex-col gap-4">
          <!-- <select name="" v-model="extension" id="" class="px-3 py-2 font-pop rounded text-sm  ">
          <option value="" selected>Pilih Extension</option>
          <option value="xls" >Excel</option>
          <option value="pdf" >PDF</option>
        </select> -->

      
        <select name="" v-model="filter" id="" class="px-3 py-2 font-pop rounded text-sm  ">
          <option value="" selected>Pilih Filter</option>
          <option value="department" >Departement</option>
          <option value="position" >Posisi</option>
          <option value="employeeStatus" >Kontrak Kerja</option>\
          <option value="end_contract" >Sisa Kontrak</option>
          <option value="accountStatus" >Status</option>
        </select>

        <div class="sm:col-span-1" v-if="this.filter === 'end_contract'">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Jumlah Hari</label>
              <div class="mt-2">
               <input type="number" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6" v-model="id" name="" id="">
              </div>
            </div>


        <div class="sm:col-span-2"  v-if="this.filter === 'department'">
              <label for="country" class="block text-sm font-medium leading-6 text-gray-900">Departement</label>
              <div class="mt-2">
                <select   v-model="id" id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                  <option value=""  selected>Pilih Departement</option>
                  <option
                    v-for="categoory in projectdata.data"
                    :key="categoory.id"
                    :value="categoory.id"
            >
              {{ categoory.name }}
            </option>
                </select>
              </div>
            </div>

            <div class="sm:col-span-1" v-if="this.filter === 'position'">
              <label for="country" class="block text-sm font-medium leading-6 text-gray-900">Jabatan</label>
              <div class="mt-2">
                <select   v-model="id" id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                  <option value=""  selected>Pilih Jabatan</option>
                  <option
                    v-for="project in categorydata.data"
                    :key="project.id"
                    :value="project.id"
            >
              {{ project.name }}
            </option>
                </select>
              </div>
            </div>

            <div class="sm:col-span-1" v-if="this.filter === 'accountStatus'">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Status Karyawan</label>
              <div class="mt-2">
                <select v-model="id" id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                  <option value=""  selected>Pilih Status Karyawan</option>
                  <option value="1">Aktif</option>
                  <option value="0">TIdak</option>
                </select>
              </div>
            </div>

            <div class="sm:col-span-1" v-if="this.filter === 'employeeStatus'">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Jenis Ikatan Kerja</label>
              <div class="mt-2">
                <select v-model="id" id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                  <option value=""  selected>Pilih Ikatan Kerja</option>
                  <option value="tetap">Karyawan Tetap</option>
                  <option value="kontrak">Kontrak</option>
                </select>
              </div>
            </div>

      </div>
           
          <div class="flex justify-end">
            <button
              class="mt-8 bg-red-500 px-5 py-3 rounded-md"
              @click="close6()"
            >
              <h1 class="text-[12px] text-white">Kembali</h1>
            </button>
            
            <button
              class="ml-4 mt-8 bg-[#A6CF98] px-[3rem] py-3 rounded-md"
               type="submit" @click="getfilter(this.filter,this.id)"
            >
              <h1 class="text-[12px] text-white">Terapkan</h1>
            </button>
       
          </div>
        </div>
      </div>
    
    </div>
  </transition>


  <transition>
    <div
      class="fixed z-10 left-0 top-0 w-full h-full backdrop-blur-sm flex items-center justify-center overflow-auto bg-black/10 bg-opacity-50 font-pop font-regular"
      v-if="open5"
    >
   
      <div class="bg-white px-16 py-4 rounded-lg shadow-lg">
        <div class="w-full">
          <h1 class="text-[20px] mb-4">Cetak Laporan</h1>
          <hr class="mb-4" />
        
          <div class="flex flex-col gap-4">
          <select name="" v-model="extension" id="" class="px-3 py-2 font-pop rounded text-sm  ">
          <option value="" selected>Pilih Extension</option>
          <option value="xls" >Excel</option>
          <option value="pdf" >PDF</option>
        </select>

        <select name="" v-model="filter" id="" class="px-3 py-2 font-pop rounded text-sm  ">
          <option value="" selected>Pilih Filter</option>
          <option value="department" >Departement</option>
          <option value="position" >Posisi</option>
          <option value="employeeStatus" >Kontrak Kerja</option>\
          <option value="end_contract" >Sisa Kontrak</option>
          <option value="accountStatus" >Status</option>
        </select>

        <div class="sm:col-span-2"  v-if="this.filter === 'department'">
              <label for="country" class="block text-sm font-medium leading-6 text-gray-900">Departement</label>
              <div class="mt-2">
                <select   v-model="id" id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                  <option value=""  selected>Pilih Departement</option>
                  <option
                    v-for="categoory in projectdata.data"
                    :key="categoory.id"
                    :value="categoory.id"
            >
              {{ categoory.name }}
            </option>
                </select>
              </div>
            </div>

            <div class="sm:col-span-1" v-if="this.filter === 'position'">
              <label for="country" class="block text-sm font-medium leading-6 text-gray-900">Jabatan</label>
              <div class="mt-2">
                <select   v-model="id" id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                  <option value=""  selected>Pilih Jabatan</option>
                  <option
                    v-for="project in categorydata.data"
                    :key="project.id"
                    :value="project.id"
            >
              {{ project.name }}
            </option>
                </select>
              </div>
            </div>

            <div class="sm:col-span-1" v-if="this.filter === 'end_contract'">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Jumlah Hari</label>
              <div class="mt-2">
               <input type="number" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6" v-model="id" name="" id="">
              </div>
            </div>

            <div class="sm:col-span-1" v-if="this.filter === 'accountStatus'">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Status Karyawan</label>
              <div class="mt-2">
                <select v-model="id" id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                  <option value=""  selected>Pilih Status Karyawan</option>
                  <option value="1">Aktif</option>
                  <option value="0">TIdak</option>
                </select>
              </div>
            </div>

            <div class="sm:col-span-1" v-if="this.filter === 'employeeStatus'">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Jenis Ikatan Kerja</label>
              <div class="mt-2">
                <select v-model="id" id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                  <option value=""  selected>Pilih Ikatan Kerja</option>
                  <option value="tetap">Karyawan Tetap</option>
                  <option value="kontrak">Kontrak</option>
                </select>
              </div>
            </div>

      </div>
           
          <div class="flex justify-end">
            <button
              class="mt-8 bg-red-500 px-5 py-3 rounded-md"
              @click="close5()"
            >
              <h1 class="text-[12px] text-white">Kembali</h1>
            </button>
            
            <button
              class="ml-4 mt-8 bg-[#A6CF98] px-[3rem] py-3 rounded-md"
               type="submit" @click="previewReport(this.extension,this.filter,this.id)"
            >
              <h1 class="text-[12px] text-white">Cetak</h1>
            </button>
       
          </div>
        </div>
      </div>
    
    </div>
  </transition>



    <!-- <div
      v-if="isOpen"
      class="absolute flex items-center justify-end z-10 top-[14rem] right-0 px-32 font-pop"
    >
      <div class="modal bg-white rounded-lg shadow-lg p-4 w-52">
      
        <div class="mb-4">
          <label class="block text-sm font-medium text-gray-700"
            >Filter by Category:</label
          >
          <div class="mt-1">
            <label for="cuti" class="flex items-center cursor-pointer">
              <input
                type="checkbox"
                id="cuti"
                v-model="selectedStatus"
                value="Hadir"
                class="h-5 w-5 text-blue-500 rounded-md border-gray-300 focus:ring-blue-400"
              />
              <span class="ml-2 text-sm text-gray-700">Kapal Tanker</span>
            </label>
            <label for="sakit" class="flex items-center cursor-pointer">
              <input
                type="checkbox" 
                id="sakit"
                v-model="selectedStatus"
                value="Tidak Hadir"
                class="h-5 w-5 text-blue-500 rounded-md border-gray-300 focus:ring-blue-400"
              />
              <span class="ml-2 text-sm text-gray-700">Kapal Ferry</span>
            </label>
            <label for="izin" class="flex items-center cursor-pointer">
              <input
                type="checkbox"
                id="izin"
                v-model="selectedStatus"
                value="Cuti"
                class="h-5 w-5 text-blue-500 rounded-md border-gray-300 focus:ring-blue-400"
              />
              <span class="ml-2 text-sm text-gray-700">Kapal Persiar</span>
            </label>
          </div>
        </div>

       
        <div class="mb-4">
          <label class="block text-sm font-medium text-gray-700"
            >Sort by:</label
          >
          <div class="mt-1">
            <label for="asc" class="flex items-center cursor-pointer">
              <input
                type="radio"
                id="asc"
                v-model="selectedSort"
                value="asc"
                class="h-5 w-5 text-blue-500 rounded-full border-gray-300 focus:ring-blue-400"
              />
              <span class="ml-2 text-sm text-gray-700">Ascending</span>
            </label>
            <label for="desc" class="flex items-center cursor-pointer">
              <input
                type="radio"
                id="desc"
                v-model="selectedSort"
                value="desc"
                class="h-5 w-5 text-blue-500 rounded-full border-gray-300 focus:ring-blue-400"
              />
              <span class="ml-2 text-sm text-gray-700">Descending</span>
            </label>
          </div>
        </div>
      </div>
    </div> -->


    <!-- <div v-if="shiftdata.data" class="grid md:grid-cols-4 gap-10 justify-between items-center xxs:grid-cols-1 font-pop mt-10 w-full">
      <router-link :to="'/DetailUser/' + shift.id" v-for="shift in shiftdata.data" :key="shift.id" class="bg-white rounded overflow-hidden cursor-pointer px-6" style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; animation: muncul-kiri-kanan-fade 0.5s;">
        <div class="flex flex-row items-center">
          <img class="object-cover md:w-14 md:h-14 xxs:w-14 xxs:h-full rounded" :src="shift.imageUrl" alt="Profile Image">
          <div class="flex flex-col p-6 overflow-hidden">
          <h2 class="text-[12px] font-semibold mb-2">{{ shift.name }}</h2>
          <div class="flex flex-row">
            <p class="md:text-[12px] xxs:text-[12px] text-gray-600 mr-4">{{ shift.position }}</p>
            <div class="flex">
              <p class="md:text-[12px] xxs:text-[10px] text-gray-600">{{ shift.email }}</p>
            </div>
          </div>
        </div>
        </div>
      </router-link>
    </div>
    <div v-else class="flex flex-col h-[40rem] items-center justify-center text-[24px] font-medium" style="animation: muncul-kiri-kanan-fade 1s;">
      <img src="../../assets/empty.png" class="w-56 h-56 mb-6" alt="">
      <p class="text-[#7D7D7D]">Data User Tidak Ada</p>
    </div> -->
<div v-if="shiftdata.data" class="grid lg:grid-cols-4 gap-10 md:grid-cols-3 xs:grid-cols-2 justify-between items-center xxs:grid-cols-1 font-pop mt-10 w-full" >
    <div  v-for="shift in shiftdata.data" :key="shift.id" class="max-w-sm mx-auto md:w-96   bg-white shadow-lg rounded-sm border border-gray-200" style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; animation: muncul-kiri-kanan-fade 0.8s;">
    <div class="flex flex-col h-full">
      <!-- Card top -->
      <div class="flex-grow p-5">
        <div class="flex justify-between items-start">
          <!-- Image + name -->
          <header>
            <div class="flex mb-2">
              <a class="relative inline-flex items-start mr-5" href="#0">
                <div class="absolute top-0 right-0 -mr-2 bg-white rounded-full shadow" aria-hidden="true">
                  <svg class="w-8 h-8 fill-current text-yellow-500" viewBox="0 0 32 32">
                    <path d="M21 14.077a.75.75 0 01-.75-.75 1.5 1.5 0 00-1.5-1.5.75.75 0 110-1.5 1.5 1.5 0 001.5-1.5.75.75 0 111.5 0 1.5 1.5 0 001.5 1.5.75.75 0 010 1.5 1.5 1.5 0 00-1.5 1.5.75.75 0 01-.75.75zM14 24.077a1 1 0 01-1-1 4 4 0 00-4-4 1 1 0 110-2 4 4 0 004-4 1 1 0 012 0 4 4 0 004 4 1 1 0 010 2 4 4 0 00-4 4 1 1 0 01-1 1z" />
                  </svg>
                </div>
                <img class="rounded-full object-cover w-16 h-16" :src="shift.imageUrl"  alt="Profile Image" />
              </a>
              <div class="mt-1 pr-1">
                <a class="inline-flex text-gray-800 hover:text-gray-900" href="#0">
                  <h2 class="text-xl leading-snug justify-center font-semibold">{{ shift.name }}</h2>
                </a>
                <div class="flex items-center"><span class="text-sm font-medium text-gray-400 -mt-0.5 mr-1">-&gt;</span> <span>{{ shift.position }}</span></div>
              </div>
            </div>
          </header>
          <!-- Menu button -->
          <div class="relative inline-flex flex-shrink-0" @click.prevent="toggleMenu(shift)">
            <button class="text-gray-400 hover:text-gray-500 rounded-full focus:outline-none focus-within:ring-2" :class="{ 'bg-gray-100 text-gray-500': isMenuOpen }" aria-haspopup="true">
              <span class="sr-only">Menu</span>
              <svg class="w-8 h-8 fill-current" viewBox="0 0 32 32">
                <circle cx="16" cy="16" r="2" />
                <circle cx="10" cy="16" r="2" />
                <circle cx="22" cy="16" r="2" />
              </svg>
            </button>
            <div v-if="shift.isMenuOpen" class="origin-top-right z-10 absolute top-full right-0 min-w-[9rem] bg-white border border-gray-200 py-1.5 rounded shadow-lg overflow-hidden mt-1" @click="closeMenu" @keydown.escape="closeMenu">
              <ul>
                <li>
                  <a class="font-medium text-sm text-red-500 hover:text-red-600 flex py-1 px-3" href="#0">Hapus</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- Bio -->
        <div class="mt-2">
          <div class="text-sm">{{ shift.email }}</div>
        </div>
      </div>
      <!-- Card footer -->
      <div class="border-t border-gray-200">
        <div class="flex divide-x divide-gray-200r">
          <router-link :to="'/DetailUser/' + shift.id" class="block flex-1 text-center text-sm text-red-500 hover:text-red-600 font-medium px-3 py-4" >
            <div class="flex items-center justify-center">
              <svg class="w-4 h-4 fill-current flex-shrink-0 mr-2" viewBox="0 0 24 24">
              <path fill="none" d="M0 0h24v24H0V0z"/>
              <path d="M6 6h2v10H6zm3.5 6c-.83 0-1.54.5-1.85 1.22l1.23.7c.21-.36.61-.59 1.07-.59.66 0 1.2.55 1.2 1.2s-.55 1.2-1.2 1.2-1.2-.55-1.2-1.2c0-.46.23-.86.59-1.07l-.7-1.23C7.5 13.04 7 12.29 7 11.5c0-1.93 1.57-3.5 3.5-3.5s3.5 1.57 3.5 3.5-1.57 3.5-3.5 3.5zm9-2V8h-2v2h-2v2h2v2h2v-2h2v-2h-2z"/>
            </svg>

              <span>View Profile</span>
            </div>
          </router-link>
           <router-link :to="'/DetailUser/' + shift.id" class="block flex-1 text-center text-sm text-gray-600 hover:text-gray-800 font-medium px-3 py-4 group" >
            <div class="flex items-center justify-center">
              <svg class="w-4 h-4 fill-current text-gray-400 group-hover:text-gray-500 flex-shrink-0 mr-2" viewBox="0 0 16 16">
                <path d="M11.7.3c-.4-.4-1-.4-1.4 0l-10 10c-.2.2-.3.4-.3.7v4c0 .6.4 1 1 1h4c.3 0 .5-.1.7-.3l10-10c.4-.4.4-1 0-1.4l-4-4zM4.6 14H2v-2.6l6-6L10.6 8l-6 6zM12 6.6L9.4 4 11 2.4 13.6 5 12 6.6z" />
              </svg>
              <span>Edit Profile</span>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</div>
<div v-else class="flex flex-col h-[40rem] items-center justify-center text-[24px] font-medium" style="animation: muncul-kiri-kanan-fade 1s;">
      <img src="../../assets/empty.png" class="w-56 h-56 mb-6" alt="">
      <p class="text-[#7D7D7D]">Data User Tidak Ada</p>
    </div> 

    <div>
   
   <div class="flex justify-end mt-8 text-sm">
     <!-- <button
       @click="getmaterial(1)"
       :disabled="pagination.current_page === 1"
       class="px-4 py-2 mx-1 text-white bg-blue-500 rounded-md hover:bg-blue-600"
     >
       First
     </button> -->
     <button
       @click="getshift('','',pagination.current_page - 1)"
       :disabled="pagination.current_page === 1"
       class="px-4 py-2 mx-1 text-white bg-red-500 rounded-md hover:bg-red-600"
     >
     Sebelumnya
     </button>
     <button
       v-for="page in pagination.last_page"
       :key="page"
       @click="getshift('','',page)"
       :class="{ 'bg-red-500 text-white': pagination.current_page === page }"
       class="px-4 py-2 mx-1  text-black rounded-md hover:bg-red-600"
     >
       {{ page }}
     </button>
     <button
       @click="getshift('','',pagination.current_page + 1)"
       :disabled="pagination.current_page === pagination.last_page"
       class="px-4 py-2 mx-1 text-white bg-red-500 rounded-md hover:bg-red-600"
     >
       Selanjutnya
     </button>
     <!-- <button
       @click="getmaterial(pagination.last_page)"
       :disabled="pagination.current_page === pagination.last_page"
       class="px-4 py-2 mx-1 text-white bg-blue-500 rounded-md hover:bg-blue-600"
     >
       Last
     </button> -->
   </div>
 </div>

    <!-- <div class="md:flex items-center justify-between border-t border-gray-200 mt-10 py-3 xxs:hidden" style="animation: muncul-kiri-kanan-fade 1.5s;">
    <div class="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
      <div>
        <p class="text-sm text-gray-700">
          Tampilan {{ ' ' }} <span class="font-medium">{{ currentPage }}</span>
          {{ ' ' }} Dari {{ ' ' }} <span class="font-medium">{{ Math.ceil(filteredEntries.length / perPage) }}</span>
          {{ ' ' }} Data
        </p>
        <h1>{{ console.log(totalPages)}}</h1>
      </div>
      <div>
        <nav class="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
          <button @click="currentPage -= 1" :disabled="currentPage === 1" class="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
            <span class="sr-only">Previous</span>
            <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
          </button>
          
          <button v-for="page in totalPages" :key="page" @click="changePage(page)" :class="{ 'bg-red-500': currentPage === page, 'text-white': currentPage === page }" class="relative z-10 inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
          {{ page }}
          </button>
          <button @click="currentPage += 1" :disabled="currentPage === Math.ceil(filteredEntries.length / perPage)" class="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
            <span class="sr-only">Next</span>
            <ChevronRightIcon class="md:h-5 md:w-5 xxs:hidden" aria-hidden="true" />
          </button>
        </nav>
      </div>
    </div>
  </div> -->

  <div v-if="previewUrl" class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50"  style="animation: muncul-kiri-kanan-fade 1s;" @click="closePreview">
      <iframe :src="previewUrl" class="flex items-center justify-center w-[100rem] h-[50rem]" @click="preventClose"></iframe>
    </div>

  <div v-if="successModal" class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50"  style="  animation: fadein 1s;">
      <div class="bg-white p-8 rounded-lg flex flex-col justify-center items-center  "  style="animation: muncul-kiri-kanan-fade 1s; ">
        <img src="../../assets/verify.png" class="w-16 h-16 mb-6 " alt="">
        <p>Data Project Berhasil ditambahkan</p>
      </div>
    </div>

    <div v-if="uploading" class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50" style="animation: fadein 1s;">
        <div class="bg-white p-8 rounded-lg flex flex-col justify-center items-center  "  style="animation: muncul-kiri-kanan-fade 1s; ">
          <img src="../../assets/loading.gif" class="w-16 h-16 mb-6 " alt="">
          <p class="  font-semibold w-72 text-center" >Laporan Sedang diproses ...</p>
        </div>
      </div>

    <div v-if="showPreviewModal" class="fixed top-0 left-0 w-full h-full bg-black bg-opacity-70 flex justify-center items-center" @click="closePreview">
  <div class="max-w-4xl max-h-4xl overflow-auto">
    <img class="w-full rounded-lg" :src="selectedImageUrl" alt="Employee Photo" style="animation: muncul-kiri-kanan-fade 1s;">
  </div>
</div>

  </template>
  

  <script>
  import axios from "../../token";
  export default {
    data() {
      return {
        isOpen: false,
        id:'',
        shiftdata: [],
      currentPage: 1,
      uploading:false,
      perPage: 10, 
      previewUrl: null,
      selectedStatus: [], 
      filter:'',
      open5:false,
      open6:false,
      successModal:false,
      search:'',
      end_contract:'',
      pagination: {
        current_page: 1,
        from: null,
        last_page: null
      },
      typingTimer: null,
      doneTypingInterval: 300 ,
      extension:'',
      isMenuOpen: false,
      categorydata:[],
      projectdata:[],
      };
    },
    computed: {
  filteredEntries() {
    if (this.shiftdata && this.shiftdata.data) {
      if (this.selectedStatus.length > 0) {
        return this.shiftdata.filter((shift) =>
          this.selectedStatus.includes(shift.id)
        );
      }
      return this.shiftdata.data;
    }
    return [];
  },
  totalPages() {
    return Math.ceil(this.filteredEntries.length / this.perPage);
  },
  pagedShiftData() {
    const start = (this.currentPage - 1) * this.perPage;
    const end = start + this.perPage;
    return this.filteredEntries.slice(start, end);
  },
},  

    methods: {
      cetak(){
          this.open5=true
      },
      close5(){
        this.open5=false
        this.id='';
        this.filter='';
        this.extension='';
      },
      close6(){
        this.open6=false
        this.id='';
        this.filter='';
        this.extension='';
      },
      toggleModal() {
        this.isOpen = !this.isOpen;
      },
      closePreview() {
      this.previewUrl = null;
    },
      toggleMenu(shift) {
      shift.isMenuOpen = !shift.isMenuOpen;
    },
    closeMenu() {
      this.isMenuOpen = false;
    },
    getfilter(filter,id){
      this.getshift(filter,id)
    },
      handleInput() {
    console.log("handleInput triggered");
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(() => {
        console.log("getShift triggered");
        this.getshift();
    }, this.doneTypingInterval);
},
      async fetchImage(id, shift) {
      try {
        const response = await axios.get(`${process.env.VUE_APP_BACKEND_HOST}/file`, {
          params: {
            id: id,
          },
          responseType: 'arraybuffer',
        });

        const base64Image = btoa(
          new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), '')
        );

        // Update the imageUrl property of the specific shift
        shift.imageUrl = `data:${response.headers['content-type']};base64,${base64Image}`;
      } catch (error) {
        console.error('Error fetching image:', error);
      }
    },
    // async getmaterial(page) {
    //   let params = {
    //     search: this.search,
    //     limit: 8,
    //     page: Number.isInteger(page) ? page : 1
    //   };

    //   if (this.filterValue) {
    //     params.filter = 'project';
    //     params.filter_value = this.filterValue;
    //   }

    //   if (this.urutan1) {
    //     params.sort = this.urutan1;
    //     params.sort_by = 'code';
    //   }

    //   try {
    //     const response = await axios.get(`${process.env.VUE_APP_BACKEND_HOST}/material-take-off/project`, { params });
    //     this.materialdata = response.data;
    //     this.pagination = {
    //       current_page: response.data.current_page,
    //       from: response.data.from,
    //       last_page: response.data.last_page
    //     };
    //   } catch (error) {
    //     console.error(error);
    //     this.materialdata = [];
    //     this.pagination = {
    //       current_page: 1,
    //       from: null,
    //       last_page: null
    //     };
    //   }
    // },
    getshift(filter,id,page) {
      let params = {
        limit: 12,
        page:page
  };

  if (filter) {
    params.filter = filter;
    params.filter_value = id;
  }
     axios
       .get(`${process.env.VUE_APP_BACKEND_HOST}/hrd/employee/list`,{
        params: params 
       })
       .then((response) => {
         this.shiftdata = response.data;
         this.pagination = {
          current_page: response.data.current_page,
          from: response.data.from,
          last_page: response.data.last_page
        };
         this.open6=false
         this.shiftdata.data.forEach((shift) => {
           this.fetchImage(shift.photo, shift);
         });
       })
       
       .catch((error) => {
         console.log(error);
         this.open6=false
          this.shiftdata= []
          this.pagination = {
          current_page: 1,
          from: null,
          last_page: null
        };
       });
     
   },
    changePage(page) {
    this.currentPage = page;
  },
  handleClickOutside(event) {
      const dropdown = this.$refs.dropdown;
      if (!dropdown.contains(event.target)) {
        this.open = false; // Mengatur open ke false saat klik di luar dropdown
      }
    },
    handleEscapeKey(event) {
      if (event.key === 'Escape' || event.key === 'Esc') {
        this.open = false; // Mengatur open ke false saat tombol escape ditekan
      }
    },
    getlaporan(extension, filter,id) {
  let params = {
    extension: extension,
    year: this.selectedYear,
    month: this.selectedMonth,
  };

  if (filter) {
    params.filter = filter;
    params.filter_value = id;
  }

  this.uploading = true;
  axios
    .get(`${process.env.VUE_APP_BACKEND_HOST}/hrd/employee/report`, {
      responseType: 'blob',
      params: params 
    })
    .then((response) => {
      this.uploading = false;
      if (extension === 'pdf') {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        this.previewUrl = window.URL.createObjectURL(blob);
      } else if (extension === 'xls') {
        // Create a blob with Excel content type
        const blob = new Blob([response.data], { type: 'application/vnd.ms-excel' });
        // Invoke download function directly
        this.downloadBlob(blob, 'report.xls');
      }
    })
    .catch((error) => {
      console.error(error);
    });
},

previewReport(extension,filter,id) {
  this.getlaporan(extension,filter,id);
},

exportReport() {
  if (this.previewUrl) {
    window.open(this.previewUrl, '_blank');
  } else {
    // Trigger download for Excel file
    this.getlaporan('xls');
  }
},

downloadBlob(blob, filename) {
  // Create a temporary link element
  const link = document.createElement('a');
  // Set link's href to object URL of the blob
  link.href = window.URL.createObjectURL(blob);
  // Set download attribute to specify filename
  link.download = filename;
  // Programmatically trigger click event to download
  link.click();
  // Cleanup
  window.URL.revokeObjectURL(link.href);
},
getcategory() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/position`)
          .then((response) => {
            this.categorydata = response.data;
  
          })
          .catch((error) => {
            console.log(error);
          });
      },
      getproject() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/department`)
          .then((response) => {
            this.projectdata = response.data;
  
          })
          .catch((error) => {
            console.log(error);
          });
      },

    },
    mounted() {
    this.getshift();
    this.getcategory()
    this.getproject()
    
    }
  }
  </script>


<style scoped>
.fade-in-enter-active, .fade-in-leave-active {
  transition: opacity 1s;
}
.fade-in-enter, .fade-in-leave-to {
  opacity: 0;
}
</style>
  