<template>
    <div class="bottom-nav bg-[#ffffff] h-[70px] items-center shadow-lg  ">
      <router-link to="/" class="nav-item ">
        <div class="xl:hidden xxs:flex  flex-row justify-center items-center">
            <img
                  class="xl:mx-auto xl:h-[52px] w-auto xxs:h-[40px]"
                  src="../assets/Logo.png"
                  alt=""
                />
              <div class="ml-3 font-pop font-semibold xl:text-[26px] xxs:text-[20px]  text-[#52709D]">
                Sis<span class=" font-semibold text-[#FFCB39]">ERP</span>
              </div> 
        </div>
      </router-link>
      <router-link to="/" class="nav-item ">
        <img
                  class="xl:mx-auto xl:h-[52px] w-auto xxs:h-[50px] mt-2"
                  src="../assets/orang.svg"
                  alt=""
                />
    
      </router-link>
    </div>
  </template>
  
  

  
  <style scoped>
  .bottom-nav {
    color: #fff;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    justify-content: space-between;
    padding: 30px;
    z-index: 1;
  }
  
  .nav-item {
    text-decoration: none;
    color: #fff;


  }
  </style>
  