<template>
   

  <div class="  first-letter:relative overflow-x-auto border sm:rounded-lg bg-white shadow-lg font-pop" style=" animation: muncul-kiri-kanan-fade 1s;">
   
      <table class="min-w-full text-sm text-left text-gray-500 ] dark:text-gray-400">
          <thead class="text-xs text-gray-700 border-b bg-[#64707628]  dark:bg-gray-700 dark:text-gray-400 text-[14px]">
              <tr class="text-[#868686]">
                  <th scope="col" class="px-6 py-3  font-medium">
                      No
                  </th>
                  <th scope="col" class="px-6 py-3  font-medium">
                      Nama Karyawan
                  </th>
                  <th scope="col" class="px-1 py-3">
                      <div class="flex items-center  font-medium">
                       NIK
                      </div>
                  </th>
                  <th scope="col" class="px-6 py-3">
                      <div class="flex items-center  font-medium">
                          Jam Masuk
                      </div>
                  </th>
                  <th scope="col" class="px-6 py-3">
                      <div class="flex items-center  font-medium">
                         Jam Keluar
                      </div>
                  </th>
                  <th scope="col" class="px-6 py-3">
                      <div class="flex items-center  font-medium">
                      Total Jam Kerja
                      </div>
                  </th>
                  <th scope="col" class="px-6 py-3">
                    <div class="flex items-center  font-medium">
                      Overtime
                      </div>
                  </th>
                  <th scope="col" class="px-6 py-3">
                    <div class="flex items-center  font-medium">
                     Total Overtime Yang Disetujui
                      </div>
                  </th>
                  <th scope="col" class="px-6 py-3">
                         
                  </th>
                
              </tr>
          </thead>
          <tbody v-if="permohonanData.data">
              <tr class=" border-b dark:bg-gray-800 dark:border-gray-700 text-[16px] font-medium text-black" v-for="(permohonan,index) in permohonanData.data" :key="permohonan.id" >
                  <td class="px-6 py-4">
                     {{ index +1 }}
                  </td>
                  <td class="px-4 py-4">
                    <h1 class="ml-2">{{ permohonan.name || '-:-'}}</h1>
                  </td>
                  <td class="px-1 py-4">
                     {{ permohonan.nik || '-:-' }}
                  </td>
                  <td class="px-6 py-4">
                      {{ permohonan.time_in || '-:-' }}
                  </td>
                  <td class="px-6 py-4">
                      {{ permohonan.time_out || '-:-' }}
                  </td>
                  <td class="px-6 py-4">
                      {{ permohonan.work_hour || '-:-' }}
                  </td>
                  <td class="px-6 py-4">
                    
                      <h3 class="">{{ permohonan.overtime || '-:-' }}</h3>
                    
                  </td>
                  <td class="px-6 py-4">
                    
                    <h3 class="">{{ permohonan.approved_overtime || '-:-' }}</h3>
                  
                </td>
                  <td class="px-6 py-4">
                    <div class="flex flex-row gap-4">
                     <FormApproval :permohonandata="permohonan" ></FormApproval>
                     <div  @click="openModal(permohonan)" class="px-4 py-1 rounded-lg text-center bg-red-400  text-white text-sm cursor-pointer hover:bg-red-500">Overtime</div>
                    </div>
              </td>
             
              </tr>
             
          </tbody>
          <tbody v-else style=" animation: muncul-kiri-kanan-fade 0.8s;">
          <tr >
              <td colspan="8" class="text-center font-pop text-[16px] py-4 text-gray-600 dark:text-gray-400">Data Tidak Tersedia</td>
          </tr>
          </tbody>
      </table>
  </div>

  <transition>
    <div
      class="fixed z-10 left-0 top-0 w-full h-full flex items-center justify-center overflow-auto bg-black/40 bg-opacity-50 font-pop font-regular"
      v-if="open1"
    >
      <div class="min-w-[500px] bg-[#fff] px-20 py-10 rounded-xl">
      
          <div class="mb-8">
            <h1 class="text-[20px] mb-4">Approved Lembur</h1>
            <hr class="border-gray-300" />
          </div>
          <div class="mt-6 grid xxs:grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-6">
          <div class="sm:col-span-full mb-4">
                <label for="country" class="block  font-medium leading-6 text-gray-900">Nama Karyawan</label>
                <div class="mt-2">
                  <select   v-model="name"  id="country" name="country" autocomplete="country-name" class="block min-w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6" >
                    <option value="" disabled selected>Pilih Karyawan</option>
                    <option
                      v-for="data1 in userdata.data"
                      :key="data1.user.name"
                      :value="data1.id"
              >
                {{ data1.user.name }}
              </option>
                  </select>
                </div>
              </div>

              <div class="sm:col-span-3">
            <label for="deskripsi" class="block text-sm font-medium leading-6 text-gray-900">Tanggal & jam pulang</label>
            <div class="mt-2">
              <input v-model="mulaipengajuan" type="datetime-local" name="deskripsi" id="deskripsi" autocomplete="deskripsi" class="block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>

          <div class="sm:col-span-3">
            <label for="deskripsi" class="block text-sm font-medium leading-6 text-gray-900">Total Jam Lembur</label>
            <div class="mt-2">
              <input v-model="selesaipengajuan" type="time" name="deskripsi" id="deskripsi" autocomplete="deskripsi" class="block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>
          
          <div class="sm:col-span-full mt-4">
            <label for="message" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Keterangan</label>
            <div class="mt-2">
            <textarea id="message" v-model="description" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Tambahkan Keterangan Jika Perlu..."></textarea>
            </div>
            </div>
            
          <!-- <div class="flex flex-row gap-8">
            <div class="flex flex-col">
              <label for="">Tanggal</label>
              <input
                class="w-[16.3rem] max-w-full  mt-4 mb-4 py-2 border-gray-300 rounded-md"
                type="date"
                name="datetime"
                id="datetime"
                v-model="mulaipengajuan"
              />
            </div>
            <div class="flex flex-col">
              <label for="">Jam Selesai Lembur</label>
              <input
                class="w-[16.3rem] max-w-full  mt-4 mb-4 py-2 border-gray-300 rounded-md"
                type="time"
                name="datetime"
                id="datetime"
                v-model="selesaipengajuan"
              />
            </div>
            <div class="flex flex-col">
              <label for="">Keterangan</label>
              <input
                class="w-[16.3rem] max-w-full mt-4 mb-4 py-2 border-gray-300 rounded-md"
                type="text"
                name="NomorInduk"
                id="NomorInduk"
                v-model="keterangan"
              />
            </div>
          </div> -->
          </div>
          <div class="flex justify-end">
            <button
              class="mt-5 bg-red-500 px-5 py-3 rounded-md"
              @click="closeModal()"
            >
              <h1 class="text-[12px] text-white">Kembali</h1>
            </button>
            <form @submit.prevent="submitApprove">
            <button
              class="ml-4 mt-5 bg-[#82C97C] px-[6rem] py-3 rounded-md"
            type="submit"
            >
              <h1 class="text-[12px] text-white">Simpan</h1>
            </button>
          </form>
          </div>
     
      </div>
    </div>
  </transition>

  <transition>
    <div
      class="fixed z-10 left-0 top-0 w-full h-full flex items-center justify-center overflow-auto bg-black/40 bg-opacity-50 font-pop font-regular"
      v-if="open"
    >
   
      <div class="bg-white px-16 py-4 w-[50rem] rounded-lg shadow-lg">
        <div class="w-full">
          <div class="flex items-center justify-center justify-between ">
          <h1 class="text-[20px] ">Tambah Presensi</h1>
          <ul class=" flex flex-row items-center justify-center gap-4  text-sm font-medium text-gray-500 dark:text-gray-400 md:me-4 mb-4 md:mb-0">        
        <li  @click="changeTab('Manual')">
          <a
            href="#"
            class="inline-flex items-center px-4 py-3  rounded-lg active w-full dark:bg-blue-600 bg-gray-50"
            aria-current="page" :class="{ 'bg-red-500 text-white': activeTab === 'Manual' }"
          >
          Manual
          </a>
        </li>

        <li  @click="changeTab('Upload')">
          <a
            href="#"
            class="inline-flex items-center px-4 py-3 rounded-lg  bg-gray-50  w-full dark:bg-gray-800 dark:hover:bg-gray-700 dark:hover:text-white"  :class="{ 'bg-red-500 text-white': activeTab === 'Upload' }"
          >
            Upload
          </a>
        </li>
        </ul>
        </div>
          <hr class="mb-4 mt-4" />

          <template v-if="activeTab === 'Manual'">
            <div class="border-b  border-gray-900/10 pb-12  pb-6">
            <h1 class="font-pop font-medium mt-6 mb-4" style="  animation: muncul-kiri-kanan-fade 0.5s;">Penginputan Tambah Presensi</h1>
          <div class="mt-4 grid xxs:grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-6" style="  animation: muncul-kiri-kanan-fade 0.5s;">
            
         
           
            <div class="sm:col-span-3">
                <label for="country" class="block text-sm font-medium leading-6 text-gray-900">Nama Karyawan</label>
                <div class="mt-2">
                  <select   v-model="name" @change="material2"  id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                    <option value="" disabled selected>Pilih Karyawan</option>
                    <option
                      v-for="data1 in userdata.data"
                      :key="data1.id"
                      :value="data1.id"
              >
                {{ data1.user.name }}
              </option>
                  </select>
                </div>
              </div>

              <div class="sm:col-span-3">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Tanggal</label>
              <div class="mt-2">
                <input    v-model="date" type="date" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>

            <div class="sm:col-span-2">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Jam Masuk</label>
              <div class="mt-2">
                <input    v-model="time1" type="time" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>

            <div class="sm:col-span-2">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Jam Keluar</label>
              <div class="mt-2">
                <input    v-model="time2" type="time" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>

            <div class="sm:col-span-2">
                <label for="country" class="block text-sm font-medium leading-6 text-gray-900">Shift Kerja</label>
                <div class="mt-2">
                  <select   v-model="day" id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                    <option value="" disabled selected>Pilih Shift Kerja</option>
                    <option
                      v-for="data1 in daydata.data"
                      :key="data1.id"
                      :value="data1.id"
              >
                {{ data1.name }}
              </option>
                  </select>
                </div>
              </div>

            </div>
          </div>

          <div class="flex justify-end" style="  animation: muncul-kiri-kanan-fade 0.5s;">
            <button
              class="mt-8 bg-red-500 px-5 py-3 rounded-md"
              @click="open = false"
            >
              <h1 class="text-[12px] text-white">Kembali</h1>
            </button>
            <form @submit.prevent="submitAbsen">
            <button
              class="ml-4 mt-8 bg-green-400  px-[3rem] py-3 rounded-md"
               type="submit"
            >
              <h1 class="text-[12px] text-white">Simpan</h1>
            </button>
          </form>
          </div>

        </template>

        <template v-if="activeTab === 'Upload'">
              <h1 class="font-pop font-medium mt-6 mb-4" style="  animation: muncul-kiri-kanan-fade 0.5s;">Penguplotan Data Absensi Disini</h1>
     
          
          <div class="flex items-center justify-center items-center w-full mb-4 ">
          
              <label for="dropzone-file" class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                  <div class="flex flex-col items-center justify-center pt-5 pb-6">
                      <svg   v-if="!uploadedFileName" class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
                      </svg>
                      <img v-if="uploadedFileName" class="w-12 h-12 mb-10" src="../../assets/excel.png" alt="">
                      <p v-if="uploadedFileName" class="text-[14px] text-gray-500 dark:text-gray-400">{{ uploadedFileName }}</p>
                      <p v-if="!uploadedFileName" class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Klik Untuk Upload</span> Atau Tarik dan Lepas</p>
                      <p v-if="!uploadedFileName" class="text-xs text-gray-500 dark:text-gray-400">XLSX Only</p>
                  </div>
                  <input id="dropzone-file" ref="fileInput" type="file" class="hidden" @change="handleFileUpload" />
              </label>
          </div> 
         
   
        <div class="flex justify-end gap-4">
            <button
              class="mt-4 bg-red-500 px-5 py-3 rounded-md"
              @click="open = false"
            >
              <h1 class="text-[12px] text-white">Kembali</h1>
            </button>
            <form @submit.prevent="insertData"  >
            <button
              class="mt-4 bg-green-400 px-5 py-3 rounded-md"
              type="submit"
            >
              <h1 class="text-[12px] text-white">Upload Data</h1>
            </button>
          </form>
          <!-- <button type="submit"  class="rounded-md bg-green-400 px-5 py-3 text-sm  font-semibold text-white shadow-sm ">Upload Data</button> -->
          </div>
      
     
          </template>

         
    
  

       
        </div>
      </div>

    </div>
  </transition>

  <div v-if="successModal" class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50"  style="  animation: fadein 1s;">
        <div class="bg-white p-8 rounded-lg flex flex-col justify-center items-center  "  style="animation: muncul-kiri-kanan-fade 1s; ">
          <img src="../../assets/verify.png" class="w-16 h-16 mb-6 " alt="">
          <p>Data Berhasil Ditambahkan</p>
        </div>
      </div>

  <div v-if="errorModal" class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50" style="animation: fadein 1s;">
        <div class="bg-white p-8 rounded-lg flex flex-col justify-center items-center  "  style="animation: muncul-kiri-kanan-fade 1s; ">
          <img src="../../assets/cancel.png" class="w-16 h-16 mb-6 " alt="">
          <p class="  font-semibold w-72 text-center" >{{ message }}</p>
        </div>
      </div>

  <div v-if="uploading" class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50" style="animation: fadein 1s;">
        <div class="bg-white p-8 rounded-lg flex flex-col justify-center items-center  "  style="animation: muncul-kiri-kanan-fade 1s; ">
          <img src="../../assets/loading.gif" class="w-16 h-16 mb-6 " alt="">
          <p class="  font-semibold w-72 text-center" >Data Sedang diproses ...</p>
        </div>
      </div>


</template>

<script>
import axios from "../../token";
import FormApproval from './FormAbsensi'

import * as XLSX from 'xlsx';

export default {
  components: { FormApproval },
 
  data() {
    return {
      activeTab :'',
      permohonanData: [], 
      open:false,
      open1:false,
      userdata:[],
      selectedTanggal: new Date().toISOString().substr(0, 10),
      uploading:false,
      errorModal:false,
      successModal:false,
      uploadedFileName: null,
          exceldata:[],
          daydata:[],
          material:'',
          name:'',
          
    };
  },
  methods: {
   
    handleFileUpload(event) {
  const fileInput = event.target;
  const files = fileInput.files;

  if (files.length > 0) {
    const file = files[0];
    // Memeriksa ekstensi file untuk memastikan bahwa file adalah xlsx
    if (file.name.endsWith('.xlsx') || file.name.endsWith('.xls') || file.name.endsWith('.csv')) {
      this.uploadedFileName = file.name;
      this.readExcelData(file);
    } else {
      this.errorModal = true;
      setTimeout(() => {
        this.errorModal = false;
       
      }, 3000);
      this.message = 'File Harus Berupa Excel';
      fileInput.value = '';
    }
  }
},
changeTab(tabName) {
    this.activeTab = tabName;
    localStorage.setItem('activeTab', tabName); 
  },
openModal(category) {

      this.selectedCategory = category; 
  this.name = this.selectedCategory.user_id,
    // this.mulaipengajuan = new Date(this.selectedCategory.date + 'T' + this.selectedCategory.time_in).toISOString().slice(0, -1);
    this.mulaipengajuan = this.selectedCategory.date + 'T' + this.selectedCategory.time_out;
      

      this.id= this.selectedCategory.id,
      this.open1 = true; 
    },
    closeModal() {
      this.open1 = false; // Menutup modal
      this.name= '',
      this.mulaipengajuan= ''
    },
    readExcelData(file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: 'binary' });
        
      
        const sheetName = 'Sheet1'; 

        if (workbook.SheetNames.includes(sheetName)) {
          const sheet = workbook.Sheets[sheetName];
          
          // Ambil data per baris dari kolom A
          const columnAtoGData = XLSX.utils.sheet_to_json(sheet, { header: 1, range: 1}).map(row => row.slice(1, 10));
          console.log(columnAtoGData)
           this.exceldata= columnAtoGData;
          
        } else {
          console.error(`Sheet "${sheetName}" not found in the Excel file.`);
          
        }
      };

      reader.readAsBinaryString(file);
    },
    handleChangeDate(event) {
   
    const selectedDate = event.target.value;
    
  
    this.selectedTanggal = selectedDate;
    this.getPermohonan(this.selectedTanggal)
  
  },
    
    getday() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/work-shift`,{
           
          })
          .then((response) => {
            this.daydata = response.data;
  
          })
          .catch((error) => {
            console.log(error);
          });
      },
    async submitForm() {
            this.uploading = true; // Set the loading state to true
          const formData = new FormData();
    
          formData.append('file', this.$refs.fileInput.files[0]);
    
          
          try {
            const response = await axios.post(`${process.env.VUE_APP_BACKEND_HOST}/presence/upload`, formData);
    
            console.log(response);
            await new Promise(resolve => setTimeout(resolve, 1000));
            this.uploading = false; 
            this.successModal = true;
        
          
            setTimeout(() => {
              this.successModal = false;
              this.open = false;
            }, 2000);
            setTimeout(() => {
          }, 2000);
          } catch (error) {
            console.log(error);
            this.uploading = false; 
            this.errorModal = true;
            setTimeout(() => {
              this.errorModal = false;
           
            }, 3000);
            this.message = error.response.data.message;
          } 
        },
        async submitAbsen() {
          
          const formData = new FormData();
    
          formData.append('user_id',this.name);
          formData.append('date',this.date);
          formData.append('time_in',this.time1);
          formData.append('time_out',this.time2);
          formData.append('workshift_id',this.day);
    
          
          try {
            const response = await axios.post(`${process.env.VUE_APP_BACKEND_HOST}/presence/create`, formData);
    
            console.log(response);
          
            this.successModal = true;
        
          
            setTimeout(() => {
              this.successModal = false;
              this.open = false;
            }, 2000);
            setTimeout(() => {
          }, 2000);
          } catch (error) {
            console.log(error);
            this.uploading = false; 
            this.errorModal = true;
            setTimeout(() => {
              this.errorModal = false;
            }, 3000);
            this.message = error.response.data.message;
          } 
        },
        async submitApprove() {
      try {
        let tanggalAwal = new Date(this.mulaipengajuan);

      // Mendapatkan komponen-komponen tanggal
      let tahun = tanggalAwal.getFullYear();
      let bulan = String(tanggalAwal.getMonth() + 1).padStart(2, '0'); // Bulan dimulai dari 0
      let tanggal = String(tanggalAwal.getDate()).padStart(2, '0');
      let jam = String(tanggalAwal.getHours()).padStart(2, '0');
      let menit = String(tanggalAwal.getMinutes()).padStart(2, '0');

   
      let hasilFormat = `${tahun}-${bulan}-${tanggal} ${jam}:${menit}`;
        const formData = new FormData();
        formData.append("user_id", this.name);
        formData.append("date", hasilFormat);
        formData.append("approved_hour", this.selesaipengajuan);
        formData.append("reason", this.keterangan);
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        const response = await axios.post(
          // `${process.env.VUE_APP_BACKEND_HOST}/employee-request/overtime`,
          `${process.env.VUE_APP_BACKEND_HOST}/hrd/overtime`,
          formData,
          config
        );
        console.log(response);
        
        this.successModal = true;
        setTimeout(() => {
          this.successModal = false;
          this.open = false;
          window.location.reload()
        }, 3000);
      } catch (error) {
          console.log(error);
        this.message = error.response.data.message;
        this.errorModal = true;
      setTimeout(() => {
        this.errorModal = false;
      }, 3000);
      }
    },
    insertData() {
      this.columnAtoGData=[]
      this.uploadedFileName=null
      console.log('Inserting data:', this.entries);
      this.submitForm()
    },
    getPermohonan(date) {
      let tanggal;
      if (date) {
        tanggal = date; 
      } else {
        tanggal =  new Date().toISOString().substr(0, 10); 
      }

      axios
        .get(`${process.env.VUE_APP_BACKEND_HOST}/presence/list`,{
          params:{
            date:tanggal
          }
        })
        .then((response) => {
          this.permohonanData = response.data;
        })
        .catch((error) => {
          console.log(error);
          this.permohonanData=[]
        });
    },
    getuser() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/hrd/employee`)
          .then((response) => {
            this.userdata = response.data;
  
          })
          .catch((error) => {
            console.log(error);
          });
      },
  },
  mounted() {
    this.getPermohonan();
    this.activeTab ='Manual';
    this.getuser();
    this.getday();
  },
};
</script>

