<template>
  <div class="">
    <button class="w-[118px] h-[30px] flex-shrink-0 rounded-lg text-[#868686] bg-[#EBEDF0] " @click="open = true">
      <h3 class="text-[12px] font-medium">Lihat Data</h3>
    </button>
  </div>

  <transition>
    <div
      class="fixed z-10 left-0 top-0 w-full h-full flex items-center justify-center overflow-auto bg-black/40 bg-opacity-50 font-pop font-regular"
      v-if="open">
      <div class="min-w-[500px] bg-[#fff] px-20 py-10 rounded-xl">

        <div class="mb-8">
          <h1 class="text-[20px] mb-4">Pengajuan Lembur</h1>
          <hr class="border-gray-300" />
        </div>
        <div class="mt-6 grid xxs:grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-6">
          <div class="sm:col-span-full mb-4">
            <label for="country" class="block  font-medium leading-6 text-gray-900">Nama Karyawan</label>
            <div class="mt-2">
              <select v-model="name" id="country" name="country" autocomplete="country-name"
                class="block min-w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                disabled>
                <option value="" disabled selected>Pilih Karyawan</option>
                <option v-for="data1 in userdata.data" :key="data1.user.name" :value="data1.id">
                  {{ data1.user.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="sm:col-span-3">
            <!-- tab pengajuan lembur, modal tambah permohonan -->
            <label for="deskripsi" class="block text-sm font-medium leading-6 text-gray-900">Tanggal & jam
              pulang</label>
            <div class="mt-2">
              <input v-model="mulaipengajuan" type="datetime-local" name="deskripsi" id="deskripsi"
                autocomplete="deskripsi"
                class="block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>

          <div class="sm:col-span-3">
            <label for="deskripsi" class="block text-sm font-medium leading-6 text-gray-900">Total Jam Lembur</label>
            <div class="mt-2">
              <input v-model="selesaipengajuan" type="time" name="deskripsi" id="deskripsi" autocomplete="deskripsi"
                class="block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>

          <div class="sm:col-span-full mt-4">
            <label for="message" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Keterangan</label>
            <div class="mt-2">
              <textarea id="message" v-model="description" rows="4"
                class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Tambahkan Keterangan Jika Perlu..."></textarea>
            </div>
          </div>

          <!-- <div class="flex flex-row gap-8">
            <div class="flex flex-col">
              <label for="">Tanggal</label>
              <input
                class="w-[16.3rem] max-w-full  mt-4 mb-4 py-2 border-gray-300 rounded-md"
                type="date"
                name="datetime"
                id="datetime"
                v-model="mulaipengajuan"
              />
            </div>
            <div class="flex flex-col">
              <label for="">Jam Selesai Lembur</label>
              <input
                class="w-[16.3rem] max-w-full  mt-4 mb-4 py-2 border-gray-300 rounded-md"
                type="time"
                name="datetime"
                id="datetime"
                v-model="selesaipengajuan"
              />
            </div>
            <div class="flex flex-col">
              <label for="">Keterangan</label>
              <input
                class="w-[16.3rem] max-w-full mt-4 mb-4 py-2 border-gray-300 rounded-md"
                type="text"
                name="NomorInduk"
                id="NomorInduk"
                v-model="keterangan"
              />
            </div>
          </div> -->
        </div>
        <div class="flex justify-between">

          <button class=" mt-5 bg-red-500 px-5 py-3 rounded-md" @click="open = false">
            <h1 class="text-[12px] text-white">Kembali</h1>
          </button>
          <div class="">
            <button @click="handleButtonClick('rejected')"
              class="rounded-md mr-4 mt-5 bg-red-500  px-5 py-3 text-sm font-semibold text-white shadow-sm hover:bg-green-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              Ditolak
            </button>

            <button @click="handleButtonClick('approved')"
              class="rounded-md mt-5 bg-red-5 bg-green-400  px-5 py-3 text-sm font-semibold text-white shadow-sm hover:bg-green-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              Approved
            </button>
          </div>
        </div>

      </div>
    </div>
  </transition>

  <!-- <transition>
        <div class="fixed z-10 left-0 top-0 w-full h-full flex items-center justify-center overflow-auto bg-black/40 bg-opacity-50  font-pop font-regular" v-if="open">
            <div class="relative w-[1000px]  bg-[#fff] px-20 py-10 rounded-xl">
                <div class="mb-8">
                    <h1 class="text-[20px] mb-4">Approval Pengajuan Lembur</h1>
                    <hr class=" border-gray-300">
                </div>
            <div class="grid  grid-cols-2 gap-6">
             
           
                <div class="grid grid-flow-row-dense grid-cols-3">
                    <label class="mt-5" for="">Nama</label>
                    <div class="col-span-2">
                        <input v-model="nama" class="w-full px-7 mt-4 mb-4 py-2 border-gray-300 rounded-md " type="text" disabled>
                    </div>
                </div>
                <div class="grid grid-flow-row-dense grid-cols-3">
                    <label class="mt-5" for="">NIK</label>
                    <div class="col-span-2">
                        <input v-model="NIK" class="w-full px-7 mt-4 mb-4 py-2 border-gray-300 rounded-md " type="text" disabled>
                    </div>
                </div>
                <div class="grid grid-flow-row-dense grid-cols-3">
                    <label class="mt-5" for="">Mulai Pengajuan</label>
                    <div class="col-span-2">
                        <input v-model="mulai" class="w-full px-7 mt-4 mb-4 py-2 border-gray-300 rounded-md " type="text" disabled>
                    </div>
                </div>

                <div class="grid grid-flow-row-dense grid-cols-3">
                    <label class="mt-5" for="">Selesai</label>
                    <div class="col-span-2">
                        <input v-model="Selesai" class="w-full px-7 mt-4 mb-4 py-2 border-gray-300 rounded-md " type="text" disabled>
                    </div>
                </div>
                <div class="grid grid-flow-row-dense grid-cols-3">
                    <label class="mt-5" for="">Keterangan</label>
                    <div class="col-span-2">
                        <input v-model="Keterangan" class="w-full px-7 mt-4 mb-4 py-2 border-gray-300 rounded-md " type="text" >
                    </div>
                </div>
                <div class="grid grid-flow-row-dense grid-cols-3">
                    <label class="mt-5" for="">Status</label>
                    <div class="col-span-2">
                        <input v-model="status" class="w-full px-7 mt-4 mb-4 py-2 border-gray-300 rounded-md " type="text" disabled>
                    </div>
                </div>
                
        </div>
        <div class="flex justify-between">
            
              <button class=" mt-5 bg-red-500 px-5 py-3 rounded-md" @click="open = false">
                <h1 class="text-[12px] text-white">Back</h1>
              </button>
              <div class="">
                <button @click="handleButtonClick('rejected')" class="rounded-md mr-4 mt-5 bg-red-500  px-5 py-3 text-sm font-semibold text-white shadow-sm hover:bg-green-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                Ditolak
              </button>

              <button @click="handleButtonClick('approved')" class="rounded-md mt-5 bg-red-5 bg-green-400  px-5 py-3 text-sm font-semibold text-white shadow-sm hover:bg-green-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                Approved
              </button>
            </div>
            </div>
            </div>
          </div>          
    </transition> -->

  <div v-if="successModal"
    class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50"
    style="  animation: fadein 1s;">
    <div class="bg-white p-8 rounded-lg flex flex-col justify-center items-center  "
      style="animation: muncul-kiri-kanan-fade 1s; ">
      <img src="../../assets/verify.png" class="w-16 h-16 mb-6 " alt="">
      <p>Status Berhasil Di Update</p>
    </div>
  </div>

  <div v-if="errorModal"
    class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50"
    style="animation: fadein 1s;">
    <div class="bg-white p-8 rounded-lg flex flex-col justify-center items-center  "
      style="animation: muncul-kiri-kanan-fade 1s; ">
      <img src="../../assets/cancel.png" class="w-16 h-16 mb-6 " alt="">
      <p class="  font-semibold w-72 text-center">{{ message }}</p>
    </div>
  </div>

</template>

<script>
import axios from "../../token";
export default {

  data() {
    // Initialize mulaipengajuan based on this.permohonandata.time_out
    let mulaipengajuan;
    // this.permohonandata.time_out
    // this.mulaipengajuan = this.selectedCategory.date + 'T' + this.selectedCategory.time_out;
    if (this.permohonandata.time_out !== null && this.permohonandata.time_out !== undefined) {
      // mulaipengajuan = new Date(this.permohonandata.date + 'T' + this.permohonandata.time_out).toISOString().slice(0, -1);
      mulaipengajuan = this.permohonandata.date + 'T' + this.permohonandata.time_out;
    } else {
      // mulaipengajuan = this.permohonandata.date + (this.permohonandata.time_out ? 'T' + this.permohonandata.time_out : 'T00:00');
      mulaipengajuan = this.permohonandata.date + 'T00:00';

    }

    return {
        open: false,
        errorModal: false,
        successModal: false,
        userdata: [],
        name: this.permohonandata.user_id,
        status: this.permohonandata.status,
        description: this.permohonandata.note,
      selesaipengajuan: this.permohonandata.overtime_approved,
        mulaipengajuan: mulaipengajuan, // Use the initialized mulaipengajuan variable
        // NIK: this.permohonandata.nik,
        id: this.permohonandata.id,
        permidata: [],
    };
},
    props: {
    permohonandata: {
      type: Object, // Adjust the type according to your data structure
      required: true,
    },
  },
  methods: {
    material2() {
    this.material1 = this.project;
    console.log(this.material1)
    if (this.material1) {
      this.getuser()
    } else {
      this.material1 = '';
    }
  },
        unit() {
      const selectedMaterial1 = this.userdata.data.find(material => material.id === this.selectedMaterial.id);

      if (selectedMaterial1) {
        this.eom = selectedMaterial1.symbol;
      } else {
        this.eom = '';
      }
    },
    toggleModal() {
      this.isOpen = !this.isOpen;
    },
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    handleButtonClick(action) {
      console.log(`Button clicked: ${action}`);
      this.status1 = action;
      this.submitForm();
    },
    getpermi1() {
      axios
        .get(`${process.env.VUE_APP_BACKEND_HOST}/permission/check`)
        .then((response) => {
          this.permidata = response.data;

        
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async submitForm() {
      const formData = new FormData();

      formData.append('status',this.status1),
      formData.append('approved_hour',this.Selesai),
      formData.append('note',this.Keterangan),
      formData.append('id',this.id)

      try {
        const response = await axios.post(`${process.env.VUE_APP_BACKEND_HOST}/hrd/overtime/process`, formData);

        console.log(response);
        await new Promise(resolve => setTimeout(resolve, 1000));
        this.successModal = true;
    
      
        setTimeout(() => {
          this.successModal = false;
          this.open = false;
        }, 2000);
        setTimeout(() => {
            window.location.reload()
      }, 2000);
      } catch (error) {
        console.log(error);
        this.errorModal = true;
        setTimeout(() => {
          this.errorModal = false;
          this.open = false;
        }, 3000);
        this.message = error.response.data.message;
      }
    },
    getuser() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/hrd/employee`)
          .then((response) => {
            this.userdata = response.data;
  
          })
          .catch((error) => {
            console.log(error);
          });
      },
  
  },
  mounted(){
    this.getpermi1();
    this.getuser();
  }
  };
  </script>

  <style scoped>
  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.5s ease;
  }
  
  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }


  
  </style>