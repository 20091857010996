<template>
    <div class="bottom-nav bg-ungu h-[70px] items-center">
      <router-link to="/Riwayat" class="nav-item">
        <img src="../gambar/dashboard.svg" alt="">
      </router-link>
      <router-link to="/Dashboard" class="nav-item">
        <img  src="../gambar/riwayat.svg" alt="">
      </router-link>
      <router-link to="/kontak" class="nav-item">
        <button @click="toggleModal" data-collapse-toggle="navbar-hamburger" type="button" class="flex items-center justify-center    focus:outline-none" aria-controls="navbar-hamburger" aria-expanded="false">
          <img class="md:h-[52px] xxs:h-[40px]" src="../gambar/orang.svg" alt="">
        </button>
      </router-link>
    </div>
  </template>
  
  <script>
  export default {
    name: 'BottomBar',
  };
  </script>
  
  <style scoped>
  .bottom-nav {
    color: #fff;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: space-around;
    padding: 10px 0;
    z-index: 1;
  }
  
  .nav-item {
    text-decoration: none;
    color: #fff;
  }
  </style>
  