<template>
    <div class="flex font-pop"  style=" animation: muncul-kiri-kanan-fade 1.3s;">
    <ul class=" flex-column space-y space-y-4 text-sm font-medium text-gray-500 dark:text-gray-400 md:me-4 mb-4 md:mb-0">
        <h1 class=" text-[18px] mb-2">Master Data</h1>
           
        <li  @click="changeTab('project')">
  <a
    href="#"
    class="inline-flex items-center px-4 py-3 text-black  rounded-lg active w-full dark:bg-blue-600 bg-gray-50"
    aria-current="page" :class="{ 'bg-red-500 text-white': activeTab === 'project' }"
  >
    Project
  </a>
</li>

<li  @click="changeTab('Inventory')">
  <a
    href="#"
    class="inline-flex items-center px-4 py-3 rounded-lg  bg-gray-50  w-full dark:bg-gray-800 dark:hover:bg-gray-700 dark:hover:text-white"  :class="{ 'bg-red-500 text-white': activeTab === 'Inventory' }"
  >
    Inventory
  </a>
</li>

<li @click="changeTab('HR')">
  <a
    href="#"
    class="inline-flex items-center px-4 py-3 rounded-lg  bg-gray-50  w-full dark:bg-gray-800 dark:hover:bg-gray-700 dark:hover:text-white"
    :class="{ 'bg-red-500 text-white': activeTab === 'HR' }"

  >
    HumanResource
  </a>
</li>

<!-- <li @click="changeTab('Work')">
  <a
    href="#"
    class="inline-flex items-center px-4 py-3 rounded-lg  bg-gray-50  w-full dark:bg-gray-800 dark:hover:bg-gray-700 dark:hover:text-white"
    :class="{ 'bg-red-500 text-white': activeTab === 'Work' }"

  >
    Work Order
  </a>
</li> -->




</ul>

<template v-if="activeTab === 'Work'">
    <div class="flex flex-col font-pop mt-12 ml-10 w-full">

       
<div class="grid gap-x-8 gap-y-8 md:grid-cols-3 xxs:grid-cols-1 "  style=" animation: muncul-kiri-kanan-fade 1.3s;">

    <router-link  v-if="permidata.data && permidata.data.includes('team')" to="/MasterTeam" class="bg-white flex flex-row items-center px-6 py-6 transition-transform transform-gpu md:hover:scale-110  xxs:hover:scale-90" style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;">
        <div class="p-1 rounded  h-[50px] w-[50px] flex justify-center items-center bg-[#00000040] mr-4">
            <img src="../assets/team.png" class="h-[30px]" alt="">
        </div>
        <div class="">
            <h1 class=" font-medium">Team</h1>
            <h2 class="text-[12px]">
                Penginputan Master Team
            </h2>
        </div>
    </router-link>
    </div>
    </div>

        </template>

<template v-if="activeTab === 'project'">
    <div class="flex flex-col font-pop mt-12 ml-10 w-full">

       
<div class="grid gap-x-8 gap-y-8 md:grid-cols-3 xxs:grid-cols-1 "  style=" animation: muncul-kiri-kanan-fade 1.3s;">

    <router-link  v-if="permidata.data && permidata.data.includes('Kategori Projek')" to="/MasterProject" class="bg-white flex flex-row items-center px-6 py-6 transition-transform transform-gpu md:hover:scale-110  xxs:hover:scale-90" style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;">
        <div class="p-1 rounded  h-[50px] w-[50px] flex justify-center items-center bg-green-100 mr-4">
            <img src="../assets/project.png" class="h-[30px]" alt="">
        </div>
        <div class="">
            <h1 class=" font-medium">Kategory Project</h1>
            <h2 class="text-[12px]">
                Penginputan Master Project
            </h2>
        </div>
    </router-link>
    </div>
    </div>

        </template>


        <template v-if="activeTab === 'Inventory'">
    <div class="flex flex-col font-pop mt-12 ml-10 w-full">

       
<div class="grid gap-x-8 gap-y-8 md:grid-cols-3 xxs:grid-cols-1 "  style=" animation: muncul-kiri-kanan-fade 1.3s;">

    <router-link  v-if="permidata.data && permidata.data.includes('Material')" to="/MasterMaterial" class="bg-white flex flex-row items-center px-6 py-6 transition-transform transform-gpu md:hover:scale-110  xxs:hover:scale-90" style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;">
                <div class="p-1 rounded  h-[50px] w-[50px] flex justify-center items-center   bg-red-300 mr-4">
                    <img src="../assets/material.png" class="h-[40px]" alt="">
                </div>
                <div class="">
                    <h1 class=" font-medium">Material</h1>
                    <h2 class="text-[12px]">
                        Penginputan Master Material
                    </h2>
                </div>
            </router-link>

            <router-link  v-if="permidata.data && permidata.data.includes('Supplier')" to="/MasterSupplier" class="bg-white flex flex-row items-center px-6 py-6 transition-transform transform-gpu md:hover:scale-110  xxs:hover:scale-90" style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;">
                <div class="p-1 rounded  h-[50px] w-[50px] flex justify-center items-center bg-blue-300 mr-4">
                    <img src="../assets/supplier.png" class="h-[35px]" alt="">
                </div>
                <div class="">
                    <h1 class=" font-medium">Supplier</h1>
                    <h2 class="text-[12px]">
                        Penginputan Master Supplier
                    </h2>
                </div>
            </router-link>

            <router-link  v-if="permidata.data && permidata.data.includes('Unit')" to="/MasterUnit" class="bg-white flex flex-row items-center px-6 py-6 transition-transform transform-gpu md:hover:scale-110  xxs:hover:scale-90" style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;">
                <div class="p-1 rounded  h-[50px] w-[50px] flex justify-center bg-gray-300 mr-4">
                    <img src="../assets/unit.png" class="h-[40px]" alt="">
                </div>
                <div class="">
                    <h1 class=" font-medium">Unit</h1>
                    <h2 class="text-[12px]">
                        Penginputan Master Unit
                    </h2>
                </div>
            </router-link>
    </div>
    </div>

        </template>

        <template v-if="activeTab === 'HR'">
    <div class="flex flex-col font-pop mt-12 ml-10 w-full">

       
<div class="grid gap-x-8 gap-y-8 md:grid-cols-3 xxs:grid-cols-1 "  style=" animation: muncul-kiri-kanan-fade 1.3s;">

    <router-link v-if="permidata.data && permidata.data.includes('Department')" to="/MasterDepartement" class="bg-white flex flex-row items-center px-6 py-6 transition-transform transform-gpu md:hover:scale-110  xxs:hover:scale-90" style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;">
                <div class="p-1 rounded  h-[50px] w-[50px] flex justify-center items-center  bg-blue-200  mr-4">
                    <img src="../assets/department.png" class="h-[35px]" alt="">
                </div>
                <div class="">
                    <h1 class=" font-medium">Departement</h1>
                    <h2 class="text-[12px]">
                        Penginputan Master Departement
                    </h2>
                </div>
            </router-link>

            <router-link  v-if="permidata.data && permidata.data.includes('Permission')" to="/MasterPermission" class="bg-white flex flex-row items-center px-6 py-6 transition-transform transform-gpu md:hover:scale-110  xxs:hover:scale-90" style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;">
                <div class="p-1 rounded  h-[50px] w-[50px] flex justify-center items-center bg-orange-200 mr-4">
                    <img src="../assets/permission.png" class="h-[30px]" alt="">
                </div>
                <div class="">
                    <h1 class=" font-medium">Permission</h1>
                    <h2 class="text-[12px]">
                        Penginputan Master Permission
                    </h2>
                </div>
            </router-link>
            
            <router-link  v-if="permidata.data && permidata.data.includes('Shift Kerja')" to="/MasterShift" class="bg-white flex flex-row items-center px-6 py-6 transition-transform transform-gpu md:hover:scale-110  xxs:hover:scale-90" style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;">
                <div class="p-1 rounded  h-[50px] w-[50px] flex justify-center items-center bg-yellow-100 mr-4">
                    <img src="../assets/shift.png" class="h-[40px]" alt="">
                </div>
                <div class="">
                    <h1 class=" font-medium">Shift</h1>
                    <h2 class="text-[12px]">
                        Penginputan Master Shift
                    </h2>
                </div>
            </router-link>

            <router-link  v-if="permidata.data && permidata.data.includes('Posisi')" to="/MasterPosisi" class="bg-white flex flex-row items-center px-6 py-6 transition-transform transform-gpu md:hover:scale-110  xxs:hover:scale-90" style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;">
                <div class="p-1 rounded  h-[50px] w-[50px] flex justify-center items-center bg-purple-100 mr-4">
                    <img src="../assets/posisi.png" class="h-[35px]" alt="">
                </div>
                <div class="">
                    <h1 class=" font-medium">Posisi</h1>
                    <h2 class="text-[12px]">
                        Penginputan Master Posisi
                    </h2>
                </div>
            </router-link>

            <router-link  v-if="permidata.data && permidata.data.includes('Role')" to="/MasterRole" class="bg-white flex flex-row items-center px-6 py-6 transition-transform transform-gpu md:hover:scale-110  xxs:hover:scale-90" style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;">
                <div class="p-1 rounded  h-[50px] w-[50px] flex justify-center items-center  bg-gray-100 mr-4">
                    <img src="../assets/role.png" class="h-[35px]" alt="">
                </div>
                <div class="">
                    <h1 class=" font-medium">Role</h1>
                    <h2 class="text-[12px]">
                        Penginputan Master Role
                    </h2>
                </div>
            </router-link>

            <router-link  v-if="permidata.data && permidata.data.includes('Hari Libur')" to="/MasterHariLibur" class="bg-white flex flex-row items-center px-6 py-6 transition-transform transform-gpu md:hover:scale-110  xxs:hover:scale-90" style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;">
                <div class="p-1 rounded  h-[50px] w-[50px] flex justify-center items-center  bg-gray-100 mr-4">
                    <img src="../assets/calendar-transparent.png" class="h-[35px]" alt="">
                </div>
                <div class="">
                    <h1 class=" font-medium">Hari Libur</h1>
                    <h2 class="text-[12px]">
                        Penginputan Master Hari Libur
                    </h2>
                </div>
            </router-link>
    </div>
    </div>

        </template>

</div>
</template>


<script>
import { ref } from 'vue';
import axios from "../token";
export default {
data() {
  return {
    activeTab: '',
    permidata:[],
    dataArray: [
      {
        title: "Kapal Tanker",
        PM: "Susanto",
        description:
          "Pengerjaan Project dimulai dari tanggal 12 agustus 2019 dan tanggal akhir 12 agustus 2020",
        imageSrc:
          "https://asset.kompas.com/crops/k0__dJytqamRwPJW6K4j_fqCJvw=/0x0:1800x1200/750x500/data/photo/2022/09/10/631c1d6276c69.jpg",
      },
    ],
  };
},
setup() {
  const entries = ref([
        { id: 1,data:"Tongkang - Electrical Tongkang",tanggal:"24-01-2024", employee: 'Mild Steel Bracket (200mm x 420mm)', request: '', dateIn: '1',dateOut:"pcs",status:"100.000",total:"100.000" },
        { id: 2,data:"Tongkang - Hull&Structure Construction",tanggal:"24-01-2024", employee: 'Mild Steel Bracket (200mm x 420mm)', request: '', dateIn: '1',dateOut:"pcs",status:"100.000",total:"100.000" },
        { id: 3, data:"Tongkang - Outfitting",tanggal:"24-01-2024",employee: 'Mild Steel Bracket (200mm x 420mm)', request: '', dateIn: '1',dateOut:"pcs",status:"100.000",total:"100.000"  },
  ]);

  return {
    entries,
  }
},
methods: {
  changeTab(tabName) {
    this.activeTab = tabName;
    localStorage.setItem('activeTab', tabName); 
  },
  getpermi1() {
      axios
        .get(`${process.env.VUE_APP_BACKEND_HOST}/permission/check`)
        .then((response) => {
          this.permidata = response.data;

        
        })
        .catch((error) => {
          console.log(error);
        });
    },
},
created() {
  const storedTab = localStorage.getItem('activeTab');
  if (storedTab) {
    this.activeTab = storedTab;
  } else {
    this.activeTab = 'project';
  }
},
mounted(){
    this.activeTab='project'
    this.getpermi1();
}
};
</script>