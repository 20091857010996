<template>
  <div class="flex justify-between items-center mb-5" style="animation: muncul-kiri-kanan-fade 0.8s;">
    <div class="flex gap-4 items-center">
      <label for="month">Bulan:</label>
      <select
        class="block w-[10rem] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        v-model="selectedMonth" id="month">
        <option v-for="(month, index) in months" :key="index" :value="index + 1">{{ month }}</option>
      </select>

      <label for="year">Tahun:</label>
      <select
        class="block  w-[10rem] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        v-model="selectedYear" id="year">
        <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
      </select>

      <!-- Add a button or trigger an action to apply the filter -->
      <button
        class="rounded-md bg-green-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        @click="applyFilters">Terapkan Filter</button>
    </div>

    <button class="w-[142px] h-[30px] p-1 rounded text-white bg-red-500" @click="open1 = true">
      <div class="flex flex-row justify-center">
        <img src="../../assets/add.png" class="mr-1 w-5" alt="" />
        <h3 class="text-[12px] font-medium">Tambah Hari Libur</h3>
      </div>
    </button>
  </div>

<div class="first-letter:relative overflow-x-auto border sm:rounded-lg bg-white shadow-lg font-pop"
  style=" animation: muncul-kiri-kanan-fade 0.8s;">
  <table class="min-w-full text-sm text-left text-gray-500 ] dark:text-gray-400">
    <thead class="text-xs text-gray-700 border-b bg-[#64707628] dark:bg-gray-700 dark:text-gray-400 text-[14px]">
      <tr class="text-[#868686]">
        <th scope="col" class="px-6 py-3 font-medium text-center">No</th>
        <th scope="col" class="px-6 py-3 text-center">
          <div class="font-medium">Tanggal</div>
        </th>
        <th scope="col" class="px-6 py-3 text-center">
          <div class="font-medium">Keterangan</div>
        </th>
        <th scope="col" class="px-6 py-3 text-center">
          <div class="font-medium">Tipe</div>
        </th>
        <th scope="col" class="px-6 py-3">
          <div class="font-medium">Aksi</div>
        </th>
      </tr>
    </thead>
    <tbody v-if="categorydata.data && categorydata.data.length > 0">
      <tr class="border-b dark:bg-gray-800 dark:border-gray-700 text-[16px] font-medium text-black"
        v-for="(entry, index) in categorydata.data" :key="entry.id">
        <td class="px-6 py-4 text-center">{{ index + 1 }}</td>
        <td class="px-6 py-4 text-center">{{ entry.date }}</td>
        <td class="px-6 py-4 text-center">{{ entry.description }}</td>
        <td class="px-6 py-4 text-center">{{ entry.type }}</td>
        <td class="px-6 py-4 text-center">
          <div class="flex flex-row gap-4 flex-wrap">
            <img class="w-8 h-8 cursor-pointer" @click="openModalWithId(entry.id)" src="../../assets/edit.png" alt="">
            <img class="w-8 h-8 cursor-pointer" @click="deleteMaterial(entry.id)" src="../../assets/bin.png" alt="">
          </div>
        </td>
      </tr>
    </tbody>
    <tbody v-else style=" animation: muncul-kiri-kanan-fade 0.8s;">
      <tr>
        <td colspan="5" class="px-6 py-4 text-center font-pop text-[16px] text-gray-600 dark:text-gray-400">Data Tidak
          Tersedia</td>
      </tr>
    </tbody>
  </table>
</div>

  <transition>
    <div
      class="fixed z-10 left-0 top-0 w-full h-full flex items-center justify-center overflow-auto bg-black/40 bg-opacity-50 font-pop font-regular"
      v-if="open1">

      <div class="bg-white px-16 py-4 rounded-lg shadow-lg">
        <div class="w-full">
          <h1 class="text-[20px] mb-4">Tambah Hari Libur</h1>
          <hr class="mb-4" />

          <div class="modal-body space-y-4">
            <div>
              <label for="date" class="block text-sm font-medium text-gray-700">Date:</label>
              <input type="date" v-model="date" id="date"
                class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
            </div>

            <div>
              <label for="description" class="block text-sm font-medium text-gray-700">Description:</label>
              <input type="text" v-model="description" id="description"
                class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
            </div>

            <div>
              <label for="type" class="block text-sm font-medium text-gray-700">Type:</label>
              <select v-model="type" id="type"
                class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                <option>Libur Nasional</option>
                <option>Cuti Bersama</option>
              </select>
            </div>
          </div>

          <div class="flex justify-end">
            <button class="mt-8 bg-red-500 px-5 py-3 rounded-md" @click="open1 = false">
              <h1 class="text-[12px] text-white">Kembali</h1>
            </button>
            <form @submit.prevent="submitForm">
              <button class="ml-4 mt-8 bg-[#A6CF98] px-[3rem] py-3 rounded-md" type="submit">
                <h1 class="text-[12px] text-white">Simpan</h1>
              </button>
            </form>
          </div>
        </div>
      </div>

    </div>
  </transition>

  <transition>
    <div
      class="fixed z-10 left-0 top-0 w-full h-full flex items-center justify-center overflow-auto bg-black/40 bg-opacity-50 font-pop font-regular"
      v-if="openEditModal">

      <div class="bg-white px-16 py-4 rounded-lg shadow-lg">
        <div class="w-full">
          <h1 class="text-[20px] mb-4">Edit Hari Libur</h1>
          <hr class="mb-4" />

          <div class="modal-body space-y-4">
            <div>
              <label for="editDate" class="block text-sm font-medium text-gray-700">Date:</label>
              <input type="date" v-model="editDate" id="editDate"
                class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
            </div>

            <div>
              <label for="editDescription" class="block text-sm font-medium text-gray-700">Description:</label>
              <input type="text" v-model="editDescription" id="editDescription"
                class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
            </div>

            <div>
              <label for="editType" class="block text-sm font-medium text-gray-700">Type:</label>
              <select v-model="editType" id="editType"
                class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                <option>Libur Nasional</option>
                <option>Cuti Bersama</option>
              </select>
            </div>
          </div>

          <div class="flex justify-end">
            <button class="mt-8 bg-red-500 px-5 py-3 rounded-md" @click="openEditModal = false">
              <h1 class="text-[12px] text-white">Kembali</h1>
            </button>
            <form @submit.prevent="submitEditForm()">
              <button class="ml-4 mt-8 bg-[#A6CF98] px-[3rem] py-3 rounded-md" type="submit">
                <h1 class="text-[12px] text-white">Simpan</h1>
              </button>
            </form>
          </div>
        </div>
      </div>

    </div>
  </transition>
</template>




<script>

import axios from "../../token";
export default {
  data() {
    return {
      searchTerm: "",
      open: "",
      open1: false,
      openEditModal: false,
      selectedDate: this.getInitialDate(),
      name: "",
      id: '',
      date: '',
      description: '',
      editId: '',
      editDate: '',
      editDescription: '',
      editType: '',
      type: '',
      successModal1:false,
      successModal:false,
      categorydata:[],
      errorModal: false,
      selectedMonth: new Date().getMonth() + 1, // Current month
      selectedYear: new Date().getFullYear(), // Current year
      months: Array.from({ length: 12 }, (_, i) => i + 1), // Months from 1 to 12
      years: Array.from({ length: 21 }, (_, i) => new Date().getFullYear() - 10 + i), // Years from 10 years ago to 10 years in the future
    };
  },
  components: {
   
  },
  computed: {
    filteredEntries() {
      let filtered = this.entries;

      if (this.selectedStatus.length > 0) {
        filtered = filtered.filter((entry) =>
          this.selectedStatus.includes(entry.status)
        );
      }

      if (this.selectedSort === "asc") {
        return filtered.sort((a, b) => a.dateIn.localeCompare(b.dateIn));
      } else {
        return filtered.sort((a, b) => b.dateIn.localeCompare(a.dateIn));
      }
    },
  },
  methods: {
    openModal(category) {
      this.selectedCategory = category; 
      this.name= this.selectedCategory.name,
      this.id= this.selectedCategory.id,
      this.open1 = true; // Menampilkan modal
    },
    closeModal() {
      this.open1 = false; // Menutup modal
    },
    applyFilters() {
      this.getcategory();
    },
    getInitialDate() {
      return new Date().toISOString().split("T")[0];
    },
    async deleteMaterial(id) {
        try {
          let data = new URLSearchParams();
          data.append('id', id);
  
          let response = await axios.delete(`${process.env.VUE_APP_BACKEND_HOST}/holiday`, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
             
            },
            data: data
          });
          this.getcategory();
          console.log(response.data);
          this.successModal1 = true;
          setTimeout(() => {
            this.successModal1 = false;
          }, 3000);
        } catch (error) {
          console.error(error);
          this.message = error.response.data.message;
          this.errorModal = true;
          setTimeout(() => {
            this.errorModal = false;
          }, 3000);
        }
      },
    async submitForm() {
      try {
        const response = await axios.post(`${process.env.VUE_APP_BACKEND_HOST}/holiday`, {
          date: this.date,
          description: this.description,
          type: this.type
        });
        console.log(response);
        this.successModal = true;
        setTimeout(() => {
          this.successModal = false;
          location.reload();
        }, 3000);
      } catch (error) {
        console.log(error);
        this.message = error.response.data.message;
        this.errorModal = true;
        setTimeout(() => {
          this.errorModal = false;
         
        }, 3000);
      }
    },
    async openModalWithId(id) {
      try {
        const response = await axios.get(`/holiday/detail`, { params: { id: id } });
        const data = response.data.data;
        this.editId = data.id;  
        this.editDate = data.date;
        this.editDescription = data.description;
        this.editType = data.type;
        this.openEditModal = true;
      } catch (error) {
        console.error(error);
      }
    },
    submitEditForm() {
      try {
        const formData = new FormData();
        formData.append('id', this.editId);
        formData.append('date', this.editDate);
        formData.append('description', this.editDescription);
        formData.append('type', this.editType);
        formData.append('_method', 'put');

        axios.post(`/holiday`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then((response) => {
          console.log(response);
          this.successModal = true;
          setTimeout(() => {
            this.successModal = false;
            location.reload();
          }, 3000);
        }).catch((error) => {
          console.error(error);
          this.message = error.response.data.message;
          this.errorModal = true;
          setTimeout(() => {
            this.errorModal = false;
          }, 3000);
        });
      } catch (error) {
        console.error(error);
      }
    },
    async submitUpdate() {
    try {
        const formData = new FormData();
        formData.append('name', this.name);
        formData.append('_method','put'),
        formData.append('id',this.id)

        const response = await axios.post(`${process.env.VUE_APP_BACKEND_HOST}/project-category`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        console.log(response);

        this.successModal = true;
        setTimeout(() => {
            this.successModal = false;
            location.reload();
        }, 3000);
    } catch (error) {
        console.log(error);
        this.message = error.response.data.message;
        this.errorModal = true;
        setTimeout(() => {
            this.errorModal = false;
        }, 3000);
    }
},
    getcategory() {
      axios
        .get(`${process.env.VUE_APP_BACKEND_HOST}/holiday`, {
          params: {
            month: this.selectedMonth,
            year: this.selectedYear
          }
        })
        .then((response) => {
          this.categorydata = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted(){
    this.getcategory();
  }
};
</script>

<style>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.search-input:focus {
  outline: none;
  box-shadow: none; /* Optional: Remove box-shadow if it's present */
}
</style>
