<template>
    <dashboard-layout :isDarkMode="isDarkMode">
        <!-- Sidebar content -->
        <template #sidebar>
          <BottomBar class="xxs:flex xl:hidden"  />
        </template>
    
        <!-- Navbar content -->
        <template #navbar>
          <NavBar :isDarkMode="isDarkMode" />
        </template>
    
        <!-- Page content -->
        <template #content>
          <Module/>
        </template>
      </dashboard-layout>
  </template>

  <script>
  



import Module from '../Dashboard/ModuleBar'
import NavBar from '../Dashboard/NavBar'
import BottomBar from '../Dashboard/BottomBar'



import DashboardLayout from "@/Dashboard/DashboardLayout.vue";

  
  export default {
    components: {
        NavBar,
        BottomBar,
        DashboardLayout,
        Module
    },
    data() {
      return {
        isDarkMode: false,
      };
    },
    computed: {
    isLargeScreen() {
      return window.innerWidth > 480; 
    },
  },
  methods: {
    toggleDarkMode() {
      this.isDarkMode = !this.isDarkMode;
    },
  },
  };
  </script>
  
  <style scoped>
/* Style for the rounded event cells */
.fc-daygrid-day {
  border-radius: 10px; /* Adjust the value to control the roundness of the cells */
  overflow: hidden; /* Ensures content doesn't overflow rounded corners */
}
.rounded-event {
  background-color: #007BFF; /* Change the background color to your preference */
  color: #fff; /* Set the text color */
  padding: 5px; /* Adjust padding as needed */
}
</style>