<template>

<nav class="flex mb-10 font-pop" aria-label="Breadcrumb">
  <ol class="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
    <li>
      <div class="flex items-center">
       
        <router-link to="/Konfigurasi" class="ms-1 text-sm font-medium text-gray-700 hover:text-red-500 md:ms-2 dark:text-gray-400 dark:hover:text-white">Konfigurasi</router-link>
      </div>
    </li>
    <li aria-current="page">
      <div class="flex items-center">
        <svg class="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4"/>
        </svg>
        <span class="ms-1 text-sm font-medium text-red-500 md:ms-2 dark:text-gray-400">Master Shift</span>
      </div>
    </li>
  </ol>
</nav>

   <div class="md:flex md:flex-row md:justify-between xxs:hidden">
    <div class="relative text-gray-600">
  <input type="search" name="serch" placeholder="Pencarian..." class="bg-[#FAFBFD] h-10 px-5 pr-24 rounded text-sm focus:outline-none">
  <button type="submit" class="absolute right-0 top-0 mt-3 mr-4">
    <svg class="h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 56.966 56.966" style="enable-background:new 0 0 56.966 56.966;" xml:space="preserve" width="512px" height="512px">
      <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z"/>
    </svg>
  </button>
</div>

<div class="">
  <transition name="fade-in">
 
  <button
      class="w-[142px] h-[30px] p-1 mr-4 rounded text-white bg-red-500"
      @click="open = true"
    >
      <div class="flex flex-row justify-center">
        <img src="../../assets/add.png" class="mr-1 w-5" alt="" />
        <h3 class="text-[12px] font-medium">Tambah Shift</h3>
      </div>
    </button>
 
</transition>
   
 
</div>

  </div>

  <div class="grid md:grid-cols-4 gap-10 justify-between items-center xxs:grid-cols-1 font-pop mt-10">
     
     <div v-for="category in categorydata.data" :key="category.id"  class="  bg-white  rounded overflow-hidden cursor-pointer px-6 "  style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;  animation: muncul-kiri-kanan-fade 1.5s;">
       <transition name="fade-in">
         <div class="flex flex-row justify-between  items-center">
          <h2 class="text-[12px] font-semibold mb-2 underline" @click="openModal(category)">{{ category.name }}</h2>
           <div class=" flex flex-col p-6"> 
            
           
           </div>
           <img class="w-8 h-8 cursor-pointer" @click="deleteMaterial(category.id)" src="../../assets/bin.png" alt="">
           
          
         
       
       </div>
     </transition>
     </div>
 
   </div>

  <transition>
    <div
      class="fixed z-10 left-0 top-0 w-full h-full flex items-center justify-center overflow-auto bg-black/40 bg-opacity-50 font-pop font-regular"
      v-if="open"
    >
   
      <div class="bg-white px-16 py-4 rounded-lg shadow-lg">
        <div class="w-full">
          <h1 class="text-[20px] mb-4">Tambah Shift</h1>
          <hr class="mb-4" />
          <div class="mt-6 grid xxs:grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-6">
          <div class="sm:col-span-2">
              <label for="last-name" class="block text-[14px] font-medium leading-6 text-gray-900">Nama Shift</label>
              <div class="mt-2">
                <input    v-model="name" type="text" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>

            <div class="sm:col-span-2">
              <label for="last-name" class="block text-[14px] font-medium leading-6 text-gray-900">Deskripsi</label>
              <div class="mt-2">
                <input    v-model="description" type="text" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>

            <div class="sm:col-span-2">
              <label for="last-name" class="block text-[14px] font-medium leading-6 text-gray-900">Jam Masuk</label>
              <div class="mt-2">
                <input    v-model="time_in" type="time" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>

            <div class="sm:col-span-2">
              <label for="last-name" class="block text-[14px] font-medium leading-6 text-gray-900">Waktu Telat</label>
              <div class="mt-2">
                <input    v-model="late" type="time" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>

            <div class="sm:col-span-2">
              <label for="last-name" class="block text-[14px] font-medium leading-6 text-gray-900">Jam Pulang</label>
              <div class="mt-2">
                <input    v-model="time_out" type="time" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>

            <div class="sm:col-span-2">
              <label for="last-name" class="block text-[14px] font-medium leading-6 text-gray-900">Mulai Istirahat Lembur</label>
              <div class="mt-2">
                <input    v-model="start_break" type="time" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>

            <div class="sm:col-span-2">
              <label for="last-name" class="block text-[14px] font-medium leading-6 text-gray-900">Selesai Istirahat Lembur</label>
              <div class="mt-2">
                <input    v-model="end_break" type="time" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>

            

            <div class="sm:col-span-2">
              <label for="last-name" class="block text-[14px] font-medium leading-6 text-gray-900">Mulai Lembur</label>
              <div class="mt-2">
                <input    v-model="overtime" type="time" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>


            </div>
          <div class="flex justify-end">
            <button
              class="mt-8 bg-red-500 px-5 py-3 rounded-md"
              @click="open = false"
            >
              <h1 class="text-[12px] text-white">Kembali</h1>
            </button>
            <form @submit.prevent="submitForm">
            <button
              class="ml-4 mt-8 bg-green-500 px-[3rem] py-3 rounded-md"
               type="submit"
            >
              <h1 class="text-[12px] text-white">Simpan</h1>
            </button>
          </form>
          </div>
        </div>
      </div>
      
    </div>
  </transition>

  <transition>
    <div
      class="fixed z-10 left-0 top-0 w-full h-full flex items-center justify-center overflow-auto bg-black/40 bg-opacity-50 font-pop font-regular"
      v-if="open1"
    >
   
      <div class="bg-white px-16 py-4 rounded-lg shadow-lg">
        <div class="w-full">
          <h1 class="text-[20px] mb-4">View Shift</h1>
          <hr class="mb-4" />
          <div class="mt-6 grid xxs:grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-6">
          <div class="sm:col-span-2">
              <label for="last-name" class="block text-[14px] font-medium leading-6 text-gray-900">Nama Shift</label>
              <div class="mt-2">
                <input    v-model="name" type="text" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>

            <div class="sm:col-span-2">
              <label for="last-name" class="block text-[14px] font-medium leading-6 text-gray-900">Deskripsi</label>
              <div class="mt-2">
                <input    v-model="description" type="text" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>

            <div class="sm:col-span-2">
              <label for="last-name" class="block text-[14px] font-medium leading-6 text-gray-900">Jam Masuk</label>
              <div class="mt-2">
                <input    v-model="time_in" type="time" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>

            <div class="sm:col-span-2">
              <label for="last-name" class="block text-[14px] font-medium leading-6 text-gray-900">Waktu Telat</label>
              <div class="mt-2">
                <input    v-model="late" type="time" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>

            <div class="sm:col-span-2">
              <label for="last-name" class="block text-[14px] font-medium leading-6 text-gray-900">Jam Pulang</label>
              <div class="mt-2">
                <input    v-model="time_out" type="time" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>

            <div class="sm:col-span-2">
              <label for="last-name" class="block text-[14px] font-medium leading-6 text-gray-900">Mulai Istirahat Lembur</label>
              <div class="mt-2">
                <input    v-model="start_break" type="time" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>

            <div class="sm:col-span-2">
              <label for="last-name" class="block text-[14px] font-medium leading-6 text-gray-900">Selesai Istirahat Lembur</label>
              <div class="mt-2">
                <input    v-model="end_break" type="time" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>

            

            <div class="sm:col-span-2">
              <label for="last-name" class="block text-[14px] font-medium leading-6 text-gray-900">Mulai Lembur</label>
              <div class="mt-2">
                <input    v-model="overtime" type="time" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>


            </div>
          <div class="flex justify-end">
            <button
              class="mt-8 bg-red-500 px-5 py-3 rounded-md"
              @click="open1 = false"
            >
              <h1 class="text-[12px] text-white">Kembali</h1>
            </button>
            <form @submit.prevent="submitUpdate">
            <button
              class="ml-4 mt-8 bg-green-500 px-[3rem] py-3 rounded-md"
               type="submit"
            >
              <h1 class="text-[12px] text-white">Simpan</h1>
            </button>
          </form>
          </div>
        </div>
      </div>
      
    </div>
  </transition>

  <div v-if="successModal" class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50"  style="  animation: fadein 1s;">
      <div class="bg-white p-8 rounded-lg flex flex-col justify-center items-center  "  style="animation: muncul-kiri-kanan-fade 1s; ">
        <img src="../../assets/verify.png" class="w-16 h-16 mb-6 " alt="">
        <p>Data Shift Berhasil ditambahkan</p>
      </div>
    </div>

    <div v-if="successModal1" class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50"  style="  animation: fadein 1s;">
      <div class="bg-white p-8 rounded-lg flex flex-col justify-center items-center  "  style="animation: muncul-kiri-kanan-fade 1s; ">
        <img src="../../assets/verify.png" class="w-16 h-16 mb-6 " alt="">
        <p>Data Shift Berhasil dihapus</p>
      </div>
    </div>

    <div v-if="errorModal" class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50" style="animation: fadein 1s;">
    <div class="bg-white p-8 rounded-lg flex flex-col justify-center items-center  "  style="animation: muncul-kiri-kanan-fade 1s; ">
      <img src="../../assets/cancel.png" class="w-16 h-16 mb-6 " alt="">
      <p class="  font-semibold w-72 text-center" >{{ message }}</p>
    </div>
  </div>


</template>

<script>

import axios from "../../token";
export default {
  data() {
    return {
      searchTerm: "",
      open: "",
      open1: "",
      selectedDate: this.getInitialDate(),
      name: "",
      late: "",
      successModal1:false,
      start_break:'',
      end_break:'',
      time_out: "",
      time_in: "",
      overtime: "",
      description: "",
      successModal:false,
      categorydata:[],
      errorModal:false,
      id:'',
    };
  },
  components: {
   
  },
  computed: {
    filteredEntries() {
      let filtered = this.entries;

      if (this.selectedStatus.length > 0) {
        filtered = filtered.filter((entry) =>
          this.selectedStatus.includes(entry.status)
        );
      }

      if (this.selectedSort === "asc") {
        return filtered.sort((a, b) => a.dateIn.localeCompare(b.dateIn));
      } else {
        return filtered.sort((a, b) => b.dateIn.localeCompare(a.dateIn));
      }
    },
  },
  methods: {
    openModal(category) {
      this.selectedCategory = category; 
      this.name= this.selectedCategory.name,
      this.late= this.selectedCategory.late,
      this.start_break=this.selectedCategory.start_break,
      this.end_break=this.selectedCategory.end_break,
      this.time_out= this.selectedCategory.time_out,
      this.time_in= this.selectedCategory.time_in,
      this.description= this.selectedCategory.description,
      this.overtime= this.selectedCategory.overtime,
      this.id= this.selectedCategory.id,
      this.open1 = true; // Menampilkan modal
    },
    closeModal() {
      this.open1 = false; // Menutup modal
    },
    applyFilters() {
      this.closeModal();
    },
    getInitialDate() {
      return new Date().toISOString().split("T")[0];
    },
    async deleteMaterial(id) {
        try {
          let data = new URLSearchParams();
          data.append('id[]', id);
  
          let response = await axios.delete(`${process.env.VUE_APP_BACKEND_HOST}/work-shift`, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
             
            },
            data: data
          });
          this.getcategory();
          console.log(response.data);
          this.successModal1 = true;
          setTimeout(() => {
            this.successModal1 = false;
          }, 3000);
        } catch (error) {
          console.error(error);
          this.message = error.response.data.message;
          this.errorModal = true;
          setTimeout(() => {
            this.errorModal = false;
          }, 3000);
        }
      },
    async submitForm() {
      try {
        const response = await axios.post(`${process.env.VUE_APP_BACKEND_HOST}/work-shift`, {
          name: this.name,
          description: this.description,
          time_in: this.time_in,
          time_out: this.time_out,
          start_break:this.start_break,
          end_break:this.end_break,
          late: this.late,
          overtime: this.overtime,
        });
        console.log(response);
        
        this.successModal = true;
        setTimeout(() => {
          this.successModal = false;
          location.reload();
        }, 3000);
      } catch (error) {
        console.log(error);
        this.message = error.response.data.message;
        this.errorModal = true;
        setTimeout(() => {
            this.errorModal = false;
        }, 3000);
      }
    },
    async submitUpdate() {
  try {
    const formData = new FormData();
    formData.append('name', this.name);
    formData.append('description', this.description);
    formData.append('_method','put'),
    formData.append('id',this.id),
    formData.append('time_in', this.time_in);
    formData.append('time_out', this.time_out);
    formData.append('start_break', this.start_break);
    formData.append('end_break', this.end_break);
    formData.append('late', this.late);
    formData.append('overtime', this.overtime);

    const response = await axios.post(`${process.env.VUE_APP_BACKEND_HOST}/work-shift`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    console.log(response);
    
    this.successModal = true;
    setTimeout(() => {
      this.successModal = false;
      location.reload();
    }, 3000);
  } catch (error) {
    console.log(error);
    this.message = error.response.data.message;
    this.errorModal = true;
    setTimeout(() => {
      this.errorModal = false;
    }, 3000);
  }
},
    getcategory() {
      axios
        .get(`${process.env.VUE_APP_BACKEND_HOST}/work-shift`)
        .then((response) => {
          this.categorydata = response.data;

        
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted(){
    this.getcategory();
  }
};
</script>

<style>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.search-input:focus {
  outline: none;
  box-shadow: none; /* Optional: Remove box-shadow if it's present */
}
</style>
