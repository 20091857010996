<template>
  <div class="flex flex-col justify-center items-center" >
  

    <div class="xl:mt-16 xxs:mt-4 font-pop font-regular text-center" style="  animation: muncul-kiri-kanan-fade 1s;">
      <h1 class="xl:text-[32px] md:text-[32px] xxs:text-[23px] text-[#373636]">Selamat Pagi, {{nama}}</h1>
      <h2 class="xl:text-[40px] md:text-[40px] xxs:text-[29px] font-light text-[#373636]">{{ formattedTime }}</h2>
      <h3 class="md:text-[18px] xxs:text-[13px] font-light font-mutiara text-[#373636]">{{ formattedDate }}</h3>
    </div>

    <div class="">
      <button @click="toggleAbsenStatus" :class="buttonClass" style="  animation: fadeIn 1s;">
        <img class="xl:h-[127px] md:h-[127px] xxs:h-[70px] xl:mt-14 xxs:mt-12 mb-7" src="../gambar/touch.png" alt="">
        <span class="xl:text-[24px] md:text-[24px] xxs:text-[16px] font-pop font-light">{{ absenStatus }}</span>
      </button>
      <h1 class="text-black mt-10 font-pop text-center">{{  ket }}</h1>
    </div>

    <div >
  <div class="flex flex-row justify-between xl:w-[760px] xxs:w-[278px] xl:ml-7 xxs:ml-7 xl:mt-16 md:mt-24 xxs:mt-10 text-center" v-for="status in Statusdata.data" :key="status.id">
    <div class="flex flex-col">
      <img class="xl:h-[64px] xl:w-[64px] md:w-[64px] md:h-[64px] xxs:h-[41p] xxs:w-[41px]" src="../gambar/back-in-time.png" alt="">
      <span class="xl:text-[24px] md:text-[24px] xxs:text-[17px] font-pop font-light mt-3">{{ status.time_in }}</span>
      <span class="text-[14px] font-pop font-light mt-1">Masuk</span>
    </div>
    <div class="flex flex-col">
      <img class="xl:h-[64px] xl:w-[64px] md:w-[64px] md:h-[64px] xxs:h-[41p] xxs:w-[41px] text-red" src="../gambar/clock.png" alt="">
      <span class="xl:text-[24px] md:text-[24px] xxs:text-[17px] font-pop font-light mt-3">{{ status.time_out }}</span>
      <span class="text-[14px] font-pop font-light mt-1">Keluar</span>
    </div>
    <div class="flex flex-col">
      <img class="xl:h-[64px] xl:w-[64px] md:w-[64px] md:h-[64px] xxs:h-[41px] xxs:w-[41px]" src="../gambar/check.png" alt="">
      <span class="text-[24px] md:text-[24px] xxs:text-[17px] font-pop font-light mt-3">{{ status.work_hour }} Jam</span>
      <span class="text-[14px] font-pop font-light mt-1">Kerja</span>
    </div>
  </div>
</div>

</div>

</template>

<script>
import axios from 'axios';

export default {
  name: "AbsensiUser",
  data() {
    const now = new Date();
    
    return {
      nama: localStorage.getItem('name') || 'Default Value',
      email: localStorage.getItem('email') || 'Default Value',
      Statusdata:[],
      islogin:false,
      ket:'',
      formattedTime: this.getFormattedTime(),
      absenStatus: 'Absen Masuk',
      buttonClass: 'flex flex-col items-center space-x-2 text-white shadow-xl font-semibold rounded-xl mt-10 xl:h-[278px] xl:w-[278px] md:w-[278px] md:h-[278px] xxs:w-[200px] xxs:h-[200px] transition-transform hover:scale-105 hover:shadow-3xl transform-gpu bg-[#A6CF98]',
      formattedDate: this.formatDate(now),
      quotes: [
        "Langkah pertama menuju sukses adalah keberanian untuk mencoba",
        "Kegagalan adalah kesempatan untuk mulai lagi, dengan lebih bijaksana",
        "Keberhasilan dimulai dengan tekad untuk mencoba",
        "Jangan menunda-nunda, hari ini adalah hari untuk bertindak",
        "Kemajuan bukanlah tentang seberapa cepat kamu bergerak, tapi seberapa sering kamu tidak berhenti",
        "Tetaplah berfokus pada tujuanmu, meski perjalanan terasa sulit",
        "Jangan biarkan ketakutan menghalangi impianmu, berani melangkah",
        "Semua impian dapat diwujudkan dengan tekad dan usaha yang konsisten",
        "Kesuksesan dimulai dengan satu keputusan berani setiap hari",
        "Jadilah sumber inspirasi bagi dirimu sendiri dan orang lain",
      ],
      currentIndex: 0
    };
  },
 
   
 
  computed: {
    currentQuote() {
      return this.quotes[this.currentIndex];
    },
   
  },
  mounted() {
    setInterval(() => {
      this.formattedTime = this.getFormattedTime();
    }, 1000);
    setInterval(this.changeQuote, 10000);
    this.getstatus();
  },

  methods: {
    getFormattedTime() {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();

      const formattedHours = hours > 9 ? hours : `0${hours}`;
      const formattedMinutes = minutes > 9 ? minutes : `0${minutes}`;

      return `${formattedHours}:${formattedMinutes}`;
    },
    formatDate(date) {
      const options = { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' };
      return date.toLocaleDateString('id-ID', options);
    },
    changeQuote() {
      this.currentIndex = (this.currentIndex + 1) % this.quotes.length;
    },
    async toggleAbsenStatus() {
      try {
        const endpoint = this.islogin === false ? 'in' : 'out';
        
        // const now = new Date();
        // const formattedDate = this.formatDate(now);

        
        const response = await axios.get(
          `${process.env.VUE_APP_BACKEND_HOST}/presence/check-${endpoint}`,
        );
        this.islogin=true
        console.log(response.data);
       
        this.ket=response.data.message;
        this.getstatus();
     
        if (this.islogin === true ) {
          this.islogin = 'Absen Keluar';
          this.buttonClass = 'flex flex-col items-center space-x-2 text-white shadow-2xl font-semibold rounded-xl mt-10 xl:h-[278px] xl:w-[278px] md:w-[278px] md:h-[278px] xxs:w-[200px] xxs:h-[200px] transition-transform hover:scale-105 hover:shadow-3xl transform-gpu bg-[#E96A6A]';
        } else {
          this.islogin = 'Absen Masuk';
          this.buttonClass = 'flex flex-col items-center space-x-2 text-white shadow-2xl font-semibold rounded-xl mt-10 xl:h-[278px] xl:w-[278px] md:w-[278px] md:h-[278px] xxs:w-[200px] xxs:h-[200px] transition-transform hover:scale-105 hover:shadow-3xl transform-gpu bg-[#99BC85]';
        }

      } catch (error) {
        console.error('Error during Axios request:', error);
        this.ket =  error.response.data.message;
     
      }
    },
    getstatus() {
      axios
        .get(`${process.env.VUE_APP_BACKEND_HOST}/presence/status`)
        .then((response) => {
          this.Statusdata = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

</style>
