
<template>

<nav class="flex mb-6 font-pop" aria-label="Breadcrumb" style=" animation: muncul-kiri-kanan-fade 1s;"> 
  <ol class="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
    <li>
      <div class="flex items-center">
       
        <router-link to="/Payroll" class="ms-1 text-sm font-medium text-gray-700 hover:text-red-500 md:ms-2 dark:text-gray-400 dark:hover:text-white">Payroll</router-link>
      </div>
    </li>
    <li aria-current="page">
      <div class="flex items-center">
        <svg class="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4"/>
        </svg>
        <span class="ms-1 text-sm font-medium text-red-500 md:ms-2 dark:text-gray-400">Input Payroll</span>
      </div>
    </li>
  </ol>
</nav>

    
      <div class="space-y-12 bg-white md:mb-0 md:px-32 py-4 xxs:px-4 xxs:mb-16 rounded-xl" style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;  animation: muncul-kiri-kanan-fade 1s;">
       <div class="flex flex-row justify-between font-pop">
          <h2 class="text-base text-[22px] font-semibold leading-7 text-gray-900 mt-4 uppercase">{{ nama }}</h2>
          <div class="flex flex-row gap-4 justify-center items-center mt-4">
            <label  for="month">Bulan:</label>
            <div class="mt-2">
                <input v-model="selectedMonth" disabled type="text" name="last-name" id="last-name" autocomplete="family-name" class="block w-[3rem] max-w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            <!-- <select class="block w-[10rem] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" v-model="selectedMonth" id="month" disabled >
              <option v-for="(month, index) in months" :key="index" :value="index + 1">{{ month }}</option>
            </select> -->

            <label for="year">Tahun:</label>
            <div class="mt-2">
                <input v-model="selectedYear" disabled type="text" name="last-name" id="last-name" autocomplete="family-name" class="block w-[4rem] max-w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>

            <!-- <select class="block  w-[10rem] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" v-model="selectedYear" id="year" disabled>
              <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
            </select> -->

           
          </div>
        </div>
          
          <div class="border-b border-gray-900/10 pb-12 mb-6">
            <h1 class="font-pop font-medium uppercase">Informasi Gaji</h1>
          <div class="mt-10 grid xxs:grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-6">

          
            <div class="sm:col-span-2">
            <label for="project" class="block text-sm font-medium leading-6 text-gray-900">Status</label>
            <div class="mt-2">
              <select v-model="status" disabled  id="project" name="project" autocomplete="project-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                <option value="" disabled selected>Pilih Status</option>
                <option   value="TK/0">TK/0</option>
                <option   value="TK/1">TK/1</option>
                <option   value="TK/2">TK/2</option>
                <option   value="TK/3">TK/3</option>
                <option   value="K/0">K/0</option>
                <option  value="K/1">K/1</option>
                <option  value="K/2">K/2</option>
                <option  value="K/3">K/3</option>
              </select>
            </div>
          </div>

             <div class="sm:col-span-2">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Gaji Pokok</label>
              <div class="mt-2">
                <input  v-model="gajipokok" disabled type="text" @input="handleInput($event, 'gajipokok')" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>

            

            <div class="sm:col-span-2">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">T.Jabatan</label>
              <div class="mt-2">
                <input v-model="Jabatan" disabled type="text" @input="handleInput($event, 'Jabatan')" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>

            <div class="sm:col-span-2">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">T.Transportasi & Telepon</label>
              <div class="mt-2">
                <input v-model="Transportasi" disabled type="text" @input="handleInput($event, 'Transportasi')" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>

            <div class="sm:col-span-2">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">T.Makan</label>
              <div class="mt-2">
                <input v-model="Makan" disabled type="text" @input="handleInput($event, 'Makan')"  name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>

            <div class="sm:col-span-2">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Pokok untuk Hitung BPJS</label>
              <div class="mt-2">
                <input v-model="PokokBPJS" disabled  type="text" @input="handleInput($event, 'PokokBPJS')"  name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>

           

            <!-- <div class="sm:col-span-0">
              <label for="region" class="block text-sm font-medium leading-6 text-gray-900">Tempat</label>
              <div class="mt-2">
                <input v-model="birthPlace" type="text" name="region" id="region" autocomplete="address-level1" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>

            <div class="sm:col-span-1">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Tanggal Lahir</label>
              <div class="mt-2">
                <input v-model="birthDate" type="date" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div> -->

            <!-- <div class="sm:col-span-2">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Lembur Otomatis</label>
              <div class="mt-2">
                <input  v-model="formattedNumber" type="text" @input="handleInput" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div> -->

            <div class="sm:col-span-2">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">THR/Bonus/Incentive</label>
              <div class="mt-2">
                <input  v-model="Bonus" type="text" @input="handleInput($event, 'Bonus')"  name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>

            <div class="sm:col-span-2">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Adjustment</label>
              <div class="mt-2">
                <input  v-model="Adjustment" type="text" @input="handleInput($event, 'Adjustment',true)" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>

            <div class="sm:col-span-2">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Lembur</label>
              <div class="mt-2">
                <input  v-model="Lembur2" type="text"  @input="handleInput($event, 'Lembur2')" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled />
              </div>
            </div>

            <div class="sm:col-span-2">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Gaji Kotor</label>
              <div class="mt-2">
                <input  v-model="GajiKotor" type="text"  @input="handleInput($event, 'GajiKotor')" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled />
              </div>
            </div>

            <div class="sm:col-span-2">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Telat</label>
              <div class="mt-2">
                <input  v-model="telat" type="text"  @input="handleInput($event, 'telat')" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled />
              </div>
            </div>

            <div class="sm:col-span-2">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Absen</label>
              <div class="mt-2">
                <input  v-model="absen" type="text"  @input="handleInput($event, 'absen')" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled />
              </div>
            </div>
            <div class="sm:col-span-2">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Pinjaman</label>
              <div class="mt-2">
                <input  v-model="loan_payment" type="text"  @input="handleInput($event, 'loan_payment')" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>


            <div class="sm:col-span-2 flex items-center">
                <form @submit.prevent="submitcheck">
                    <button type="submit" class="rounded-md bg-green-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Kalkulasi Perhitungan</button>
                </form>
            </div>
            
           

            <!-- <div class="sm:col-span-1">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">No KTP</label>
              <div class="mt-2">
                <input v-model="ktp" type="text" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>

            <div class="sm:col-span-1">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">No SIM</label>
              <div class="mt-2">
                <input v-model="sim" type="text" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>

            <div class="sm:col-span-1">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">No Paspor</label>
              <div class="mt-2">
                <input v-model="passport" type="text" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>

            <div class="sm:col-span-1">
              <label for="country" class="block text-sm font-medium leading-6 text-gray-900">Agama</label>
              <div class="mt-2">
                <select v-model="religion" id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                  <option value="" disabled selected>Pilih Agama</option>
                  <option>Islam</option>
                  <option>Kristen</option>
                  <option>Buddha</option>
                </select>
              </div>
            </div>

            <div class="sm:col-span-2">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Golongan Darah</label>
              <div class="mt-2">
                <select v-model="bloodType" id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                  <option value="" disabled selected>Pilih Golongan Darah</option>
                  <option>A</option>
                  <option>O</option>
                  <option>AB</option>
                </select>
              </div>
            </div>

            <div class="sm:col-span-2">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Status Kawin</label>
              <div class="mt-2">
                <select v-model="maritalStatus" id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                  <option value="" disabled selected>Pilih Status Kawin</option>
                  <option>Kawin</option>
                  <option>Duda</option>
                  <option>Janda</option>
                </select>
              </div>
            </div>

            <div class="sm:col-span-1">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Jumlah Anak</label>
              <div class="mt-2">
                <input v-model="children" type="text" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>

            <div class="sm:col-span-1">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">No. BPJS</label>
              <div class="mt-2">
                <input v-model="bpjsHealth" type="text" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div> -->

           

            <!-- <div class="sm:col-span-1">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Tanggal Gabung</label>
              <div class="mt-2">
                <input v-model="joinDate" type="date" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>

            <div class="sm:col-span-1">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Tanggal Pengakatan</label>
              <div class="mt-2">
                <input v-model="appointmentDate" type="date" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>

            <div class="sm:col-span-1">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Tanggal Berhenti</label>
              <div class="mt-2">
                <input v-model="resignDate" type="date" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div> -->

            <!-- <div class="sm:col-span-1">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">NIK Atasan</label>
              <div class="mt-2">
                <input v-model="gender" type="text" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div> -->


            

            <!-- <div class="sm:col-span-1">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Mulai Kontrak</label>
              <div class="mt-2">
                <input v-model="contractStart" type="date" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>

            <div class="sm:col-span-1">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Selesai Kontrak</label>
              <div class="mt-2">
                <input v-model="contractEnd" type="date" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div> -->

  
            <!-- <div class="col-span-full">
              <label for="about" class="block text-sm font-medium leading-6 text-gray-900">Bio Karyawan</label>
              <div class="mt-2">
                <textarea id="about" name="about" rows="3" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
              <p class="mt-3 text-sm leading-6 text-gray-600">Bio Karyawan Opsional Boleh diisi boleh tidak</p>
            </div> -->
  
           
           
                  </div>
                  
                 
                </div>
                <div class="border-b border-gray-900/10 pb-12 mb-6">
                  <h1 class="font-pop font-medium uppercase">Hasil Perhitungan</h1>
                 
                  <div class="mt-10 grid xxs:grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-6">
                 
                    <div class="sm:col-span-full">
                    <h1 class="font-pop font-medium uppercase underline">BPJS Ketenagakerjaan</h1>
                  </div>
                <div class="sm:col-span-2">
                  <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">JKK</label>
                  <div class="mt-2">
                    <input  v-model="jkk" type="text" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled />
                  </div>
                </div>


                <div class="sm:col-span-2">
                  <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">JKM</label>
                  <div class="mt-2">
                    <input  v-model="jkm" type="text" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled />
                  </div>
                </div>


                <div class="sm:col-span-2">
                  <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">JHT Perusahaan</label>
                  <div class="mt-2">
                    <input  v-model="jht_perusahaan" type="text" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled />
                  </div>
                </div>
                </div>

                <div class="border-b border-gray-900/10 pb-12 mb-6">
                  <div class="mt-10 grid xxs:grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-6">

                    
                  
                <div class="sm:col-span-2">
                  <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">JHT Karyawan</label>
                  <div class="mt-2">
                    <input  v-model="jht" type="text" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled />
                  </div>
                </div>

                <div class="sm:col-span-2">
                  <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">JP Perusahaan</label>
                  <div class="mt-2">
                    <input  v-model="jp_perusahaan" type="text" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled />
                  </div>
                </div>

                <div class="sm:col-span-2">
                  <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">JP Karyawan</label>
                  <div class="mt-2">
                    <input  v-model="jp_karyawan" type="text" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled />
                  </div>
                </div>


                <div class="sm:col-span-2">
                  <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Total BPJS Ketenagakerjaan</label>
                  <div class="mt-2">
                    <input  v-model="total_bpjs_kt" type="text" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled />
                  </div>
                </div>
                </div>

               
                </div>

                <div class="border-b border-gray-900/10 pb-12 mb-6">
                  <div class="mt-10 grid xxs:grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-6">

                    <div class="sm:col-span-full">
                    <h1 class="font-pop font-medium uppercase underline">BPJS Kesehatan</h1>
                  </div>

                    <div class="sm:col-span-2">
                  <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Kesehatan Perusahaan</label>
                  <div class="mt-2">
                    <input  v-model="jkn_perusahaan" type="text" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled />
                  </div>
                </div>


                <div class="sm:col-span-2">
                  <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Kesehatan Karyawan </label>
                  <div class="mt-2">
                    <input  v-model="jkn_karyawan" type="text" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled />
                  </div>
                </div>

                <div class="sm:col-span-2">
                  <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Total BPJS Kesehatan</label>
                  <div class="mt-2">
                    <input  v-model="total_bpjs_kes" type="text" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled />
                  </div>
                </div>

                </div>
                </div>

                
                  <div class="mt-10 grid xxs:grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-6">

                    <div class="sm:col-span-full">
                    <h1 class="font-pop font-medium uppercase underline">Informasi Pajak</h1>
                  </div>


                <div class="sm:col-span-2">
                  <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Bruto Pajak</label>
                  <div class="mt-2">
                    <input  v-model="bruto_pajak"  @input="handleInput($event, 'bruto_pajak')" type="text" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled />
                  </div>
                </div>

                
                <div class="sm:col-span-2">
                  <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">TER</label>
                  <div class="mt-2">
                    <input  v-model="ter" type="text" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled />
                  </div>
                </div>

                
                <div class="sm:col-span-2">
                  <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Tarif</label>
                  <div class="mt-2">
                    <input  v-model="ter_rate" type="text" @input="handleInput($event, 'ter_rate')"  name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled />
                  </div>
                </div>


                <div class="sm:col-span-2">
                  <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Pajak</label>
                  <div class="mt-2">
                    <input  v-model="pph21" type="text" @input="handleInput($event, 'pph21')" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled />
                  </div>
                </div>
               
                </div>


              </div>
                  
          

      
      
    
      
       
    
    
    
       

        <div class="mt-6 flex items-center justify-end gap-x-6">
          <button class="rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" @click="previewReport">Cetak Slip Gaji</button>
        <router-link to="/Payroll"  class="rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Kembali</router-link>
        <form @submit.prevent="submitForm">
        <button type="submit" class="rounded-md bg-green-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Simpan</button>
        </form>
      </div>
      </div>
  
      <div v-if="uploading" class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50" style="animation: fadein 1s;">
        <div class="bg-white p-8 rounded-lg flex flex-col justify-center items-center  "  style="animation: muncul-kiri-kanan-fade 1s; ">
          <img src="../../assets/loading.gif" class="w-16 h-16 mb-6 " alt="">
          <p class="  font-semibold w-72 text-center" >Data Sedang diproses ...</p>
        </div>
      </div>


    <div v-if="successModal" class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50"  style="  animation: fadein 1s;">
    <div class="bg-white p-8 rounded-lg flex flex-col justify-center items-center  "  style="animation: muncul-kiri-kanan-fade 1s; ">
      <img src="../../assets/verify.png" class="w-16 h-16 mb-6 " alt="">
      <p>Data  Berhasil ditambahkan</p>
    </div>
  </div>

  <div v-if="previewUrl" class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50"  style="animation: muncul-kiri-kanan-fade 1s;" @click="closePreview">
      <iframe :src="previewUrl" class="flex items-center justify-center w-[100rem] h-[50rem]" @click="preventClose"></iframe>
    </div>

  <div v-if="errorModal" class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50" style="animation: fadein 1s;">
    <div class="bg-white p-8 rounded-lg flex flex-col justify-center items-center  "  style="animation: muncul-kiri-kanan-fade 1s; ">
      <img src="../../assets/cancel.png" class="w-16 h-16 mb-6 " alt="">
      <p class="  font-semibold w-72 text-center" >{{ message }}</p>
    </div>
  </div>

  </template>
  
  <script >
  import axios from "../../token";
  // import { PhotoIcon, UserCircleIcon } from '@heroicons/vue/24/solid'
  export default {
    data() {
      return {
        id: localStorage.getItem('id') || 'Default Value',
        isOpen: false,
        open: "",
      
        email: "",
        uploadedFileName: null,
      password: "",
      selectedMonth:this.$route.params.month, // Default to the current month
    selectedYear: this.$route.params.year, // Default to the current year
    // months: this.month,
    // years: this.year,
      // nik: this.userdata.name,
      workshift_id: "",
      workday_id: "",
      name: "",
      gender: "",
      position: "",
      department: "",
      role: '',
      religion: "",
      formattedNumber:'',
      birthPlace: "",
      birthDate: "",
      address: "",
      phone: "",
      nama:'',
      ktp: "",
      sim: "",
      passport: "",
      GajiKotor:"0",
      Lembur2:"0",
      Adjustment:"0",
      Bonus:"0",
      Lembur1:"0",
      Makan:"0",
      Transportasi:"0",
      Jabatan:"0",
     jkk :  '0',
     jkm :  '0',
     jkn_perusahaan : '0',
     bruto :  '0',
     telat:'0',
     absen:'0',
     loan_payment:'0',
     by_jabatan :  '0',
     jht_perusahaan :  '0',
     jkn_karyawan :  '0',
     netto :  '0',
     netto_setahun :  '0',
     ptkp :  '0',
     PokokBPJS:'0',
     pkp :  '0',
     ter :  '0',
     ter_rate :  '0',
     pph21_terhutang :  '0',
      gajipokok:"0",
      status:"",
      employeeStatus: 1,
      contractType: "",
      contractStart: "",
      contractEnd: "",
      bloodType: "",
      maritalStatus: "",
      children: "",
      accountStatus:1,
      npwp: "",
      bpjsHealth: "",
      bpjsEmployment: "",
      joinDate: "",
      appointmentDate: "",
      resignDate: "",
      description: "",
      successModal:false,
      errorModal:false,
      roledata:'',
      userdata:[],
      previewUrl: null,
      uploading:false,
      cekdata:[],
      iuran_jht:'0',
      categorydata:[],
        projectdata:[],
        photo:null,
      paydata:{},
      total_bpjs_kt:'0',
      jht:'0',
      jp_perusahaan:'0',
      jp_karyawan:'0',
      };
    },
    computed: {
      filteredEntries() {
        let filtered = this.absendata;
  
        if (this.selectedStatus.length > 0) {
          filtered = filtered.filter((abse) =>
            this.selectedStatus.includes(abse.status)
          );
        }
  
        if (this.selectedSort === "asc") {
          return filtered.sort((a, b) => a.jammasuk.localeCompare(b.jammasuk));
        } else {
          return filtered.sort((a, b) => b.jammasuk.localeCompare(a.jammasuk));
        }
      },
    //   formattedNumber() {
    //   // Check if Lembur1 is not null or undefined
    //   if (this.Lembur1 !== null && this.Lembur1 !== undefined) {
    //     // Convert number to string and format it using Intl.NumberFormat
    //     return new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(this.Lembur1);
    //   }
    //   // Return empty string if Lembur1 is null or undefined
    //   return '';
    // }

    },
    props: ['year', 'month'],
    methods: {
      toggleModal() {
        this.isOpen = !this.isOpen;
      },
      openModal() {
        this.isModalOpen = true;
      },
      closePreview() {
      this.previewUrl = null;
    },
      closeModal() {
        this.isModalOpen = false;
      },
      handleInput(event, propertyName, allowNegative = false) {
        // Mendapatkan nilai input tanpa karakter non-angka
        let numericValue;
        if (allowNegative) {
          numericValue = event.target.value.replace(/[^0-9-]/g, '');
        } else {
          numericValue = event.target.value.replace(/\D/g, '');
        }
        // Memformat nilai numerik sesuai dengan preferensi Anda
        const formattedValue = this.formatNumber(numericValue); // Anda perlu menentukan fungsi formatNumber sesuai preferensi Anda
        // Perbarui nilai properti dengan nilai yang diformat
        this[propertyName] = formattedValue;
      },
    formatNumber(value) {
      // Fungsi ini dapat Anda sesuaikan sesuai dengan preferensi Anda untuk format angka
      // Contoh: Tambahkan titik sebagai pemisah ribuan
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  
      handleFileUpload(event) {
      const fileInput = event.target;
      const files = fileInput.files;

    if (files.length > 0) {
      const file = files[0];
      this.uploadedFileName = file.name;
    }
    },
    getlaporan() {
    this.uploading = true; 
    axios
      .get(`${process.env.VUE_APP_BACKEND_HOST}/salary/paycheck`, {
        responseType: 'blob',
        params: {
          user_id:this.$route.params.userId,
          year: this.$route.params.year,
          month: this.$route.params.month,
        }
      })
      .then((response) => {
        this.uploading = false; 
        const blob = new Blob([response.data], { type: 'application/pdf' });
        this.previewUrl = window.URL.createObjectURL(blob);
      })
      .catch((error) => {
        this.uploading = false;
        this.message = error.response.data.message
        console.log(this.message)
    this.errorModal = true;
    setTimeout(() => {
      this.errorModal = false;
    }, 3000);
        console.error(error);
      });
  },
  previewReport() {
    this.getlaporan() 
  },

  exportReport() {
    if (this.previewUrl) {
      // Assuming you have a library or method to handle file downloads
      this.downloadFile(this.previewUrl, 'report.pdf');
    } else {
      this.getShift();
    }
  },

  downloadFile(url, filename) {
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;

    // Append the link to the body and trigger the click event
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
  },
    async submitcheck() {
      this.uploading = true; // Set the loading state to true
  try {
    const formData = new FormData();
    formData.append('user_id', this.$route.params.userId );
    formData.append('dependents', this.status);
    formData.append('pokok_bpjs', this.PokokBPJS.replace(/\./g, ''));
    formData.append('year', this.$route.params.year);
    formData.append('month',this.$route.params.month);
    formData.append('basic_salary', this.gajipokok.replace(/\./g, ''));
    formData.append('meal_allowance', this.Makan.replace(/\./g, ''));
    formData.append('position_allowance', this.Jabatan.replace(/\./g, ''));
    formData.append('transport_n_comm_allowance', this.Transportasi.replace(/\./g, ''));
    formData.append('thr_bonus_incentive', this.Bonus.replace(/\./g, ''));
    formData.append('adjustment', this.Adjustment.replace(/\./g, ''));
    formData.append('loan_payment', (this.loan_payment.replace(/[.]/g, '')).replace(/[,]/g, '.'));
  
    const response = await axios.post(`${process.env.VUE_APP_BACKEND_HOST}/salary/calculate`, formData);
    // Set the loading state to true
    // this.successModal = true;
    setTimeout(() => {
      this.uploading = false;
    }, 1000);
    
    console.log(response);
    this.cekdata= response.data;
    console.log(this.cekdata)

   
            const firstEntry = this.cekdata; 
            this.jkk = firstEntry.jkk || '';
            this.jkm = firstEntry.jkm || '';
            this.telat = firstEntry.late_deduction || '';
            this.absen = firstEntry.absence_deduction || '';
            this.iuran_jht = firstEntry.jkm || '';
            this.jkn_perusahaan = firstEntry.jkn_perusahaan || '';
            this.bruto = firstEntry.bruto || '';
            this.GajiKotor = firstEntry.bruto || '';
            this.by_jabatan = firstEntry.by_jabatan || '';
            this.jht_perusahaan = firstEntry.jht_perusahaan || '';
            this.jkn_karyawan = firstEntry.jkn_karyawan || '';
            this.jht = firstEntry.jht || '';
            this.jp_perusahaan = firstEntry.jp_perusahaan || '';
            this.jp_karyawan = firstEntry.jp_karyawan || '';
            this.total_bpjs_kt = firstEntry.total_bpjs_kt || '';
            this.total_bpjs_kes = firstEntry.total_bpjs_kes || '';
            this.bruto_pajak = firstEntry.bruto_pajak || '';
            this.netto = firstEntry.netto || '';
            this.netto_setahun = firstEntry.netto_setahun || '';
            this.ptkp = firstEntry.ptkp || '';
            this.pkp = firstEntry.pkp || '';
            this.ter = firstEntry.ter || '';
            this.Lembur2 = firstEntry.overtime_total || '';
            this.ter_rate = firstEntry.ter_rate || '0';
            this.pph21 = firstEntry.pph21 || '';
   
  } catch (error) {
    console.error(error);
    this.uploading = false;
    this.message = error.response.data.message;
    this.errorModal = true;
    setTimeout(() => {
      this.errorModal = false;
    }, 3000);
  }
},
async submitForm() {
  try {
    const formData = new FormData();
    formData.append('user_id', this.$route.params.userId );
    formData.append('dependents', this.status);
    formData.append('pokok_bpjs', this.PokokBPJS.replace(/\./g, ''));
    formData.append('year', this.selectedYear);
    formData.append('month', this.selectedMonth);
    formData.append('basic_salary', this.gajipokok.replace(/\./g, ''));
    formData.append('meal_allowance', this.Makan.replace(/\./g, ''));
    formData.append('position_allowance', this.Jabatan.replace(/\./g, ''));
    formData.append('transport_n_comm_allowance', this.Transportasi.replace(/\./g, ''));
    formData.append('thr_bonus_incentive', this.Bonus.replace(/\./g, ''));
    formData.append('adjustment', this.Adjustment.replace(/\./g, ''));
    formData.append('loan_payment', (this.loan_payment.replace(/[.]/g, '')).replace(/[,]/g, '.'));
  
    const response = await axios.post(`${process.env.VUE_APP_BACKEND_HOST}/salary/payroll`, formData);
    
    this.successModal = true;
    setTimeout(() => {
      this.successModal = false;
      this.$router.push('/Payroll');
    }, 3000);
    
    console.log(response);
    
  } catch (error) {
    console.error(error);
    this.message = error.response.data.message;
    this.errorModal = true;
    setTimeout(() => {
      this.errorModal = false;
    }, 3000);
  }
},
getuser() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/hrd/employee`,{
            params:{
             id:this.$route.params.userId 
            }
          })
          .then((response) => {
            this.userdata = response.data;
            if (this.userdata && this.userdata.data.length > 0) {
            const firstEntry = this.userdata.data[0]; 
            this.nama = firstEntry.user.name || '';
            this.status =  firstEntry.dependents  || '' ;
          this.gajipokok =  firstEntry.basic_salary || '' ;
          this.Jabatan =  firstEntry.position_allowance || '' ;
          this.Transportasi =  firstEntry.transport_n_comm_allowance || '' ;
          this.Makan =  firstEntry.meal_allowance || '' ;
          this.PokokBPJS =  firstEntry.pokok_bpjs || '' ;
          this.loan_payment =  firstEntry.loan_payment || '' ;
          }
            
          })
          .catch((error) => {
            console.log(error);
          });
      },
      getcheck() {
      
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/salary`,{
            params:{

             id:this.$route.params.id 
            }
          })
          .then((response) => {
            this.paydata = response.data;
            const firstEntry = this.paydata; 
            this.jkk = firstEntry.jkk || '';
            this.jkm = firstEntry.jkm || '';
            this.telat = firstEntry.late_deduction || '';
            this.absen = firstEntry.absence_deduction || '';
            this.iuran_jht = firstEntry.jkm || '';
            this.jkn_perusahaan = firstEntry.jkn_perusahaan || '';
            this.bruto = firstEntry.bruto || '';
            this.GajiKotor = firstEntry.bruto || '';
            this.by_jabatan = firstEntry.by_jabatan || '';
            this.jht_perusahaan = firstEntry.jht_perusahaan || '';
            this.jkn_karyawan = firstEntry.jkn_karyawan || '';
            this.jht = firstEntry.jht || '';
            this.jp_perusahaan = firstEntry.jp_perusahaan || '';
            this.jp_karyawan = firstEntry.jp_karyawan || '';
            this.total_bpjs_kt = firstEntry.total_bpjs_kt || '';
            this.total_bpjs_kes = firstEntry.total_bpjs_kes || '';
            this.bruto_pajak = firstEntry.bruto_pajak || '';
            this.netto = firstEntry.netto || '';
            this.netto_setahun = firstEntry.netto_setahun || '';
            this.ptkp = firstEntry.ptkp || '';
            this.pkp = firstEntry.pkp || '';
            this.ter = firstEntry.ter || '';
         
            this.ter_rate = firstEntry.ter_rate || '0';
            this.pph21 = firstEntry.pph21 || '';
            // this.nama = firstEntry.name || '';
            this.Lembur2 = firstEntry.overtime || '';
            this.gajipokok = firstEntry.basic_salary || '';
            this.loan_payment = firstEntry.loan || '';
            this.PokokBPJS = firstEntry.pokok_bpjs || '';
            this.Makan = firstEntry.meal_allowance || '';
            this.Jabatan = firstEntry.position_allowance || '';
            this.Transportasi = firstEntry.transport_n_comm_allowance || '';
            this.Bonus = firstEntry.thr_bonus_incentive || ''; 
            this.Adjustment = firstEntry.adjustment || '';
            this.status  = firstEntry.dependents || '';
            this.jkk = firstEntry.jkk || '';
            this.jkm = firstEntry.jkm || '';
            this.telat = firstEntry.late_deduction || '';
            this.absen = firstEntry.absence_deduction || '';
            this.iuran_jht = firstEntry.jkm || '';
            this.jkn_perusahaan = firstEntry.jkn_perusahaan || '';
            this.bruto = firstEntry.bruto || '';
            this.by_jabatan = firstEntry.by_jabatan || '';
            this.jht_perusahaan = firstEntry.jht_perusahaan || '';
            this.jkn_karyawan = firstEntry.jkn_karyawan || '';
            this.netto = firstEntry.netto || '';
            this.netto_setahun = firstEntry.netto * 12 || '';
            this.ptkp = firstEntry.ptkp || '';
            this.pkp = firstEntry.pkp || '';
            this.ter = firstEntry.ter || '';
            this.ter_rate = firstEntry.ter_rate || '0';
            this.pph21_terhutang = firstEntry.pph21_allowance || '';
            this.GajiKotor= firstEntry.bruto || '';
            this.jkk = firstEntry.jkk || '';
            this.jkm = firstEntry.jkm || '';
            this.telat = firstEntry.late_deduction || '';
            this.absen = firstEntry.absence_deduction || '';
            this.iuran_jht = firstEntry.jkm || '';
            this.jkn_perusahaan = firstEntry.jkn_perusahaan || '';
            this.bruto = firstEntry.bruto || '';
            this.by_jabatan = firstEntry.by_jabatan || '';
            this.jht_perusahaan = firstEntry.jht_perusahaan || '';
            this.jkn_karyawan = firstEntry.jkn_karyawan || '';
            this.jht = firstEntry.jht || '';
            this.jp_perusahaan = firstEntry.jp_perusahaan || '';
            this.jp_karyawan = firstEntry.jp_karyawan || '';
            this.total_bpjs_kt = firstEntry.total_bpjs_kt || '';
            this.total_bpjs_kes = firstEntry.total_bpjs_kes || '';
            this.bruto_pajak = firstEntry.bruto_pajak || '';
            this.netto = firstEntry.netto || '';
            this.netto_setahun = firstEntry.netto_setahun || '';
            this.ptkp = firstEntry.ptkp || '';
            this.pkp = firstEntry.pkp || '';
            this.ter = firstEntry.ter || '';
            this.ter_rate = firstEntry.ter_rate || '';
            this.pph21_terhutang = firstEntry.pph21_terhutang || '';
            
          })
          .catch(async () => {
            await this.getuser();
          });
      },
      getcategory() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/position`)
          .then((response) => {
            this.categorydata = response.data;
  
          })
          .catch((error) => {
            console.log(error);
          });
      },
      getrole() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/role`)
          .then((response) => {
            this.roledata = response.data;
  
          })
          .catch((error) => {
            console.log(error);
          });
      },
      getproject() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/department`)
          .then((response) => {
            this.projectdata = response.data;
  
          })
          .catch((error) => {
            console.log(error);
          });
      },
    },
    mounted(){
      this.uploading = true;
      this.getcategory();
      this.getproject();
      this.getrole();
      this.getcheck();
      setTimeout(() => {
      this.uploading = false;
    }, 1000);
    }
  };
  </script>