
<template>

<nav class="flex mb-6 font-pop" aria-label="Breadcrumb" style=" animation: muncul-kiri-kanan-fade 1s;"> 
  <ol class="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
    <li>
      <div class="flex items-center">
       
        <router-link to="/MR" class="ms-1 text-sm font-medium text-gray-700 hover:text-red-500 md:ms-2 dark:text-gray-400 dark:hover:text-white">Material Request</router-link>
      </div>
    </li>
    <li aria-current="page">
      <div class="flex items-center">
        <svg class="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4"/>
        </svg>
        <span class="ms-1 text-sm font-medium text-red-500 md:ms-2 dark:text-gray-400">Update Status Material Request</span>
      </div>
    </li>
  </ol>
</nav>

    <div class="font-pop">
    <div  class="space-y-12 bg-white md:mb-0 md:px-24 py-4 xxs:px-4 xxs:mb-16 rounded-xl" style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; animation: muncul-kiri-kanan-fade 1s;">
      <div class="border-b border-gray-900/10 pb-12">
        <h2 class="text-base text-[22px] font-semibold leading-7 text-gray-900 mt-4">Update Status Material Request</h2>
        <p class="mt-1 text-sm leading-6 text-gray-600">Lakukan Approval Data Material Request.</p>
        <div class="mt-6 grid xxs:grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-6">
          <div class="sm:col-span-full">
            <label for="project" class="block text-sm font-medium leading-6 text-gray-900">Project</label>
            <div class="mt-2">
              <select v-model="project" id="project" name="project" autocomplete="project-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled>
                <option value="" disabled selected>Pilih Project</option>
                <option v-for="data2 in projectdata2.data" :key="data2.id" :value="data2.id">{{ data2.name }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="sm:col-span-full mt-6">
            <label for="message" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Keterangan</label>
            <div class="mt-2">
            <textarea id="message" v-model="description" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Tambahkan Keterangan Jika Perlu..."></textarea>
            </div>
            </div>
           
            <div class="flex flex-row justify-end gap-8 mt-4">
              <router-link to="/MR"  class="rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Kembali</router-link>
              <div class=" " v-if="permidata.data && permidata.data.includes('Approval Material Request')">
                <button @click="handleButtonClick('rejected')" class="rounded-md mr-4 bg-red-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                Ditolak
              </button>

              <button @click="handleButtonClick('approved')" class="rounded-md bg-green-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                Approved
              </button>
            </div>
          
            </div>
    
      </div> 

  <div class="relative overflow-x-auto shadow-md sm:rounded-lg font-pop ">
    <table class="w-full text-[12px] text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead class="t text-gray-700  bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" class="px-6 py-3">
                    No
                </th>
                <th scope="col" class="px-6 py-3">
                    Material
                </th>
                <th scope="col" class="px-6 py-3">
                    Dekripsi
                </th>
                <th scope="col" class="px-6 py-3">
                    quantity
                </th>
                <th scope="col" class="px-6 py-3">
                    Units
                </th>
            </tr>
        </thead>
        <tbody>
            <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"  v-for="(entry, index) in mrdata.data" :key="index">
              <td class="px-6 py-4">
                    {{ index + 1 }}
                    </td>
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {{ entry.material.name }}
                </th>
                <td class="px-6 py-4">
                    {{ entry.description }}
                </td>
                <td class="px-6 py-4">
                    {{ entry.quantity }}
                </td>
                <td class="px-6 py-4">
                    {{ entry.material.unit_name }}
                </td>
                
           
            </tr>
           
        </tbody>
    </table>
</div>
</div>
  </div> 

  <div v-if="successModal" class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50"  style="  animation: fadein 1s;">
    <div class="bg-white p-8 rounded-lg flex flex-col justify-center items-center  "  style="animation: muncul-kiri-kanan-fade 1s; ">
      <img src="../../../assets/verify.png" class="w-16 h-16 mb-6 " alt="">
      <p>Status Berhasil Di Update</p>
    </div>
  </div>

  <div v-if="errorModal" class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50" style="animation: fadein 1s;">
    <div class="bg-white p-8 rounded-lg flex flex-col justify-center items-center  "  style="animation: muncul-kiri-kanan-fade 1s; ">
      <img src="../../../assets/cancel.png" class="w-16 h-16 mb-6 " alt="">
      <p class="  font-semibold w-72 text-center" >{{ message }}</p>
    </div>
  </div>

</template>






<script>
import axios from "../../../token";
import { ref } from 'vue';
import { setupLeaveConfirmation } from '../../../token/confirmLeave.js';




export default {
  data() {
    return {
      id: localStorage.getItem('id') || 'Default Value',
      isOpen: false,
      entries: [],
      open: "",
      project:this.$route.params.projectId,
      selectedMaterial: null,
      eom: [],
      Deskripsi: '',
      price:[],
      quantity: [],
      description:[],
      category: '',
      material:[],
      coo: [],
      BeratSatuan: '',
      dimension:[],
      Units: [],
      successModal:false,
      errorModal:false,
      status:"Proses Pengerjaan",
      categorydata:[],
      projectdata:[],
      projectdata2:[],
      userdata:[],
      mrdata:[],
      permidata:[],
      status1:'',
    };
  },
  setup() {
    const isHovered = ref(false);
    setupLeaveConfirmation();

    return {
     
      isHovered,
      setupLeaveConfirmation
      
    }
},
  computed: {
    filteredEntries() {
      let filtered = this.absendata;

      if (this.selectedStatus.length > 0) {
        filtered = filtered.filter((abse) =>
          this.selectedStatus.includes(abse.status)
        );
      }

      if (this.selectedSort === "asc") {
        return filtered.sort((a, b) => a.jammasuk.localeCompare(b.jammasuk));
      } else {
        return filtered.sort((a, b) => b.jammasuk.localeCompare(a.jammasuk));
      }
    },
  },
  methods: {
    material2() {
    this.material1 = this.project;
    console.log(this.material1)
    if (this.material1) {
      this.getuser()
    } else {
      this.material1 = '';
    }
  },
        unit() {
      const selectedMaterial1 = this.userdata.data.find(material => material.id === this.selectedMaterial.id);

      if (selectedMaterial1) {
        this.eom = selectedMaterial1.symbol;
      } else {
        this.eom = '';
      }
    },
  removeEntry(index) {
      this.mrdata.data.splice(index, 1);
    },
  editEntry(index) {
      const editedEntry = this.mrdata.data[index];
      this.selectedMaterial = editedEntry.material ? { id: editedEntry.material.id, name: editedEntry.material.name, dimension: editedEntry.material.dimension, symbol: editedEntry.material.unit_name } : null;
      this.description = editedEntry.description || '';
      this.quantity = editedEntry.quantity || '';   
      this.eom = editedEntry.material.unit_name || '';
      this.isEditMode = true;
      this.editedIndex = index
    },
    toggleModal() {
      this.isOpen = !this.isOpen;
    },
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    handleButtonClick(action) {
      console.log(`Button clicked: ${action}`);
      this.status1 = action;
      this.submitForm();
    },
//     submittemp() {

//       const emptyFields = [];


// if (!this.selectedMaterial || /^\s*$/.test(this.selectedMaterial)) {
//   emptyFields.push("Material");
// }

// if (!this.description || /^\s*$/.test(this.description)) {
//   emptyFields.push("Description");
// }

// if (!this.quantity || /^\s*$/.test(this.quantity)) {
//   emptyFields.push("Quantity");
// }

// // if (!this.eom || /^\s*$/.test(this.eom)) {
// //   emptyFields.push("EOM");
// // }



// if (emptyFields.length > 0) {

//   this.errorModal = true;
//   this.message = `Field ${emptyFields.join(", ")} Tidak Boleh Kosong.`;

//   setTimeout(() => {
//     this.errorModal = false;
//     this.open = false;
//   }, 3000);

//   return;
// }

// if (!this.isEditMode){
// const materialExists = this.mrdata.data.some(entry => entry.material.name === this.selectedMaterial.name);

// if (materialExists) {
//   this.errorModal = true;
//   this.message = 'Data Material Sudah Di Ajukan Permintaan';

//   setTimeout(() => {
//     this.errorModal = false;
//     this.open = false;
//   }, 3000);
//   this.resetForm();
  
//   return;
// }
// }


// const entryData = {
//     material: this.selectedMaterial ? { id: this.selectedMaterial.id, name: this.selectedMaterial.name, dimension:this.selectedMaterial.dimension, unit_name:this.selectedMaterial.symbol} : null,
//     description: this.description,
//     quantity: this.quantity,
   
//   };

//   if (this.isEditMode) {
//     this.mrdata.data[this.editedIndex] = { ...entryData };
   
//     this.isEditMode = false;

//   } else {
//     console.log(entryData)
//     this.mrdata.data.push(entryData);
//   }

//   this.resetForm();
//     },
    resetForm() {
    this.selectedMaterial=[];
    this.editedIndex = ''
    this.description = '';
    this.quantity = '';
  },
    async submitForm() {
      const formData = new FormData();

      formData.append('status',this.status1),
      formData.append('id',this.$route.params.id),
      formData.append('description',this.description)

      
      try {
        const response = await axios.post(`${process.env.VUE_APP_BACKEND_HOST}/material-request/approval/process`, formData);

        console.log(response);
      
        this.successModal = true;
    
      
        setTimeout(() => {
          this.successModal = false;
          this.open = false;
        }, 2000);
        setTimeout(() => {
        this.$router.push('/MR');
      }, 2000);
      } catch (error) {
        console.log(error);
        this.errorModal = true;
        setTimeout(() => {
          this.errorModal = false;
          this.open = false;
        }, 3000);
        this.message = error.response.data.message;
      }
    },
    getcategory() {
      axios
        .get(`${process.env.VUE_APP_BACKEND_HOST}/project-category`)
        .then((response) => {
          this.categorydata = response.data;

        })
        .catch((error) => {
          console.log(error);
        });
    },
    getuser() {
      axios
        .get(`${process.env.VUE_APP_BACKEND_HOST}/material`,{
          params:{
            project_id:this.$route.params.projectId 
          }
        })
        .then((response) => {
          this.userdata = response.data;

        })
        .catch((error) => {
          console.log(error);
        });
    },
    getpermi1() {
      axios
        .get(`${process.env.VUE_APP_BACKEND_HOST}/permission/check`)
        .then((response) => {
          this.permidata = response.data;

        
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getmr() {
      const materialId = this.$route.params.id;
      axios
        .get(`${process.env.VUE_APP_BACKEND_HOST}/material-request`,{
          params:{
            id:materialId
          }
        })
        .then((response) => {
          
          this.mrdata = response.data;
          if (this.mrdata && this.mrdata.data.length > 0) {
        const firstEntry = this.mrdata.data[0]; 
        this.description = firstEntry.approval_desc || '';
      }

        })
        .catch((error) => {
          console.log(error);
        });
    },
    getproject2() {
      axios
        .get(`${process.env.VUE_APP_BACKEND_HOST}/project/list`)
        .then((response) => {
          this.projectdata2 = response.data;

        })
        .catch((error) => {
          console.log(error);
        });
    },
    getproject() {
      axios
        .get(`${process.env.VUE_APP_BACKEND_HOST}/unit`)
        .then((response) => {
          this.projectdata = response.data;

        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted(){
    this.getcategory();
    this.getproject2();
    this.getproject();
    this.getuser();
    this.getmr();
    this.getpermi1();
  }
};
</script>
