
<template>

<nav class="flex mb-6 font-pop" aria-label="Breadcrumb" style=" animation: muncul-kiri-kanan-fade 1s;"> 
  <ol class="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
    <li>
      <div class="flex items-center">
       
        <router-link to="/User" class="ms-1 text-sm font-medium text-gray-700 hover:text-red-500 md:ms-2 dark:text-gray-400 dark:hover:text-white">Pengguna</router-link>
      </div>
    </li>
    <li aria-current="page">
      <div class="flex items-center">
        <svg class="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4"/>
        </svg>
        <span class="ms-1 text-sm font-medium text-red-500 md:ms-2 dark:text-gray-400">Tambah karyawan</span>
      </div>
    </li>
  </ol>
</nav>

      <form @submit.prevent="submitForm">
      <div class="space-y-12 bg-white md:mb-0 md:px-32 py-4 xxs:px-4 xxs:mb-16 rounded-xl" style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;  animation: muncul-kiri-kanan-fade 1s;">
        <div class="border-b border-gray-900/10 pb-12">
          <h2 class="text-base text-[22px] font-semibold leading-7 text-gray-900 mt-4">Tambah Karyawan</h2>
          <p class="mt-1 text-sm leading-6 text-gray-600">Lakukan Penginputan Data Karyawan Baru.</p>
          <div class="border-b border-gray-900/10 pb-12 mb-6">
           
          <div class="mt-14 grid xxs:grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-6">

            <div class="sm:col-span-full">
            <h1 class="font-pop font-medium ">Informasi Karyawan</h1>
            </div>
            <div class="sm:col-span-2">
              <label for="first-name" class="block text-sm font-medium leading-6 text-gray-900">Nomor Induk Karyawan</label>
              <div class="mt-2">
                <input v-model="nik" type="text" name="first-name" id="first-name" autocomplete="given-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>
  
            <div class="sm:col-span-2">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Email</label>
              <div class="mt-2">
                <input  v-model="email" type="email" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>

            

            <div class="sm:col-span-2">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Nama Lengkap</label>
              <div class="mt-2">
                <input v-model="name" type="text" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>

            <div class="sm:col-span-2">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Kata Sandi</label>
              <div class="mt-2">
                <input v-model="password" type="password" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>

            <div class="sm:col-span-1">
              <label for="country" class="block text-sm font-medium leading-6 text-gray-900">Jenis Kelamin</label>
              <div class="mt-2">
                <select v-model="gender" id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                  <option value="" disabled selected>Jenis Kelamin</option>
                  <option value="L">Laki - Laki</option>
                  <option value="P">Perempuan</option>
                </select>
              </div>
            </div>

            <!-- <div class="sm:col-span-0">
              <label for="region" class="block text-sm font-medium leading-6 text-gray-900">Tempat</label>
              <div class="mt-2">
                <input v-model="birthPlace" type="text" name="region" id="region" autocomplete="address-level1" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>

            <div class="sm:col-span-1">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Tanggal Lahir</label>
              <div class="mt-2">
                <input v-model="birthDate" type="date" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div> -->

            <div class="sm:col-span-2">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Alamat Lengkap</label>
              <div class="mt-2">
                <input v-model="address" type="text" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>

            <div class="sm:col-span-1">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">No HP</label>
              <div class="mt-2">
                <input  v-model="phone" type="text" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>

            <!-- <div class="sm:col-span-1">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">No KTP</label>
              <div class="mt-2">
                <input v-model="ktp" type="text" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>

            <div class="sm:col-span-1">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">No SIM</label>
              <div class="mt-2">
                <input v-model="sim" type="text" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>

            <div class="sm:col-span-1">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">No Paspor</label>
              <div class="mt-2">
                <input v-model="passport" type="text" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>

            <div class="sm:col-span-1">
              <label for="country" class="block text-sm font-medium leading-6 text-gray-900">Agama</label>
              <div class="mt-2">
                <select v-model="religion" id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                  <option value="" disabled selected>Pilih Agama</option>
                  <option>Islam</option>
                  <option>Kristen</option>
                  <option>Buddha</option>
                </select>
              </div>
            </div>

            <div class="sm:col-span-2">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Golongan Darah</label>
              <div class="mt-2">
                <select v-model="bloodType" id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                  <option value="" disabled selected>Pilih Golongan Darah</option>
                  <option>A</option>
                  <option>O</option>
                  <option>AB</option>
                </select>
              </div>
            </div>

            <div class="sm:col-span-2">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Status Kawin</label>
              <div class="mt-2">
                <select v-model="maritalStatus" id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                  <option value="" disabled selected>Pilih Status Kawin</option>
                  <option>Kawin</option>
                  <option>Duda</option>
                  <option>Janda</option>
                </select>
              </div>
            </div>

            <div class="sm:col-span-1">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Jumlah Anak</label>
              <div class="mt-2">
                <input v-model="children" type="text" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>

            <div class="sm:col-span-1">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">No. BPJS</label>
              <div class="mt-2">
                <input v-model="bpjsHealth" type="text" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div> -->

            <div class="sm:col-span-1">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Status Karyawan</label>
              <div class="mt-2">
                <select v-model="employeeStatus" id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                  <option value="" disabled selected>Pilih Status Karyawan</option>
                  <option value="1">Aktif</option>
                  <option value="0">TIdak</option>
                </select>
              </div>
            </div>

            <div class="sm:col-span-1">
              <label for="country" class="block text-sm font-medium leading-6 text-gray-900">Role</label>
              <div class="mt-2">
                <select   v-model="role" id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                  <option value="" disabled selected>Pilih Role</option>
                  <option
                    v-for="project in roledata.data"
                    :key="project.id"
                    :value="project.id"
            >
              {{ project.name }}
            </option>
                </select>
              </div>
            </div>

            <div class="sm:col-span-2">
              <label for="country" class="block text-sm font-medium leading-6 text-gray-900">Departement</label>
              <div class="mt-2">
                <select   v-model="department" id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                  <option value="" disabled selected>Pilih Departement</option>
                  <option
                    v-for="categoory in projectdata.data"
                    :key="categoory.id"
                    :value="categoory.id"
            >
              {{ categoory.name }}
            </option>
                </select>
              </div>
            </div>

            <div class="sm:col-span-1">
              <label for="country" class="block text-sm font-medium leading-6 text-gray-900">Jabatan</label>
              <div class="mt-2">
                <select   v-model="position" id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                  <option value="" disabled selected>Pilih Jabatan</option>
                  <option
                    v-for="project in categorydata.data"
                    :key="project.id"
                    :value="project.id"
            >
              {{ project.name }}
            </option>
                </select>
              </div>
            </div>

            <!-- <div class="sm:col-span-1">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Tanggal Gabung</label>
              <div class="mt-2">
                <input v-model="joinDate" type="date" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>

            <div class="sm:col-span-1">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Tanggal Pengakatan</label>
              <div class="mt-2">
                <input v-model="appointmentDate" type="date" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>

            <div class="sm:col-span-1">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Tanggal Berhenti</label>
              <div class="mt-2">
                <input v-model="resignDate" type="date" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div> -->

            <!-- <div class="sm:col-span-1">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">NIK Atasan</label>
              <div class="mt-2">
                <input v-model="gender" type="text" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div> -->


            <div class="sm:col-span-1">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Jenis Ikatan Kerja</label>
              <div class="mt-2">
                <select v-model="contractType" id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                  <option value="" disabled selected>Pilih Ikatan Kerja</option>
                  <option value="tetap">Karyawan Tetap</option>
                  <option value="kontrak">Kontrak</option>
                </select>
              </div>
            </div>

            <div class="sm:col-span-1">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Mulai Kontrak</label>
              <div class="mt-2">
                <input v-model="contractStart" type="date" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>

            <div class="sm:col-span-1">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Selesai Kontrak</label>
              <div class="mt-2">
                <input v-model="contractEnd" type="date" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>


            </div>
            </div>

            <div class="border-b border-gray-900/10 pb-12 mb-6">
              <div class="mt-4 grid xxs:grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-6">

                <div class="sm:col-span-full">
                  <h1 class="font-pop font-medium">Informasi Gaji</h1>
                  </div>
                  <div class="sm:col-span-2">
            <label for="project" class="block text-sm font-medium leading-6 text-gray-900">Status</label>
            <div class="mt-2">
              <select v-model="status"  id="project" name="project" autocomplete="project-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" >
                <option value="" disabled selected>Pilih Status</option>
                <option   value="TK/0">TK/0</option>
                <option   value="TK/1">TK/1</option>
                <option   value="TK/2">TK/2</option>
                <option   value="TK/3">TK/3</option>
                <option   value="K/0">K/0</option>
                <option  value="K/1">K/1</option>
                <option  value="K/2">K/2</option>
                <option  value="K/3">K/3</option>
              </select>
            </div>
          </div>

             <div class="sm:col-span-2">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Gaji Pokok</label>
              <div class="mt-2">
                <input  v-model="gajipokok" type="text" @input="handleInput($event, 'gajipokok')" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"  />
              </div>
            </div>

            

            <div class="sm:col-span-2">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">T.Jabatan</label>
              <div class="mt-2">
                <input v-model="d" type="text" @input="handleInput($event, 'Jabatan')" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"  />
              </div>
            </div>

            <div class="sm:col-span-2">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">T.Transportasi & Telepon</label>
              <div class="mt-2">
                <input v-model="Transportasi" type="text" @input="handleInput($event, 'Transportasi')" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"  />
              </div>
            </div>

            <div class="sm:col-span-2">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">T.Makan</label>
              <div class="mt-2">
                <input v-model="Makan" type="text" @input="handleInput($event, 'Makan')"  name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"  />
              </div>
            </div>

            <div class="sm:col-span-2">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Pokok untuk Hitung BPJS</label>
              <div class="mt-2">
                <input v-model="PokokBPJS" type="text" @input="handleInput($event, 'PokokBPJS')"  name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"  />
              </div>
            </div>
                </div>
              </div>

              <div class="border-b border-gray-900/10 pb-12 mb-6">
              <div class="mt-4 grid xxs:grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-6">

                <div class="sm:col-span-full">
                  <h1 class="font-pop font-medium">Dokumen</h1>
                  </div>

                  <div class="sm:col-span-full">
                      <div class="">
                        <input
                          ref="berkas"
                          id="berkas"
                          @change="handleFileChange"
                          type="file"
                          multiple
                          autocomplete="qty"
                          class="block w-full rounded-md border-0  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                  </div>

                 
                  
                </div>
                <div v-if="selectedFiles.length > 0" class="border-dashed border-2  border-gray-400 rounded-lg grid grid-cols-3   p-2 mt-4 gap-3">
                    <div v-for="(file, index) in selectedFiles" :key="index">
                      <img :src="file.url" :alt="file.name" class="rounded-lg shadow-lg w-44 h-44" />
                      <button @click="removeFile(index)" class="flex justify-center items-center mt-2 px-3 py-1 bg-red-500 text-white rounded-lg">Hapus</button>
                    </div>
                  </div>
              </div>
            

            <!-- <div class="sm:col-span-1">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Mulai Kontrak</label>
              <div class="mt-2">
                <input v-model="contractStart" type="date" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>

            <div class="sm:col-span-1">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Selesai Kontrak</label>
              <div class="mt-2">
                <input v-model="contractEnd" type="date" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div> -->

  
            <!-- <div class="col-span-full">
              <label for="about" class="block text-sm font-medium leading-6 text-gray-900">Bio Karyawan</label>
              <div class="mt-2">
                <textarea id="about" name="about" rows="3" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
              <p class="mt-3 text-sm leading-6 text-gray-600">Bio Karyawan Opsional Boleh diisi boleh tidak</p>
            </div> -->
  
           
            <div class="col-span-full">
                    <h1 class="font-pop font-medium mb-4">Upload Photo</h1>
                    <label for="dropzone-file" class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                    <div class="flex flex-col items-center justify-center pt-5 pb-6">
                        <svg   v-if="!uploadedFileName" class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
                        </svg>
                        <img v-if="uploadedFileName" class="w-12 h-12 mb-10" src="../../assets/picture.png" alt="">
                        <p v-if="uploadedFileName" class="text-[14px] text-gray-500 dark:text-gray-400">{{ uploadedFileName }}</p>
                        <p v-if="!uploadedFileName" class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Klik Untuk Upload</span> Atau Tarik dan Lepas</p>
                        <p v-if="!uploadedFileName" class="text-xs text-gray-500 dark:text-gray-400">JPG And PNG Only</p>
                    </div>
                    <input id="dropzone-file" ref="fileInput" type="file" class="hidden" @change="handleFileUpload" />
                </label>
                   
                  </div>
             
                </div>
              
          
          

      
      
    
      
       
    
    
    
       

        <div class="mt-6 flex items-center justify-end gap-x-6">
        <router-link to="/HumanResource"  class="rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Kembali</router-link>
        <button type="submit" class="rounded-md bg-green-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Simpan</button>
      </div>
      </div>
  
    
    </form>

    <div v-if="successModal" class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50"  style="  animation: fadein 1s;">
    <div class="bg-white p-8 rounded-lg flex flex-col justify-center items-center  "  style="animation: muncul-kiri-kanan-fade 1s; ">
      <img src="../../assets/verify.png" class="w-16 h-16 mb-6 " alt="">
      <p>Data Karyawan Berhasil ditambahkan</p>
    </div>
  </div>

  <div v-if="errorModal" class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50" style="animation: fadein 1s;">
    <div class="bg-white p-8 rounded-lg flex flex-col justify-center items-center  "  style="animation: muncul-kiri-kanan-fade 1s; ">
      <img src="../../assets/cancel.png" class="w-16 h-16 mb-6 " alt="">
      <p class="  font-semibold w-72 text-center" >{{ message }}</p>
    </div>
  </div>

  </template>
  
  <script >
  import axios from "../../token";
  // import { PhotoIcon, UserCircleIcon } from '@heroicons/vue/24/solid'
  export default {
    data() {
      return {
        id: localStorage.getItem('id') || 'Default Value',
        isOpen: false,
        open: "",
        email: "",
        uploadedFileName: null,
      password: "",
      nik: "",
      workshift_id: "",
      workday_id: "",
      name: "",
      gender: "",
      position: "",
      department: "",
      role: '',
      selectedFiles:[],
      religion: "",
      birthPlace: "",
      birthDate: "",
      Transportasi:'',
      Jabatan:'',
      Makan:'',
      gajipokok:'',
      PokokBPJS:'',
      status:'',

      address: "",
      phone: "",
      ktp: "",
      sim: "",
      passport: "",
      employeeStatus: 1,
      contractType: "",
      contractStart: "",
      contractEnd: "",
      bloodType: "",
      maritalStatus: "",
      children: "",
      accountStatus:1,
      npwp: "",
      bpjsHealth: "",
      bpjsEmployment: "",
      joinDate: "",
      appointmentDate: "",
      resignDate: "",
      description: "",
      successModal:false,
      errorModal:false,
      roledata:'',
      categorydata:[],
        projectdata:[],
        photo:null,
      
      };
    },
    computed: {
      filteredEntries() {
        let filtered = this.absendata;
  
        if (this.selectedStatus.length > 0) {
          filtered = filtered.filter((abse) =>
            this.selectedStatus.includes(abse.status)
          );
        }
  
        if (this.selectedSort === "asc") {
          return filtered.sort((a, b) => a.jammasuk.localeCompare(b.jammasuk));
        } else {
          return filtered.sort((a, b) => b.jammasuk.localeCompare(a.jammasuk));
        }
      },
    },
    methods: {
      toggleModal() {
        this.isOpen = !this.isOpen;
      },
      openModal() {
        this.isModalOpen = true;
      },
      removeFile(index) {
    this.selectedFiles.splice(index, 1);
  },
  handleFileChange(event) {
  const input = event.target;
  console.log("Selected files:", input.files);
  if (input.files.length > 0) {
    for (let i = 0; i < input.files.length; i++) {
      const selectedFile = input.files[i];
      // Check file extension
      const fileNameParts = selectedFile.name.split('.');
      const fileExtension = fileNameParts[fileNameParts.length - 1].toLowerCase();
      if (fileExtension !== 'png' && fileExtension !== 'jpeg' && fileExtension !== 'jpg') {
        console.log("File", selectedFile.name, "is not a valid PNG, JPEG, or JPG file. Skipping...");
        this.message = 'Jenis File Harus  PNG, JPEG, or JPG file';
    this.errorModal = true;
    setTimeout(() => {
      this.errorModal = false;
      this.open = false;
    }, 3000);
        continue; // Skip this file
      }
      console.log("Processing file:", selectedFile.name);
      // Create a new FileReader object to read file content as an array buffer
      const reader = new FileReader();
      reader.onload = () => {
        // Push file metadata along with its binary content to selectedFiles array
        this.selectedFiles.push({
          name: selectedFile.name,
          url: URL.createObjectURL(selectedFile),
          type: selectedFile.type,
          file: selectedFile,
          size: selectedFile.size,
          data: reader.result // Binary data (array buffer) of the file
        });
      };
      // Start reading the file as an array buffer
      reader.readAsArrayBuffer(selectedFile);
    }
    // Clear the file input's value after processing all selected files
    input.value = '';
  }
},
handleInput(event, propertyName) {
      // Mendapatkan nilai input tanpa karakter non-angka
      const numericValue = event.target.value.replace(/\D/g, '');
      // Memformat nilai numerik sesuai dengan preferensi Anda
      const formattedValue = this.formatNumber(numericValue); // Anda perlu menentukan fungsi formatNumber sesuai preferensi Anda
      // Perbarui nilai properti dengan nilai yang diformat
      this[propertyName] = formattedValue;
    },
    formatNumber(value) {
      // Fungsi ini dapat Anda sesuaikan sesuai dengan preferensi Anda untuk format angka
      // Contoh: Tambahkan titik sebagai pemisah ribuan
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

  removeEntry(index) {
      this.entries.splice(index, 1);
    },
      closeModal() {
        this.isModalOpen = false;
      },
      handleFileUpload(event) {
      const fileInput = event.target;
      const files = fileInput.files;

    if (files.length > 0) {
      const file = files[0];
      this.uploadedFileName = file.name;
    }
    },
    async submitForm() {
  try {
    const formData = new FormData();
    formData.append('email', this.email);
    formData.append('password', this.password);
    formData.append('nik', this.nik);
    formData.append('name', this.name);
    formData.append('gender', this.gender);
    formData.append('position', this.position);
    formData.append('department', this.department);
    formData.append('role', this.role);
    formData.append('religion', this.religion);
    formData.append('address', this.address);
    formData.append('contractEnd', this.contractEnd);
    formData.append('description', this.description);
    formData.append('phone', this.phone);
    formData.append('employeeStatus', this.employeeStatus);
    formData.append('contractType', this.contractType);
    formData.append('accountStatus', this.accountStatus);
    formData.append('dependents', this.status);
    formData.append('pokok_bpjs', this.PokokBPJS.replace(/\./g, ''));
    formData.append('basic_salary', this.gajipokok.replace(/\./g, ''));
    formData.append('meal_allowance', this.Makan.replace(/\./g, ''));
    formData.append('position_allowance', this.Jabatan.replace(/\./g, ''));
    formData.append('transport_n_comm_allowance', this.Transportasi.replace(/\./g, ''));

    if (this.$refs.fileInput.files.length > 0) {
      formData.append('photo', this.$refs.fileInput.files[0]);
    }
    this.selectedFiles.forEach(file => {
      formData.append('document[]', file.file); // Assuming `file` is a Blob or File object
    });

    const response = await axios.post(`${process.env.VUE_APP_BACKEND_HOST}/hrd/employee`, formData);
    
    this.successModal = true;
    setTimeout(() => {
      this.successModal = false;
      location.reload();
    }, 3000);
    
    console.log(response);
  } catch (error) {
    console.error(error);
    this.message = error.response.data.message;
    this.errorModal = true;
    setTimeout(() => {
      this.errorModal = false;
    }, 3000);
  }
},
      getcategory() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/position`)
          .then((response) => {
            this.categorydata = response.data;
  
          })
          .catch((error) => {
            console.log(error);
          });
      },
      getrole() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/role`)
          .then((response) => {
            this.roledata = response.data;
  
          })
          .catch((error) => {
            console.log(error);
          });
      },
      getproject() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/department`)
          .then((response) => {
            this.projectdata = response.data;
  
          })
          .catch((error) => {
            console.log(error);
          });
      },
    },
    mounted(){
      this.getcategory();
      this.getproject();
      this.getrole();
    }
  };
  </script>