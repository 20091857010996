<template>
   <div class="flex justify-center items-center gap-4 fopnt-pop" style="  animation: muncul-kiri-kanan-fade 0.5s;">
   
    <button
      class="w-full   px-3 py-2 mr-4 rounded-lg text-white bg-red-500"
      @click="open = true"
    >
      <div class="flex  flex-row justify-center font-pop">
        <img src="../gambar/tambah.svg" class="mr-1" alt="" />
        <h3 class="text-[12px] font-medium">Tambah Permohonan</h3>
      </div>
    </button>

  </div>  

  <transition>
    <div
      class="fixed z-10 left-0 top-0 w-full h-full flex items-center justify-center overflow-auto bg-black/40 bg-opacity-50 font-pop font-regular"
      v-if="open"
    >
      <div class="min-w-[1000px] bg-[#fff] px-20 py-10 rounded-xl">
      
          <div class="mb-8">
            <h1 class="text-[20px] mb-4">Pengajuan Perizinan</h1>
            <hr class="border-gray-300" />
          </div>
         
          <div class="sm:col-span-full mb-4">
                <label for="country" class="block  font-medium leading-6 text-gray-900">Nama Karyawan</label>
                <div class="mt-2">
                  <select   v-model="name"  id="country" name="country" autocomplete="country-name" class="block min-w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6" >
                    <option value="" disabled selected>Pilih Karyawan</option>
                    <option
                      v-for="data1 in userdata.data"
                      :key="data1.id"
                      :value="data1.id"
              >
                {{ data1.user.name }}
              </option>
                  </select>
                </div>
              </div>
          <div class="flex flex-row justify-between">

         

            <div class="flex flex-col">
              <label for="request">Request</label>
              <select
                id="request"
                class="w-[16.3rem] max-w-full mt-4 mb-4 py-2 border-gray-300 rounded-md"
                v-model="permohonan">
                <option selected>Choose a Request</option>
                <option value="sakit">Sakit</option>
                <option value="cuti">Cuti</option>
                <option value="izin">Izin</option>
              </select>
            </div>
            <div class="flex flex-col">
              <label for="">Mulai Pengajuan</label>
              <input
                class="w-[16.3rem] max-w-full  mt-4 mb-4 py-2 border-gray-300 rounded-md"
                type="date"
                name="datetime"
                id="datetime"
                v-model="mulaipengajuan"
              />
            </div>
            <div class="flex flex-col">
              <label for="">Selesai Pengajuan</label>
              <input
                class="w-[16.3rem] max-w-full  mt-4 mb-4 py-2 border-gray-300 rounded-md"
                type="date"
                name="datetime"
                id="datetime"
                v-model="selesaipengajuan"
              />
            </div>
          </div>
          <div class="flex flex-row justify-between">
            <div class="flex flex-col">
              <label for="">Keterangan</label>
              <input
                class="w-[16.3rem] max-w-full mt-4 mb-4 py-2 border-gray-300 rounded-md"
                type="text"
                name="NomorInduk"
                id="NomorInduk"
                v-model="keterangan"
              />
            </div>
            <div class="flex flex-col mr-2">
              <label class="underline cursor-pointer hover:text-red-500" for="berkas" >Upload file</label>
              <h1 class="w-[16.3rem] max-w-full  mt-4 mb-4 py-2 h-[42px]  border-gray-300 rounded-md border overflow-hidden">
                {{ selectedFileName }}
              </h1>
              <input
                class="hidden"
                aria-describedby="file_input_help"
                ref="berkas"
                id="berkas"
                type="file"
                @change="handleFileChange"
              />
              <p
                class="mt-1 text-sm text-gray-500 dark:text-gray-300"
                id="file_input_help"
              >
                PDF, PNG, JPG or DOCS (MAX. 800x400px).
              </p>
            </div>
            <div class="flex flex-col">
              <li class="mt-8 text-[14px] font-medium">
                Upload file untuk bagi karyawan <br />
                yang mempunyai surat MC sakit
              </li>
            </div>
          </div>
          <div class="flex justify-end">
            <button
              class="mt-5 bg-red-500 px-5 py-3 rounded-md"
              @click="open = false"
            >
              <h1 class="text-[12px] text-white">Kembali</h1>
            </button>
            <form @submit.prevent="submitForm">
            <button
              class="ml-4 mt-5 bg-[#82C97C] px-[6rem] py-3 rounded-md"
            type="submit"
            >
              <h1 class="text-[12px] text-white">Simpan</h1>
            </button>
          </form>
          </div>
     
      </div>
    </div>
  </transition>

  <div v-if="successModal" class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50"  style="  animation: fadein 1s;">
    <div class="bg-white p-8 rounded-lg flex flex-col justify-center items-center  "  style="animation: muncul-kiri-kanan-fade 1s; ">
      <img src="../../assets/verify.png" class="w-16 h-16 mb-6 " alt="">
      <p>Data Permohonan Berhasil Diajukan</p>
    </div>
  </div>

  <div v-if="errorModal" class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50" style="animation: fadein 1s;">
    <div class="bg-white p-8 rounded-lg flex flex-col justify-center items-center  "  style="animation: muncul-kiri-kanan-fade 1s; ">
      <img src="../../assets/cancel.png" class="w-16 h-16 mb-6 " alt="">
      <p class="  font-semibold w-72 text-center" >{{ message }}</p>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
export default {
  data() {
    return {
      open: false,
      Nama:'',
      NomorInduk:"",
      successModal:false,
      errorModal:false,
      posisiId:"",
      departementId:"",
      divisiId:"",
      manager:"",
      userdata:[],
      permohonan:'',
      mulaipengajuan:new Date().toISOString().substr(0, 10),
      selesaipengajuan:new Date().toISOString().substr(0, 10),
      keterangan:"",
      selectedFileName: "",
      berkas:"",
      status:"Pending",
    };
  },
  
  created() {
    // Saat komponen dimuat, atur nilai awal untuk selectedDatetime
    const initialDatetime = new Date().toISOString().slice(0, 16); // Format YYYY-MM-DDTHH:mm
    this.selectedDatetime = initialDatetime;
  }, methods: {
    handleFileChange(event) {
      const input = event.target;
      if (input.files.length > 0) {
        // Mengambil nama file dari input
        this.selectedFileName = input.files[0].name;
      } else {
        // Jika tidak ada file yang dipilih, reset nama file
        this.selectedFileName = "";
      }
    },
    async submitForm() {
      try {
        const file = await this.$refs.berkas.files[0];
        const formData = new FormData();
        formData.append("user_id", this.name);
        formData.append("type", this.permohonan);
        formData.append("start_date", this.mulaipengajuan);
        formData.append("end_date", this.selesaipengajuan);
        formData.append("reason", this.keterangan);
        if (file){
        formData.append("file", file);
        }
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        const response = await axios.post(
          `${process.env.VUE_APP_BACKEND_HOST}/employee-request`,
          formData,
          config
        );
        console.log(response);
        
        this.successModal = true;
        setTimeout(() => {
          this.successModal = false;
          this.open = false;
          window.location.reload();
        }, 3000);
      } catch (error) {
          console.log(error);
        this.message = error.response.data.message;
        this.errorModal = true;
      setTimeout(() => {
        this.errorModal = false;
      }, 3000);
      }
    },
    handleChangeDate(event) {
   
   const selectedDate = event.target.value;
   
 
   this.selectedTanggal = selectedDate;
   this.getPermohonan(this.selectedTanggal)
 
 },
    getuser() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/hrd/employee`)
          .then((response) => {
            this.userdata = response.data;
  
          })
          .catch((error) => {
            console.log(error);
          });
      },

  },
    mounted(){
      this.getuser()
  }
};
</script>
<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
