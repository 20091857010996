<template>

    <div class="md:flex md:flex-row md:justify-between xxs:hidden" >
   <div class="relative text-gray-600">
 <input type="text" name="search" v-model="search" @input="getshift" placeholder="Pencarian..." class="bg-[#FAFBFD] h-10 px-5 w-64 rounded text-sm focus:outline-none">
 <button type="submit" class="absolute right-0 top-0 mt-3 mr-4">
   <svg class="h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 56.966 56.966" style="enable-background:new 0 0 56.966 56.966;" xml:space="preserve" width="512px" height="512px">
     <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z"/>
   </svg>
 </button>
</div>

<!-- <div class=""  v-if="permidata.data && permidata.data.includes('Pinjaman')">
 <transition name="fade-in">
 
 <button
     class="w-full h-[30px] p-1 mr-4 rounded text-white bg-red-500 font-pop"
     @click="open = true"
   >
     <div class="flex flex-row justify-center">
       <img src="../assets/add.png" class="mr-1 w-5" alt="" />
       <h3 class="text-[12px] font-medium">Tambah Pinjaman</h3>
     </div>
   </button>

</transition> -->
   <!-- <button
     class="w-[118px] h-[30px] flex-shrink-0 rounded text-[#BBBBBB] border border-[#BBBBBB]"  @click="toggleModal"
   >
     <div class="flex flex-row justify-center">
       <img src="../assets/filter.png" class="mr-1 w-5" alt="" />
       <h3 class="text-[12px] text-[#565656] font-medium">Filter & Short</h3>
     </div>
   </button> -->

<!-- </div> -->

 </div>



   <div
     v-if="isOpen"
     class="absolute flex items-center justify-end z-10 top-[14rem] right-0 px-32 font-pop"
   >
     <div class="modal bg-white rounded-lg shadow-lg p-4 w-52">
     
       <div class="mb-4">
         <label class="block text-sm font-medium text-gray-700"
           >Filter by Category:</label
         >
         <div class="mt-1">
           <label for="cuti" class="flex items-center cursor-pointer">
             <input
               type="checkbox"
               id="cuti"
               v-model="selectedStatus"
               value="Hadir"
               class="h-5 w-5 text-blue-500 rounded-md border-gray-300 focus:ring-blue-400"
             />
             <span class="ml-2 text-sm text-gray-700">Kapal Tanker</span>
           </label>
           <label for="sakit" class="flex items-center cursor-pointer">
             <input
               type="checkbox" 
               id="sakit"
               v-model="selectedStatus"
               value="Tidak Hadir"
               class="h-5 w-5 text-blue-500 rounded-md border-gray-300 focus:ring-blue-400"
             />
             <span class="ml-2 text-sm text-gray-700">Kapal Ferry</span>
           </label>
           <label for="izin" class="flex items-center cursor-pointer">
             <input
               type="checkbox"
               id="izin"
               v-model="selectedStatus"
               value="Cuti"
               class="h-5 w-5 text-blue-500 rounded-md border-gray-300 focus:ring-blue-400"
             />
             <span class="ml-2 text-sm text-gray-700">Kapal Persiar</span>
           </label>
         </div>
       </div>

      
       <div class="mb-4">
         <label class="block text-sm font-medium text-gray-700"
           >Sort by:</label
         >
         <div class="mt-1">
           <label for="asc" class="flex items-center cursor-pointer">
             <input
               type="radio"
               id="asc"
               v-model="selectedSort"
               value="asc"
               class="h-5 w-5 text-blue-500 rounded-full border-gray-300 focus:ring-blue-400"
             />
             <span class="ml-2 text-sm text-gray-700">Ascending</span>
           </label>
           <label for="desc" class="flex items-center cursor-pointer">
             <input
               type="radio"
               id="desc"
               v-model="selectedSort"
               value="desc"
               class="h-5 w-5 text-blue-500 rounded-full border-gray-300 focus:ring-blue-400"
             />
             <span class="ml-2 text-sm text-gray-700">Descending</span>
           </label>
         </div>
       </div>
     </div>
   </div>

   <div class="grid md:grid-cols-4 gap-10 justify-between items-center xxs:grid-cols-1 font-pop mt-10 w-full">
    
 
     <div v-for="shift in shiftdata.data" :key="shift.id"   class="  bg-white  rounded overflow-hidden cursor-pointer px-6  "  style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;  animation: muncul-kiri-kanan-fade 0.5s;">
     
         <div class="flex flex-row justify-between  items-center">
          <div class="flex flex-row items-center" @click="openCategory(shift.id)"> 
           <img class=" object-cover md:w-15 md:h-15 xxs:w-14 xxs:h-full rounded " src="https://pbs.twimg.com/media/GF6JAuVWcAETdhA.jpg"  alt="Profile Image">
           <div class=" flex flex-col p-6"> 
             <h2 class="text-[12px] font-semibold mb-2">{{ shift.user_name }}</h2>
             <div class="flex flex-col">
                <p class="md:text-[12px] xxs:text-[12px] text-gray-600 mb-4">{{ shift.payment_date }}</p>
                <p class="md:text-[12px] xxs:text-[12px] text-gray-600 font-bold">{{ shift.payment_amount }}</p>
              </div>
           
          </div>
           </div>
           <img class="w-8 h-8 cursor-pointer" @click="deleteMaterial(shift.id)" src="../assets/bin.png" alt="">
           
       
       </div>
     
    </div >
 
   </div>

   <!-- <div class="md:flex items-center justify-between border-t border-gray-200 mt-10 py-3 xxs:hidden" style="animation: muncul-kiri-kanan-fade 1.5s;">
   <div class="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
     <div>
       <p class="text-sm text-gray-700">
         Tampilan {{ ' ' }} <span class="font-medium">{{ currentPage }}</span>
         {{ ' ' }} Dari {{ ' ' }} <span class="font-medium">{{ Math.ceil(filteredEntries.length / perPage) }}</span>
         {{ ' ' }} Data
       </p>
       <h1>{{ console.log(totalPages)}}</h1>
     </div>
     <div>
       <nav class="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
         <button @click="currentPage -= 1" :disabled="currentPage === 1" class="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
           <span class="sr-only">Previous</span>
           <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
         </button>
         
         <button v-for="page in totalPages" :key="page" @click="changePage(page)" :class="{ 'bg-red-500': currentPage === page, 'text-white': currentPage === page }" class="relative z-10 inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
         {{ page }}
         </button>
         <button @click="currentPage += 1" :disabled="currentPage === Math.ceil(filteredEntries.length / perPage)" class="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
           <span class="sr-only">Next</span>
           <ChevronRightIcon class="md:h-5 md:w-5 xxs:hidden" aria-hidden="true" />
         </button>
       </nav>
     </div>
   </div>
 </div> -->

 <transition>
      <div
        class="fixed z-10 left-0 top-0 w-full h-full flex items-center justify-center overflow-auto bg-black/40 bg-opacity-50 font-pop font-regular"
        v-if="open1"
      >
  
        <div class="bg-white px-16 py-4 rounded-lg shadow-lg">
          <div class="w-full">
            
            <h1 class="text-[24px] mb-4">Ubah Pembayaran Pinjaman</h1>
            <hr class="mb-4" />
            <div class="flex flex-row justify-between">
              <div class="mt-2 grid xxs:grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-6">
              
<div class="sm:col-span-full">
                <label for="country" class="block w-full text-sm font-medium leading-6 text-gray-900">Karyawan</label>
                <div class="mt-2">
               <!-- model user_name, disabled -->
                  <input v-model="userName" type="text" name="country" id="country" autocomplete="country" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled />
                </div>
              </div>

              <div class="sm:col-span-3">
                <label for="first-name" class="block text-sm font-medium leading-6 text-gray-900">Nominal</label>
                <div class="mt-2">
                  <input    v-model="amount" type="text" @input="handleInput($event, 'amount')"  name="first-name" id="first-name" autocomplete="given-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                </div>
              </div>
  
  
              <div class="sm:col-span-3">
                <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Tanggal Mulai</label>
                <div class="mt-2">
                  <input    v-model="paymentDate" type="date" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                </div>
              </div>

              <div class="sm:col-span-full ">
            <label for="message" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Keterangan</label>
            <div class="mt-2">
            <textarea id="message" v-model="description" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Tambahkan Keterangan Jika Perlu..."></textarea>
            </div>
            </div>
  
             
                
              </div>
            </div>
            <div class="flex justify-end">
              <button
                class="mt-8 bg-red-500 px-5 py-3 rounded-md"
                @click="open1 = false"
              >
                <h1 class="text-[12px] text-white">Kembali</h1>
              </button>
              <form @submit.prevent="submitForm">
              <button
                class="ml-4 mt-8 bg-[#A6CF98] px-[3rem] py-3 rounded-md"
                 type="submit"
              >
                <h1 class="text-[12px] text-white">Simpan</h1>
              </button>
            </form>
            </div>
          </div>
        </div>
      
      </div>
    </transition>


    <div v-if="successModal" class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50"  style="  animation: fadein 1s;">
    <div class="bg-white p-8 rounded-lg flex flex-col justify-center items-center  "  style="animation: muncul-kiri-kanan-fade 1s; ">
      <img src="../assets/verify.png" class="w-16 h-16 mb-6 " alt="">
      <p>Berhasil diupdate</p>
    </div>
  </div>

  <div v-if="successModal1" class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50"  style="  animation: fadein 1s;">
    <div class="bg-white p-8 rounded-lg flex flex-col justify-center items-center  "  style="animation: muncul-kiri-kanan-fade 1s; ">
      <img src="../assets/verify.png" class="w-16 h-16 mb-6 " alt="">
      <p>Data Berhasil dihapus</p>
    </div>
  </div>



  <div v-if="errorModal" class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50" style="animation: fadein 1s;">
    <div class="bg-white p-8 rounded-lg flex flex-col justify-center items-center  "  style="animation: muncul-kiri-kanan-fade 1s; ">
      <img src="../assets/cancel.png" class="w-16 h-16 mb-6 " alt="">
      <p class="  font-semibold w-72 text-center" >{{ message }}</p>
    </div>
  </div>

 </template>
 

 <script>

import axios from "../token";
 export default {
   data() {
     return {
       isOpen: false,
       open:false,
       open1:false,
       id: '',
       loanId: '',
       shiftdata: [],
     currentPage: 1,
     perPage: 10, 
     selectedStatus: [], 
     successModal:false,
     successModal1:false,
     errorModal:false,
     search:'',
     userdata:[],
     teamdata:[],
     karyawan:'',
     date1:'',
     date2:'',
       amount: '',
       amount1: '',
       userName: '',
       paymentDate: '',
     installment: '',
     description:'',
       permidata: [],
     };
   },
   computed: {
 filteredEntries() {
   if (this.shiftdata && this.shiftdata.data) {
     if (this.selectedStatus.length > 0) {
       return this.shiftdata.filter((shift) =>
         this.selectedStatus.includes(shift.id)
       );
     }
     return this.shiftdata.data;
   }
   return [];
 },
 totalPages() {
   return Math.ceil(this.filteredEntries.length / this.perPage);
 },
 pagedShiftData() {
   const start = (this.currentPage - 1) * this.perPage;
   const end = start + this.perPage;
   return this.filteredEntries.slice(start, end);
 },
},  
  methods: {

     CloseModal() {
       this.amount2 =''
       this.amount1 =''
       this.open1=false
     },
     openCategory(categoryId) {
        this.getteam(categoryId);
        this.id=categoryId
        this.open1 = !this.open1;
  },
  async deleteMaterial(id) {
        try {
          let data = new URLSearchParams();
          data.append('id', id);
  
          let response = await axios.delete(`${process.env.VUE_APP_BACKEND_HOST}/loan/payment`, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
             
            },
            data: data
          });
          this.getshift();
          console.log(response.data);
          this.successModal1 = true;
          setTimeout(() => {
            this.successModal1 = false;
          }, 3000);
        } catch (error) {
          console.error(error);
          this.message = error.response.data.message;
          this.errorModal = true;
          setTimeout(() => {
            this.errorModal = false;
          }, 3000);
        }
      },
  handleInput(event, propertyName) {
      // Mendapatkan nilai input tanpa karakter non-angka
      const numericValue = event.target.value.replace(/\D/g, '');
      // Memformat nilai numerik sesuai dengan preferensi Anda
      const formattedValue = this.formatNumber(numericValue); // Anda perlu menentukan fungsi formatNumber sesuai preferensi Anda
      // Perbarui nilai properti dengan nilai yang diformat
      this[propertyName] = formattedValue;
    },
    formatNumber(value) {
      // Fungsi ini dapat Anda sesuaikan sesuai dengan preferensi Anda untuk format angka
      // Contoh: Tambahkan titik sebagai pemisah ribuan
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

async submitForm() {
    try {
        const formData = new FormData();
      formData.append('id', this.id);
      let amount = this.amount.replace(/\./g, '');
amount = amount.replace(/,/g, '.');
      formData.append('amount', amount);
        formData.append('_method','put'),
        formData.append('loan_id',this.loanId);
        formData.append('description', this.description);
        formData.append('payment_date', this.paymentDate);
    

        const response = await axios.post(`${process.env.VUE_APP_BACKEND_HOST}/loan/payment`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        console.log(response);

        this.successModal = true;
        setTimeout(() => {
            this.successModal = false;
            this.open1 = false;
            window.location.reload();
        }, 3000);
    } catch (error) {
        console.log(error);
        this.message = error.response.data.message;
        this.errorModal = true;
        setTimeout(() => {
            this.errorModal = false;
            this.open1 = false;
        }, 3000);
    }
},
     async fetchImage(id, shift) {
     try {
       const response = await axios.get(`${process.env.VUE_APP_BACKEND_HOST}/file`, {
         params: {
           id: id,
         },
         responseType: 'arraybuffer',
       });

       const base64Image = btoa(
         new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), '')
       );

       // Update the imageUrl property of the specific shift
       shift.imageUrl = `data:${response.headers['content-type']};base64,${base64Image}`;
     } catch (error) {
       console.error('Error fetching image:', error);
     }
   },
   getshift() {
    
     axios
       .get(`${process.env.VUE_APP_BACKEND_HOST}/loan/payment/list`,{
        params:{
          search: this.search
        }
    })
       .then((response) => {
         this.shiftdata = response.data;

        //  this.shiftdata.data.forEach((shift) => {
        //    this.fetchImage(shift.photo, shift);
        //  });
       })
       .catch((error) => {
         console.log(error);
       });
     
   },
   getteam(id) {
      axios
        .get(`${process.env.VUE_APP_BACKEND_HOST}/loan/payment`,{
          params:{
            id:id
          }
        })
        .then((response) => {
          const data = response.data;
          this.amount = data.payment_amount;
          this.paymentDate = data.payment_date;
          this.description = data.description; 
          this.userName = data.user_name;
          this.loanId = data.loan_id;

        })
        .catch((error) => {
          console.log(error);
        });
    },
//    getloan(id) {
    
//     axios
//       .get(`${process.env.VUE_APP_BACKEND_HOST}/loan`,{
//       params:{

//         id:id
//        //  search: this.search
//       }
//    })
//       .then((response) => {
//         this.dataloan = response.data;
//         if (this.dataloan && this.dataloan.data.length > 0) {
//         const firstEntry = this.dataloan.data[0];
//         console.log('First entry:', firstEntry);

//         this.karyawan = firstEntry.user_id || '';
//         this.description = firstEntry.description || '';
//         this.amount = firstEntry.amount || '';
//         this.date1 = firstEntry.loan_date || '';
//         this.date2 = firstEntry.due_date || '';

//         console.log('Updated data:', {
//           karyawan: this.karyawan,
//           description: this.description,
//           amount: this.amount,
//           date1: this.date1,
//           date2: this.date2
//         });
//       }
//       })
//       .catch((error) => {
//         console.log(error);
//       });
    
//   },
   getuser() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/hrd/employee/list`,{
            params:{
              limit:100
            }
          })
          .then((response) => {
            this.userdata = response.data;
            
          })
          .catch((error) => {
            console.log(error);
          });
      },
   changePage(page) {
   this.currentPage = page;
 },
 getpermi1() {
      axios
        .get(`${process.env.VUE_APP_BACKEND_HOST}/permission/check`)
        .then((response) => {
          this.permidata = response.data;

        
        })
        .catch((error) => {
          console.log(error);
        });
    },
   },
   mounted() {
   this.getshift();
   this.getuser();
   this.getpermi1();
 },
 }
 </script>


<style scoped>
.fade-in-enter-active, .fade-in-leave-active {
 transition: opacity 1s;
}
.fade-in-enter, .fade-in-leave-to {
 opacity: 0;
}
</style>
 