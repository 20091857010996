
<template>

<nav class="flex mb-6 font-pop" aria-label="Breadcrumb" style=" animation: muncul-kiri-kanan-fade 1s;"> 
  <ol class="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
    <li>
      <div class="flex items-center">
       
        <router-link to="/PO" class="ms-1 text-sm font-medium text-gray-700 hover:text-red-500 md:ms-2 dark:text-gray-400 dark:hover:text-white">Purchase Order</router-link>
      </div>
    </li>
    <li aria-current="page">
      <div class="flex items-center">
        <svg class="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4"/>
        </svg>
        <span class="ms-1 text-sm font-medium text-red-500 md:ms-2 dark:text-gray-400">Tambah Purchase Order</span>
      </div>
    </li>
  </ol>
</nav>

    <div class="font-pop">
    <form @submit.prevent="submittemp" class="space-y-12 bg-white md:mb-0 md:px-24 py-4 xxs:px-4 xxs:mb-16 rounded-xl" style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; animation: muncul-kiri-kanan-fade 1s;">
      <div class="border-b border-gray-900/10 pb-12">
        <h2 class="text-base text-[22px] font-semibold leading-7 text-gray-900 mt-4">Tambah Purchase Order</h2>
        <p class="mt-1 text-sm leading-6 text-gray-600">Lakukan Penginputan Data Purchase Order Baru.</p>
        <div class="mt-6 grid xxs:grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-6">

          <div class="sm:col-span-full">
            <label for="project" class="block text-sm font-medium leading-6 text-gray-900">Project</label>
            <div class="mt-2">
              <select v-model="project" id="project" @change="material2" name="project" autocomplete="project-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                <option value="" disabled selected>Pilih Project</option>
                <option v-for="data2 in projectdata2.data" :key="data2.id" :value="data2.id">{{ data2.name }}</option>
              </select>
            </div>
          </div>
          
          <div class="sm:col-span-3">
            <label for="project" class="block text-sm font-medium leading-6 text-gray-900">Vendor</label>
            <div class="mt-2">
              <select v-model="supplier" id="project" name="project" autocomplete="project-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                <option value="" disabled selected>Pilih Vendor</option>
                <option v-for="data3 in vendordata.data" :key="data3.id" :value="data3.id">{{ data3.name }}</option>
              </select>
            </div>
          </div>

          <!-- <div class="sm:col-span-1">
            <label for="qty" class="block text-sm font-medium leading-6 text-gray-900">No Invoice</label>
            <div class="mt-2">
              <input v-model="po_no" type="text" name="qty" id="qty" autocomplete="qty" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div> -->


          <div class="sm:col-span-2">
            <label for="qty" class="block text-sm font-medium leading-6 text-gray-900">Tanggal Pembelian</label>
            <div class="mt-2">
              <input v-model="po_date" type="date" name="qty" id="qty" autocomplete="qty" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>

          
        </div>
      </div>
      <div class="border-b border-gray-900/10 pb-12">
        <div class="mt-4 grid xxs:grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-6">
          <div class="sm:col-span-3">
            <label for="material" class="block text-sm font-medium leading-6 text-gray-900">Material</label>
            <div class="mt-2">
              <select v-model="material" id="material" @change="unit" name="material" autocomplete="material-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                <option value="" disabled selected>Pilih Material</option>
                <option v-for="data1 in userdata.data" :key="{ id: data1.id, name: data1.name}" :value="{ id: data1.id, name: data1.name}">{{ data1.name_dimension }}</option>
              </select>
            </div>
          </div>
          
         
          <div class="sm:col-span-2">
            <label for="qty" class="block text-sm font-medium leading-6 text-gray-900">Qty</label>
            <div class="mt-2">
              <input v-model="qty" type="text" name="qty" id="qty" autocomplete="qty" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>

          <div class="sm:col-span-1">
            <label for="negara" class="block text-sm font-medium leading-6 text-gray-900">EOM</label>
            <div class="mt-2">
              <input v-model="eom" type="text" name="negara" id="negara" autocomplete="negara" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled />
            </div>
          </div>

          <div class="sm:col-span-3">
            <label for="berat-satuan" class="block text-sm font-medium leading-6 text-gray-900">Harga Satuan</label>
            <div class="mt-2">
              <input v-model="price" type="text" @input="handleInput($event, 'price')" name="berat-satuan" id="berat-satuan" autocomplete="berat-satuan" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>

          <div class="sm:col-span-3">
            <label for="qty" class="block text-sm font-medium leading-6 text-gray-900">Total</label>
            <div class="mt-2"> 
              <input v-model="total"  type="text" @input="handleInput($event, 'total')"  name="negara" id="negara" autocomplete="negara" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled />
            </div>
          </div>

      
          
        </div>
        <div class="flex justify-end mt-6 gap-4">
          <form @submit.prevent="resetForm">
          <button   class="px-4 py-2 text-sm font-medium text-white bg-red-400 rounded-md hover:bg-red-700 focus:outline-none">Batal</button>
        </form>
          <button v-if="!isEditMode" type="submit" class="px-4 py-2 text-sm font-medium text-white bg-green-400 rounded-md hover:bg-green-700 focus:outline-none">Tambahkan</button>
          
          <button v-if="isEditMode" type="submit" class="px-4 py-2 text-sm font-medium text-white bg-green-400 rounded-md hover:bg-green-700 focus:outline-none">Ubah Data</button>
      </div>
        
      </div>
      
<div class="border-b border-gray-900/10 pb-12">
  <div class="relative overflow-x-auto shadow-md sm:rounded-lg font-pop ">
    <table class="w-full text-[12px] text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead class="t text-gray-700  bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
                <th scope="col" class="px-6 py-3">
                    Material
                </th>
                <th scope="col" class="px-6 py-3">
                    Qty
                </th>
                <th scope="col" class="px-6 py-3">
                    Eom
                </th>
                <th scope="col" class="px-6 py-3">
                    Harga
                </th>
                <th scope="col" class="px-6 py-3">
                    Total
                </th>
               
                <th scope="col" class="px-6 py-3">
                    Aksi
                </th>
                <th scope="col" class="px-6 py-3">
                    
                </th>
            </tr>
        </thead>
        <tbody>
            <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"  v-for="(entry, index) in entries" :key="index">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {{ entry.material.name }}
                </th>
                <td class="px-6 py-4">
                    {{ entry.qty }}
                </td>
                <td class="px-6 py-4">
                    {{ entry.eom }}
                </td>
                <td class="px-6 py-4">
                    {{ entry.price }}
                </td>
                <td class="px-6 py-4">
                    {{ entry.total }}
                </td>
                
                <td class="px-6 py-4">
                  <a
          href="#"
          class="font-medium text-red-600 dark:text-red-500 hover:underline"
          @click.prevent="removeEntry(index)"
        >Hapus</a>
                </td>
                <td  class="px-6 py-4">
                  <a  href="#"  class="font-medium text-red-600 dark:text-red-500 hover:underline"  @click.prevent="editEntry(index)">Ubah</a> 
                </td>
            </tr>
           
        </tbody>
    </table>
</div>
</div>
<div class="mt-10 flex items-center justify-end gap-x-6">
      <router-link to="/PO"  class="rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Kembali</router-link>
      <form @submit.prevent="submitForm">
      <button type="submit" class="rounded-md bg-green-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Simpan</button>
    </form>
    </div>

    </form>
  </div>
  <div v-if="successModal" class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50"  style="  animation: fadein 1s;">
    <div class="bg-white p-8 rounded-lg flex flex-col justify-center items-center  "  style="animation: muncul-kiri-kanan-fade 1s; ">
      <img src="../../../assets/verify.png" class="w-16 h-16 mb-6 " alt="">
      <p>Data Purchase Order Berhasil ditambahkan</p>
    </div>
  </div>

  <div v-if="errorModal" class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50" style="animation: fadein 1s;">
    <div class="bg-white p-8 rounded-lg flex flex-col justify-center items-center  "  style="animation: muncul-kiri-kanan-fade 1s; ">
      <img src="../../../assets/cancel.png" class="w-16 h-16 mb-6 " alt="">
      <p class="  font-semibold w-72 text-center" >{{ message }}</p>
    </div>
  </div>

</template>






<script>
import axios from "../../../token";
import { ref } from 'vue';
import { setupLeaveConfirmation } from '../../../token/confirmLeave.js';




export default {
  data() {
    return {
      id: localStorage.getItem('id') || 'Default Value',
      isOpen: false,
      entries: [],
      open: "",
      project: '',
      po_no: '',
      po_date: new Date().toISOString().substr(0, 10),
      supplier:'',
      eom: '',
      isEditMode: false,
      Deskripsi: '',
      price:[],
      qty: [],
      description:[],
      category: '',
      material:[],
      coo: [],
      BeratSatuan: '',
      dimension:[],
      weight: [],
      successModal:false,
      errorModal:false,
      status:"Proses Pengerjaan",
      categorydata:[],
      projectdata:[],
      projectdata2:[],
      userdata:[],
      vendordata:[],  
    };
  },
  setup() {
    const isHovered = ref(false);
    setupLeaveConfirmation();

    return {
     
      isHovered,
      setupLeaveConfirmation
      
    }
},
  computed: {
    filteredEntries() {
      let filtered = this.absendata;

      if (this.selectedStatus.length > 0) {
        filtered = filtered.filter((abse) =>
          this.selectedStatus.includes(abse.status)
        );
      }

      if (this.selectedSort === "asc") {
        return filtered.sort((a, b) => a.jammasuk.localeCompare(b.jammasuk));
      } else {
        return filtered.sort((a, b) => b.jammasuk.localeCompare(a.jammasuk));
      }
    },
  },
  watch: {
    qty() {
      console.log(this.price)
      this.updateqty();
  },
  price() {
  
    this.updateTotal();
  }
  },
  methods: {
    updateqty() {
      if (this.price){
      const totalValue = this.price *  this.qty;
      let formattedTotal = totalValue.toLocaleString('id-ID', {
    minimumFractionDigits: totalValue >= 0 ? 0 : 2 // Menentukan jumlah digit desimal berdasarkan nilai
});
    this.total =formattedTotal
      }
   
},
    updateTotal() {
    const totalValue = this.price.replace(/\./g, '')  *  this.qty;
    // Format total menjadi mata uang IDR
    let formattedTotal = totalValue.toLocaleString('id-ID', {
    minimumFractionDigits: totalValue >= 0 ? 0 : 2 // Menentukan jumlah digit desimal berdasarkan nilai
});
    this.total =formattedTotal
  },
    handleInput(event, propertyName) {
      // Mendapatkan nilai input tanpa karakter non-angka
      const numericValue = event.target.value.replace(/\D/g, '');
      // Memformat nilai numerik sesuai dengan preferensi Anda
      const formattedValue = this.formatNumber(numericValue); // Anda perlu menentukan fungsi formatNumber sesuai preferensi Anda
      // Perbarui nilai properti dengan nilai yang diformat
      this[propertyName] = formattedValue;
    },
    formatNumber(value) {
      // Fungsi ini dapat Anda sesuaikan sesuai dengan preferensi Anda untuk format angka
      // Contoh: Tambahkan titik sebagai pemisah ribuan
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    unit() {
    const selectedMaterial = this.userdata.data.find(material => material.id === this.material.id);
    if (selectedMaterial) {
      this.eom = selectedMaterial.symbol;
    } else {
      this.eom = '';
    }
  },
  material2() {
    this.material1 = this.project;
    console.log(this.material1)
    if (this.material1) {
      this.getuser()
    } else {
      this.material1 = '';
    }
  },
   
  removeEntry(index) {
      this.entries.splice(index, 1);
    },
    editEntry(index) {
      const editedEntry = this.entries[index];
      this.material = editedEntry.material
      this.price = editedEntry.price || '';
     
      this.qty = editedEntry.qty || '';
      this.total = editedEntry.total || '';
   
      this.eom = editedEntry.eom || '';
      this.isEditMode = true;
      this.editedIndex = index
    },
    toggleModal() {
      this.isOpen = !this.isOpen;
    },
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    submittemp() {

      const emptyFields = [];


if (!this.material || /^\s*$/.test(this.material)) {
  emptyFields.push("Material");
}


if (!this.price || /^\s*$/.test(this.price)) {
  emptyFields.push("Price");
}

if (!this.qty || /^\s*$/.test(this.qty)) {
  emptyFields.push("Quantity");
}



if (!this.eom || /^\s*$/.test(this.eom)) {
  emptyFields.push("EOM");
}

if (!this.total || /^\s*$/.test(this.total)) {
  emptyFields.push("TOTAL");
}


if (emptyFields.length > 0) {

this.errorModal1 = true;
this.message = `Field ${emptyFields.join(", ")} Tidak Boleh Kosong.`;

setTimeout(() => {
  this.errorModal1 = false;
  this.open = false;
}, 3000);

return;
}

const entryData = {
material: this.material,
price: this.price,
qty: this.qty,
total: this.total,
eom: this.eom,

};

if (this.isEditMode) {
this.entries[this.editedIndex] = { ...entryData };

this.isEditMode = false;

} else {
console.log(entryData)
this.entries.push(entryData);
}

this.resetForm();
     
    },
    resetForm() {
    this.selectedMaterial=[];
    this.editedIndex = ''
    this.material='';
    this.qty = '';
    this.total = '';
    this.eom = '';
    this.price='';
  },
    async submitForm() {
      const formData = new FormData();

      this.entries.forEach((entries) => {
        formData.append('material[]', entries.material.id);
        formData.append('qty[]', entries.qty);
        formData.append('price[]', entries.price.replace(/\./g, ''));
        formData.append('total[]', entries.total.replace(/\./g, ''));
        

      },
        formData.append('project',this.project),
        formData.append('supplier',this.supplier),
        formData.append('po_date',this.po_date),
        formData.append('po_no',this.po_no)
      );
      
      try {
        const response = await axios.post(`${process.env.VUE_APP_BACKEND_HOST}/purchase-order`, formData);

        console.log(response);
        await new Promise(resolve => setTimeout(resolve, 1000));
        this.successModal = true;

        this.getproject();
      
        setTimeout(() => {
          this.successModal = false;
          this.open = false;
        }, 1000);
        setTimeout(() => {
        this.$router.push('/PO');
      }, 2000);
      } catch (error) {
        console.log(error);
        this.errorModal = true;
        setTimeout(() => {
          this.errorModal = false;
        }, 2000);
        this.message = error.response.data.message;
      }
    },
    getcategory() {
      axios
        .get(`${process.env.VUE_APP_BACKEND_HOST}/project-category`)
        .then((response) => {
          this.categorydata = response.data;

        })
        .catch((error) => {
          console.log(error);
        });
    },
    getuser() {
      axios
        .get(`${process.env.VUE_APP_BACKEND_HOST}/material`,{
          params:{
            project_id:this.project
          }
        })
        .then((response) => {
          this.userdata = response.data;

        })
        .catch((error) => {
          console.log(error);
        });
    },
    getproject2() {
      axios
        .get(`${process.env.VUE_APP_BACKEND_HOST}/project/list`)
        .then((response) => {
          this.projectdata2 = response.data;

        })
        .catch((error) => {
          console.log(error);
        });
    },
    getsupplier() {
      axios
        .get(`${process.env.VUE_APP_BACKEND_HOST}/supplier/list`)
        .then((response) => {
          this.vendordata = response.data;

        })
        .catch((error) => {
          console.log(error);
        });
    },
    getproject() {
      axios
        .get(`${process.env.VUE_APP_BACKEND_HOST}/unit`)
        .then((response) => {
          this.projectdata = response.data;

        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted(){
    this.getcategory();
    this.getproject2();
    this.getproject();
    this.getuser();
    this.getsupplier();
  }
};
</script>
