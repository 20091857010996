
<template>

<nav class="flex mb-6 font-pop" aria-label="Breadcrumb" style=" animation: muncul-kiri-kanan-fade 1s;"> 
  <ol class="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
    <li>
      <div class="flex items-center">
       
        <router-link to="/stockIn" class="ms-1 text-sm font-medium text-gray-700 hover:text-red-500 md:ms-2 dark:text-gray-400 dark:hover:text-white">Stock In</router-link>
      </div>
    </li>
    <li aria-current="page">
      <div class="flex items-center">
        <svg class="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4"/>
        </svg>
        <span class="ms-1 text-sm font-medium text-red-500 md:ms-2 dark:text-gray-400">Ubah Stock In</span>
      </div>
    </li>
  </ol>
</nav>

  <div class="font-pop">
  <form @submit.prevent="submittemp" class="space-y-12 bg-white md:mb-0 md:px-24 py-4 xxs:px-4 xxs:mb-16 rounded-xl" style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; animation: muncul-kiri-kanan-fade 1s;">
    <div class="border-b border-gray-900/10 pb-12">
      <h2 class="text-base text-[22px] font-semibold leading-7 text-gray-900 mt-4">Ubah Stock In</h2>
      <p class="mt-1 text-sm leading-6 text-gray-600">Lakukan Perubahan Data Stock In Baru.</p>
      <div class="mt-6 grid xxs:grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-6">

        <div class="sm:col-span-full">
          <label for="project" class="block text-sm font-medium leading-6 text-gray-900">PO#</label>
          <div class="mt-2">
            <select v-model="po_id" id="project" name="project" autocomplete="project-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled>
              <option value="" disabled selected>Pilih PO#</option>
              <option v-for="data4 in podata.data" :key="data4.id" :value="data4.id">{{ data4.code }}</option>
            </select>
          </div>
        </div>

        <div class="sm:col-span-3">
          <label for="project" class="block text-sm font-medium leading-6 text-gray-900">Vendor</label>
          <div class="mt-2">
            <select v-model="supplier" id="project" name="project" autocomplete="project-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled>
              <option value="" disabled selected>Pilih Vendor</option>
              <option v-for="data3 in vendordata.data" :key="data3.id" :value="data3.id">{{ data3.name }}</option>
            </select>
          </div>
        </div>

     


        <div class="sm:col-span-2">
          <label for="qty" class="block text-sm font-medium leading-6 text-gray-900">Tanggal Barang Masuk</label>
          <div class="mt-2">
            <input v-model="date" type="date" name="qty" id="qty" autocomplete="qty" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled />
          </div>
        </div>

      
      </div>
    </div>

    
<div class="border-b border-gray-900/10 pb-12">
<div class="relative overflow-x-auto shadow-md sm:rounded-lg font-pop ">
  <table class="w-full text-[12px] text-left rtl:text-right text-gray-500 dark:text-gray-400">
      <thead class="t text-gray-700  bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" class="px-6 py-3">
                  No
              </th>
              <th scope="col" class="px-6 py-3">
                  Material
              </th>
              <th scope="col" class="px-6 py-3">
                  Qty
              </th>
              <th scope="col" class="px-6 py-3">
                  Eom
              </th>
              <th scope="col" class="px-6 py-3">
                Qty Terima
              </th>
             
              <th scope="col" class="px-6 py-3">
                  Aksi
              </th>
             
          </tr>
      </thead>
      <tbody>
          <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"  v-for="(entry, index) in SIData.data" :key="index">
            <td class="px-6 py-4">
                  {{ index + 1 }}
              </td>
              <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                  {{ entry.material.name }}
              </th>
              <td class="px-6 py-4">
                  {{ entry.po_quantity }}
              </td>
              <td class="px-6 py-4">
                  {{ entry.material.unit_name }}
              </td>
              <td class="px-6 py-4">
                <div class="sm:col-span-1">
          <div class="mt-2">
            <input
            v-model="entry.quantity"
            type="text"
            :id="'newQuantity' + index"
            autocomplete="qty"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />


          </div>
        </div>
              </td>
              
              <!-- <td class="px-6 py-4 ">
                  <a  href="#"  class="font-medium text-red-600 dark:text-red-500 hover:underline"  @click.prevent="editEntry(index)">Ubah</a> 
                 </td> -->
                 <td class="px-6 py-4 ">
                  <a  href="#"  class="font-medium text-red-600 dark:text-red-500 hover:underline"  @click.prevent="removeEntry(index)">Hapus</a> 
                 </td>
          </tr>
         
      </tbody>
  </table>
</div>
</div>
<div class="mt-10 flex items-center justify-end gap-x-6">
    <router-link to="/stockIn"  class="rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Kembali</router-link>
    <form @submit.prevent="submitForm">
    <button type="submit" class="rounded-md bg-green-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Simpan</button>
  </form>
  </div>

  </form>
</div>
<div v-if="successModal" class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50"  style="  animation: fadein 1s;">
  <div class="bg-white p-8 rounded-lg flex flex-col justify-center items-center  "  style="animation: muncul-kiri-kanan-fade 1s; ">
    <img src="../../../assets/verify.png" class="w-16 h-16 mb-6 " alt="">
    <p>Data Stock In Berhasil Diubah</p>
  </div>
</div>

<div v-if="errorModal" class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50" style="animation: fadein 1s;">
    <div class="bg-white p-8 rounded-lg flex flex-col justify-center items-center  "  style="animation: muncul-kiri-kanan-fade 1s; ">
      <img src="../../../assets/cancel.png" class="w-16 h-16 mb-6 " alt="">
      <p class="  font-semibold w-72 text-center" >{{ message }}</p>
    </div>
  </div>

</template>






<script>
import axios from "../../../token";
import { ref } from 'vue';
import { setupLeaveConfirmation } from '../../../token/confirmLeave.js';




export default {
data() {
  return {
    id: localStorage.getItem('id') || 'Default Value',
    isOpen: false,
    entries: [],
    open: "",
    po_no: '',
    date: '',
    supplier:1,
    po_id:'',
    eom: '',
    Deskripsi: '',
    quantity:[],
    price:[],
    qty: [],
    description:[],
    category: '',
    material:[],
    coo: [],
    BeratSatuan: '',
    dimension:[],
    weight: [],
    successModal:false,
    errorModal:false,
    status:"Proses Pengerjaan",
    categorydata:[],
    projectdata:[],
    projectdata2:[],
    userdata:[],
    vendordata:[],
    total:[],
    podata:[],
    SIData:[],
    modifiedQuantities: [],
  };
},
setup() {
  const isHovered = ref(false);
  setupLeaveConfirmation();

  return {
   
    isHovered,
    setupLeaveConfirmation
    
  }
},
computed: {
  filteredEntries() {
    let filtered = this.absendata;

    if (this.selectedStatus.length > 0) {
      filtered = filtered.filter((abse) =>
        this.selectedStatus.includes(abse.status)
      );
    }

    if (this.selectedSort === "asc") {
      return filtered.sort((a, b) => a.jammasuk.localeCompare(b.jammasuk));
    } else {
      return filtered.sort((a, b) => b.jammasuk.localeCompare(a.jammasuk));
    }
  },
},
watch: {
  qty(val) {

  this.total = val * this.price;
},
price(val) {

  this.total = this.qty * val;
},
quantity(newQuantity) {
      console.log('New Quantity:', newQuantity);
      this.handleQuantityChange(newQuantity);
    },
},
methods: {
  fetchData() {
    // Assuming SIData.data is the array you receive from the API
    this.SIData.data.forEach((entry, index) => {
      // Initialize the quantityArray with default values
      this.$set(this.quantity, index, entry.quantity || 0);
    });
  },
  updateQuantity(index, value) {
  // Update the quantity value in the data array
  this.SIData.data[index].quantity = value;

  // Log the changes
  console.log(`Updated quantity at index ${index} to ${value}`);
},

  unit() {
  const selectedMaterial = this.userdata.data.find(material => material.id === this.material);
  if (selectedMaterial) {
    this.eom = selectedMaterial.symbol;
  } else {
    this.eom = '';
  }
},

material2() {
    this.material1 = this.project;
    console.log(this.material1)
    if (this.material1) {
      this.getuser()
    } else {
      this.material1 = '';
    }
  },
  removeEntry(index) {
  this.SIData.data.splice(index,1);

},
    editEntry(index) {
      const editedEntry = this.SIData.data[index];
      this.selectedMaterial = editedEntry.material ? { id: editedEntry.material.id, name: editedEntry.material.name, dimension: editedEntry.material.dimension, symbol: editedEntry.material.unit_name } : null;
      this.total = editedEntry.total || '';
      this.price = editedEntry.price || '';
      this.quantity = editedEntry.quantity || '';   
      this.eom = editedEntry.material.unit_name || '';
      this.isEditMode = true;
      this.editedIndex = index
    },
  toggleModal() {
    this.isOpen = !this.isOpen;
  },
  openModal() {
    this.isModalOpen = true;
  },
  closeModal() {
    this.isModalOpen = false;
  },
  // handleQuantityChange(newQuantity) {
  //     const emptyFields = [];

  //     if (!newQuantity || /^\s*$/.test(newQuantity)) {
  //       emptyFields.push("Quantity");
  //     }

  //     if (emptyFields.length === 0) {
  //       // Ensure that entries is initialized as an array before pushing
  //       if (!Array.isArray(this.entries)) {
  //         console.error('Entries is not an array:', this.entries);
  //         this.entries = []; // Initialize as an array
  //       }
  //       const materials = this.SIData.data
  // .filter(entry => entry.material)
  // .map(entry => entry.material);

  //       console.log(materials)
  //       // If validation passes, push the quantity into the entries array
  //       this.entries.push({
  //         quantity: newQuantity,
  //         material:materials
  //       });

  //       console.log('Entries:', this.entries);
  //     }
  //   },
 
  async submitForm() {
    const formData = new FormData();
    

    this.SIData.data.forEach((entry, index) => {
    formData.append(`material[${index}]`, entry.material.id);
  formData.append(`qty[${index}]`, entry.quantity);
   
    // Add other properties as needed
  });

  formData.append('date', this.date);
  formData.append('po_id', this.po_id);
  formData.append('received_by', this.id);
  formData.append('id',this.$route.params.id),
  formData.append('_method','put')
    
    try {
  const response = await axios.post(`${process.env.VUE_APP_BACKEND_HOST}/stock-in`, formData);

  console.log(response);
  this.successModal = true;
  await new Promise(resolve => setTimeout(resolve, 2000));
  this.successModal = false;


  this.open = false;
  setTimeout(() => {
        this.$router.push('/stockIn');
      }, 2000);
} catch (error) {
  console.log(error);

  this.errorModal = true;
  this.message = error.response.data.message;

  await new Promise(resolve => setTimeout(resolve, 2000));
  this.errorModal = false;
}
  },
  getcategory() {
    axios
      .get(`${process.env.VUE_APP_BACKEND_HOST}/project-category`)
      .then((response) => {
        this.categorydata = response.data;

      })
      .catch((error) => {
        console.log(error);
      });
  },
  getSI() {
    const materialId = this.$route.params.id;
      axios
        .get(`${process.env.VUE_APP_BACKEND_HOST}/stock-in`,{
          params:{
            id:materialId
          }
        })
        .then((response) => {
          
          this.SIData = response.data;
          if (this.SIData && this.SIData.data.length > 0) {
            const firstEntry = this.SIData.data[0]; 
        this.date = firstEntry.date || '' ;
        this.po_id = firstEntry.purchase_order_id
      }
        })
        .catch((error) => {
          console.log(error);
        });
  },
  getuser() {
      axios
        .get(`${process.env.VUE_APP_BACKEND_HOST}/material`,{
          params:{
            project_id:this.project
          }
        })
        .then((response) => {
          this.userdata = response.data;

        })
        .catch((error) => {
          console.log(error);
        });
    },
  getproject2() {
    axios
      .get(`${process.env.VUE_APP_BACKEND_HOST}/project/list`)
      .then((response) => {
        this.projectdata2 = response.data;

      })
      .catch((error) => {
        console.log(error);
      });
  },
  getsupplier() {
    axios
      .get(`${process.env.VUE_APP_BACKEND_HOST}/supplier/list`)
      .then((response) => {
        this.vendordata = response.data;

      })
      .catch((error) => {
        console.log(error);
      });
  },
  getproject() {
    axios
      .get(`${process.env.VUE_APP_BACKEND_HOST}/unit`)
      .then((response) => {
        this.projectdata = response.data;

      })
      .catch((error) => {
        console.log(error);
      });
  },
  getPolist() {
    axios
      .get(`${process.env.VUE_APP_BACKEND_HOST}/purchase-order/list`)
      .then((response) => {
        this.podata = response.data;
        

      })
      .catch((error) => {
        console.log(error);
      });
  },
},
mounted(){
  this.getcategory();
  this.getproject2();
  this.getproject();
  this.getuser();
  this.getsupplier();
  this.getPolist();
  this.getSI();
}
};
</script>
