<template>
  <div class="flex flex-col font-pop ">
    <div class="mb-4 mt-4 font-medium" style="  animation: muncul-kiri-kanan-fade 1s;">Statistik Kehadiran Pegawai</div>
    <div class="flex flex-row w-full " style="  animation: muncul-kiri-kanan-fade 1s;">
      <div class="grid gap-0 grid-cols-2 ">
        <div class="flex flex-row border rounded-tl-xl p-6 bg-white shadow-lg">
          <div class="flex flex-col justify-between">
            <h1 class="text-[14px] font-medium">Karyawan yang Hadir</h1>
            <h2 class="text-[32px] font-medium">{{hadir}}</h2>
            <h3 class="text-[14px] font-medium">Karyawan</h3>
          </div>
          <div class="flex items-center -space-x-2 overflow-hidden ">
            <img
              class="inline-block h-10 w-10 rounded-full  ring-2 ring-white shadow-lg" 
              src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
              alt=""
            />
            <img
              class="inline-block h-10 w-10 rounded-full ring-2 ring-white shadow-lg"
              src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
              alt=""
            />
            <img
              class="inline-block h-10 w-10 rounded-full ring-2 ring-white shadow-lg" 
              src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80"
              alt=""
            />
            <img
              class="inline-block h-10 w-10 rounded-full ring-2 ring-white shadow-lg"
              src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
              alt=""
            />
          </div>
        </div>
            <div class="flex flex-row border p-6 bg-white shadow-lg ">
              <div class="flex flex-col justify-between">
            <h1 class="text-[14px] font-medium">Karyawan Tidak Hadir</h1>
            <h2 class="text-[32px] font-medium">{{absen}}</h2>
            <h3 class="text-[14px] font-medium">Karyawan</h3>
          </div>
                <div class="flex items-center -space-x-2 overflow-hidden">
                  <img
                    class="inline-block h-10 w-10 rounded-full ring-2 ring-white shadow-lg"
                    src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    alt=""
                  />
                  <img
                    class="inline-block h-10 w-10 rounded-full ring-2 ring-white shadow-lg"
                    src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    alt=""
                  />
                  <img
                    class="inline-block h-10 w-10 rounded-full ring-2 ring-white shadow-lg"
                    src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80"
                    alt=""
                  />
                  <img
                    class="inline-block h-10 w-10 rounded-full ring-2 ring-white shadow-lg"
                    src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    alt=""
                  />
                </div>
        </div>
            <div class="flex flex-row border rounded-bl-xl p-6 bg-white shadow-lg">
              <div class="flex flex-col justify-between">
            <h1 class="text-[14px] font-medium">Karyawan Izin </h1>
            <h2 class="text-[32px] font-medium">{{cuti}}</h2>
            <h3 class="text-[14px] font-medium">Karyawan</h3>
          </div>
                <div class="flex items-center -space-x-2 overflow-hidden">
                  <img
                    class="inline-block h-10 w-10 rounded-full ring-2 ring-white shadow-lg"
                    src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    alt=""
                  />
                  <img
                    class="inline-block h-10 w-10 rounded-full ring-2 ring-white shadow-lg"
                    src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    alt=""
                  />
                  <img
                    class="inline-block h-10 w-10 rounded-full ring-2 ring-white shadow-lg"
                    src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80"
                    alt=""
                  />
                  <img
                    class="inline-block h-10 w-10 rounded-full ring-2 ring-white shadow-lg"
                    src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    alt=""
                  />
                </div>
              </div>
            <div class="flex flex-row  border p-6 bg-white shadow-lg ">
              <div class="flex flex-col justify-between">
            <h1 class="text-[14px] font-medium">Karyawan sakit</h1>
            <h2 class="text-[32px] font-medium">{{sakit}}</h2>
            <h3 class="text-[14px] font-medium">Karyawan</h3>
          </div>
                <div class="flex items-center -space-x-2 overflow-hidden">
                  <img
                    class="inline-block h-10 w-10 rounded-full ring-2 ring-white"
                    src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    alt=""
                  />
                  <img
                    class="inline-block h-10 w-10 rounded-full ring-2 ring-white"
                    src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    alt=""
                  />
                  <img
                    class="inline-block h-10 w-10 rounded-full ring-2 ring-white"
                    src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80"
                    alt=""
                  />
                  <img
                    class="inline-block h-10 w-10 rounded-full ring-2 ring-white"
                    src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    alt=""
                  />
                </div>
        </div>
      </div>
      <div class="flex items-center border p-1 rounded-r-lg bg-white shadow-lg ">
        <div class="  ">
      <BarChart class="w-[1000px] h-[250px]" />
     </div>

      </div>
      
    </div>
    <div class="mt-4 mb-4 font-medium" style="  animation: muncul-kiri-kanan-fade 1.2s;">Informasi Status Karyawan Hari Ini</div>
    <div class="flex  md:flex-row xxs:flex-col "  style="  animation: muncul-kiri-kanan-fade 1.2s;">
    <div class="md:w-full md:mr-8 xxs:w-[300px] xxs:h-[300px]">
      <TableBar/>
    </div>
       <!-- <div class="flex flex-col  justify-center items-center bg-white shadow-md md:w-[525px] xxs:w-[300px] rounded-lg">
        <DoughnutChart class="md:w-[300px] mt-2" />
        <h1 class="font-pop text-[16px] md:mt-6 md:mb-6">Presentase Kehadiran</h1>
       </div> -->
    
  </div>
  </div>
</template>

<script>
import axios from "../../token";
// import DoughnutChart from "../Component/DoughnutChart.vue"
import BarChart from "../Component/BarChart.vue"
import TableBar from "./TablePresensi1.vue";
export default {
  components: {BarChart,  TableBar },
  data() {
      return {
        absen: '',
        hadir:'',
        sakit:'',
        cuti:'',
      };
    },
  methods: {
      toggleModal() {
        this.isOpen = !this.isOpen;
      },
      openModal() {
        this.isModalOpen = true;
      },
      closeModal() {
        this.isModalOpen = false;
      },
      async submitForm() {
        try {
          const response = await axios.post(`${process.env.VUE_APP_BACKEND_HOST}/project`, {
            project: this.project,
            material: this.material,
            qty: this.qty,
            price: this.price,
            weight: this.weight,
            coo: this.coo,
            dimension: this.dimension
          });
          console.log(response);
     
          this.successModal = true;
  
          this.getproject();
        
          setTimeout(() => {
            this.successModal = false;
            this.open = false;
          }, 1000);
        } catch (error) {
          console.log(error);
          this.message = "There was an error submitting the form.";
        }
      },
      getcategory() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/project-category`)
          .then((response) => {
            this.categorydata = response.data;
  
          })
          .catch((error) => {
            console.log(error);
          });
      },
      getcount() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/presence/count`)
          .then((response) => {
            this.countdata = response.data;

         
          const firstEntry = this.countdata;
          console.log(firstEntry)
           this.cuti = firstEntry.cuti
            this.sakit = firstEntry.sakit
           this.hadir = firstEntry.hadir
           this.absen = firstEntry.absen
          })
          .catch((error) => {
            console.log(error);
          });
      },
      getuser() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/material`)
          .then((response) => {
            this.userdata = response.data;
  
          })
          .catch((error) => {
            console.log(error);
          });
      },
      getproject2() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/project/list`,{
            params:{
              limit:5
            }
          })
          .then((response) => {
            this.projectdata2 = response.data;
  
          })
          .catch((error) => {
            console.log(error);
          });
      },
      getproject() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/unit`)
          .then((response) => {
            this.projectdata = response.data;
  
          })
          .catch((error) => {
            console.log(error);
          });
      },
      getmaterial() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/material-take-off/list`,{
            params:{
                search:this.search
            }
          })
          .then((response) => {
            this.materialdata = response.data;
  
          })
          .catch((error) => {
            console.log(error);
          });
      },
    },
    mounted(){
      this.getcategory();
      this.getproject2();
      this.getproject();
      this.getuser();
      this.getmaterial();
      this.getcount();
    }
};
</script>
