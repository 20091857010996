<template >


  <div class="px-4 font-pop font-medium text-[#373636] " style=" animation: muncul-kiri-kanan-fade 0.8s;">
    <h1 class="text-[24px] uppercase mb-4">Work Order</h1>
  
    <div class="flex flex-row justify-between items-center font-pop font-medium mb-8 pr-[24.5rem] " style=" animation: muncul-kiri-kanan-fade 0.8s;"  >
        
              <div class="relative text-gray-600">
      <input type="search" v-model="search" @input="getmaterial" name="serch" placeholder="Pencarian..." class="bg-[#FAFBFD]  focus:ring-gray-500 focus:border-gray-500 h-10 px-5 w-64  rounded text-sm focus:outline-none border-1 border-gray-300">
      <button type="submit" class="absolute right-0 top-0 mt-3 mr-4">
        <svg class="h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 56.966 56.966" style="enable-background:new 0 0 56.966 56.966;" xml:space="preserve" width="512px" height="512px">
          <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z"/>
        </svg>
      </button>
      </div>

      <router-link to="/TambahWO">
  <button
      class=" h-[30px] px-4 mr-4 rounded text-white bg-red-500 "
    
    >
      <div class="flex flex-row justify-center ">
        <img src="../assets/add.png" class="mr-1 w-5" alt="" />
        <h3 class="text-[12px] font-medium">Tambah Work Order</h3>
      </div>
    </button>
  </router-link>
      
          </div>
          
  </div>
  
  <div class="flex   px-4 font-pop ">
  
 
  <div class="w-3/4   text-[#373636]">

    
    <div class="relative overflow-x-auto shadow-md sm:rounded-lg font-pop" style=" animation: muncul-kiri-kanan-fade 0.8s;">
    <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead class="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
            <tr>
                <!-- <th scope="col" class="p-4">
                    <div class="flex items-center">
                        <input id="checkbox-all" type="checkbox" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                        <label for="checkbox-all" class="sr-only">checkbox</label>
                    </div>
                </th> -->
                <th scope="col" class="px-6 py-3">
                   No
                </th>
                <th scope="col" class="px-6 py-3">
                  ID Work Order
                </th>
                <th scope="col" class="px-6 py-3">
                  Nama Team
                </th>
                <th scope="col" class="px-6 py-3">
                  Keterangan
                </th>
                <th scope="col" class="px-6 py-3">
                  Tanggal Mulai
                </th>
                <th scope="col" class="px-6 py-3">
                  Tanggal Akhir
                </th>
                <th scope="col" class="px-6 py-3">
                 Aksi
                </th>
            </tr>
        </thead>
        <tbody>
            <tr class="bg-white border-b text-[14px] font-medium dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600" v-for="(item,index) in materialdata.data" :key="index">
                <!-- <td class="w-4 p-4">
                    <div class="flex items-center">
                        <input id="checkbox-table-1" type="checkbox" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                        <label for="checkbox-table-1" class="sr-only">checkbox</label>
                    </div>
                </td> -->
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                  {{ index + 1 }}
                </th>
                <td class="px-6 py-4">
                  {{ item.code }}
                </td>
                <td class="px-6 py-4">
                  {{ item.team_name }}
                </td>
               
                <td class="px-6 py-4">
                  {{ item.description }}
                </td>
                <td class="px-6 py-4">
                  <p class="">{{ item.start_date }}</p>
                </td>
                <td class="px-6 py-4">
                  {{ item.end_date }}
                </td>
                <td class="px-6 py-4">
                  <div class="flex flex-row gap-4 flex-wrap">
                  <router-link :to="'/EditWorkOrder/' + item.id ">
                    <img class="w-8 h-8" src="../assets/edit.png" alt="">
                  </router-link>
                  <img class="w-8 h-8 cursor-pointer" @click="deleteMaterial(item.id)" src="../assets/bin.png" alt="">
                  </div>
                </td>
            </tr>
           
        </tbody>
    </table>
</div>

<div style=" animation: muncul-kiri-kanan-fade 1s;">
   
   <div class="flex justify-end mt-8 text-sm">
     <!-- <button
       @click="getmaterial(1)"
       :disabled="pagination.current_page === 1"
       class="px-4 py-2 mx-1 text-white bg-blue-500 rounded-md hover:bg-blue-600"
     >
       First
     </button> -->
     <button
       @click="getmaterial(pagination.current_page - 1)"
       :disabled="pagination.current_page === 1"
       class="px-4 py-2 mx-1 text-white bg-red-500 rounded-md hover:bg-red-600"
     >
     Sebelumnya
     </button>
     <button
       v-for="page in pagination.last_page"
       :key="page"
       @click="getmaterial(page)"
       :class="{ 'bg-red-500 text-white': pagination.current_page === page }"
       class="px-4 py-2 mx-1  text-black rounded-md hover:bg-red-600"
     >
       {{ page }}
     </button>
     <button
       @click="getmaterial(pagination.current_page + 1)"
       :disabled="pagination.current_page === pagination.last_page"
       class="px-4 py-2 mx-1 text-white bg-red-500 rounded-md hover:bg-red-600"
     >
       Selanjutnya
     </button>
     <!-- <button
       @click="getmaterial(pagination.last_page)"
       :disabled="pagination.current_page === pagination.last_page"
       class="px-4 py-2 mx-1 text-white bg-blue-500 rounded-md hover:bg-blue-600"
     >
       Last
     </button> -->
   </div>
 </div>

    <!-- <div class="bg-white p-2 rounded-t-lg  mb-6 flex cursor-pointer items-center justify-between font-semibold text-[14px]"  style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; animation: muncul-kiri-kanan-fade 0.8s;">
     <span>#</span>
     <span>ID Work Order</span>
     <span>Nama Team</span>
     <span>Keterangan</span>
     <span>Tanggal Mulai</span>
     <span>Tanggal Akhir</span>
     <span>Aksi</span>
     <span></span>
     
    </div>
    <div class="max-h-[34rem] overflow-auto bg-[#FAFBFD]    p-1">
    <div v-for="(item,index) in materialdata.data" :key="item.project_id">
      <router-link :to="'/EditWorkOrder/' + item.id ">
        <div
          class="group flex flex-col gap-2 rounded-lg bg-white p-5  mb-2 text-[#373636] font-medium  "
          style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; animation: muncul-kiri-kanan-fade 0.8s;"
        >
          <div class="flex cursor-pointer items-center justify-between">
            <span>{{ index + 1 }}</span>
            <span>{{ item.code }}</span>
            <span>{{ item.team_name }}</span>
            <span>{{ item.description }}</span>
            <span>{{ item.start_date }}</span>
            <span>{{ item.end_date }}</span>
            <div class="flex flex-row gap-8">
            <button @click="toggleVisibility(item.id)" class="accordion-header  ">
              <img class="w-8 h-8" src="../assets/edit.png" alt="">
            </button>
            <button class=""></button>
          </div>
          </div>
          <hr class="w-full" v-show="item.visible">
          <div v-show="item.visible" class="h-auto max-h-screen w-full items-center opacity-100 transition-all duration-1000 px-10">
          
          <div class="flex flex-row justify-center items-center">
            <h1 class="text-center">Material Request {{ item.code }}</h1>
          </div>
          <div class="flex flex-row">
            <img class="w-32 h-32" :src="getImageSource(item.status)" alt="">
          <div v-if="materialdata1 && materialdata1.data && materialdata1.data.length > 0">
           
            <div v-for="(material, index) in materialdata1.data" :key="index  " class="flex justify-between gap-4">
              <span>{{ index + 1 }}</span>
              <span>{{ material.material_name }}</span>
              <span>{{ material.name  }}</span>
              <span>{{ material.quantity }}</span>
              <span>{{ material.symbol }}</span>
              
          
            </div>
          </div>
          <div v-else>
            No data available.
          </div>
        
        </div>
        
        </div>
        
      </div>
    </router-link>
  </div>
</div> -->
</div>
  
  
  <div class="w-1/4 pl-4 text-[#373636] " >
    <div class="bg-white p-2 rounded-lg text-center font-semibold mb-4 " style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; animation: muncul-kiri-kanan-fade 0.8s;">
      <span class="">Filter & Sort</span>
    </div>
    <div class="bg-white p-4  rounded-lg" style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; animation: muncul-kiri-kanan-fade 0.8s;">
     
    <form class="max-w-sm mx-auto text-[#373636]">
  
    
    <label for="project" class="block text-sm font-medium leading-6 text-gray-900">Project</label>
    <div class="mt-2">
      <select v-model="project" id="project" name="project" autocomplete="project-name" class="block w-full rounded-md border-0 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
        <option value="" disabled selected>Pilih Project</option>
        <option v-for="data1 in projectdata2.data" :key="data1.id" :value="data1.id">{{ data1.name }}</option>
      </select>
    </div>
         
     
  
    <label for="project" class="block text-sm font-medium leading-6 text-gray-900">Kategory Project</label>
    <div class="mt-2">
      <select v-model="project" id="project" name="project" autocomplete="project-name" class="block w-full rounded-md border-0 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
        <option value="" disabled selected>Pilih Kategory Project</option>
        <option v-for="data2 in categorydata.data" :key="data2.id" :value="data2.id">{{ data2.name }}</option>
      </select>
    </div>
  
    <span class="text-[14px]">Urutan</span>
    <div class="flex flex-row gap-2">  
          <div class="flex items-center ps-4 border border-gray-200 rounded dark:border-gray-700 w-full">
      <input id="bordered-radio-1" type="radio" value="desc" v-model="desc" name="bordered-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
      <label for="bordered-radio-1" class="w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Terbaru</label>
    </div>
    <div class="flex items-center ps-4 border border-gray-200 rounded dark:border-gray-700 w-full">
      <input checked id="bordered-radio-2" type="radio" value="asc" v-model="asc" name="bordered-radio" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
      <label for="bordered-radio-2" class="w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Lama</label>
    </div>
    </div>
  
  </form>
    </div>
  </div>
  
  </div>

  <div v-if="successModal" class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50"  style="  animation: fadein 1s;">
    <div class="bg-white p-8 rounded-lg flex flex-col justify-center items-center  "  style="animation: muncul-kiri-kanan-fade 1s; ">
      <img src="../assets/checked.png" class="w-16 h-16 mb-6 " alt="">
      <p>Data Work Order Berhasil dihapus</p>
    </div>
  </div>

  <div v-if="errorModal" class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50" style="animation: fadein 1s;">
    <div class="bg-white p-8 rounded-lg flex flex-col justify-center items-center  "  style="animation: muncul-kiri-kanan-fade 1s; ">
      <img src="../assets/cancel.png" class="w-16 h-16 mb-6 " alt="">
      <p class="  font-semibold w-72 text-center" >{{ message }}</p>
    </div>
  </div>

  
    
    </template>
    
    <script>
    import axios from "../token";
  
  
    export default {
      data() {
        return {
          id: localStorage.getItem('id') || 'Default Value',
          isOpen: false,
          open: "",
          project:"",
          material:"",
          qty:"",
          price:"",
          desc:"",
          asc:"",
          weight:"",
          coo:"",
          pagination : {
          current_page: 1,
          from: null,
          last_page: null
        },
          dimension:"",
          successModal:false,
          status:"Proses Pengerjaan",
          categorydata:[],
          projectdata:[],
          projectdata2:[],
          userdata:[],
          materialdata:[],
          materialdata1:[],
          search:'',
          urutan:''
        };
      },
      computed: {
        filteredEntries() {
          let filtered = this.absendata;
    
          if (this.selectedStatus.length > 0) {
            filtered = filtered.filter((abse) =>
              this.selectedStatus.includes(abse.status)
            );
          }
    
          if (this.selectedSort === "asc") {
            return filtered.sort((a, b) => a.jammasuk.localeCompare(b.jammasuk));
          } else {
            return filtered.sort((a, b) => b.jammasuk.localeCompare(a.jammasuk));
          }
        },
      },
      methods: {
        getImageSource(status) {
      if (status === 'Ditolak') {
        return require("../assets/rejected.gif");
      } else if (status === 'Disetujui') {
        return require("../assets/file.gif");
      } else {
        return require("../assets/file.gif");
      }
    },
        toggleModal() {
          this.isOpen = !this.isOpen;
        },
        openModal() {
          this.isModalOpen = true;
        },
        closeModal() {
          this.isModalOpen = false;
        },
        async toggleVisibility(id) {
      

        if (id) {
          await this.getmaterial1(id);
        
        }

        const clickedItem = this.materialdata.data.find(item => item.id === id);

        if (clickedItem) {
          clickedItem.visible = !clickedItem.visible;

          if (!clickedItem.visible) {
            this.id = '';
          }
        }

       
      },




  
        async submitForm() {
          try {
            const response = await axios.post(`${process.env.VUE_APP_BACKEND_HOST}/project`, {
              project: this.project,
              material: this.material,
              qty: this.qty,
              price: this.price,
              weight: this.weight,
              coo: this.coo,
              dimension: this.dimension
            });
            console.log(response);
            await new Promise(resolve => setTimeout(resolve, 1000));
            this.successModal = true;
    
            this.getproject();
          
            setTimeout(() => {
              this.successModal = false;
              this.open = false;
            }, 1000);
          } catch (error) {
            console.log(error);
            this.message = "There was an error submitting the form.";
          }
        },
  //       async deleteform(id) {
  //     try {
  //         const response = await axios.delete(`${process.env.VUE_APP_BACKEND_HOST}/material-take-off/`, {
  //             params: {
  //                 id: id
  //             }
  //         });
  //         console.log(response);
  //         await new Promise(resolve => setTimeout(resolve, 1000));
  //         this.successModal = true;
  //         this.getproject();
  //         setTimeout(() => {
  //             this.successModal = false;
  //             this.open = false;
  //         }, 1000);
  //     } catch (error) {
  //         console.log(error);
  //         this.message = "There was an error submitting the form.";
  //     }
  // },
  getStatusStyle(status) {
      if (status === 'Proses') {
        return { backgroundColor: 'rgba(255, 230, 154, 0.5)' }; 
      } else if (status === 'Ditolak') {
        return { backgroundColor: 'rgba(255, 94, 94, 0.5)' }; 
      } else if (status === 'Disetujui') {
        return { backgroundColor: '#00ff00' };
      }
      
      return {};
    },
    getTextClass(status) {
     
      if (status === 'Ditolak') {
        return 'text-[#ED4747]';
      } else if (status === 'Disetujui') {
        return 'text-[#21B115]';
      }
     
      return 'text-[#EFB812]'; 
    },
  async deleteMaterial(id) {
        try {
          let data = new URLSearchParams();
          data.append('id[]', id);
  
          let response = await axios.delete(`${process.env.VUE_APP_BACKEND_HOST}/work-order`, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
             
            },
            data: data
          });
          this.getmaterial();
          console.log(response.data);
          this.successModal = true;
          setTimeout(() => {
            this.successModal = false;
          }, 3000);
        } catch (error) {
          console.error(error);
          this.message = error.response.data.message;
          this.errorModal = true;
          setTimeout(() => {
            this.errorModal = false;
          }, 3000);
        }
      },
        getcategory() {
          axios
            .get(`${process.env.VUE_APP_BACKEND_HOST}/project-category`)
            .then((response) => {
              this.categorydata = response.data;
    
            })
            .catch((error) => {
              console.log(error);
            });
        },
        getuser() {
          axios
            .get(`${process.env.VUE_APP_BACKEND_HOST}/material`)
            .then((response) => {
              this.userdata = response.data;
    
            })
            .catch((error) => {
              console.log(error);
            });
        },
        getproject2() {
          axios
            .get(`${process.env.VUE_APP_BACKEND_HOST}/project/list`)
            .then((response) => {
              this.projectdata2 = response.data;
    
            })
            .catch((error) => {
              console.log(error);
            });
        },
        getproject() {
          axios
            .get(`${process.env.VUE_APP_BACKEND_HOST}/unit`)
            .then((response) => {
              this.projectdata = response.data;
    
            })
            .catch((error) => {
              console.log(error);
            });
        },
        async getmaterial(page) {
      let params = {
        search: this.search,
        limit: 8,
        page: Number.isInteger(page) ? page : 1
      };

      if (this.filterValue) {
        params.filter = 'project';
        params.filter_value = this.filterValue;
      }

      if (this.urutan1) {
        params.sort = this.urutan1;
        params.sort_by = 'code';
      }

      try {
        const response = await axios.get(`${process.env.VUE_APP_BACKEND_HOST}/work-order/list`, { params });
        this.materialdata = response.data;
        this.pagination = {
          current_page: response.data.current_page,
          from: response.data.from,
          last_page: response.data.last_page
        };
      } catch (error) {
        console.error(error);
        this.materialdata = [];
        this.pagination = {
          current_page: 1,
          from: null,
          last_page: null
        };
      }
    },
        // getmaterial() {
        //   console.log(this.desc)
        //   axios
        //     .get(`${process.env.VUE_APP_BACKEND_HOST}/work-order/list`,{
        //       params:{
        //           search:this.search,
        //           // filter:'project',
        //           // filter_value:'1'
        //           limit:100,
        //           sort: this.desc === 'desc' ? 'desc' : 'asc'
        //       }
        //     })
        //     .then((response) => {
        //       this.materialdata = response.data;
            
        //     })
        //     .catch((error) => {
        //       console.log(error);
        //     });
        // },
        getmaterial1(id) {
          axios
            .get(`${process.env.VUE_APP_BACKEND_HOST}/material-request`,{
              params:{
                  // search:this.search,
                  //  filter:'project',
                   id:id
              }
            })
            .then((response) => {
              this.materialdata1 = response.data;
            })
            .catch((error) => {
              console.log(error);
            });
        },
      },
      mounted(){
        this.getcategory();
        this.getproject2();
        this.getproject();
        this.getuser();
        this.getmaterial();
        this.getmaterial1();
      }
    };
    </script>
    