<template>
<div class="py-[1rem] ">
   
  
       
 
    </div>
    <TablePermohonan/>


</template>

<script>
import TablePermohonan from "../Component/TableLembur.vue"


export default {
    components :{ 
     TablePermohonan,
  
    },
}

</script>