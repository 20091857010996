
<template>

<nav class="flex mb-6 font-pop" aria-label="Breadcrumb" style=" animation: muncul-kiri-kanan-fade 1s;"> 
  <ol class="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
    <li>
      <div class="flex items-center">
       
        <router-link to="/stockIn" class="ms-1 text-sm font-medium text-gray-700 hover:text-red-500 md:ms-2 dark:text-gray-400 dark:hover:text-white">Stock In</router-link>
      </div>
    </li>
    <li aria-current="page">
      <div class="flex items-center">
        <svg class="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4"/>
        </svg>
        <span class="ms-1 text-sm font-medium text-red-500 md:ms-2 dark:text-gray-400">Tambah Stock In</span>
      </div>
    </li>
  </ol>
</nav>

  <div class="font-pop">
  <form @submit.prevent="submittemp" class="space-y-12 bg-white md:mb-0 md:px-24 py-4 xxs:px-4 xxs:mb-16 rounded-xl" style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; animation: muncul-kiri-kanan-fade 1s;">
    <div class="border-b border-gray-900/10 pb-12">
      <h2 class="text-base text-[22px] font-semibold leading-7 text-gray-900 mt-4">Tambah Stock In</h2>
      <p class="mt-1 text-sm leading-6 text-gray-600">Lakukan Penginputan Data Purchase Order Baru.</p>
      <div class="mt-6 grid xxs:grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-6">


        <div class="sm:col-span-full">
            <label for="project" class="block text-sm font-medium leading-6 text-gray-900">Project</label>
            <div class="mt-2">
              <select v-model="project" id="project" @change="material2" name="project" autocomplete="project-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                <option value="" disabled selected>Pilih Project</option>
                <option v-for="data2 in projectdata2.data" :key="data2.id" :value="data2.id">{{ data2.name }}</option>
              </select>
            </div>
          </div>

        <div class="sm:col-span-1">
          <label for="project" class="block text-sm font-medium leading-6 text-gray-900">PO#</label>
          <div class="mt-2">
            <select v-model="po_id" id="project"  name="project" @change="material3" autocomplete="project-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
              <option value=""  selected>Pilih PO#</option>
              <option v-for="data4 in listdata.data" :key="data4.id" :value="data4.id">{{ data4.code }}</option>
            </select>
          </div>
        </div>

        <div class="sm:col-span-3">
          <label for="project" class="block text-sm font-medium leading-6 text-gray-900">Vendor</label>
          <div class="mt-2">
            <select v-model="supplier" id="project" name="project" autocomplete="project-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled>
              <option value="" disabled selected>Pilih Vendor</option>
              <option v-for="data3 in vendordata.data" :key="data3.id" :value="data3.id">{{ data3.name }}</option>
            </select>
          </div>
        </div>

     


        <div class="sm:col-span-2">
          <label for="qty" class="block text-sm font-medium leading-6 text-gray-900">Tanggal Barang Masuk</label>
          <div class="mt-2">
            <input v-model="date" type="date" name="qty" id="qty" autocomplete="qty" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
          </div>
        </div>

      
      </div>
    </div>
    <!-- <div class="border-b border-gray-900/10 pb-12"> -->
      <!-- <div class="mt-4 grid xxs:grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-6">
        <div class="sm:col-span-3">
          <label for="material" class="block text-sm font-medium leading-6 text-gray-900">Material</label>
          <div class="mt-2">
            <select v-model="material" id="material" @change="unit" name="material" autocomplete="material-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
              <option value="" disabled selected>Pilih Material</option>
              <option v-for="data1 in userdata.data" :key="data1.id" :value="data1.id">{{ data1.name }}</option>
            </select>
          </div>
        </div>
        
       
        <div class="sm:col-span-1">
          <label for="qty" class="block text-sm font-medium leading-6 text-gray-900">Qty</label>
          <div class="mt-2">
            <input v-model="qtypo" type="text" name="qty" id="qty" autocomplete="qty" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
          </div>
        </div>

        <div class="sm:col-span-1">
          <label for="negara" class="block text-sm font-medium leading-6 text-gray-900">EOM</label>
          <div class="mt-2">
            <input v-model="eom" type="text" name="negara" id="negara" autocomplete="negara" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled />
          </div>
        </div>

        <div class="sm:col-span-1">
          <label for="qty" class="block text-sm font-medium leading-6 text-gray-900">Qty Terima</label>
          <div class="mt-2">
            <input v-model="qty" type="number" name="qty" id="qty" autocomplete="qty" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
          </div>
        </div>

    
        
      </div>
      <div class="flex justify-end mt-6">
      <button type="submit" class="px-4 py-2 text-sm font-medium text-white bg-green-400 rounded-md hover:bg-green-700 focus:outline-none ">Tambahkan</button>
    </div>
       -->
    <!-- </div>
     -->
<div class="border-b border-gray-900/10 pb-12">
<div class="relative overflow-x-auto shadow-md sm:rounded-lg font-pop ">
  <table class="w-full text-[12px] text-left rtl:text-right text-gray-500 dark:text-gray-400">
      <thead class="t text-gray-700  bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" class="px-6 py-3">
                  No
              </th>
              <th scope="col" class="px-6 py-3">
                  Material
              </th>
              <th scope="col" class="px-6 py-3">
                  Qty
              </th>
              <th scope="col" class="px-6 py-3">
                  Eom
              </th>
              <th scope="col" class="px-6 py-3">
                Qty Yang Belum Diterima
              </th>
             
              <th scope="col" class="px-6 py-3">
                Qty Terima
              </th>
             
             
          </tr>
      </thead>
      <tbody>
          <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"  v-for="(entry, index) in podata.data" :key="index">
            <td class="px-6 py-4">
                  {{ index + 1 }}
              </td>
              <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                  {{ entry.material.name }}
              </th>
              <td class="px-6 py-4">
                  {{ entry.quantity }}
              </td>
              <td class="px-6 py-4">
                  {{ entry.material.unit_name }}
              </td>
              <td class="px-6 py-4">
                  {{ entry.remaining_quantity }}
              </td>
              <td class="px-6 py-4">
                <div class="sm:col-span-1">
          <div class="mt-2">
            <input v-model="quantity1[index]" type="number" :id="'newQuantity' + index" autocomplete="qty" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
          </div>
        </div>
              </td>
          </tr>
         
      </tbody>
  </table>
</div>

</div>
<div class="mt-10 flex items-center justify-end gap-x-6">
    <router-link to="/stockIn"  class="rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Kembali</router-link>
    <form @submit.prevent="submitForm">
    <button type="submit" class="rounded-md bg-green-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Simpan</button>
  </form>
  </div>

  </form>
</div>
<div v-if="successModal" class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50"  style="  animation: fadein 1s;">
  <div class="bg-white p-8 rounded-lg flex flex-col justify-center items-center  "  style="animation: muncul-kiri-kanan-fade 1s; ">
    <img src="../../../assets/verify.png" class="w-16 h-16 mb-6 " alt="">
    <p>Data Stock In Berhasil ditambahkan</p>
  </div>
</div>

<div v-if="errorModal" class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50" style="animation: fadein 1s;">
    <div class="bg-white p-8 rounded-lg flex flex-col justify-center items-center  "  style="animation: muncul-kiri-kanan-fade 1s; ">
      <img src="../../../assets/cancel.png" class="w-16 h-16 mb-6 " alt="">
      <p class="  font-semibold w-72 text-center" >{{ message }}</p>
    </div>
  </div>

</template>






<script>
import axios from "../../../token";
import { ref } from 'vue';
import { setupLeaveConfirmation } from '../../../token/confirmLeave.js';




export default {
data() {
  return {
    id: localStorage.getItem('id') || 'Default Value',
    isOpen: false,
    entries: [],
    open: "",
    project: '',
    po_no: '',
    date: '',
    supplier:'',
    po_id:'',
    eom: '',
    Deskripsi: '',
    price:[],
    quantity1: [],
    description:[],
    category: '',
    material:[],
    coo: [],
    BeratSatuan: '',
    dimension:[],
    weight: [],
    successModal:false,
    errorModal:false,
    status:"Proses Pengerjaan",
    categorydata:[],
    projectdata:[],
    projectdata2:[],
    userdata:[],
    vendordata:[],
    total:[],
    podata:[],
    material1:'',
    material4:'',
    listdata:[]
  };
},
setup() {
  const isHovered = ref(false);
  setupLeaveConfirmation();

  return {
   
    isHovered,
    setupLeaveConfirmation
    
  }
},
computed: {
  filteredEntries() {
    let filtered = this.absendata;

    if (this.selectedStatus.length > 0) {
      filtered = filtered.filter((abse) =>
        this.selectedStatus.includes(abse.status)
      );
    }

    if (this.selectedSort === "asc") {
      return filtered.sort((a, b) => a.jammasuk.localeCompare(b.jammasuk));
    } else {
      return filtered.sort((a, b) => b.jammasuk.localeCompare(a.jammasuk));
    }
  },
},
watch: {
  qty(val) {

  this.total = val * this.price;
},
price(val) {

  this.total = this.qty * val;
},
quantity(newQuantity) {
    console.log('New Quantity:', newQuantity);
    this.handleQuantityChange(newQuantity);
  },
},
methods: {
  unit() {
  const selectedMaterial = this.userdata.data.find(material => material.id === this.material);
  if (selectedMaterial) {
    this.eom = selectedMaterial.symbol;
  } else {
    this.eom = '';
  }
},
createQuantityHandler(index) {
      return function(newQuantity) {
        console.log('New Quantity:', newQuantity);
        this.handleQuantityChange(newQuantity, index);
      }.bind(this); // Ensure the correct `this` context
    },
material2() {
    this.material1 = this.project;
    console.log(this.material1)
    if (this.material1) {
      this.getpo1()
      this.podata=[]
      this.po_id=''
    } else {
      this.material1 = '';
      this.podata=[]
    }
  },
  material3() {
    this.material4 = this.po_id;
    console.log(this.material4)
    if (this.material4) {
      this.getPolist()
    } else {
      this.material4 = '';
      this.podata=[]
    }
  },
 
  removeEntry(index) {
      this.podata.data.splice(index, 1);
  },
  toggleModal() {
    this.isOpen = !this.isOpen;
  },
  openModal() {
    this.isModalOpen = true;
  },
  closeModal() {
    this.isModalOpen = false;
  },
  resetForm() {
    this.podata=[];
    this.supplier=[];
    this.po_id = '';
    this.date = '';
    this.project='';
  },
  handleQuantityChange(newQuantity) {
  const emptyFields = [];

  if (!newQuantity || /^\s*$/.test(newQuantity)) {
    emptyFields.push("Quantity");
  }

  if (emptyFields.length === 0) {
    // Ensure that entries is initialized as an array before pushing
    if (!Array.isArray(this.entries)) {
      console.error('Entries is not an array:', this.entries);
      this.entries = []; // Initialize as an array
    }

    // Assuming this.podata.data is an array of objects
    const materials = this.podata.data
      .filter(entry => entry.material)
      .map(entry => entry.material);

    console.log(materials);

    // If validation passes, push a new object with quantity and corresponding materials into the entries array
    // this.entries[index].push({
    //   quantity: newQuantity,
    //   materials: materials, // or simply materials if you want the key to be the same as the variable
    // });

    console.log(this.entries)
  }
},


  async submitForm() {
    const formData = new FormData();
    console.log(this.entries)
    this.podata.data.forEach((entry, index) => {
    formData.append(`material[${index}]`, entry.material.id);
    formData.append(`qty[${index}]`,this.quantity1[index]);
   
    // Add other properties as needed
  });

  formData.append('date', this.date);
  formData.append('po_id', this.po_id);
  formData.append('received_by', this.id);

    
    try {
  const response = await axios.post(`${process.env.VUE_APP_BACKEND_HOST}/stock-in`, formData);

  console.log(response);


  this.successModal = true;
  setTimeout(() => {
  this.successModal = false;
}, 2000);
  this.entries=[]

  this.open = false;

  this.resetForm()
  setTimeout(() => {
        this.$router.push('/stockIn');
      }, 2000);
} catch (error) {
  console.log(error);

  this.errorModal = true;
  this.message = error.response.data.message;

  await new Promise(resolve => setTimeout(resolve, 2000));
  this.errorModal = false;
}
  },
  getcategory() {
    axios
      .get(`${process.env.VUE_APP_BACKEND_HOST}/project-category`)
      .then((response) => {
        this.categorydata = response.data;

      })
      .catch((error) => {
        console.log(error);
      });
  },
  getuser() {
    axios
      .get(`${process.env.VUE_APP_BACKEND_HOST}/material`)
      .then((response) => {
        this.userdata = response.data;

      })
      .catch((error) => {
        console.log(error);
      });
  },
  getpo1() {
    axios
      .get(`${process.env.VUE_APP_BACKEND_HOST}/purchase-order/dropdown`,{
        params:{
          project_id:this.material1
        }
      })
      .then((response) => {
        this.listdata = response.data;

      })
      .catch((error) => {
        
        this.message = error.response.data.message;
        this.errorModal = true;
        setTimeout(() => {
          this.errorModal = false;
          this.open = false;
          this.project='';
        }, 3000);
        
      });
  },
  getproject2() {
    axios
      .get(`${process.env.VUE_APP_BACKEND_HOST}/project/list`)
      .then((response) => {
        this.projectdata2 = response.data;

      })
      .catch((error) => {
        console.log(error);
      });
  },
  getsupplier() {
    axios
      .get(`${process.env.VUE_APP_BACKEND_HOST}/supplier/list`)
      .then((response) => {
        this.vendordata = response.data;

      })
      .catch((error) => {
        console.log(error);
      });
  },
  getproject() {
    axios
      .get(`${process.env.VUE_APP_BACKEND_HOST}/unit`)
      .then((response) => {
        this.projectdata = response.data;

      })
      .catch((error) => {
        console.log(error);
      });
  },
  getPolist() {
    axios
      .get(`${process.env.VUE_APP_BACKEND_HOST}/purchase-order`,{
        params:{
          id:this.material4
        }
      })
      .then((response) => {
        this.podata = response.data;

        if (this.podata && this.podata.data.length > 0) {
        const firstEntry = this.podata.data[0]; 
    //     // this.entries = firstEntry.material || '';
    //     if (firstEntry.material) {
    //   this.entries.push({
    //     material: firstEntry.material
    //   });
    // }
        this.po_no = firstEntry.code || '';
        console.log(firstEntry.date)
        this.date = firstEntry.date || '' ;
        this.supplier = firstEntry.supplier_id || '';
      }
    })
      .catch((error) => {
        console.log(error);
      });
  },
},
mounted(){
  this.getcategory();
  this.getproject2();
  this.getproject();
  this.getuser();
  this.getsupplier();
}
};
</script>
