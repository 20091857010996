<template>
   <div class="xl:grid xl:gap-10 xl:grid-cols-4 md:grid-cols-3  xxs:grid xxs:grid-cols-2 xxs:gap-12 items-center justify-center h-[30rem] font-pop font-medium text-[#5C5C68] text-[14px] xl:px-[35rem] xl:py-8c xxs:px-20 xxs:py-7   "   style="  animation: muncul-kiri-kanan-fade 1s;">


      <router-link v-if="permidata.data && permidata.data.includes('Project')" to="/Project">
      <div class="flex flex-col items-center justify-center   transition-transform transform-gpu hover:scale-110">
        <div class="flex items-center justify-center bg-white xl:w-[100px] xl:h-[100px] xxs:w-[80px] xxs:h-[80px] rounded-lg mb-3 " style="box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;"
        >
           <img src="../assets/project1.png" class="xl:w-[70px] xxs:w-[60px]" alt="">
        </div>
        <h2 class="text-center">Project</h2>
    </div>
   </router-link> 

   <router-link v-if="permidata.data && permidata.data.includes('Modul Inventory')" to="/Inventory">
      <div class="flex flex-col items-center justify-center   transition-transform transform-gpu hover:scale-110">
        <div class="flex items-center justify-center bg-white xl:w-[100px] xl:h-[100px] xxs:w-[80px] xxs:h-[80px] rounded-lg mb-3 " style="box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;"
        >
           <img src="../assets/iv.png" class="xl:w-[60px] xxs:w-[60px]" alt="">
        </div>
        <h2 class="text-center">Inventory</h2>
    </div>
   </router-link>

   <router-link v-if="permidata.data && permidata.data.includes('Modul Work Order')" to="/WO">
      <div class="flex flex-col items-center justify-center   transition-transform transform-gpu hover:scale-110">
        <div class="flex items-center justify-center bg-white xl:w-[100px] xl:h-[100px] xxs:w-[80px] xxs:h-[80px] rounded-lg mb-3 " style="box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;"
        >
           <img src="../assets/wo.png" class="xl:w-[60px] xxs:w-[60px]" alt="">
        </div>
        <h2 class="text-center">Work Order</h2>
    </div>
   </router-link>

    <router-link v-if="permidata.data && permidata.data.includes('Modul Absensi')" to="/DashboardAbsensi">
    <div class="flex flex-col items-center justify-center   transition-transform transform-gpu hover:scale-110">
        <div class="flex items-center justify-center bg-white xl:w-[100px] xl:h-[100px] xxs:w-[80px] xxs:h-[80px] rounded-lg mb-3 " style="box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;"
        
        >
           <img src="../assets/calendar.png" class="xl:w-[70px] xxs:w-[60px]" alt="">
        </div>
        <h2 class="text-center">Absensi</h2>
    </div>
   </router-link>

   <router-link v-if="permidata.data && permidata.data.includes('Modul Payroll')" to="/Payroll">
    <div class="flex flex-col items-center justify-center   transition-transform transform-gpu hover:scale-110">
        <div class="flex items-center justify-center bg-white xl:w-[100px] xl:h-[100px] xxs:w-[80px] xxs:h-[80px] rounded-lg mb-3 " style="box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;">
           <img src="../assets/Payroll.png" class="xl:w-[70px] xxs:w-[60px]" alt="">
        </div>
        <h2 class="text-center">Payroll</h2>
    </div>
   </router-link>


    <router-link v-if="permidata.data && permidata.data.includes('Modul HRD')" to="/HumanResource">
    <div class="flex flex-col items-center justify-center   transition-transform transform-gpu hover:scale-110">
        <div class="flex items-center justify-center bg-white xl:w-[100px] xl:h-[100px] xxs:w-[80px] xxs:h-[80px] rounded-lg mb-3 " style="box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;">
           <img src="../assets/hr1.png" class="xl:w-[70px] xxs:w-[60px]" alt="">
        </div>
        <h2 class="text-center">HR</h2>
    </div>
    </router-link>

    <router-link v-if="permidata.data && permidata.data.includes('Pinjaman')" to="/Pinjaman">
    <div class="flex flex-col items-center justify-center   transition-transform transform-gpu hover:scale-110">
        <div class="flex items-center justify-center bg-white xl:w-[100px] xl:h-[100px] xxs:w-[80px] xxs:h-[80px] rounded-lg mb-3 " style="box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;">
           <img src="../assets/loan.png" class="xl:w-[65px] xxs:w-[60px]" alt="">
        </div>
        <h2 class="text-center">Pinjaman</h2>
    </div>
    </router-link>

    <router-link v-if="permidata.data && permidata.data.includes('Modul Konfigurasi')" to="/Konfigurasi">
        <div class="flex flex-col items-center justify-center transition-transform transform-gpu hover:scale-110">
          <div class="flex items-center justify-center bg-white xl:w-[100px] xl:h-[100px] xxs:w-[80px] xxs:h-[80px] rounded-lg mb-3" style="box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;">
            <img src="../assets/settings.png" class="xl:w-[70px] xxs:w-[60px]" alt="">
          </div>
          <h2 class="">Konfigurasi</h2>
        </div>
      </router-link>
    </div>
</template>

<script>
import axios from "../token";
export default {
  name: "NavBar",
  data() {
    return {
      nama: localStorage.getItem('name') || 'Default Value',
      email: localStorage.getItem('email') || 'Default Value',
      isDropdownOpen: false,
      isModalOpen: false, // Add this line to define isModalOpen
      avatarSrc: 'https://awsimages.detik.net.id/community/media/visual/2019/02/19/42393387-9c5c-4be4-97b8-49260708719e.jpeg?w=600&q=90',
      permidata:[],
    };
  },
  methods: {
    toggleModal() {
      this.isModalOpen = !this.isModalOpen;
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    postlogout() {
      axios
        .post(`${process.env.VUE_APP_BACKEND_HOST}/logout`)
        .then((response) => {
          this.logout = response.data;
           this.$router.push('/');
           localStorage.removeItem('token');
          localStorage.removeItem('id');
          localStorage.removeItem('name');
          localStorage.removeItem('email');
          localStorage.removeItem('file');
          localStorage.removeItem('id');
          localStorage.removeItem('activeTab');
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getpermi1() {
      axios
        .get(`${process.env.VUE_APP_BACKEND_HOST}/permission/check`)
        .then((response) => {
          this.permidata = response.data;

        
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {
    isLargeScreen() {
      return window.innerWidth > 480;
    },
   
  },
  mounted() {
    this.token = localStorage.getItem('token')
    if (!this.token) {
      this.$router.push('/404');
    }
    this.getpermi1();
  },
};
</script>

