<template>
    <div class="">
        <button class="w-[118px] h-[30px] flex-shrink-0 rounded-lg text-[#868686] bg-[#EBEDF0] "  @click="open = true">
            <h3 class="text-[12px] font-medium">Lihat Data</h3>
          </button>
    </div>

    
    <transition>
        <div class="fixed z-10 left-0 top-0 w-full h-full flex items-center justify-center overflow-auto bg-black/40 bg-opacity-50  font-pop font-regular" v-if="open">
            <div class="relative w-[1000px]  bg-[#fff] px-20 py-10 rounded-xl">
                <div class="mb-8">
                    <h1 class="text-[20px] mb-4">Approval Pengajuan</h1>
                    <hr class=" border-gray-300">
                </div>
            <div class="grid  grid-cols-2 gap-6">
             
           
                <div class="grid grid-flow-row-dense grid-cols-3">
                    <label class="mt-5" for="">Nama</label>
                    <div class="col-span-2">
                        <input v-model="nama" class="w-full px-7 mt-4 mb-4 py-2 border-gray-300 rounded-md " type="text" disabled>
                    </div>
                </div>
                <div class="grid grid-flow-row-dense grid-cols-3">
                    <label class="mt-5" for="">Type Pengajuan</label>
                    <div class="col-span-2">
                        <input v-model="type" class="w-full px-7 mt-4 mb-4 py-2 border-gray-300 rounded-md " type="text" disabled>
                    </div>
                </div>
                <div class="grid grid-flow-row-dense grid-cols-3">
                    <label class="mt-5" for="">Mulai Pengajuan</label>
                    <div class="col-span-2">
                        <input v-model="mulai" class="w-full px-7 mt-4 mb-4 py-2 border-gray-300 rounded-md " type="text" disabled>
                    </div>
                </div>

                <div class="grid grid-flow-row-dense grid-cols-3">
                    <label class="mt-5" for="">Selesai</label>
                    <div class="col-span-2">
                        <input v-model="Selesai" class="w-full px-7 mt-4 mb-4 py-2 border-gray-300 rounded-md " type="text" disabled>
                    </div>
                </div>
                <div class="grid grid-flow-row-dense grid-cols-3">
                    <label class="mt-5" for="">Keterangan</label>
                    <div class="col-span-2">
                        <input v-model="Keterangan" class="w-full px-7 mt-4 mb-4 py-2 border-gray-300 rounded-md " type="text" disabled>
                    </div>
                </div>
                <div class="grid grid-flow-row-dense grid-cols-3">
                    <label class="mt-5" for="">Status</label>
                    <div class="col-span-2">
                        <input v-model="status" class="w-full px-7 mt-4 mb-4 py-2 border-gray-300 rounded-md " type="text" disabled>
                    </div>
                </div>
                
        </div>
        <div class="flex justify-between">
            
              <button class=" mt-5 bg-red-500 px-5 py-3 rounded-md" @click="open = false">
                <h1 class="text-[12px] text-white">Back</h1>
              </button>
              <div class=""  v-if="permidata.data && permidata.data.includes('Approval Permohonan Karyawan')">
                <button @click="handleButtonClick('rejected')" class="rounded-md mr-4 mt-5 bg-red-500  px-5 py-3 text-sm font-semibold text-white shadow-sm hover:bg-green-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                Tolak
              </button>

              <button @click="handleButtonClick('approved')" class="rounded-md mt-5 bg-red-5 bg-green-400  px-5 py-3 text-sm font-semibold text-white shadow-sm hover:bg-green-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                Setujui
              </button>
            </div>
            </div>
            </div>
          </div>          
    </transition>

    <div v-if="successModal" class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50"  style="  animation: fadein 1s;">
    <div class="bg-white p-8 rounded-lg flex flex-col justify-center items-center  "  style="animation: muncul-kiri-kanan-fade 1s; ">
      <img src="../../assets/verify.png" class="w-16 h-16 mb-6 " alt="">
      <p>Status Berhasil Di Update</p>
    </div>
  </div>

  <div v-if="errorModal" class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50" style="animation: fadein 1s;">
    <div class="bg-white p-8 rounded-lg flex flex-col justify-center items-center  "  style="animation: muncul-kiri-kanan-fade 1s; ">
      <img src="../../assets/cancel.png" class="w-16 h-16 mb-6 " alt="">
      <p class="  font-semibold w-72 text-center" >{{ message }}</p>
    </div>
  </div>

</template>

<script>
import axios from "../../token";
export default {

    data() {
      return {
        open: false,
        errorModal:false,
        successModal:false,
        nama: this.permohonandata.employee_name,
        status:this.permohonandata.status,
        Selesai:this.permohonandata.end_date,
        Keterangan:this.permohonandata.reason,
        mulai:this.permohonandata.start_date,
        type:this.permohonandata.type,
        id:this.permohonandata.id,
        permidata:[],
      };
    },
    props: {
    permohonandata: {
      type: Object, // Adjust the type according to your data structure
      required: true,
    },
  },
  methods: {
    material2() {
    this.material1 = this.project;
    console.log(this.material1)
    if (this.material1) {
      this.getuser()
    } else {
      this.material1 = '';
    }
  },
        unit() {
      const selectedMaterial1 = this.userdata.data.find(material => material.id === this.selectedMaterial.id);

      if (selectedMaterial1) {
        this.eom = selectedMaterial1.symbol;
      } else {
        this.eom = '';
      }
    },
    toggleModal() {
      this.isOpen = !this.isOpen;
    },
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    handleButtonClick(action) {
      console.log(`Button clicked: ${action}`);
      this.status1 = action;
      this.submitForm();
    },
    getpermi1() {
      axios
        .get(`${process.env.VUE_APP_BACKEND_HOST}/permission/check`)
        .then((response) => {
          this.permidata = response.data;

        
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async submitForm() {
      const formData = new FormData();

      formData.append('status',this.status1),
      formData.append('id',this.id)

      try {
        const response = await axios.post(`${process.env.VUE_APP_BACKEND_HOST}/employee-request/process`, formData);

        console.log(response);
        await new Promise(resolve => setTimeout(resolve, 1000));
        this.successModal = true;
    
      
        setTimeout(() => {
          this.successModal = false;
          this.open = false;
        }, 2000);
        setTimeout(() => {
            window.location.reload()
      }, 2000);
      } catch (error) {
        console.log(error);
        this.errorModal = true;
        setTimeout(() => {
          this.errorModal = false;
          this.open = false;
        }, 3000);
        this.message = error.response.data.message;
      }
    },
  
  },
  mounted(){
    this.getpermi1();
  }
  };
  </script>

  <style scoped>
  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.5s ease;
  }
  
  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }


  
  </style>