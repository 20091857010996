
<template>

<nav class="flex mb-6 font-pop" aria-label="Breadcrumb" style=" animation: muncul-kiri-kanan-fade 1s;"> 
  <ol class="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
    <li>
      <div class="flex items-center">
       
        <router-link to="/MTO" class="ms-1 text-sm font-medium text-gray-700 hover:text-red-500 md:ms-2 dark:text-gray-400 dark:hover:text-white">Material Take Off</router-link>
      </div>
    </li>
    <li aria-current="page">
      <div class="flex items-center">
        <svg class="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4"/>
        </svg>
        <span class="ms-1 text-sm font-medium text-red-500 md:ms-2 dark:text-gray-400">Tambah Material Take Off</span>
      </div>
    </li>
  </ol>
</nav>



    <div class="font-pop">
    <form @submit.prevent="submittemp" class="space-y-12 bg-white md:mb-0 md:px-24 py-4 xxs:px-4 xxs:mb-16 rounded-xl" style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; animation: muncul-kiri-kanan-fade 1s;">
      <div class="border-b border-gray-900/10 pb-12">
        <h2 class="text-base text-[22px] font-semibold leading-7 text-gray-900 mt-4">Tambah Material Take Off</h2>
        <p class="mt-1 text-sm leading-6 text-gray-600">Lakukan Penginputan Data Materiak Baru.</p>
        <div class="mt-6 grid xxs:grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-6">
          <div class="sm:col-span-full">
            <label for="project" class="block text-sm font-medium leading-6 text-gray-900">Project</label>
            <div class="mt-2">
              <select v-model="project_id" id="project" name="project" autocomplete="project-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                <option value="" disabled selected>Pilih Project</option>
                <option v-for="data2 in projectdata2.data" :key="data2.id" :value="data2.id">{{ data2.name }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="border-b border-gray-900/10 pb-12">
        <div class="mt-4 grid xxs:grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-6">
          

          <div class="sm:col-span-3">
            <label for="material" class="block text-sm font-medium leading-6 text-gray-900">Material</label>
            <div class="mt-2">
              <select v-model="material" id="material" @change="unit" name="material" autocomplete="material-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                <option value="" disabled selected>Pilih Material</option>
                <option v-for="data1 in userdata.data" :key="data1.name" :value="data1.name">{{ data1.name_dimension }}</option>
              </select>
            </div>
          </div>

          <div class="sm:col-span-3">
            <label for="deskripsi" class="block text-sm font-medium leading-6 text-gray-900">Demension</label>
            <div class="mt-2">
              <input v-model="dimension" type="text" name="deskripsi" id="deskripsi" autocomplete="deskripsi" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>

          <div class="sm:col-span-3">
            <label for="deskripsi" class="block text-sm font-medium leading-6 text-gray-900">Deskripsi</label>
            <div class="mt-2">
              <input v-model="description" type="text" name="deskripsi" id="deskripsi" autocomplete="deskripsi" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>

   

          <div class="sm:col-span-1">
            <label for="qty" class="block text-sm font-medium leading-6 text-gray-900">Qty</label>
            <div class="mt-2">
              <input v-model="qty" type="text" name="qty" id="qty" autocomplete="qty" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>

          <div class="sm:col-span-1">
            <label for="negara" class="block text-sm font-medium leading-6 text-gray-900">EOM</label>
            <div class="mt-2">
              <input v-model="eom1" type="text" name="negara" id="negara" autocomplete="negara" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled />
            </div>
          </div>

          <div class="sm:col-span-1">
            <label for="negara" class="block text-sm font-medium leading-6 text-gray-900">Negara</label>
            <div class="mt-2">
              <input v-model="coo" type="text" name="negara" id="negara" autocomplete="negara" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>

          <div class="sm:col-span-1">
            <label for="berat-satuan" class="block text-sm font-medium leading-6 text-gray-900">Berat Satuan</label>
            <div class="mt-2">
              <input v-model="weight" type="number" name="berat-satuan" id="berat-satuan" autocomplete="berat-satuan" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>

          <div class="sm:col-span-2">
            <label for="qty" class="block text-sm font-medium leading-6 text-gray-900">Berat Total</label>
            <div class="mt-2">
              <input v-model="weightTotal" type="text" name="qty" id="qty" autocomplete="qty" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled />
            </div>
          </div>


          
          
        </div>
        <div class="flex justify-end mt-6 gap-4">
          <form @submit.prevent="resetForm">
          <button   class="px-4 py-2 text-sm font-medium text-white bg-red-400 rounded-md hover:bg-red-700 focus:outline-none">Batal</button>
        </form>
          <button v-if="!isEditMode" type="submit" class="px-4 py-2 text-sm font-medium text-white bg-green-400 rounded-md hover:bg-green-700 focus:outline-none">Tambahkan</button>
          
          <button v-if="isEditMode" type="submit" class="px-4 py-2 text-sm font-medium text-white bg-green-400 rounded-md hover:bg-green-700 focus:outline-none">Ubah Data</button>
      </div>
       <form @submit.prevent="insertData">
      <div class="flex items-center justify-center w-full mb-4 mt-4">
          <label for="dropzone-file" class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
              <div class="flex flex-col items-center justify-center pt-5 pb-6">
                  <svg   v-if="!uploadedFileName" class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
                  </svg>
                  <img v-if="uploadedFileName" class="w-12 h-12 mb-10" src="../../../assets/excel.png" alt="">
                  <p v-if="uploadedFileName" class="text-[14px] text-gray-500 dark:text-gray-400">{{ uploadedFileName }}</p>
                  <p v-if="!uploadedFileName" class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Klik Untuk Upload</span> Atau Tarik dan Lepas</p>
                  <p v-if="!uploadedFileName" class="text-xs text-gray-500 dark:text-gray-400">XLSX Only</p>
              </div>
              <input id="dropzone-file" type="file" class="hidden" @change="handleFileUpload" />
          </label>
      </div> 
      <button type="submit"  class="rounded-md bg-green-400 px-3 py-2 text-sm  font-semibold text-white shadow-sm hover:bg-green-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Upload Data</button>
    </form>
    </div>
      
<div class="border-b border-gray-900/10 pb-12">
  <div class="relative overflow-x-auto shadow-md sm:rounded-lg font-pop  ">
    <table class="w-full text-[12px] text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead class=" text-gray-700  bg-gray-50 dark:bg-gray-700 dark:text-gray-400 sticky top-0 z-10">
            <tr>
              <th scope="col" class="px-6 py-3">
                    No
                </th>
                <th scope="col" class="px-6 py-3">
                    Material
                </th>
                <th scope="col" class="px-6 py-3">
                    Dekripsi
                </th>
                <th scope="col" class="px-6 py-3">
                    Demensi
                </th>
                <th scope="col" class="px-6 py-3">
                    Qty
                </th>
              
                <th scope="col" class="px-6 py-3">
                    Eom
                </th>
                <th scope="col" class="px-6 py-3">
                    Negara
                </th>
                <th scope="col" class="px-6 py-3">
                    Berat Satuan
                </th>
                <th scope="col" class="px-6 py-3">
                    Berat Total
                </th>
               
                <th scope="col" class="px-6 py-3">
                    Aksi
                </th>
                <th scope="col" class="px-6 py-3">
                    
                </th>
            </tr>
        </thead>
        <tbody>
            <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"  v-for="(entry, index) in entries" :key="index">
              <td class="px-6 py-4">
                    {{ index + 1 }}
                </td>
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                  {{ entry.material.length > 0 ? entry.material : ' ' }}
                </th>
                <td class="px-6 py-4">
                    {{ entry.description > 0 ? entry.description : ' ' }}
                </td>
                <td class="px-6 py-4">
                    {{ entry.dimension  }}
                </td>
                <td class="px-6 py-4">
                    {{ entry.qty> 0 ? entry.qty : ' ' }}
                </td>
               
                <td class="px-6 py-4">
                    {{ entry.eom  }}
                </td>
                <td class="px-6 py-4">
                    {{ entry.coo > 0 ? entry.coo : ' '}}
                </td>
                <td class="px-6 py-4">
                    {{ entry.weight > 0 ? entry.weight : ' '}}
                </td>
                <td class="px-6 py-4">
                    {{ entry.weightTotal  }}
                </td>
                
                <td class="px-6 py-4 ">
                  <a  href="#"  class="font-medium text-red-600 dark:text-red-500 hover:underline"  @click.prevent="editEntry(index)">Ubah</a> 
                 </td>
                 <td>
                  <a  href="#"  class="font-medium text-red-600 dark:text-red-500 hover:underline"  @click.prevent="removeEntry(index)">Hapus</a> 
                 </td>
            </tr>
           
        </tbody>
    </table>
</div>
</div>
<div class="mt-10 flex items-center justify-end gap-x-6">
      <router-link to="/MTO"  class="rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Kembali</router-link>
      <form @submit.prevent="submitForm">
      <button type="submit" class="rounded-md bg-green-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Simpan</button>
    </form>
    </div>

    </form>
  </div>
  <div v-if="successModal" class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50"  style="  animation: fadein 1s;">
    <div class="bg-white p-8 rounded-lg flex flex-col justify-center items-center  "  style="animation: muncul-kiri-kanan-fade 1s; ">
      <img src="../../../assets/checked.png" class="w-16 h-16 mb-6 " alt="">
      <p>Data Material Take Off Berhasil ditambahkan</p>
    </div>
  </div>

  <div v-if="errorModal1" class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50" style="animation: fadein 1s;">
    <div class="bg-white p-8 rounded-lg flex flex-col justify-center items-center  "  style="animation: muncul-kiri-kanan-fade 1s; ">
      <img src="../../../assets/cancel.png" class="w-16 h-16 mb-6 " alt="">
      <p class="  font-semibold w-72 text-center" >{{ message }}</p>
    </div>
  </div>


  <div v-if="errorModal" class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50  flex justify-center items-center z-50" style="animation: fadein 1s;">
    <div class="bg-white p-8 rounded-lg flex flex-col justify-center items-center      "  style="animation: muncul-kiri-kanan-fade 1s; "    >
  
       
      <img src="../../../assets/cancel.png" class="w-16 h-16 mb-6 " alt="">
      <p class="  font-semibold w-72 text-center mb-4" >{{ message }}</p>
      <div class="relative overflow-x-auto overflow-y-auto h-[15rem]">
    <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 sticky top-0 z-10">
            <tr>
                <th scope="col" class="px-6 py-3">
                    Material & Dimension
                </th>
            </tr>
        </thead>
        <tbody>
            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700" v-for="(entry, index) in master.data" :key="index">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {{ entry }}
                </th>
            </tr>
        </tbody>
    </table>
</div>

<p class=" font-semibold w-80 text-center mt-4 mb-4">Apakah data diatas akan disimpan dalam master material ?</p>
<div class="flex flex-row gap-6">

  <button @click="handleButtonClick(false)" class="rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
      Tidak
    </button>
    <button @click="handleButtonClick(true)" class="rounded-md bg-green-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
      Iya
    </button>
    </div>

    </div>

  </div>

  <div v-if="errorModal2" class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50  flex justify-center items-center z-50" style="animation: fadein 1s;">
    <div class="bg-white p-8 rounded-lg flex flex-col justify-center items-center      "  style="animation: muncul-kiri-kanan-fade 1s; "    >
  
       
      <img src="../../../assets/cancel.png" class="w-16 h-16 mb-6 " alt="">
      <p class="  font-semibold w-72 text-center mb-4" >{{ message }}</p>
      <div class="relative overflow-x-auto overflow-y-auto h-[15rem]">
    <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 sticky top-0 z-10">
            <tr>
                <th scope="col" class="px-6 py-3">
                    Material & Dimension
                </th>
            </tr>
        </thead>
        <tbody>
            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700" v-for="(entry, index) in master.data" :key="index">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {{ entry }}
                </th>
            </tr>
        </tbody>
    </table>
</div>

<div class="flex flex-row gap-6">

  <button @click="handleButtonClick1(false)" class="rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
      Tutup
    </button>

    </div>

    </div>

  </div>

</template>






<script>
import axios from "../../../token";
import { ref } from 'vue';
import { setupLeaveConfirmation } from '../../../token/confirmLeave.js';
import * as XLSX from 'xlsx';



export default {
  data() {
    return {
      id: localStorage.getItem('id') || 'Default Value',
      uploadedFileName: null,
      isOpen: false,
      exceldata:[],
      entries: [],
      isEditMode: false,
      open: "",
      weightTotal:'',
      project_id: '',
      eom1: '',
      register:false,
      Deskripsi: '',
      price:[],
      qty: [],
      description:[],
      category: '',
      material:[],
      coo: [],
      BeratSatuan: '',
      dimension:[],
      weight: [],
      successModal:false,
      errorModal:false,
      errorModal1:false,
      errorModal2:false,
      status:"Proses Pengerjaan",
      categorydata:[],
      projectdata:[],
      projectdata2:[],
      userdata:[],
      master:[],
    };
  },
  setup() {
    const isHovered = ref(false);
    setupLeaveConfirmation();

    return {
     
      isHovered,
      setupLeaveConfirmation
      
    }
},
  computed: {
    filteredEntries() {
      let filtered = this.absendata;

      if (this.selectedStatus.length > 0) {
        filtered = filtered.filter((abse) =>
          this.selectedStatus.includes(abse.status)
        );
      }

      if (this.selectedSort === "asc") {
        return filtered.sort((a, b) => a.jammasuk.localeCompare(b.jammasuk));
      } else {
        return filtered.sort((a, b) => b.jammasuk.localeCompare(a.jammasuk));
      }
    },
  },
  watch: {
    qty() {
      console.log(this.weightTotal)
      this.updateqty();
  },
  weight() {
      console.log(this.weightTotal)
      this.updateqty();
  },
  },
  methods: {
    updateqty(){
      this.weightTotal = this.qty * this.weight
    },
    handleButtonClick1(value) {
    
      this.register = value;

      if(this.register === false){
        setTimeout(() => {
          this.errorModal2 = false;
          this.open = false;
        }, 1000);
      }
    },
    handleInput(event, propertyName) {
      // Mendapatkan nilai input tanpa karakter non-angka
      const numericValue = event.target.value.replace(/\D/g, '');
      // Memformat nilai numerik sesuai dengan preferensi Anda
      const formattedValue = this.formatNumber(numericValue); // Anda perlu menentukan fungsi formatNumber sesuai preferensi Anda
      // Perbarui nilai properti dengan nilai yang diformat
      this[propertyName] = formattedValue;
    },
    formatNumber(value) {
      // Fungsi ini dapat Anda sesuaikan sesuai dengan preferensi Anda untuk format angka
      // Contoh: Tambahkan titik sebagai pemisah ribuan
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    handleButtonClick(value) {
    
    this.register = value;

    if(this.register === true){
        this.errorModal = false;
        this.open = false;
        this.submitForm()
        this.register= false;
    } else {
      
        this.errorModal = false;
        this.open = false;
    }
  },
    handleFileUpload(event) {
      const fileInput = event.target;
      const files = fileInput.files;

    if (files.length > 0) {
      const file = files[0];
      this.uploadedFileName = file.name;
      this.readExcelData(file);
    }
    },
    readExcelData(file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: 'binary' });
        
      
        const sheetName = 'Sheet1'; 

        if (workbook.SheetNames.includes(sheetName)) {
          const sheet = workbook.Sheets[sheetName];
          
          // Ambil data per baris dari kolom A
          const columnAtoGData = XLSX.utils.sheet_to_json(sheet, { header: 1 }).map(row => row.slice(0, 7));
          console.log(columnAtoGData)
           this.exceldata= columnAtoGData;
          
        } else {
          console.error(`Sheet "${sheetName}" not found in the Excel file.`);
        }
      };

      reader.readAsBinaryString(file);
    },
    insertData() {
      this.entries = this.entries.concat(this.exceldata.map(row => ({
        material: row[0],
        dimension: row[1],
        qty: row[2],
        eom1: row[3],
        coo: row[4],
        weight: row[5],
     
     
       
      })));
      this.columnAtoGData=[]
      this.uploadedFileName=null
      console.log('Inserting data:', this.entries);
    },
    editEntry(index) {
      const editedEntry = this.entries[index];
      this.material = editedEntry.material
   
      this.description = editedEntry.description || '';
      this.qty = editedEntry.qty || '';
      this.category = editedEntry.category || '';
      this.coo = editedEntry.coo || '';
      this.weight = editedEntry.weight || '';
      this.eom1 = editedEntry.eom || '';
      this.dimension = editedEntry.dimension || '';
      this.weightTotal = editedEntry.weightTotal || '';  
      this.isEditMode = true;
      this.editedIndex = index
      // this.userdata.data.splice(index, 1);
    },
    unit() {
    const selectedMaterial = this.userdata.data.find(material => material.name === this.material);
    if (selectedMaterial) {
      this.eom1 = selectedMaterial.symbol;
      this.dimension =selectedMaterial.dimension
    } else {
      this.eom1 = '';
    }
  },
  resetForm() {
    this.selectedMaterial=[];
    this.editedIndex = '';
    this.price = '';
    this.description = '';
    this.qty = '';
    this.dimension = '';
    this.weightTotal = '';
    this.category = '';
    this.coo = '';
    this.weight = '';
    this.material = '';
    this.eom1 = '';
  },
  removeEntry(index) {
      this.entries.splice(index, 1);
    },
    toggleModal() {
      this.isOpen = !this.isOpen;
    },
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    submittemp() {

      const emptyFields = [];


      if (!this.material || /^\s*$/.test(this.material)) {
        emptyFields.push("Material");
      }

      // if (!this.description || /^\s*$/.test(this.description)) {
      //   emptyFields.push("Description");
      // }

      // if (!this.price || /^\s*$/.test(this.price)) {
      //   emptyFields.push("Price");
      // }

      if (!this.qty || /^\s*$/.test(this.qty)) {
        emptyFields.push("Quantity");
      }

      if (!this.eom1 || /^\s*$/.test(this.eom1)) {
        emptyFields.push("EOM");
      }

      // if (!this.coo || /^\s*$/.test(this.coo)) {
      //   emptyFields.push("COO");
      // }

      if (!this.weight || /^\s*$/.test(this.weight)) {
        emptyFields.push("Weight");
      }

      // if (!this.dimension || /^\s*$/.test(this.dimension)) {
      //   emptyFields.push("Dimension");
      // }


      if (emptyFields.length > 0) {

        this.errorModal1 = true;
        this.message = `Field ${emptyFields.join(", ")} Tidak Boleh Kosong.`;

        setTimeout(() => {
          this.errorModal1 = false;
          this.open = false;
        }, 3000);

        return;
      }

        const entryData = {
        material: this.material,
       
        description: this.description,
        qty: this.qty,
        category: this.category,
        coo: this.coo,
        weightTotal:this.weightTotal,
      
        eom: this.eom1,
        weight: this.weight,
        dimension:this.dimension
      };
      console.log(this.eom1)

  if (this.isEditMode) {
    this.entries[this.editedIndex] = { ...entryData };
   
    this.isEditMode = false;

  } else {
    console.log(entryData)
    this.entries.push(entryData);
  }

  this.resetForm();
    },
    async submitForm() {
      const formData = new FormData();

    this.entries.forEach((entries) => {
    formData.append('material[]', entries.material  );
    formData.append('description[]', entries.description );
    formData.append('qty[]', entries.qty );
    formData.append('price[]',0 );
    formData.append('unit[]', entries.eom );
    formData.append('weight[]', entries.weight );
    formData.append('coo[]', entries.coo  );
    formData.append('dimension[]', entries.dimension );
    },
        formData.append('project_id',this.project_id),
        formData.append('with_register',this.register),
        
      );
      
     
      try {
        const response = await axios.post(`${process.env.VUE_APP_BACKEND_HOST}/material-take-off/batch`, formData);

        console.log(response);
      
        this.successModal = true;

      
        setTimeout(() => {
          this.successModal = false;
          this.open = false;
        }, 3000);
        this.eom =''
        this.project_id=''
        this.entries=[]
        setTimeout(() => {
        this.$router.push('/MTO');
      }, 2000);
      } catch (error) {
        console.log(error);
        this.message = error.response.data.message;

        if (error.response.data.message === 'Terdapat material yang tidak terdaftar pada master data') {
          this.master = error.response.data;
          this.errorModal = true;
          
        } else if (error.response.data.message === 'Terdapat material yang sudah ada pada inventory project') {
          this.master = error.response.data;
          this.errorModal2 = true;
          
        }else  {
          this.errorModal1 = true;
          
          setTimeout(() => {
            this.errorModal1 = false;
            this.open = false;
            this.entries=[]
          }, 3000);
        }

      }
    },
    getcategory() {
      axios
        .get(`${process.env.VUE_APP_BACKEND_HOST}/project-category`)
        .then((response) => {
          this.categorydata = response.data;

        })
        .catch((error) => {
          console.log(error);
        });
    },
    getuser() {
      axios
        .get(`${process.env.VUE_APP_BACKEND_HOST}/material`)
        .then((response) => {
          this.userdata = response.data;

        })
        .catch((error) => {
          console.log(error);
        });
    },
    getproject2() {
      axios
        .get(`${process.env.VUE_APP_BACKEND_HOST}/project/list`)
        .then((response) => {
          this.projectdata2 = response.data;

        })
        .catch((error) => {
          console.log(error);
        });
    },
    getproject() {
      axios
        .get(`${process.env.VUE_APP_BACKEND_HOST}/unit`)
        .then((response) => {
          this.projectdata = response.data;

        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted(){
    this.getcategory();
    this.getproject2();
    this.getproject();
    this.getuser();
  }
};
</script>
