
<template>

  <nav class="flex mb-6 font-pop" aria-label="Breadcrumb" style=" animation: muncul-kiri-kanan-fade 1s;"> 
    <ol class="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
      <li>
        <div class="flex items-center">
         
          <router-link to="/Report" class="ms-1 text-sm font-medium text-gray-700 hover:text-red-500 md:ms-2 dark:text-gray-400 dark:hover:text-white">Report</router-link>
        </div>
      </li>
      <li aria-current="page">
        <div class="flex items-center">
          <svg class="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4"/>
          </svg>
          <span class="ms-1 text-sm font-medium text-red-500 md:ms-2 dark:text-gray-400">View Report</span>
        </div>
      </li>
    </ol>
  </nav>
  
      <div class="font-pop">
      <form @submit.prevent="submittemp" class="space-y-12 bg-white md:mb-0 md:px-24 py-4 xxs:px-4 xxs:mb-16 rounded-xl" style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; animation: muncul-kiri-kanan-fade 1s;">
        <div class="border-b border-gray-900/10 pb-12">
          <h2 class="text-base text-[22px] font-semibold leading-7 text-gray-900 mt-4">View Report</h2>
          <p class="mt-1 text-sm leading-6 text-gray-600">Lakukan Penginputan Data Report Baru.</p>
          <div class="mt-6 grid xxs:grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-6">
       
            <div class="sm:col-span-3">
              <label for="project" class="block text-sm font-medium leading-6 text-gray-900">Project</label>
              <div class="mt-2">
                <select v-model="project" @change="material2" id="project" name="project" autocomplete="project-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled>
                  <option value="" disabled selected>Pilih Project</option>
                  <option v-for="data2 in projectdata2.data" :key="data2.id" :value="data2.id">{{ data2.name }}</option>
                </select>
              </div>
            </div>
  
            <div class="sm:col-span-3">
              <label for="project" class="block text-sm font-medium leading-6 text-gray-900">Work Order</label>
              <div class="mt-2">
                <select v-model="WO" @change="material3" id="project" name="project" autocomplete="project-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled>
                  <option value="" disabled selected>Pilih WO</option>
                  <option v-for="data2 in materialdata.data" :key="data2.team_id" :value="data2.team_id">{{ data2.code }}</option>
                </select>
              </div>
            </div>
  
            <div class="sm:col-span-3">
              <label for="project" class="block text-sm font-medium leading-6 text-gray-900">Team</label>
              <div class="mt-2">
                <select v-model="Team" id="project" name="project" autocomplete="project-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled>
                  <option value="" disabled selected>Pilih Team</option>
                  <option v-for="data2 in teamdata1.data" :key="data2.id" :value="data2.id">{{ data2.name }}</option>
                </select>
              </div>
            </div>
      
      
  
            <div class="sm:col-span-3">
              <label for="negara" class="block text-sm font-medium leading-6 text-gray-900">Judul Report</label>
              <div class="mt-2">
                <input v-model="name" type="text" name="negara" id="negara" autocomplete="negara" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"  />
              </div>
            </div>
  
  
           
  
            <div class="sm:col-span-3">
              <label for="qty" class="block text-sm font-medium leading-6 text-gray-900">Tanggal Report</label>
              <div class="mt-2">
                <input v-model="date1" type="date" name="qty" id="qty" autocomplete="qty" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>
  
            <div class="sm:col-span-3">
              <label for="qty" class="block text-sm font-medium leading-6 text-gray-900">Persentase</label>
              <div class="mt-2">
                <input v-model="progress" type="text" name="qty" id="qty" autocomplete="qty" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>
  
  
            <div class="sm:col-span-full mt-4">
              <label for="message" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Keterangan</label>
              <div class="mt-2">
              <textarea id="message" v-model="description" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Tambahkan Keterangan Jika Perlu..."></textarea>
              </div>
              </div>
  
  
            <div class="sm:col-span-full">
      <label for="qty" class="block text-sm font-medium leading-6 text-gray-900">Tambah Photo</label>
      <div class="mt-2">
        <input
          ref="berkas"
          id="berkas"
          @change="handleFileChange"
          type="file"
          multiple
          autocomplete="qty"
          class="block w-full rounded-md border-0  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
      </div>
      <!-- <img class="object-cover md:w-14 md:h-14 xxs:w-14 xxs:h-full rounded" :src="shift.imageUrl" alt="Profile Image"> -->
    
  
            <!-- <div v-if="selectedFiles.length > 0" class="border-dashed border-2  border-gray-400 rounded-lg grid grid-cols-3   p-2 mt-4 gap-3">
          
        <div v-for="(file, index) in selectedFiles" :key="index">
          <img :src="file.url" :alt="file.name" class="rounded-lg shadow-lg w-44 h-44 " />
        </div>
      </div> -->

      

<div v-if="imageUrls.length > 0" class="border-dashed border-2 border-gray-400 rounded-lg grid grid-cols-3 px-6 py-6 mt-6 gap-3">
  <div v-for="(imageUrl, index) in imageUrls" :key="index">
    <img class="rounded-lg shadow-lg w-56 h-56 object-cover cursor-pointer" :src="imageUrl" @click="openPreview(imageUrl)" alt="Employee Photo">
  </div>
</div>
</div>

    
<!-- 
    <div v-if="workdata.data">
    <div v-for="shift in workdata.data" :key="shift.id">
     
      <img :src="shift.imageUrl" alt="Image" />
    
      <p>{{ shift.description }}</p>
    </div>
  </div>
  
   -->
  
          
          </div>
        </div>
       
             
  
  
  <div class="mt-10 flex items-center justify-end gap-x-6">
        <router-link to="/Report"  class="rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Kembali</router-link>
        <form @submit.prevent="submitForm">
        <button type="submit" class="rounded-md bg-green-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Simpan</button>
      </form>
      </div>
  
      </form>
    </div>
    <div v-if="successModal" class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50"  style="  animation: fadein 1s;">
      <div class="bg-white p-8 rounded-lg flex flex-col justify-center items-center  "  style="animation: muncul-kiri-kanan-fade 1s; ">
        <img src="../../assets/verify.png" class="w-16 h-16 mb-6 " alt="">
        <p>Data Report Berhasil ditambahkan</p>
      </div>
    </div>
  
    <div v-if="errorModal" class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50" style="animation: fadein 1s;">
      <div class="bg-white p-8 rounded-lg flex flex-col justify-center items-center  "  style="animation: muncul-kiri-kanan-fade 1s; ">
        <img src="../../assets/cancel.png" class="w-16 h-16 mb-6 " alt="">
        <p class="  font-semibold w-72 text-center" >{{ message }}</p>
      </div>
    </div>
  
    <div v-if="showPreviewModal" class="fixed top-0 left-0 w-full h-full bg-black bg-opacity-70 flex justify-center items-center" @click="closePreview">
  <div class="max-w-4xl max-h-4xl overflow-auto">
    <img class="w-full rounded-lg" :src="selectedImageUrl" alt="Employee Photo" style="animation: muncul-kiri-kanan-fade 1s;">
  </div>
</div>
  </template>
  
  
  
  
  
  
  <script>
  import axios from "../../token";
  import { ref } from 'vue';
  import { setupLeaveConfirmation } from '../../token/confirmLeave.js';
  
  
  
  
  export default {
    data() {
      return {
        id: localStorage.getItem('id') || 'Default Value',
        isOpen: false,
        entries: [],
        open: "",
        project: '',
        showPreviewModal:false,
       selectedImageUrl: '',
        eom: [],
        WO:'',
        Deskripsi: '',
        price:[],
        date1:new Date().toISOString().substr(0, 10),
        date2:new Date().toISOString().substr(0, 10),
        qty: [],
        description:[],
        category: '',
        material:[],
        coo: [],
        BeratSatuan: '',
        dimension:[],
        Units: [],
        successModal:false,
        errorModal:false,
        status:"Proses Pengerjaan",
        categorydata:[],
        projectdata:[],
        projectdata2:[],
        userdata:[],
        material1:'',
        teamdata:[],
        teamdata1:[],
        workdata:[],
        materialdata:[],
        team:'',
        assignment:'',
        assignment_item:'',
        berkas:[],
        selectedFileUrl:[],
        selectedFileName:[],
        selectedFiles: [],
        material4:'',
        imageUrls: [],
      };
    },
    setup() {
      const isHovered = ref(false);
      setupLeaveConfirmation();
  
      return {
       
        isHovered,
        setupLeaveConfirmation
        
      }
  },
    computed: {
      filteredEntries() {
        let filtered = this.absendata;
  
        if (this.selectedStatus.length > 0) {
          filtered = filtered.filter((abse) =>
            this.selectedStatus.includes(abse.status)
          );
        }
  
        if (this.selectedSort === "asc") {
          return filtered.sort((a, b) => a.jammasuk.localeCompare(b.jammasuk));
        } else {
          return filtered.sort((a, b) => b.jammasuk.localeCompare(a.jammasuk));
        }
      },
    },
    methods: {
      openPreview(imageUrl) {
    this.selectedImageUrl = imageUrl;
    this.showPreviewModal = true;
  },
  closePreview() {
    this.showPreviewModal = false;
  },
      getteam1(id) {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/team`,{
            params:{
              id:id
            }
          })
          .then((response) => {
            this.teamdata1 = response.data;
        //     if (this.teamdata && this.teamdata.data.length > 0) {
        //   const firstEntry = this.teamdata.data[0]; 
        //   this.name=firstEntry.name || '';
        //   this.teamleader=firstEntry.leader || '';
        // }
  
          
          })
          .catch((error) => {
            console.log(error);
          });
      },
      getmaterial() {
            console.log(this.desc)
            axios
              .get(`${process.env.VUE_APP_BACKEND_HOST}/work-order/list`,{
                params:{
                    limit:100,
                    filter:'project',
                    filter_value:this.project
                }
              })
              .then((response) => {
                this.materialdata = response.data;

                if (this.materialdata ) {
            const firstEntry = this.materialdata;
            
            // Check if 'firstEntry' is defined before accessing its properties
            if (firstEntry) {
              this.WO = firstEntry.report || '';
            } else {
              // Handle the case when 'firstEntry' is undefined
              console.error('First entry is undefined');
            }
          } else {
            // Handle the case when there are no entries in this.workdata.data
            console.error('No data available in this.workdata.data');
          }

              
              })
              .catch((error) => {
                console.log(error);
              });
          },
         
    
    //   material2() {
    //   this.material1 = this.project;
    //   console.log(this.material1)
    //   if (this.material1) {
    //     this.getmaterial(this.material1)
    //   } else {
    //     this.material1 = '';
    //   }
    // },
    // material3() {
    //   this.material4 = this.WO;
    //   console.log(this.material1)
    //   if (this.material4) {
    //     this.getteam1(this.material4)
    //   } else {
    //     this.material4 = '';
    //   }
    // },
    unit() {
      const selectedMaterial = this.userdata.data.find(material => material.id === this.material.id);
      if (selectedMaterial) {
        this.eom = selectedMaterial.symbol;
      } else {
        this.eom = '';
      }
    },
    handleFileChange(event) {
    const input = event.target;
    console.log("Selected files:", input.files);
    if (input.files.length > 0) {
      for (let i = 0; i < input.files.length; i++) {
        const selectedFile = input.files[i];
        console.log("Processing file:", selectedFile.name);
        const imageUrl = URL.createObjectURL(selectedFile);
        // Push new image URL into imageUrls array
        this.imageUrls.push({
          url: imageUrl,
          name: selectedFile.name,
          type: selectedFile.type,
          size: selectedFile.size
        });
      }
      // Clear the file input's value after processing all selected files
      input.value = '';
    }
  },
    removeEntry(index) {
        this.entries.splice(index, 1);
      },
      toggleModal() {
        this.isOpen = !this.isOpen;
      },
      openModal() {
        this.isModalOpen = true;
      },
      closeModal() {
        this.isModalOpen = false;
      },
      submittemp() {
  
        const emptyFields = [];
  
  
  if (!this.material || /^\s*$/.test(this.material)) {
    emptyFields.push("Material");
  }
  
  
  
  if (!this.qty || /^\s*$/.test(this.qty)) {
    emptyFields.push("Quantity");
  }
  
  if (!this.eom || /^\s*$/.test(this.eom)) {
    emptyFields.push("EOM");
  }
  
  
  
  if (emptyFields.length > 0) {
  
    this.errorModal = true;
    this.message = `Field ${emptyFields.join(", ")} Tidak Boleh Kosong.`;
  
    setTimeout(() => {
      this.errorModal = false;
      this.open = false;
    }, 3000);
  
    return;
  }
  
  
        this.entries.push({
        
          material: this.material,
          qty: this.qty,
          eom: this.eom
        });
        this.material = '';
        this.qty = '';
        this.eom = '';
      },
      async submitForm() {
    try {
      const formData = new FormData();
  
      // Append each file to formData
      this.selectedFiles.forEach(file => {
        formData.append('files[]', file.file); // Assuming `file` is a Blob or File object
      });
  
      // Append other form fields
    formData.append('project_id', this.project);
    formData.append('_method','put'),
    formData.append('id',this.$route.params.id),
    formData.append('name', this.name);
    formData.append('wo_id', this.WO);
    formData.append('team_id', this.Team);
    formData.append('date', this.date1);
    formData.append('progress', this.progress);
    formData.append('report', this.description);
  
      // Send formData using axios
      const response = await axios.post(`${process.env.VUE_APP_BACKEND_HOST}/project/report`, formData);
  
      console.log(response);
      this.successModal = true;
      setTimeout(() => {
        this.successModal = false;
        this.open = false;
        this.$router.push('/Report');
      }, 3000);
  
      // Clear form data
      this.selectedFiles = [];
    } catch (error) {
      console.error(error);
      this.message = error.response.data.message;
      this.errorModal = true;
      setTimeout(() => {
        this.errorModal = false;
        this.open = false;
      }, 3000);
    }
  },
  
      getcategory() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/project-category`)
          .then((response) => {
            this.categorydata = response.data;
  
          })
          .catch((error) => {
            console.log(error);
          });
      },
      getteam() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/team`)
          .then((response) => {
            this.teamdata = response.data;
  
          })
          .catch((error) => {
            console.log(error);
          });
      },
      getuser() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/material`,{
            params:{
              project_id:this.material1
            }
          })
          .then((response) => {
            this.userdata = response.data;
  
          })
          .catch((error) => {
            console.log(error);
          this.message = error.response.data.message;
          this.errorModal = true;
          setTimeout(() => {
            this.errorModal = false;
            this.open = false;
            this.project='';
          }, 3000);
          
          });
      },
      getproject2() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/project/list`)
          .then((response) => {
            this.projectdata2 = response.data;
  
          })
          .catch((error) => {
            console.log(error);
          });
      },
      getproject() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/unit`)
          .then((response) => {
            this.projectdata = response.data;
  
          })
          .catch((error) => {
            console.log(error);
          });
      },
      getwork() {
      axios
        .get(`${process.env.VUE_APP_BACKEND_HOST}/project/report`,{
          params:{
            id:this.$route.params.id,
          }
        })
        .then((response) => {
          this.workdata = response.data.data;
          console.log(this.workdata);

          if (this.workdata ) {
            const firstEntry = this.workdata;
            
            // Check if 'firstEntry' is defined before accessing its properties
            if (firstEntry) {
              this.description = firstEntry.report || '';
              this.project = parseInt(firstEntry.project_id || ''); 
              this.WO = parseInt(firstEntry.wo_id || ''); 
              this.getteam1(this.WO);
              
              this.Team = parseInt(firstEntry.team_id || ''); 
              this.date1 = firstEntry.date || '';
              this.name = firstEntry.name || '';
              this.progress=  firstEntry.progress  || '';
             
                
              if (Array.isArray(firstEntry.files)) {
                firstEntry.files.forEach((file) => {
                  this.fetchImage(file.id);
                });
              } else {
                console.error('Data files bukan array, periksa pengambilan data Anda.');
              }
           
                
      
            } else {
              // Handle the case when 'firstEntry' is undefined
              console.error('First entry is undefined');
            }
          } else {
            // Handle the case when there are no entries in this.workdata.data
            console.error('No data available in this.workdata.data');
          }

                  
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async fetchImage(id) {
  try {
    const response = await axios.get(`${process.env.VUE_APP_BACKEND_HOST}/file`, {
      params: {
        id: id,
      },
      responseType: 'arraybuffer',
    });

    const base64Image = btoa(
      new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), '')
    );

    const imageUrl = `data:${response.headers['content-type']};base64,${base64Image}`;

    // Push the imageUrl into the imageUrls array
    this.imageUrls.push(imageUrl);

  } catch (error) {
    console.error('Error fetching image:', error);
  }
}

    },
    mounted(){
      this.getcategory();
      this.getproject2();
      this.getproject();
      this.getuser();
      this.getteam1();
      this.getmaterial();
      this.getwork();
      
    }
  };
  </script>
  