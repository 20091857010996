<template>
  <div class="flex flex-row font-pop gap-6">

    <div class="grid grid-cols-2 gap-4" style="  animation: muncul-kiri-kanan-fade 0.5s;">

      <div class="flex flex-row border p-6 bg-white shadow rounded">
              <div class="flex flex-col justify-between">
            <h1 class="text-[14px] font-medium">Total Karyawan</h1>
            <h2 class="text-[32px] font-medium">{{ totalkaryawan}}</h2>
            <h3 class="text-[14px] font-medium">Karyawan</h3>
          </div>
                <div class="flex items-center -space-x-2 overflow-hidden">
                  <img
                    class="inline-block h-10 w-10 rounded-full ring-2 ring-white shadow-lg"
                    src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    alt=""
                  />
                  <img
                    class="inline-block h-10 w-10 rounded-full ring-2 ring-white shadow-lg"
                    src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    alt=""
                  />
                  <img
                    class="inline-block h-10 w-10 rounded-full ring-2 ring-white shadow-lg"
                    src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80"
                    alt=""
                  />
                  <img
                    class="inline-block h-10 w-10 rounded-full ring-2 ring-white shadow-lg"
                    src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    alt=""
                  />
                </div>
              </div>

              <div class="flex flex-row border p-6 bg-white shadow rounded">
              <div class="flex flex-col justify-between">
            <h1 class="text-[14px] font-medium">Total Departement</h1>
            <h2 class="text-[32px] font-medium">{{ totaldepartement}}</h2>
            <h3 class="text-[14px] font-medium">Departement</h3>
          </div>
                <div class="flex items-center   overflow-hidden">
                    <img class="w-24 h-24 ml-5" src="../assets/dp.png" alt="">
                </div>
              </div>

              <div class="flex flex-row border p-6 bg-white shadow rounded">
              <div class="flex flex-col justify-between">
            <h1 class="text-[14px] font-medium">Total Posisi</h1>
            <h2 class="text-[32px] font-medium">{{ totalposisi}}</h2>
            <h3 class="text-[14px] font-medium">Posisi</h3>
          </div>
                <div class="flex items-center justify-center  overflow-hidden">
                  <img class="w-24 h-24 ml-14"   src="../assets/posisis.png" alt="">
                </div>
              </div>

              <div class="flex flex-row border p-6 bg-white shadow rounded">
              <div class="flex flex-col justify-between">
            <h1 class="text-[14px] font-medium">Total Role</h1>
            <h2 class="text-[32px] font-medium">{{ totalrole}}</h2>
            <h3 class="text-[14px] font-medium">Role</h3>
          </div>
                <div class="flex items-center justify-center  overflow-hidden">
                  <img class="w-24 h-24 ml-[5rem]"  src="../assets/RL.png" alt="">
                </div>
              </div>
             

    </div>
    <BarChart class="w-[66rem] bg-white p-4 rounded shadow" style="  animation: muncul-kiri-kanan-fade 0.5s;"/>


  </div>
  <div class="flex flex-col font-pop  mt-10 ">
            <div class="flex flex-row mb-4 font-medium">
            <!-- <div class="" style="  animation: muncul-kiri-kanan-fade 1s;">Statistik Human Resource</div> -->
            <div class="" style="  animation: muncul-kiri-kanan-fade 1s;">Informasi Karyawan</div>
          </div>
          <div class="flex flex-row gap-10">
            <!-- <div class="flex flex-col justify-center items-center bg-white  rounded-lg  w-[32rem] py-4 h-[20rem]  " style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;  animation: muncul-kiri-kanan-fade 1s;">
            <donut />
            <p class="text-center text-[14px] px-10 mt-4" style="  animation: muncul-kiri-kanan-fade 1s;">Data disajikan untuk memotoring  berapa jumlah data dari departement</p>
            </div> -->

      <div class="w-full  first-letter:relative overflow-x-auto border sm:rounded-lg bg-white shadow-lg font-pop"  style="  animation: muncul-kiri-kanan-fade 1s;">
     
     <table class="min-w-full text-sm text-left text-gray-500 ] dark:text-gray-400">
         <thead class="text-xs text-gray-700 border-b bg-[#64707628]  dark:bg-gray-700 dark:text-gray-400 text-[14px]">
             <tr class="text-[#868686]">
                 <th scope="col" class="px-6 py-3  font-medium">
                     No
                 </th>
                 <th scope="col" class="px-6 py-3  font-medium">
                     NIK 
                 </th>
                 <th scope="col" class="px-6 py-3">
                     <div class="flex items-center  font-medium">
                     Nama
                     </div>
                 </th>
                 
                 <th scope="col" class="px-6 py-3">
                     <div class="flex items-center  font-medium">
                        Email
                     </div>
                 </th>
                 <th scope="col" class="px-6 py-3">
                     <div class="flex items-center  font-medium">
                      Posisi
                     </div>
                 </th>
                 <th scope="col" class="px-6 py-3">
                     <div class="flex items-center  font-medium">
                      Departement
                     </div>
                 </th>
                 

             </tr>
         </thead>
         <tbody>
             <tr class=" border-b dark:bg-gray-800 dark:border-gray-700 text-[16px] font-medium text-black" v-for="(material,index) in materialdata.data" :key="material.id">
                 <td class="px-6 py-4">
                     {{ index + 1 }}
                  </td>
                  <td class="px-6 py-4">
                    {{ material.employee_id }}
                </td>
                 <td class="px-6 py-4">
                    {{ material.name }}
                 </td>
                 <td class="px-6 py-4">
                    {{ material.email }}
                 </td>
                 <td class="px-6 py-4">
                    {{ material.position }}
                    </td>
                 <td class="px-6 py-4">
                    {{ material.department }}
                 </td>
                
                 
             </tr>
            
         </tbody>
     </table>
 </div>
</div>
</div>
</template>

<script>
import axios from "../token";
import BarChart from '../HR/BarChart.vue'
// import donut  from '../Invetory/DoughnutChart.vue';
export default{
  components:{
    BarChart,
    // donut
  },
  data() {
      return {
        id: localStorage.getItem('id') || 'Default Value',
        isOpen: false,
        open: "",
        project:"",
        material:"",
        qty:"",
        price:"",
        weight:"",
        coo:"",
        totalrole:'',
        totaldepartement:'',
        totalkaryawan:'',
        totalposisi:'',
        dimension:"",
        countdata:[],
        successModal:false,
        status:"Proses Pengerjaan",
        categorydata:[],
        projectdata:[],
        projectdata2:[],
        userdata:[],
        materialdata:[],
        search:'',
        items: [
        { title: 'Total', value: this.getmaterial.length, image:require('@/assets/MTO.png'), description: 'Material Take Off' },
        { title: 'Total', value: this.getmaterial.length, image:require('@/assets/learning.png'), description: 'Material Request' },
        { title: 'Total', value: this.getmaterial.length, image:require('@/assets/po.png'), description: 'Purchase Order' },
        { title: 'Total', value: this.getmaterial.length, image:require('@/assets/stockin.png'), description: 'Stock In' },
        { title: 'Total', value: this.getmaterial.length, image:require('@/assets/stockout.png'), description: 'Stock Out' }
      ]
      };
    },
    computed: {
      filteredEntries() {
        let filtered = this.absendata;
  
        if (this.selectedStatus.length > 0) {
          filtered = filtered.filter((abse) =>
            this.selectedStatus.includes(abse.status)
          );
        }
  
        if (this.selectedSort === "asc") {
          return filtered.sort((a, b) => a.jammasuk.localeCompare(b.jammasuk));
        } else {
          return filtered.sort((a, b) => b.jammasuk.localeCompare(a.jammasuk));
        }
      },
      materialDataLength() {
      return this.materialdata.length;
    }
    },
    methods: {
      toggleModal() {
        this.isOpen = !this.isOpen;
      },
      openModal() {
        this.isModalOpen = true;
      },
      closeModal() {
        this.isModalOpen = false;
      },
      async submitForm() {
        try {
          const response = await axios.post(`${process.env.VUE_APP_BACKEND_HOST}/project`, {
            project: this.project,
            material: this.material,
            qty: this.qty,
            price: this.price,
            weight: this.weight,
            coo: this.coo,
            dimension: this.dimension
          });
          console.log(response);
          await new Promise(resolve => setTimeout(resolve, 1000));
          this.successModal = true;
  
          this.getproject();
        
          setTimeout(() => {
            this.successModal = false;
            this.open = false;
          }, 1000);
        } catch (error) {
          console.log(error);
          this.message = "There was an error submitting the form.";
        }
      },
      getcategory() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/project-category`)
          .then((response) => {
            this.categorydata = response.data;
  
          })
          .catch((error) => {
            console.log(error);
          });
      },
      getcount() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/hrd/count`)
          .then((response) => {
            this.countdata = response.data;
            const firstEntry = this.countdata;
          console.log(firstEntry)
           this.totalkaryawan = firstEntry.employee
            this.totaldepartement = firstEntry.department
           this.totalrole = firstEntry.role
           this.totalposisi = firstEntry.position
  
          })
          .catch((error) => {
            console.log(error);
          });
      },
      
      getuser() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/material`)
          .then((response) => {
            this.userdata = response.data;
  
          })
          .catch((error) => {
            console.log(error);
          });
      },
      getproject2() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/project/list`)
          .then((response) => {
            this.projectdata2 = response.data;
  
          })
          .catch((error) => {
            console.log(error);
          });
      },
      getproject() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/unit`)
          .then((response) => {
            this.projectdata = response.data;
  
          })
          .catch((error) => {
            console.log(error);
          });
      },
      getmaterial() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/hrd/employee/list`,{
            params:{
              limit: 5,
              search:this.search
            }
          })
          .then((response) => {
            this.materialdata = response.data;
  
          })
          .catch((error) => {
            console.log(error);
          });
      },
    },
    mounted(){
      this.getcategory();
      this.getproject2();
      this.getproject();
      this.getuser();
      this.getcount();
      this.getmaterial();
    }
}

</script>