
export function setupLeaveConfirmation() {
    const confirmLeave = () => {
      const message = 'Apakah Anda yakin ingin meninggalkan halaman ini? Perubahan yang Anda buat mungkin tidak disimpan.';
      return confirm(message);
    };
  
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = '';
    };
  
    window.addEventListener('beforeunload', confirmLeave);
    window.addEventListener('unload', handleBeforeUnload);
  
    return () => {
      window.removeEventListener('beforeunload', confirmLeave);
      window.removeEventListener('unload', handleBeforeUnload);
    };
  }
  