<template>
    <dashboard-layout >
      <!-- Sidebar content -->
      <template #sidebar>
        
        <BottomBar v-if="isLargeScreen" />
      </template>
  
      <!-- Navbar content -->
      <template #navbar>
        <NavBar  />
      </template>
  
      <!-- Page content -->
      <template #content>
        <AbsensiUser />
      </template>
    </dashboard-layout>
  </template>
  
  <script>
import NavBar from '../Absensi/Component/NavBar'
import BottomBar from '../Absensi/Component/BottomBar'
import AbsensiUser from '../Absensi/Component/PermohonanBar'

// import SideBar from '../Absensi/Component/SideBar'
import DashboardLayout from "../Absensi/Component/DashboardLayout.vue";
  
  export default {
    components: {
    NavBar,
    BottomBar,
    AbsensiUser,
    // SideBar,
    DashboardLayout,
    },
    computed: {
    isLargeScreen() {
      return window.innerWidth < 480; 
    },
  },
  data() {
    return {
      isDarkMode: false,
    };
  },
  methods: {
   
  },
  };
  </script>
  
  <style>
  /* Add any custom CSS styles here */
  </style>