
<template>

  <nav class="flex mb-6 font-pop" aria-label="Breadcrumb" style=" animation: muncul-kiri-kanan-fade 1s;"> 
  <ol class="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
    <li>
      <div class="flex items-center">
       
        <router-link to="/TambahProject" class="ms-1 text-sm font-medium text-gray-700 hover:text-red-500 md:ms-2 dark:text-gray-400 dark:hover:text-white">List Project</router-link>
      </div>
    </li>
    <li aria-current="page">
      <div class="flex items-center">
        <svg class="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4"/>
        </svg>
        <span class="ms-1 text-sm font-medium text-red-500 md:ms-2 dark:text-gray-400">Detail Project</span>
      </div>
    </li>
  </ol>
</nav>
    <div class="space-y-12 bg-white md:mb-0 md:px-10 py-10 xxs:px-4 xxs:mb-16 rounded-xl font-pop" style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;  animation: muncul-kiri-kanan-fade 1s;">

      <div class="flex flex-row justify-between mb-10">

        <div class="flex flex-col">
        
          <div class="flex flex-col w-[30rem] font-pop">
          <p class="mt-4 text-[24px] font-medium">{{name}} # {{ project_id }}</p>
          <h1 class="mt-2">Kategory Kapal -> <span class="font-medium"> {{ project1 }}</span></h1>
          <h1 class="">Project Manager -> <span class="font-medium"> {{ pm }}</span></h1>
          <h1>Mulai Project <span class="font-medium">[{{ date1 }}]</span> Sampai Dengan <span class='font-medium'>[{{ date2 }}]</span></h1>
          <h1 class="">Status -> <span class="font-medium"> {{ status }}</span></h1>
          <h1  class="mt-4 text-[14px]">Bio</h1>
          <h1 class="text-[14px]">{{ description }}</h1>

          <button
          class="w-[142px] h-[30px] p-1 mt-24 rounded text-white bg-red-500"
          @click="open = true"
          >
            <div class="flex flex-row justify-center">
              <img src="../../assets/add.png" class="mr-1 w-5" alt="" />
              <h3 class="text-[12px] font-medium">Ubah Data</h3>
            </div>
          </button>
        </div>
       
        </div>
        <div class="w-72 max-h-54 ">
          <img @click="showPreview = true" class="rounded-lg w-72 h-[20rem] shadow-xl object-cover" style="animation: muncul-kiri-kanan-fade 1.2s;" :src="isLoading ? '' : (imageUrl ? imageUrl : 'https://asset.kompas.com/crops/k0__dJytqamRwPJW6K4j_fqCJvw=/0x0:1800x1200/750x500/data/photo/2022/09/10/631c1d6276c69.jpg')" alt="Project Photo">
        </div>

      </div>

      
      <transition>
      <div
        class="fixed z-10 left-0 top-0 w-full h-full flex items-center justify-center overflow-auto bg-black/40 bg-opacity-50 font-pop font-regular"
        v-if="open"
      >
     
        <div class="bg-white px-16 py-4 rounded-lg shadow-lg">
          <div class="w-full">
            <h1 class="text-[24px] mb-4">Tambah Project</h1>
            <hr class="mb-4" />
            <div class="flex flex-row justify-between">
              <div class="mt-2 grid xxs:grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-6">
              <div class="sm:col-span-3">
                <label for="first-name" class="block text-sm font-medium leading-6 text-gray-900">Nama Project</label>
                <div class="mt-2">
                  <input    v-model="name1" type="text" name="first-name" id="first-name" autocomplete="given-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled />
                </div>
              </div>
    
              <div class="sm:col-span-3">
                <label for="country" class="block text-sm font-medium leading-6 text-gray-900">Nama Manager</label>
                <div class="mt-2">
                  <select   v-model="pm1" id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                    <option value="" disabled selected>Pilih Manager</option>
                    <option
                      v-for="data1 in userdata1.data"
                      :key="data1.id"
                      :value="data1.id"
              >
                {{ data1.user.name }}
              </option>
                  </select>
                </div>
              </div>
  
              <div class="sm:col-span-2">
                <label for="country" class="block text-sm font-medium leading-6 text-gray-900">Kategory Project</label>
                <div class="mt-2">
                  <select   v-model="project2" id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                    <option value="" disabled selected>Kategory Kapal</option>
                    <option
                      v-for="categoory in categorydata2.data"
                      :key="categoory.id"
                      :value="categoory.id"
              >
                {{ categoory.name }}
              </option>
                  </select>
                </div>
              </div>

              <div class="sm:col-span-2">
                <label for="country" class="block text-sm font-medium leading-6 text-gray-900">Status</label>
                <div class="mt-2">
                  <select   v-model="status1" id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                    <option value="" disabled selected>Pilih  Status</option>
                    <option  value="Proses Pengerjaan"> Proses Pengerjaa </option>
                    <option  value="Selesai">   Selesai</option>
                    <option  value="Tertunda">   Tertunda </option>
                  
                  
                  </select>
                </div>
              </div>
  
              <div class="sm:col-span-2">
                <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Tanggal Mulai</label>
                <div class="mt-2">
                  <input    v-model="start_date" type="date" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                </div>
              </div>
  
              <div class="sm:col-span-2">
                <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Tanggal Selesai</label>
                <div class="mt-2">
                  <input   v-model="end_date" type="date" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                </div>
              </div>
  
             
              <div class="sm:col-span-full ">
            <label for="message" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Keterangan</label>
            <div class="mt-2">
            <textarea id="message" v-model="description1" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Tambahkan Keterangan Jika Perlu..."></textarea>
            </div>
            </div>

            <!-- <div class="col-span-full">
                    <h1 class="font-pop font-medium mb-4">Upload Photo</h1>
                    <label for="dropzone-file" class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                    <div class="flex flex-col items-center justify-center pt-5 pb-6">
                        <svg   v-if="!uploadedFileName" class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
                        </svg>
                        <img v-if="uploadedFileName" class="w-12 h-12 mb-10" src="../../assets/picture.png" alt="">
                        <p v-if="uploadedFileName" class="text-[14px] text-gray-500 dark:text-gray-400">{{ uploadedFileName }}</p>
                        <p v-if="!uploadedFileName" class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Klik Untuk Upload</span> Atau Tarik dan Lepas</p>
                        <p v-if="!uploadedFileName" class="text-xs text-gray-500 dark:text-gray-400">JPG And PNG Only</p>
                    </div>
                    <input id="dropzone-file" ref="fileInput" type="file" class="hidden" @change="handleFileUpload" />
                </label>
                   
                  </div> -->

              </div>
            </div>
            <div class="flex justify-end">
              <button
                class="mt-8 bg-red-500 px-5 py-3 rounded-md"
                @click="open = false"
              >
                <h1 class="text-[12px] text-white">Kembali</h1>
              </button>
              <form @submit.prevent="submitForm1">
              <button
                class="ml-4 mt-8 bg-[#A6CF98] px-[3rem] py-3 rounded-md"
                 type="submit"
              >
                <h1 class="text-[12px] text-white">Simpan</h1>
              </button>
            </form>
            </div>
          </div>
        </div>
      
      </div>
    </transition>

    

   <div class="flex flex-col">   
    <div class="grid grid-cols-8 gap-4 text-[12px] font-semibold text-gray-500">
     <div  @click="changeTab('mto')" >
      <a
    href="#" class="inline-flex items-center px-4 py-3 rounded-lg  bg-gray-50  w-full" :class="{ 'bg-red-500 text-white': activeTab === 'mto'}" 
    aria-current="page" 
  >
  Material Take Off
  
  </a></div>
     <div  @click="changeTab('MR')" >
      <a
    href="#" class="inline-flex items-center px-4 py-3 rounded-lg  bg-gray-50  w-full"  :class="{ 'bg-red-500 text-white': activeTab === 'MR'}"
    aria-current="page" 
  >
  Material Request
  </a></div>
     <div  @click="changeTab('Purchase Order')" >  <a
    href="#" class="inline-flex items-center px-4 py-3 rounded-lg  bg-gray-50  w-full"  :class="{ 'bg-red-500 text-white': activeTab === 'Purchase Order'}"
    aria-current="page" 
  >
  Purchase Order
  </a></div>
     <div  @click="changeTab('Work Order')" >  <a
    href="#" class="inline-flex items-center px-4 py-3 rounded-lg  bg-gray-50  w-full"  :class="{ 'bg-red-500 text-white': activeTab === 'Work Order'}"
    aria-current="page" 
  >
  Work Order
  </a></div>
     <div  @click="changeTab('Stock IN')" >  <a
    href="#" class="inline-flex items-center px-4 py-3 rounded-lg  bg-gray-50  w-full"  :class="{ 'bg-red-500 text-white': activeTab === 'Stock IN'}"
    aria-current="page" 
  >
  Stock IN
  </a></div>
     <div  @click="changeTab('Stock OUT')" >  <a
    href="#" class="inline-flex items-center px-4 py-3 rounded-lg  bg-gray-50  w-full"  :class="{ 'bg-red-500 text-white': activeTab === 'Stock OUT'}"
    aria-current="page" 
  >
  Stock OUT
  </a></div>
     
  <div  @click="changeTab('Progress')" >  <a
    href="#" class="inline-flex items-center px-4 py-3 rounded-lg  bg-gray-50  w-full"  :class="{ 'bg-red-500 text-white': activeTab === 'Progress'}"
    aria-current="page" 
  >
  Progress
  </a></div>
     <div  @click="changeTab('Laporan')" >  <a
    href="#" class="inline-flex items-center px-4 py-3 rounded-lg  bg-gray-50  w-full"  :class="{ 'bg-red-500 text-white': activeTab === 'Report'}"
    aria-current="page" 
  >
 Laporan
  </a></div>
</div>
</div>
</div>



<div class="space-y-12 bg-white md:mb-0 md:px-10 py-10 xxs:px-4 xxs:mb-16 rounded-xl mt-8 font-pop" style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;  animation: muncul-kiri-kanan-fade 1s;">
  
  
  <template v-if="activeTab === 'mto'">
            <h3 class="text-lg font-bold text-gray-900 dark:text-white mb-6">Material Take Off</h3>
            <div class="  first-letter:relative overflow-x-auto border rounded-lg xxs: bg-white shadow-lg font-pop">

            <table class="min-w-full w-full  text-sm text-left text-gray-500 ] dark:text-gray-400">
            <thead class="text-xs text-gray-700 border-b bg-[#64707628]  dark:bg-gray-700 dark:text-gray-400 text-[14px]">
                <tr class="text-[#868686]">
                  <th scope="col" class="px-4 py-3  font-medium">
                        No
                    </th>
                    <th scope="col" class="px-6 py-3  font-medium">
                        Barang
                    </th>
                    <th scope="col" class="px-6 py-3">
                        <div class="flex items-center  font-medium">
                            Keterangan
                        </div>
                    </th>
                    <th scope="col" class="px-4 py-3">
                        <div class="flex items-center  font-medium">
                            Qty
                        </div>
                    </th>
                    <th scope="col" class="px-4 py-3">
                        <div class="flex items-center  font-medium">
                           Harga@Unit
                        </div>
                    </th>
                    <th scope="col" class="px-6 py-3">
                        <div class="flex items-center  font-medium">
                           Total
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <template v-if="userdata.data ">
                <tr class=" border-b dark:bg-gray-800 dark:border-gray-700 text-[16px] font-medium text-black" v-for="(entry,index) in userdata.data" :key="entry.id">
                  <td class="px-4 py-4">
                        {{ index + 1 }}
                    </td>
                    <td class="px-6 py-4">
                      {{ entry.material.name }} {{ entry.material.dimension }}
                    </td>
                    <td class="px-6 py-4">
                        {{ entry.description }}
                    </td>
                    <td class="px-6 py-4">
                        {{  entry.quantity }}
                    </td>
                   
                    <td class="px-6 py-4">
                        {{ entry.price }}
                    </td>
                    <td class="px-6 py-4">
                        {{ entry.total }}
                    </td>
                </tr>
                </template>
                <tr  v-else>
              
                </tr>
            </tbody>
        </table>
    </div>
        </template>
  
  
  <template v-if="activeTab === 'Stock OUT'">
        <h3 class="text-lg font-bold text-gray-900 dark:text-white mb-2">Stock OUT</h3>
        <div class="  first-letter:relative overflow-x-auto border rounded-lg xxs: bg-[#FAFBFD] font-pop">
        <table class="min-w-full w-full  text-sm text-left text-gray-500 ] dark:text-gray-400">
            <thead class="text-xs text-gray-700 border-b bg-[#64707628]  dark:bg-gray-700 dark:text-gray-400 text-[14px]">
                <tr class="text-[#868686]">
                    <th scope="col" class="px-6 py-3  font-medium">
                        No
                    </th>
                    <th scope="col" class="px-6 py-3  font-medium">
                        Code
                    </th>
                    <th scope="col" class="px-6 py-3  font-medium">
                        Material
                    </th>
                   
                    <th scope="col" class="px-4 py-3">
                        <div class="flex items-center  font-medium">
                           Qty
                        </div>
                    </th>
                    <!-- <th scope="col" class="px-4 py-3">
                        <div class="flex items-center  font-medium">
                           keterangan
                        </div>
                    </th> -->
                </tr>
            </thead>
            <tbody>
                <template v-if="SOdata.data ">
                    <div class="  first-letter:relative overflow-x-auto border rounded-lg xxs: bg-[#FAFBFD] font-pop"></div>
                <tr class=" border-b dark:bg-gray-800 dark:border-gray-700 text-[16px] font-medium text-black" v-for="(entry,index) in SOdata.data" :key="entry.id">
                  <td class="px-14 py-4">
                        {{ index  + 1 }}
                    </td>
                    <td class="px-6 py-4">
                        {{ entry.code }}
                    </td>
                    <td class="px-6 py-4">
                        {{  entry.material.name }}
                    </td>
                    <td class="px-4 py-4">
                       {{ entry.quantity }}
                </td>
                <!-- <td class="px-4 py-4">
                       {{ entry.description }}
                </td> -->
                </tr>
                </template>
                <tr  v-else>
               
                </tr>
            </tbody>
        </table>
    </div>
        </template>


        <template v-if="activeTab === 'MR'">
            <h3 class="text-lg font-bold text-gray-900 dark:text-white mb-6">Material Request</h3>
            <div class="  first-letter:relative overflow-x-auto border rounded-lg xxs: bg-[#FAFBFD] font-pop">

            <table class="min-w-full w-full  text-sm text-left text-gray-500 ] dark:text-gray-400">
            <thead class="text-xs text-gray-700 border-b bg-[#64707628]  dark:bg-gray-700 dark:text-gray-400 text-[14px]">
                <tr class="text-[#868686]">
                  <th scope="col" class="px-6 py-3  font-medium">
                       No
                    </th>
                    <th scope="col" class="px-6 py-3  font-medium">
                       Code
                    </th>
                    <th scope="col" class="px-6 py-3">
                        <div class="flex items-center  font-medium">
                          Material
                        </div>
                    </th>
                    <th scope="col" class="px-4 py-3">
                        <div class="flex items-center  font-medium">
                            Qty
                        </div>
                    </th>
                    <th scope="col" class="px-4 py-3">
                        <div class="flex items-center  font-medium">
                           Keterangan
                        </div>
                    </th>
                    <th scope="col" class="px-4 py-3">
                        <div class="flex items-center  font-medium">
                           Status
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <template v-if="mrdata.data">
                <tr class=" border-b dark:bg-gray-800 dark:border-gray-700 text-[16px] font-medium text-black" v-for="(entry,index) in mrdata.data" :key="entry.id">
                  <td class="px-6 py-4">
                        {{ index + 1 }}
                    </td>
                    <td class="px-6 py-4">
                      {{ entry.code }}
                    </td>
                    
                    <td class="px-6 py-4">
                        {{ entry.material.name }} {{ entry.material.dimension }}
                    </td>
                    <td class="px-6 py-4">
                        {{  entry.quantity }}
                    </td>
                    <td class="px-6 py-4">
                        {{ entry.description }}
                    </td>
                    <td class="px-4 py-4">
                      <button
                        :class="{
                            'bg-[#FFE69A]/30 text-[#EFB812]': entry.status === 'Proses',
                            'bg-[#FF5E5E]/30 text-[#ED4747]': entry.status === 'Ditolak',
                            'bg-[#82C97C]/30 text-[#21B115]': entry.status === 'Disetujui',
                        }"
                        class="w-[118px] h-[30px] flex-shrink-0 rounded-lg  ml-4"
                        >
                        <h3 class="text-[12px] font-medium">{{ entry.status }}</h3>
                        </button>
                </td>
                </tr>
                </template>
                <tr  v-else>
               
                </tr>
            </tbody>
        </table>
    </div>
        </template>

        <template v-if="activeTab === 'Purchase Order'">
        <h3 class="text-lg font-bold text-gray-900 dark:text-white mb-6">Purchase Order</h3>
        <div class="  first-letter:relative overflow-x-auto border rounded-lg xxs: bg-[#FAFBFD] font-pop">

        <table class="min-w-full w-full  text-sm text-left text-gray-500 ] dark:text-gray-400">
            <thead class="text-xs text-gray-700 border-b bg-[#64707628]  dark:bg-gray-700 dark:text-gray-400 text-[14px]">
                <tr class="text-[#868686]">
                  <th scope="col" class="px-6 py-3  font-medium">
                        No
                    </th>
                    <th scope="col" class="px-6 py-3  font-medium">
                        Code
                    </th>
                    <th scope="col" class="px-6 py-3  font-medium">
                        Material
                    </th>
                    <th scope="col" class="px-14 py-3">
                        <div class="flex items-center  font-medium">
                            Keterangan
                        </div>
                    </th>
                    <th scope="col" class="px-4 py-3">
                        <div class="flex items-center  font-medium">
                            Qty
                        </div>
                    </th>
                   
                    <th scope="col" class="px-4 py-3">
                        <div class="flex items-center  font-medium">
                           Harga@Unit
                        </div>
                    </th>
                    <th scope="col" class="px-6 py-3">
                        <div class="flex items-center  font-medium">
                           Total
                        </div>
                    </th>
                    <th scope="col" class="px-14 py-3">
                        <div class="flex items-center  font-medium">
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <template v-if="podata.data">
                <tr class=" border-b dark:bg-gray-800 dark:border-gray-700 text-[16px] font-medium text-black" v-for="(entry,index) in podata.data" :key="entry.id">
                  <td class="px-6 py-4">
                        {{ index + 1 }}
                    </td>
                    <td class="px-6 py-4">
                        {{ entry.code }}
                    </td>
                    <td class="px-6 py-4">
                        {{ entry.material.name }} {{ entry.material.dimension }}
                    </td>
                    <td class="px-6 py-4">
                        {{ entry.description }}
                    </td>
                    <td class="px-6 py-4">
                        {{  entry.quantity }}
                    </td>
                    <td class="px-6 py-4">
                        {{ entry.price }}
                    </td>
                    <td class="px-6 py-4">
                        {{ entry.total }}
                    </td>
                    <td class="px-4 py-4">
                      <button
                        :class="{
                            'bg-[#FFE69A]/30 text-[#EFB812]': entry.status === 'Order',
                            'bg-[#FF5E5E]/30 text-[#ED4747]': entry.status === 'Ditolak',
                            'bg-[#82C97C]/30 text-[#21B115]': entry.status === 'Disetujui',
                        }"
                        class="w-[118px] h-[30px] flex-shrink-0 rounded-lg  ml-4"
                        >
                        <h3 class="text-[12px] font-medium">{{ entry.status }}</h3>
                        </button>
                </td>
                </tr>
                </template>
                <tr  v-else>
              
                </tr>
            </tbody>
        </table>
    </div>
        </template>

        <template v-if="activeTab === 'Work Order'">
        <h3 class="text-lg font-bold text-gray-900 dark:text-white mb-2">Work Order</h3>
        <div class="  first-letter:relative overflow-x-auto border rounded-lg xxs: bg-[#FAFBFD] font-pop">
        <table class="min-w-full w-full  text-sm text-left text-gray-500 ] dark:text-gray-400">
            <thead class="text-xs text-gray-700 border-b bg-[#64707628]  dark:bg-gray-700 dark:text-gray-400 text-[14px]">
                <tr class="text-[#868686]">
                    <th scope="col" class="px-6 py-3  font-medium">
                     No
                    </th>
                    <th scope="col" class="px-4 py-3">
                        <div class="flex items-center  font-medium">
                          Code
                        </div>
                    </th>
                    <th scope="col" class="px-4 py-3">
                        <div class="flex items-center  font-medium">
                          Pengerjaan
                        </div>
                    </th>
                    <th scope="col" class="px-4 py-3">
                        <div class="flex items-center  font-medium">
                            Team
                        </div>
                    </th>
                    <th scope="col" class="px-4 py-3">
                        <div class="flex items-center  font-medium">
                            Keterangan
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <template v-if="workdata.data ">
                <tr class=" border-b dark:bg-gray-800 dark:border-gray-700 text-[16px] font-medium text-black" v-for="(entry,index) in workdata.data" :key="entry.id">
                  <td class="px-6 py-4">
                        {{ index + 1  }}
                    </td>
                    <td class="px-6 py-4">
                        {{ entry.code }}
                    </td>
                    <td class="px-6 py-4">
                        {{  entry.assignment }} {{  entry.assignment_item }}
                    </td>
                    <td class="px-6 py-4">
                        {{ entry.description }}
                    </td>
                </tr>
                </template>
                <tr  v-else>
              
                </tr>
            </tbody>
        </table>
    </div>
        </template>

        <template v-if="activeTab === 'Stock IN'">
        <h3 class="text-lg font-bold text-gray-900 dark:text-white mb-2">Stock In</h3>
        <div class="  first-letter:relative overflow-x-auto border rounded-lg xxs: bg-[#FAFBFD] font-pop">
        <table class="min-w-full w-full  text-sm text-left text-gray-500 ] dark:text-gray-400">
            <thead class="text-xs text-gray-700 border-b bg-[#64707628]  dark:bg-gray-700 dark:text-gray-400 text-[14px]">
                <tr class="text-[#868686]">
                    <th scope="col" class="px-6 py-3  font-medium">
                        No
                    </th>
                    <th scope="col" class="px-6 py-3  font-medium">
                        Code
                    </th>
                    <th scope="col" class="px-6 py-3  font-medium">
                        Material
                    </th>
                    <th scope="col" class="px-4 py-3">
                        <div class="flex items-center  font-medium">
                           Qty
                        </div>
                    </th>
                    <th scope="col" class="px-4 py-3">
                        <div class="flex items-center  font-medium">
                            Qty Terima
                        </div>
                    </th>
                   
                </tr>
            </thead>
            <tbody>
                <template v-if="SIdata.data">
                    <div class="  first-letter:relative overflow-x-auto border rounded-lg xxs: bg-[#FAFBFD] font-pop"></div>
                <tr class=" border-b dark:bg-gray-800 dark:border-gray-700 text-[16px] font-medium text-black" v-for="(entry,index) in SIdata.data" :key="entry.id">
                  <td class="px-6 py-4">
                       {{ index + 1 }}
                    </td>
                    <td class="px-6 py-4">
                        {{entry.code}}
                    </td>
                    <td class="px-6 py-4">
                        {{  entry.material.name }} {{  entry.material.dimension }}
                    </td>
                    <td class="px-6 py-4">
                        {{ entry.po_quantity }}
                    </td>
                    <td class="px-6 py-4">
                        {{ entry.quantity }}
                    </td>
                   
                </tr>
                </template>
                <tr  v-else>
                
                </tr>
            </tbody>
        </table>
    </div>
        </template>

        <template v-if="activeTab === 'Report'">
        <h3 class="text-lg font-bold text-gray-900 dark:text-white ">Laporan</h3>
            
        <form  @submit.prevent="getshift" class="text-white ">
          <button type="button" class=" mr-6 bg-red-500 px-5 py-2 rounded-md" @click="previewReport">Preview Laporan</button>
          <button type="button" class=" bg-red-500 px-5 py-2 rounded-md"  @click="exportReport">Export Laporan</button>
        </form>

        <div v-if="previewUrl" class="mt-4"  style="  animation: muncul-kiri-kanan-fade 1s;">
    <iframe :src="previewUrl" width="100%" height="500px"></iframe>
  </div>

        </template>

        <template v-if="activeTab === 'Progress'">
          <h3 class="text-lg font-bold text-gray-900 dark:text-white ">Proggres</h3>

          <div class="grid xl:grid-cols-5 lg:grid-cols-4  md:grid-cols-3 xs:grid-cols-2 gap-10 justify-between items-center xxs:grid-cols-1 font-pop mt-10">
  <div v-for="category in categorydata.data" :key="category.id" class="relative flex flex-col mt-6 text-gray-700 bg-white shadow-md bg-clip-border rounded-xl w-80 h-80" style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;  animation: muncul-kiri-kanan-fade 1s;"   >
    <div class="relative h-44 mx-4 -mt-6 overflow-hidden text-white shadow-lg bg-clip-border rounded-xl bg-blue-gray-500 shadow-blue-gray-500/40">
  <div class="relative overflow-hidden">
    <div class="flex transition-transform duration-500 ease-in-out" :style="{ 'transform': 'translateX(' + translateX + ')' }">
      <div v-for="(image, index) in images" :key="index" class="w-full h-44 flex-shrink-0">
        <img :src="image.url" alt="Slide Image" class="w-full h-full object-cover">
      </div>
    </div>
  </div>
  </div>
  <div class="px-6 mt-4">
    <h5 class="block mb-2 font-pop text-xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
      {{ category.name }}
    </h5>
    <div class="flex w-full h-4 mb-2 overflow-hidden font-sans text-xs font-medium rounded-full flex-start bg-blue-gray-50">
      <div class="flex items-center justify-center w-1/2 h-full overflow-hidden text-black break-all  rounded-full"   :style="{ width: category.progress + '%', backgroundColor: parseInt(category.progress) === 100 ? '#527853' : '#FAA300' }">
        {{category.progress}}%
      </div>
    </div>  
    <p class="block font-pop  text-sm antialiased font-medium leading-relaxed text-inherit">
      ## Report ini terkait progress dari project <span class="font-bold underline">{{category.project_name}}</span>
      yang dikerjakan oleh team <span class="font-bold underline">{{category.team_name}}</span>
    </p>
  </div>
  <div class="p-6 pt-0 mt-4 flex flex-row justify-between items-center">
    <router-link :to="'/ViewReport/' + category.id"
      class="align-middle select-none font-pop font-bold text-center  transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-2 px-5 rounded-lg bg-red-500 text-white shadow-md shadow-gray-900/10 hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
    >
      View Report
    </router-link>
    <h1 class="font-pop text-sm font-medium">{{ category.date }}</h1>
  </div>
  </div>
  </div>
          
          </template>




</div>

<div v-if="showPreview" class="fixed top-0 left-0 w-full h-full bg-black bg-opacity-70 flex justify-center items-center" @click="showPreview = false">
  <div class="max-w-4xl max-h-4xl overflow-auto">
    <img class="w-full rounded-lg" :src="imageUrl" alt="Employee Photo" style="animation: muncul-kiri-kanan-fade 1s;">
  </div>
</div>


<div v-if="successModal" class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50"  style="  animation: fadein 1s;">
    <div class="bg-white p-8 rounded-lg flex flex-col justify-center items-center  "  style="animation: muncul-kiri-kanan-fade 1s; ">
      <img src="../../assets/verify.png" class="w-16 h-16 mb-6 " alt="">
      <p>Data Karyawan Berhasil ditambahkan</p>
    </div>
  </div>

  <div v-if="errorModal" class="fixed font-pop top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50" style="animation: fadein 1s;">
    <div class="bg-white p-8 rounded-lg flex flex-col justify-center items-center  "  style="animation: muncul-kiri-kanan-fade 1s; ">
      <img src="../../assets/cancel.png" class="w-16 h-16 mb-6 " alt="">
      <p class="  font-semibold w-72 text-center" >{{ message }}</p>
    </div>
  </div>

  
   
  </template>
  
  <script >
  import axios from "../../token";

  export default {
    data() {
      return {
        id: localStorage.getItem('id') || 'Default Value',
        isOpen: false,
        open: "",
        email: "",
        accountStatus:'',
        activeTab: '',
        name:'',
        project1:'',
        status:'',
      showPreview: false,
      mrdata:[],
      start_date:'',
      end_date:'',
      pm1:'',
      name1:'',
      categorydata2:[],
      userdata1:[],
      podata:[],
      status1:'',
      workdata:[],
      SIdata:[],
      isLoading:false,
      successModal:false,
      description:'',
      project2:'',
      errorModal:false,
      description1:'',
      categorydata:[],
      SOdata:[],
      images: [
         { url: 'https://www.iberian-partners.com/wp-content/uploads/2024/01/Gaji-Pelayaran-Bagian-Mesin.webp' },
        { url: 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEgoBq2qgsXRp9aeY_I2tYQdaIBY-BnidvxnN99dpdvS3BzjktrKOoowzCxcYfJfe_KHnocs0tI55Aor2dn0aEZ6TC7aS0NgBWq3XcC9t41Fy2KYnNb3FHyzJMqkjZ8iji-v01AoVSFIVu8/s1024/IMG-20200105-WA0014.jpg',},
        { url: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/06/Kristina_Regina_wheelhouse.jpg/640px-Kristina_Regina_wheelhouse.jpg',},
      ],
        userdata:[],
        imageUrl:null,
        selectedMonth: new Date().getMonth() + 1, // Default to the current month
      selectedYear: new Date().getFullYear(), // Default to the current year
      months: [
        'Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni',
        'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'
      ],
      years: [2022, 2023, 2024], // You can customize the list of years
      };
    },
    components:{
    
    },
    // computed: {
    //   filteredEntries() {
    //     let filtered = this.absendata;
  
    //     if (this.selectedStatus.length > 0) {
    //       filtered = filtered.filter((abse) =>
    //         this.selectedStatus.includes(abse.status)
    //       );
    //     }
  
    //     if (this.selectedSort === "asc") {
    //       return filtered.sort((a, b) => a.jammasuk.localeCompare(b.jammasuk));
    //     } else {
    //       return filtered.sort((a, b) => b.jammasuk.localeCompare(a.jammasuk));
    //     }
    //   },
    // },
    methods: {
      toggleModal() {
        this.isOpen = !this.isOpen;
      },
      openModal() {
        this.isModalOpen = true;
      },
      closeModal() {
        this.isModalOpen = false;
      },
      changeTab(tabName) {
    this.activeTab = tabName;
    localStorage.setItem('activeTab', tabName); 
  },
  applyFilter() {
      
      console.log('Selected Month:', this.selectedMonth);
      console.log('Selected Year:', this.selectedYear);
      this.getabsen();
      
    },
    getcategory() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/project-category`)
          .then((response) => {
            this.categorydata2 = response.data;
  
          })
          .catch((error) => {
            console.log(error);
          });
      },
      getuser1() {
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/hrd/employee`)
          .then((response) => {
            this.userdata1 = response.data;
  
          })
          .catch((error) => {
            console.log(error);
          });
      },
    getso() {
      const materialId =  this.$route.params.id;
      axios
        .get(`${process.env.VUE_APP_BACKEND_HOST}/stock-out`,{
          params:{
            project_id:materialId
          }
        })
        .then((response) => {
          
          this.SOdata = response.data;
         


        })
        .catch((error) => {
          console.log(error);
        });
    },
    getuser() {
      const materialId =  this.$route.params.id;
      axios
        .get(`${process.env.VUE_APP_BACKEND_HOST}/material-take-off`,{
          params:{
            project_id:materialId
          }
        })
        .then((response) => {
          
          this.userdata = response.data;

        })
        .catch((error) => {
          console.log(error);
        });
    },
     
      
     
    async fetchImage(id) {
  try {
    const response = await axios.get(`${process.env.VUE_APP_BACKEND_HOST}/file`, {
      params: {
        id: id,
      },
      responseType: 'arraybuffer',
    });

    const base64Image = btoa(
      new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), '')
    );

    const imageUrl = `data:${response.headers['content-type']};base64,${base64Image}`;

    // Update the imageUrl property for the employee
    this.imageUrl = imageUrl;
    this.isLoading = false;
    // Update the imageUrl property for all shifts
    // this.shiftdata.data.forEach((shift) => {
    //   shift.imageUrl = imageUrl;
    
    // });

  } catch (error) {
    console.error('Error fetching image:', error);
    this.isLoading = false;
  }
},
getproject() {
      const materialId =  this.$route.params.id;
        axios
          .get(`${process.env.VUE_APP_BACKEND_HOST}/project`,{
            params:{
              id:materialId
            }
          })
          .then((response) => {
            this.projectdata = response.data;
        
            if (this.projectdata && this.projectdata.data.length > 0) {
            const firstEntry = this.projectdata.data[0]; 
            this.project_id=firstEntry.code || '';
            this.name=firstEntry.name || '';
            this.name1=firstEntry.name || '';
            this.date1=firstEntry.start_date || '';
            this.date2=firstEntry.end_date || '';
            this.start_date=firstEntry.start_date || '';
            this.end_date=firstEntry.end_date || '';
            this.pm=firstEntry.pm.name || '';
            this.pm1=firstEntry.pm.id || '';
            this.project1=firstEntry.category.name
            this.project2=firstEntry.category.id
            this.status=firstEntry.status
            this.status1=firstEntry.status
            this.description=firstEntry.description
            this.description1=firstEntry.description
            this.fetchImage(firstEntry.photo);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      },
      getSI() {
    const materialId =  this.$route.params.id;
      axios
        .get(`${process.env.VUE_APP_BACKEND_HOST}/stock-in`,{
          params:{
            project_id:materialId
          }
        })
        .then((response) => {
          this.SIdata = response.data;
          
        })
        .catch((error) => {
          console.log(error);
        });
  },
  getmr() {
      const materialId =  this.$route.params.id;
      axios
        .get(`${process.env.VUE_APP_BACKEND_HOST}/material-request`,{
          params:{
            project_id:materialId
          }
        })
        .then((response) => {
          
          this.mrdata = response.data;

        })
        .catch((error) => {
          console.log(error);
        });
    },
    getcategory1() {
      axios
        .get(`${process.env.VUE_APP_BACKEND_HOST}/project/report/list`,{
          params:{
            filter:'project',
            filter_value:this.$route.params.id
          }
        })
        .then((response) => {
          this.categorydata = response.data;

        
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getpo() {
      const materialId = this.$route.params.id;
      axios
        .get(`${process.env.VUE_APP_BACKEND_HOST}/purchase-order`,{
          params:{
            project_id:materialId
          }
        })
        .then((response) => {
      this.podata = response.data;

      
      
    })
        .catch((error) => {
          console.log(error);
        });
    },
    getwork() {
      axios
        .get(`${process.env.VUE_APP_BACKEND_HOST}/work-order/list`,
        {
          params:{
            filter:'project',
            filter_value :this.$route.params.id,
            
          }
        })
        .then((response) => {
          this.workdata = response.data;
      

        })
        .catch((error) => {
          console.log(error);
        });
    },
    getShift() {
    this.uploading = true; 
    axios
      .get(`${process.env.VUE_APP_BACKEND_HOST}/project/tracking-stock/report`, {
        responseType: 'blob',
        params: {
          project_id: this.$route.params.id,
        }
      })
      .then((response) => {
        this.uploading = false; 
        const blob = new Blob([response.data], { type: 'application/pdf' });
        this.previewUrl = window.URL.createObjectURL(blob);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  previewReport() {
    this.getShift();
  },

  exportReport() {
    if (this.previewUrl) {
      // Assuming you have a library or method to handle file downloads
      this.downloadFile(this.previewUrl, 'report.pdf');
    } else {
      this.getShift();
    }
  },

  downloadFile(url, filename) {
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;

    // Append the link to the body and trigger the click event
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
  },
  async submitForm1() {
    try {
        const formData = new FormData();
        formData.append('name', this.name1);
        formData.append('pm', this.pm1);
        formData.append('category', this.project2);
        formData.append('start_date', this.start_date);
        formData.append('end_date', this.end_date);
        formData.append('status', this.status1);
        formData.append('description', this.description1);
        formData.append('id',this.$route.params.id),
        formData.append('_method','put')

        // if (this.$refs.fileInput.files.length > 0) {
        //     formData.append('photo', this.$refs.fileInput.files[0]);
        // }

        const response = await axios.post(`${process.env.VUE_APP_BACKEND_HOST}/project`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        console.log(response);
        
        this.successModal = true;

     

        setTimeout(() => {
            this.successModal = false;
            this.open = false;
            window.location.reload()
        }, 1000);
    } catch (error) {
        console.log(error);
        this.message = error.response.data.message;
        this.errorModal = true;
        setTimeout(() => {
          this.errorModal = false;
        }, 3000);
        }
},
    },
    mounted(){
    
      this.activeTab ='mto'
     
      this.getso()
      this.getproject()
      this.getwork()
      this.getShift()
      this.getcategory()
      this.getcategory1()
      this.getmr()
      this.getSI()
      this.getpo()
      this.getuser1()
      this.getuser()
     
    },
    created() {
  const storedTab = localStorage.getItem('activeTab');
  if (storedTab) {
    this.activeTab = storedTab;
  } else {
    this.activeTab = 'mto';
  }
},
  };
  </script>