<template>
  <div>
    <vue3-chart-js v-bind="{ ...barChart }" :style="myStyles" />
  </div>
</template>

<script>
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";

export default {
  name: 'BarChart',
  components: {
    Vue3ChartJs,
  },
  setup() {
    const barChart = {
      type: "bar",
      data: {
        labels: ["20-02-2024", "20-02-2024", "20-02-2024", "20-02-2024", "20-02-2024", "20-02-2024", "20-02-2024", "20-02-2024","20-02-2024","20-02-2024","20-02-2024","20-02-2024"],
        datasets: [
          {
            label: 'Tidak Hadir/Cuti/Sakit/Izin',
            backgroundColor: ["#9d5252", "#9d5252", "#9d5252", "#9d5252", "#9d5252", "#9d5252", "#9d5252", "#9d5252","#9d5252","#9d5252","#9d5252","#9d5252"],
            data: [30, 10, 60, 15, 10, 10, 10, 10,10,10,10,10],
            borderRadius: 5,
            barThickness: 48,
          },
          {
            label: 'Hadir',
            backgroundColor: ["#c94d4d", "#c94d4d", "#c94d4d", "#c94d4d", "#c94d4d", "#c94d4d", "#c94d4d", "#c94d4d","#9d5252","#9d5252","#9d5252","#9d5252"],
            data: [40, 20, 80, 10, 10, 10, 10, 10,10,10,10,10],
            borderRadius: 5,
            barThickness: 48,
            lineTension: 0,
          },
        ],
      },
      options: {
        indexAxis: 'x',
        cutoutPercentage: 50,
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            stacked: true,
            display: true,
            barPercentage: 0.8,
            categoryPercentage: 0.1,
            position: 'right',
          },
          y: {

            stacked: false,
            position: 'right',
            max: 100,
            min: 0,
            beginAtZero: true,
          },
        },
        plugins: {
          legend: {
            position: 'top',
            
            labels: {
              display: true,
              font: {
              family: 'Poppins', 
              size: 12
            },
            
            },
          },
        },
      },
    };

    return {
      barChart,
    };
  },
};
</script>

<style scoped>
/* Tambahkan gaya CSS jika diperlukan */
</style>
